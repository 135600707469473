import apiRequest from './apiRequest.js';
import apiRequestV1 from './apiRequestV1.js';

import { withParams, withFilters } from '../helpers/apiHelper.js';

export const createSubscribe = async (payload) => {
  return apiRequest.post('payments/create-student-subscribe', payload);
}

export const getSubscribes = async () => {
  return apiRequest.get('payments/student-subscription-prices');
}

export const createSupport = async (payload) => {
  return apiRequest.post('payments/create-support', payload);
}

export const cancelRecurrentPayment = (id, type = 'student_subscription') => {
  return apiRequest.delete(`payments/saved/${id}/${type}`);
}

export const getAuthors = () => {
  return apiRequest.get('authors/filter');
}

export const paymentCreate = (data) => {
  return apiRequest.post('payments/create', data);
}

export const getPooledSubscriptions = () => {
  return apiRequest.get('subscriptions/pooled').then(res => res.data)
}

export const createPooledSubscribe = (payload) => {
  return apiRequest.post('payments/create-pooled-subscribe', payload)
}

export const joinGroupBySubscribe = (id) => {
  return apiRequest.post(`groups/join-by-subscribe/${id}`)
}
