<template>
  <div class="px-5 lg:px-0">
    <div class="v-text--bold text-lg mt-5 mb-3 lg:hidden">{{ $t('home.join_game') }}</div>
    <div class="w-full flex flex-col items-center justify-center lg:hidden">
      <input class="v-input mb-3" type="text" :placeholder="$t('home.enter_game_code')" v-model="activityCode">
      <div class="v-button v-button--gradient-red" @click="connectActivity">{{ $t('home.join') }}</div>
    </div>

    <div class="v-text--bold text-lg mt-5 mb-3 lg:hidden">{{ $t('home.add_a_group') }}</div>
    <div class="flex items-start justify-between">
      <div class="flex items-center lg:items-start w-full lg:w-2/3 flex-col lg:flex-row">
        <div class="lg:mr-5 w-full lg:w-2/3 mb-5 lg:mb-0">
          <input class="v-input w-full" :placeholder="$t('groups.enter_the_group_code')" type="text" v-model="code" @input="error = false">
          <div class="pl-3 w-full">
            <!-- <div v-if="error" class="v-text--red mt-2">Fiil in code</div> -->
            <div class="flex mt-4 items-center">
              <img class="h-6 mr-3" src="@/assets/iii-xxxhdpi.png" alt="">
              <div class="v-text--gray">{{ $t('groups.ask_teacher_code') }}</div>
            </div>
          </div>
        </div>
        <div class="v-button v-button--gradient-red" @click="joinGroup">{{ $t('groups.activate') }}</div>
      </div>

      <div class="flex justify-end" v-if="isProfile">
        <div class="v-button v-button--gradient-red mr-5" v-if="!isEditing" @click="$emit('edit')">{{ $t('home.сhange_details') }}</div>
        <div class="v-button v-button--raised-red mr-5" v-if="isEditing" @click="$emit('cancel')">{{ $t('groups.cancel') }}</div>
        <div class="v-button v-button--gradient-red" v-if="isEditing" @click="$emit('save')">{{ $t('home.save') }}</div>
      </div>
    </div>

    <div class="mt-10 mb-10 flex-grow w-full" v-if="groups">
      <div class="v-text--bold text-lg">{{ $t('groups.your_groups') }}</div>
      <div class="v-group-list">
        <div class="flex items-center justify-between my-3 v-group-list__row" v-for="group in groups" :key="group.id">
          <div class="flex items-center">
            <img class="h-8 mr-5" src="@/assets/group-xxxhdpi.png" alt="">
            <div class="v-text--gray text-base">{{ group.name }}</div>
          </div>
          <img class="h-5 cursor-pointer v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="handleLeaveGroup(group)">
        </div>
      </div>
    </div>

    <div v-else class="flex justify-center py-20">
      <loader />
    </div>
  </div>
</template>

<script>
import { addToGroupStudent as joinGroupApi, leaveGroup as leaveGroupApi } from '../api/user';
import { mapState, mapMutations, mapActions } from 'vuex';

import io from "socket.io-client";

export default {
  props: {
    isProfile: Boolean,
    isEditing: Boolean,
  },
  mounted() {
    if (!this.groups) {
      this.$store.dispatch('GET_GROUPS');
    }
    this.socket = io((process.env.GAMES_SOCKET_URL) + '/stuff');
    this.socket.on('checkRoomCode', (payload) => {
      if (!payload) {
        this.$root.$emit('v-modal', {
          text: this.$t('groups.check_activity_code', { code: this.activityCode }),
          error: true,
        });
      } else {
        let GAMES_URL = process.env.GAMES_URL;
        let NAV_URL = GAMES_URL + '/run' + payload.path + `?number_code=${payload.number_code}`;
        this.$router.push({ name: 'activity-frame', params: { ...this.$route.params, link: NAV_URL } })
      }
    })
  },
  data: () => ({
    code: '',
    error: false,
    activityCode: '',
    socket: null,
  }),
  computed: {
    ...mapState({
      groups: state => state.user.groups,
      profile: state => state.user.profile,
    })
  },
  methods: {
    ...mapMutations(['SET_BUSY_STATE']),
    ...mapActions(['GET_GROUPS', 'GET_PROFILE_DATA']),

    connectActivity() {
      if (!this.activityCode) this.$root.$emit('v-modal', { error: true, text: this.$t('groups.enter_code') });
      else {
        this.socket.emit('checkRoomCode', this.activityCode);
      }
    },

    // Присоидениться к группе
    async joinGroup() {
      if (!this.code) {
        this.$root.$emit('v-modal', { error: true, text: this.$t('groups.enter_code') });
        this.error = true;
        return;
      }
      try {
        this.SET_BUSY_STATE(true);

        try {
          const { data } = await joinGroupApi({
            student_id: this.profile.student_id,
            code: this.code,
          })


          await this.GET_GROUPS();
          await this.GET_PROFILE_DATA(true);

          this.$root.$emit('v-modal', {
            text: this.$t('groups.the_group_was_added'),
            size: 'sm',
            buttons: [{
              text: this.$t('groups.go_to_your_lessons'),
              class: 'v-button v-button--gradient-red',
              callback: () => {
                this.$router.push({ name: 'home', params: { group_id: data.data.id } })
                this.$root.$emit('v-modal', { close: true });
              }
            }]
          });
        } catch (err) {
          this.$root.$emit('v-modal', {
            text: this.$t('groups.incorrect_group_code'),
            size: 'sm',
          });
        }
      } catch(err) {
        console.log(err);
      } finally {
        this.SET_BUSY_STATE(false);
        this.code = '';
      }
    },

    handleLeaveGroup(group) {
      this.$root.$emit('v-modal', {
        text: `${this.$t('groups.sure_delete_group')} ${group.name}?`,
        size: 'sm',
        buttons: [
          {
            text: this.$t('groups.cancel'),
            class: 'v-button v-button--raised-red',
            callback: () => {
              this.$root.$emit('v-modal', { close : true });
            }
          },
          {
            text: this.$t('groups.remove'),
            class: 'v-button v-button--gradient-red',
            callback: () => {
              this.$root.$emit('v-modal', { close : true });
              this.leaveGroup(group.id);
            }
          },
        ]
      })
    },
    async leaveGroup(group_id) {
      console.log(group_id, this.profile.student_id)
      try {
        this.SET_BUSY_STATE(true);
        const { data } = await leaveGroupApi({
          group_id,
          student_id: this.profile.student_id,
        })
        await this.GET_GROUPS();
        await this.GET_PROFILE_DATA(true);
      } catch (err) {
        console.log(err);
      } finally {
        this.SET_BUSY_STATE(false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-group-list {
  padding-left: 20px;
  border-left: 2px solid rgb(205, 205, 205);

  &__row {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: -26px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background-color: rgb(205, 205, 205);
      border-radius: 50%;
    }
  }
}

.v-input {
  text-transform: uppercase !important;

  &::placeholder {
    text-transform: none !important;
  }
}
</style>
