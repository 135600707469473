<template>
	<transition name="fade" mode="out-in">
	  <div class="v-modal" v-if="show" @click.self="show = false">
  		<div class="v-modal__wrapper">
  			<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false">
        <video :src="getVideoLink" autoplay controls/>
  		</div>
  	</div>
	</transition>
</template>

<script>
import { ref} from "vue";
import useEventBus from "@/composables/useEventBus.js";

export default {
  name: 'banner-modal',
  data: () => ({
    params: {},
  }),
  setup() {
    const emitter = useEventBus();
    const show = ref(false);

    emitter.on('custom-modal', (params = {}) => {
      show.value = true;
      this.params = { ...params };
    })
    return {
      show
    }
  },
  computed: {
    getVideoLink() {
      switch (this.params.id) {
        case 1: return '/images/video_quest_1.mp4'
        case 2: return '/images/video_quest_2.mp4'
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  form {
    width: 90%;
  }

  &__wrapper {
    background-size: cover;
    border-radius: 12px;

    width: 900px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }

    &.sm {
      width: 350px;
      min-height: 250px;
    }

    &.md {
      width: 450px;
      min-height: 250px;
    }

    &.lg {
      width: 600px;
      min-height: 350px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    cursor: pointer;
    z-index: 1;
  }
}

@media screen and (max-width: 700px) {
	.v-modal__form {
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.v-modal {
		padding: 25px;
	}
	input::placeholder {
		font-size: 16px;
	}
}
</style>
