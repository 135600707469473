<script>
import bgDefault from '@/assets/mobile_new/bg_default.png'
import { mapGetters } from 'vuex'

export default {
	name: 'NewMobileBgWrapper',
	computed: {
		...mapGetters(['isNewMobileApp']),
		bgDefaultPng() {
			return bgDefault
		},
	},
}
</script>

<template>
	<div class="new-mobile-bg-wrapper-component">
		<div
			v-if="isNewMobileApp"
			class="new-mobile-bg-wrapper-component__bg"
			:style="{
				backgroundImage: `url(${bgDefaultPng})`,
			}"
		/>

		<slot />
	</div>
</template>

<style scoped lang="scss">
.new-mobile-bg-wrapper-component {
	&__bg {
		width: 100vw;
		height: 100vh;
		position: fixed;
		background-color: #263c70;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
</style>
