<template>
  <div class="w-full flex flex-wrap justify-center">
    <div v-for="(symbol, symbolIndex) in text.split('')" :key="symbolIndex" :id="`character-target-div-${symbolIndex}`" class="transparent"></div>
  </div>
</template>

<script>
import HanziWriter from "hanzi-writer";
export default {
  props: {
    text: String,
  },
  data() {
    return {
      chineseWriters: [],
    }
  },
  watch: {
    async text() {
      await this.$nextTick();
      this.setChineseAndAnimate();
    }
  },
  mounted() {
    this.setChineseAndAnimate();
  },
  methods: {
    /**
     * Очистить все svg с ввода или показа символов
     */
    resetAllChildNodes() {
      this.done = [];
      document.querySelectorAll("[id*='character-target-div'] svg").forEach(node => {
        node.remove();
      })
    },

    /**
     * Отрисовать символы
     */
    async setChineseAndAnimate() {
      // Сброс старых
      this.resetAllChildNodes();
      await this.$nextTick();

      // Ширина символа от ширины контейнера, по умоланию
      let width = 300 / this.text.split('').length;

      if (width > 100) width = 100; // Ширина не больше 100
      else if (width < 30) width = 50; // И не меньше 50

      // Если это тест - то 1/3 ~= 90
      if (this.input) {
        width = 140;
      }

      this.chineseWriters = this.text.split('').map((e, i) => {
        return HanziWriter.create(`character-target-div-${i}`, e, {
          width: width,
          height: width,
          padding: 5,
          // strokeAnimationSpeed: 3,
          delayBetweenStrokes: 5,
          showCharacter: true,
          showOutline: true,
        });
      })

      // Анимировать в выучи слово
      setTimeout(() => {
        // Если длинна 2 - то анимируем по очереди
        if (this.chineseWriters.length === 2) {
          this.chineseWriters[0].animateCharacter({
            onComplete: () => {
              this.chineseWriters[1].animateCharacter();
            }
          })
        } else { // иначе - анимируем все сразу
          this.chineseWriters.forEach(w => {
            w.animateCharacter();
          })
        }
      }, 500);
    }
  }
}
</script>

<style>

</style>
