<template>
	<div class="parent-print mx-auto my-5" style="width: 1100px">
		<div v-if="isLoading" class="flex justify-center py-20">
			<loader />
		</div>

		<div v-else-if="learnedWordsPageData">
			<div class="my-10 no-print">
				<div class="inline-flex items-center cursor-pointer mb-5" @click="handleBack">
					<img src="@/assets/btn-back.png" alt="" class="mr-3" />
					<span style="color: #c95d48">{{ $t('lesson.back') }}</span>
				</div>
				<div class="flex justify-between">
					<div class="v-text--title-bold">{{ $t('lesson.my_dictionary') }}</div>
					<div class="flex items-center">
						<input
							type="text"
							:placeholder="$t('home.search_by_name')"
							class="v-input"
							v-model="searchText"
							v-if="!$route.params.id"
						/>
						<!-- <v-select style="width: 200px" :options="getGroupsOptions" :selected.sync="selectedGroup" v-if="groups && groups.length > 0 && !$route.params.id" /> -->
					</div>
				</div>
			</div>
			<div class="print-block mx-auto">
				<div
					class="v-question"
					style="
						width: 50%;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						margin-bottom: 0px;
					"
					v-for="word in getWords"
					:key="word.id"
				>
					<div class="card" style="display: inline-flex; align-items: center">
						<span
							class="with-border-right"
							style="display: inline-block; margin-right: 15px; height: 100%; padding-right: 10px"
						>
							<div
								style="
									display: inline-flex;
									width: 220px;
									justify-content: center;
									flex-direction: column;
									align-items: center;
								"
							>
								<div style="width: 100%; text-align: center">
									<div>{{ word.text }}</div>
									<template v-if="word.pinyin">
										<div class="divider"></div>
										<div>{{ word.pinyin }}</div>
									</template>
								</div>
							</div>
						</span>
						<div class="no-print listen-button v-clickable cursor-pointer" @click="playSound(word)">
							<img style="min-width: 25px" src="/images/player.png" alt="" />
						</div>
						<span style="display: inline-block; height: 100%; padding-left: 10px">
							<div
								style="
									display: inline-flex;
									width: 220px;
									justify-content: center;
									flex-direction: column;
									align-items: center;
								"
							>
								<img
									:class="{ 'mb-3': word.illustration }"
									onerror="this.remove()"
									style="
										max-width: 100%;
										max-height: 100px;
										display: block;
										margin-left: auto;
										margin-right: auto;
									"
									v-if="word.illustration"
									:src="word.illustration"
									alt=""
								/>
								<div style="width: 100%; text-align: center" v-html="word.translate" />
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div
			class="flex items-center my-5 no-print"
			v-if="learnedWordsPageData && learnedWordsPageData.meta.total > searchFilter.perPage"
		>
			<pagination @getEmit="onPageChange" :hide-url="true" ref="pager" />
			<v-select
				class="ml-auto"
				style="max-width: 110px"
				:options="getPerPageOptions"
				:selected="searchFilter.perPage"
				@select="loadWordsData"
			/>
		</div>

		<div class="flex justify-center my-5 no-print">
			<div class="v-button v-button--gradient-red" @click="print">Print</div>
		</div>
	</div>
</template>

<script setup>
import { getLessonWords } from '@/api/lessons.js'
import { getLearnedWords } from '@/api/user.js'

import VSelect from '@/components/Selector.vue'
import Pagination from '@/components/Pagination.vue'
import { useStore } from 'vuex'
import debounce from 'lodash/debounce'
import { computed, nextTick, reactive, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const isLoading = ref(false)
const learnedWordsPageData = ref(null)
const searchFilter = reactive({
	page: 1,
	perPage: 50,
})
const pager = ref(null)
const selectedGroup = ref(0)
const searchText = ref('')
const store = useStore()
const route = useRoute()
const router = useRouter()
const groups = computed(() => store.getters.groups)
const loadWordsDataDebounce = ref(null)
const optionsGroup = computed(() => {
	return [{ key: 0, value: this.$t('home.all_groups') }]
})
const getGroupsOptions = computed(() => {
	if (!groups) return []
	return [...optionsGroup, ...groups.map((group, index) => ({ key: group.id, value: group.name }))]
})
const getWords = computed(() => {
	if (!learnedWordsPageData.value) return []
	return learnedWordsPageData.value.data
})
const getPerPageOptions = computed(() => {
	return [
		{ key: 10, value: 10 },
		{ key: 50, value: 50 },
		{ key: 100, value: 100 },
	]
})

watch(searchText, (value) => {
	if (!!value) {
		// $set(searchFilter, 'searchBy', 'text');
		// $set(searchFilter, 'searchText', value);
		searchFilter.page = 1
		loadWordsDataDebounce()
	} else {
		delete searchFilter.searchBy
		delete searchFilter.searchText
		searchFilter.page = 1
		loadWordsDataDebounce()
	}
})

const playSound = (word) => {
	if (word.audio) {
		const audio = new Audio(word.audio)
		audio.play()
	}
}
const handleBack = () => {
	if (route.params.id && route.params.group_id) {
		router.push({ name: 'lesson', params: { id: route.params.id }, query: { group_id: route.params.group_id } })
	} else {
		router.push({ name: 'home' })
	}
}
const loadWordsData = async () => {
	try {
		isLoading.value = true
		const { data } = route.params.id
			? await getLessonWords(route.params.id, searchFilter)
			: await getLearnedWords(searchFilter)
		learnedWordsPageData.value = data

		await nextTick()
		pager && pager.pageGenerate(searchFilter.page, learnedWordsPageData.value.meta.last_page, searchFilter.perPage)
	} catch (err) {
		console.log('err', err)
	} finally {
		isLoading.value = false
	}
}
const onPageChange = (page) => {
	searchFilter.page = page
	loadWordsData()
}
const print = () => {
	window.print()
}

const created = async () => {
	loadWordsDataDebounce.value = debounce(loadWordsData, 800)
	await store.dispatch('GET_GROUPS')
	loadWordsData()
}

created()
</script>

<style lang="scss" scoped>
.parent-print {
	@media print {
		background-color: #fff !important;
	}
}
.listen-button:hover {
	filter: grayscale(100%) brightness(62%) sepia(100%) hue-rotate(65deg) saturate(1000%) contrast(0.8);
}
.with-border-right {
	@media print {
		border-right: 1px solid #d3d3d375;
	}
}
.print-block {
	width: 1100px;
	background-color: #fff;
	// filter: drop-shadow(0px 0px 5px #00000033);
	border-radius: 6px;
	padding: 10px;
}

.v-question {
	display: inline-block;
	margin-bottom: 20px;
	page-break-inside: avoid;
	width: 50%;
	padding: 10px 20px;
	vertical-align: top;

	& * {
		-webkit-print-color-adjust: exact !important;
	}
}
.card {
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	border: 2px solid lightgray;
	border-top: 3px solid #009ee0;
	border-radius: 5px;
	padding: 10px;
	min-height: 60px;
}
.divider {
	width: 90%;
	margin: 5px auto;
	height: 1px;
	background-color: lightgray;
}
.v-input {
	height: 38px;
	border: 2px solid #009ee0;
	border-radius: 24px;
	padding: 5px 30px;
	padding-right: 20px;
	cursor: pointer;
	z-index: 2;
	position: relative;
}
</style>
