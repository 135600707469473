import apiRequestV1 from './apiRequestV1.js'
import apiRequest from './apiRequest.js'
import { withParams, withFilters, convertToFormData } from '../helpers/apiHelper.js'

const base = '/api/v2'

async function withErrorHandling(asyncFunc) {
	try {
		return await asyncFunc()
	} catch (err) {
		console.log('Ошибка:')
		return []
	}
}

async function messagesGetUnreadCount(data) {
	const endpoint = `${base}/messages/unread-count`
	return await apiRequestV1.get(endpoint, { params: data })
}

async function messagesGetList(data) {
	return withErrorHandling(async () => {
		const endpoint = `/users/get-teachers-with-chat-permissions`
		const res = await apiRequest.get(endpoint, { params: data })
		return res?.data ?? []
	})
}

async function getMessages(userId, page = 1) {
	return withErrorHandling(async () => {
		const endpoint = `/messages/filter?user_id=${userId}&page=${page}&perPage=20`
		const res = await apiRequest.get(endpoint)
		console.log('res', res)
		return res?.data ?? []
	})
}

async function sendMessage(data) {
	return withErrorHandling(async () => {
		const endpoint = `/messages/send`
		const res = await apiRequest.post(endpoint, data)
		return res?.data ?? []
	})
}

async function readMessage(userId) {
	return withErrorHandling(async () => {
		const endpoind = `/messages/read-all/${userId}`
		const res = await apiRequest.post(endpoind)
		return res?.data ?? []
	})
}

async function uploadFiles(data) {
	return withErrorHandling(async () => {
		const endpoint = `/illustrations`
		const res = await apiRequest.post(endpoint, data)
		return res?.data ?? []
	})
}

async function deleteMessage(messageId) {
	return withErrorHandling(async () => {
		const endpoint = `/messages/${messageId}`
		const res = apiRequest.delete(endpoint)
		return res?.data ?? []
	})
}

async function editMessage(messageId, text) {
	return withErrorHandling(async () => {
		const endpoint = `/messages/${messageId}`
		const res = await apiRequest.put(endpoint, { text })
		return res?.data ?? []
	})
}

export default {
	messagesGetUnreadCount,
	messagesGetList,
	getMessages,
	sendMessage,
	readMessage,
	uploadFiles,
	deleteMessage,
	editMessage,
}
