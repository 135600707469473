<template>
  <transition name="fade">
    <div class="v-modal" v-if="show" @click.self="show = false">
      <div class="v-modal__wrapper">
        <div class="v-modal__content">
          <!-- #######  YAY, I AM THE SOURCE EDITOR! #########-->
          <p><strong>Пользовательское соглашение</strong></p>
          <ol>
              <li><strong> Общие положения</strong></li>
          </ol>
          <p><span style="font-weight: 400;">1.1. Настоящее Пользовательское соглашение (далее - "Соглашение") устанавливает условия использования сайта &ldquo;ВЗНАНИЯ&rdquo; (Далее - &ldquo;Сайт&rdquo;), </span><span style="font-weight: 400;">комплекс программных средств, размещенных на web-сервере www.vznaniya.ru, предназначенных для технического обеспечения услуг Сайта и </span><span style="font-weight: 400;">мобильного приложения "ВЗНАНИЯ" для iOS, Android, (далее - "Приложение"), размещенного на сайте онлайн-магазина приложений Google Play и App Store, и регулирует политику, а также процедурные аспекты сбора, использования и распространения персональных данных через Приложение (далее - "Данные", а в совокупности - "База Данных").</span></p>
          <p><span style="font-weight: 400;">1.2. В настоящем Соглашении, если из контекста не следует иное, нижеприведенные термины с заглавной буквы имеют следующие значения:</span></p>
          <p><span style="font-weight: 400;">1.2.1. ИП Бердин Павел Генадьевич, ОГРНИП </span><span style="font-weight: 400;">317028000131462</span><span style="font-weight: 400;"> (далее "Правообладатель") -&nbsp; обладатель исключительного права на основании лицензии на все объекты, доступные при помощи Сайта и Приложения по созданию и функционированию сайта &ldquo;ВЗНАНИЯ&rdquo; и мобильного приложения "ВЗНАНИЯ" для iOS, Android, в том числе элементы дизайна, графические изображения, иллюстрации, программы для ЭВМ, Данные, Базы данных и другие объекты (далее - "Содержание Приложения");</span></p>
          <p><span style="font-weight: 400;">1.2.2.&nbsp; "Персональные данные" - информация, которая дает возможность идентифицировать лицо, а также информация о деятельности лица, например, об использовании Приложения, если такое использование прямо связанно с информацией, позволяющей установить личность Пользователя;</span></p>
          <p><span style="font-weight: 400;">1.2.3. "Пользователь" - физическое лицо, зарегистрировавшееся на Сайте или в Приложении, осуществляющее его использование в соответствии с основными назначениями Приложения и присоединившееся к Соглашению в установленном порядке.</span></p>
          <p><span style="font-weight: 400;">1.2.4. "Администратор" - физическое лицо, действующее от имени Правообладателя и обладающий правами использования, управления и распоряжения Приложением, предоставляющий Приложение в пользование Пользователям и оказывающее Пользователям услуги, предусмотренные настоящим Соглашением. От имени Администратора в Приложении действует его уполномоченный представитель (модератор), назначаемый непосредственно Администратором Приложения.</span></p>
          <p><span style="font-weight: 400;">1.3. Пользователи также могут получать через свой аккаунт в Приложении персонализированную информацию о личных предложениях, основанных, в том числе, на предпочтениях Пользователя, явно или косвенно обозначенных им при использовании Приложения.</span></p>
          <p><span style="font-weight: 400;">1.4. Вся существующая в настоящий момент функциональность Сайта и Приложения, а также любое развитие и/или добавление новых функций является предметом настоящего Соглашения и дополнений к нему.&nbsp;</span></p>
          <p><span style="font-weight: 400;">1.5. Использование Сайта или Приложения Пользователем на условиях настоящего Соглашения осуществляется в личных некоммерческих целях. Для использования Приложения иными способами и для других целей Пользователю необходимо предварительно получить письменное согласие от Правообладателя на такое использование.</span></p>
          <p><span style="font-weight: 400;">1.6. Используя Сайт или Приложение, Пользователь соглашается с настоящим Пользовательским соглашением.&nbsp;</span></p>
          <p><span style="font-weight: 400;">1.7. Регистрируя и используя Аккаунт на Сайте или в Приложении, Пользователь позволяет сбор, использование и распространение своих персональных данных в соответствии с настоящей Пользовательским соглашением и применимым законодательством.&nbsp;</span></p>
          <ol start="2">
              <li><strong> Право на использование Приложения, Данных и Базы данных.</strong></li>
          </ol>
          <p><span style="font-weight: 400;">2.1. Правообладатель на условиях исключительной лицензии предоставляет Пользователю непередаваемое право использования Содержания Сайта или Приложения только в рамках функциональности Сайта или Приложения на территории всех стран мира следующими способами:&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.1.1. использовать по прямому функциональному назначению через сайт или Приложение;&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.1.2. в указанных выше целях произвести копирование и установку (воспроизведение) Приложения на устройство (-ва) Пользователя.&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.2. За исключением использования в объёмах и способами, прямо предусмотренными настоящим Соглашением или законодательством РФ, Пользователь не имеет права изменять, декомпилировать, дизассемблировать, дешифровать и производить иные действия с объектным кодом Сайта или Приложения, имеющие целью получение информации о реализации алгоритмов, используемых на Сайте или в Приложении, извлекать Данные из Базы Данных, создавать производные произведения с использованием Сайта, Приложения или Базы Данных, а также осуществлять (разрешать осуществлять) иное использование Сайта, Приложения или Базы Данных, любых их компонентов, а также хранимых Сай&nbsp; &nbsp; &nbsp; &nbsp; </span> <span style="font-weight: 400;">том, Приложением на устройстве Пользователя данных без письменного согласия Правообладателя.&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.3. Пользователь не имеет право воспроизводить и распространять Сайт, Приложение или Базу Данных в коммерческих целях (в том числе за плату), в составе сборников программных продуктов, без письменного согласия Правообладателя.&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.4. Пользователь не имеет права распространять Сайт, Приложение в виде, отличном от того, в котором он его получил, без письменного согласия Правообладателя.&nbsp;</span></p>
          <p><span style="font-weight: 400;">2.5. Любое извлечение, копирование, воспроизведение, переработка Данных, Базы данных, Содержания Сайта, Приложения, распространение, доведение до всеобщего сведения в сети Интернет, любое использование в программах и сервисах третьих лиц, средствах массовой информации и/или в коммерческих целях без предварительного письменного разрешения Правообладателя запрещается, за исключением случаев, прямо предусмотренных настоящим Соглашением и законодательством Российской Федерации.&nbsp;</span></p>
          <ol start="3">
              <li><strong> Регистрация Пользователя</strong></li>
          </ol>
          <p><span style="font-weight: 400;">3.1. Для того чтобы воспользоваться функциями Сайта, Приложения, Пользователю необходимо пройти процедуру регистрации, в результате которой для Пользователя будет создана уникальная учетная запись (далее - "Аккаунт"). В дальнейшем для использования функций Сайта, Приложения, Пользователь должен пройти процедуру авторизации.</span></p>
          <p><span style="font-weight: 400;">3.2. Для регистрации Пользователь вводит следующие данные в регистрационную форму:</span></p>
          <p><span style="font-weight: 400;">3.2.1. Имя, Фамилия, Отчество;</span></p>
          <p><span style="font-weight: 400;">3.2.2. Достоверный адрес e-mail;</span></p>
          <p><span style="font-weight: 400;">3.2.3. Пароль (на свое усмотрение);</span></p>
          <p><span style="font-weight: 400;">3.2.4. Город;</span></p>
          <p><span style="font-weight: 400;">3.2.5 .Образовательная организация</span></p>
          <p><span style="font-weight: 400;">3.2.6. Иные данные.</span></p>
          <p><span style="font-weight: 400;">3.3. Введенные Пользователем данные передаются на централизованный сервер базы данных для хранения информации.</span></p>
          <p><span style="font-weight: 400;">3.4. Информация Пользователя, содержащаяся в Аккаунте Пользователя, хранится и обрабатывается в соответствии с условиями Политики конфиденциальности.&nbsp;</span></p>
          <p><span style="font-weight: 400;">3.5. Пользователь обязан немедленно уведомить Администратора и/или уполномоченного представителя (модератора) о любом случае несанкционированного (не разрешенного Пользователем) доступа к Сайту или Приложению с использованием Аккаунта Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи.&nbsp;</span></p>
          <p><span style="font-weight: 400;">3.6. Пользователь может прекратить использование Сайта или Приложения путем его удаления из памяти мобильного телефона. Если Пользователь хочет удалить Аккаунт, то ему необходимо это сделать на Сайте или в Приложении в настройках.</span></p>
          <p><span style="font-weight: 400;">3.7 Пользователь, зарегистрированный на Сайте в роли Учителя, имеет право просматривать результаты и статистику учеников, зарегистрированных в той же образовательной организации.</span></p>
          <p><span style="font-weight: 400;">3.8 Пользователь, зарегистрированный на Сайте в роли Учителя, несёт ответственность за материалы, созданные или размещённые Пользователем на Сайте. Данные материалы не должны вступать в противоречие с требованиями законодательства Российской Федерации и общепринятых норм морали и нравственности.</span></p>
          <p><span style="font-weight: 400;">3.9 Пользователь, зарегистрированный на Сайте в роли Ученика, автоматически разрешает идентифицированным и зарегистрированным в его образовательной организации Учителям просматривать свои результаты и статистику.</span></p>
          <ol start="4">
              <li><strong> Условия использования Данных и отдельных функций Приложения</strong></li>
          </ol>
          <p><span style="font-weight: 400;">4.1. Выполнение функций Сайта или Приложения возможно только при наличии доступа к сети Интернет </span><span style="font-weight: 400;">с пропускной способностью не менее 128 кбит/с (килобит в секунду).</span><span style="font-weight: 400;">. Пользователь самостоятельно получает и оплачивает такой доступ на условиях и по тарифам своего оператора связи или провайдера доступа к сети Интернет.&nbsp;</span></p>
          <p><span style="font-weight: 400;">4.2. Выполнение функций Сайта возможно при использовании</span><span style="font-weight: 400;"> современного браузера с поддержкой CSS 3.0, JavaScript 2.0, Cookies и некоторых элементов HTML5;&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">4.3. Администратор и/или уполномоченный представитель (модератор) оставляют за собой право на свое усмотрение ограничить доступ Пользователя к Сайту или Приложению (или к определенным Данным или функциям Приложения, если это возможно технологически) с использованием его Аккаунта или полностью заблокировать Аккаунт Пользователя при неоднократном нарушении условий настоящего Соглашения, либо применить к Пользователю иные меры с целью соблюдения требований законодательства или прав и законных интересов третьих лиц.&nbsp;</span></p>
          <p><span style="font-weight: 400;">4.4. Администратор несет ответственность за рекламу, размещенную им в Приложении, в пределах, установленным законодательством о рекламе, исключительно в качестве рекламодателя.&nbsp;</span></p>
          <p><span style="font-weight: 400;">4.5. Пользователь по своему усмотрению может использовать платные функции Приложения, путем одноразового платежа или оформления подписки.</span></p>
          <ol start="5">
              <li><strong> Ограничение ответственности Администратора&nbsp;</strong></li>
          </ol>
          <p><span style="font-weight: 400;">5.1. Приложение и Сайт предоставляется на условиях "как есть" (as is). Администратор не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы Приложения, Сайта или отдельных их компонентов и/или функций, соответствия Приложения и Данных конкретным целям и ожиданиям Пользователя, не гарантирует достоверность, точность, полноту и своевременность Данных, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящем Соглашении.</span></p>
          <p><span style="font-weight: 400;">5.2. Администратор не несет ответственности за какие-либо прямые или косвенные последствия какого-либо использования или невозможности использования Сайта, Приложения и Данных и/или ущерб, причиненный Пользователю и/или третьим лицам в результате какого-либо использования, неиспользования или невозможности использования Сайта, Приложения и Данных или отдельных его компонентов и/или функций, в том числе из-за возможных ошибок или сбоев в работе программно-аппаратных средств, за исключением случаев, прямо предусмотренных законодательством и соглашениями Администратора с Пользователями.&nbsp;</span></p>
          <p><span style="font-weight: 400;">5.3 Администрация Сайта оставляет за собой право следить за поведением Пользователя в рамках использования им услуг Сайта, и предотвращать публикацию любых материалов, в том числе высказываний, нарушающих настоящие соглашение, а также принимать меры по наложению на Пользователя ответственности, предусмотренной в данном Соглашении и находящейся в компетенции Администрации. Бездействие Администрации Сайта на момент совершения пользователем действий, нарушающих настоящее соглашение, не означает, что соответствующие меры не будут приняты в дальнейшем.</span></p>
          <p><span style="font-weight: 400;">5.4 В случае утраты Пользователем Пароля, по запросу Пользователя он может быть выслан на указанный им при Регистрации электронный адрес. В случае, если по каким-либо причинам Пользователь не может использовать указанный при Регистрации электронный адрес, пароль может быть предоставлен ему только после проверки соответствия данных, предоставленным лицом, подающим запрос на получение Пароля.</span></p>
          <p><span style="font-weight: 400;">5.5 Администрация Сайта не обязуется возвращать или уничтожать Материалы, предоставленные Пользователями в связи или при пользовании услугами Сайта.</span></p>
          <p><span style="font-weight: 400;">5.6 Администрация сайта не несет ответственности в случае прямых или косвенных финансовых или иных потерь Пользователей, вызванных временной неработоспособностью Сайта по следующим причинам: плановые или внеплановые&nbsp; технические и профилактические работы, технические сбои интернет-провайдеров, компьютерных сетей, серверов и средств, а также противоправные действия третьих лиц или форс-мажорные обстоятельства. При этом Администрация Сайта берет на себя обязательство приложить максимум усилии для восстановления работоспособности Сайта в кратчайшие сроки.</span></p>
          <p><span style="font-weight: 400;">5.7 Администрация Сайта сохраняет за собой право в любое время изменять оформление Сайта&nbsp; или Приложения, его содержание, списка услуг, изменять и (или) дополнять используемые скрипты, программное обеспечение, сервисные приложения и другие объекты, которые используются или хранятся на Сайте, с уведомлением или без уведомления Пользователя.</span></p>
          <ol start="6">
              <li><strong> Информационные сообщения</strong></li>
          </ol>
          <p><span style="font-weight: 400;">6.1. Пользователь путем акцепта настоящего Соглашения дает свое согласие на получение информационных рассылок от Сайта, Приложения с последними новостями, акциями, новыми предложениями, а также рекламных рассылок, в том числе о новостях и предложениях третьих лиц посредством SMS, push-уведомлений в Приложении.&nbsp;</span></p>
          <p><span style="font-weight: 400;">6.2. Приложение может содержать ссылки на сторонние сайты. Администратор не несет ответственности за содержание и деятельность таких сайтов и обеспечение ими конфиденциальности, и за любой ущерб, связанный и возникающий вследствие посещения Клиентами таких сайтов.&nbsp;</span></p>
          <p><span style="font-weight: 400;">6.3. Передача информации личного характера при посещении внешних сайтов, даже если они содержат ссылки на Приложение, Сайт или веб-страницу Приложения на App Store или Google Play, не попадает под условия настоящего Соглашения. Исполнитель не несет ответственности за действия сторонних сайтов. Сбор и передача личной информации и персональных данных Пользователей регламентируются документами об использовании подобной информации, размещенными на внешних сайтах.</span></p>
          <p><span style="font-weight: 400;">6.4. Предоставив номер своего телефона, Пользователь дает свое согласие на получение рекламы по сетям электросвязи согласно ч. 1 ст. 18 Федерального закона "О рекламе".&nbsp;</span></p>
          <p><span style="font-weight: 400;">6.5. Отказаться от push-уведомлений в Приложении Пользователь может путем изменения настроек используемого мобильного телефона.</span></p>
          <ol start="7">
              <li><strong> Политика конфиденциальности&nbsp;</strong></li>
          </ol>
          <p><span style="font-weight: 400;">7.1. Под "Персональной информацией Пользователя" понимаются:</span></p>
          <p><span style="font-weight: 400;">7.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при создании Аккаунта или в процессе использования Сайта, Приложения (в том числе контактные данные, фамилию, имя, отчество, пол и дату рождения, номер телефона, адрес электронной почты, геолокацию, сведения о чеках);</span></p>
          <p><span style="font-weight: 400;">7.1.2. Данные, которые автоматически передаются Сайтом, Приложением в процессе его использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Приложению), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту, Приложению и иная подобная информация.</span></p>
          <p><span style="font-weight: 400;">7.1.3. Иная информация о Пользователе, обработка которой предусмотрена условиями использования Приложения.</span></p>
          <p><span style="font-weight: 400;">7.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сайта, Приложения. Администратор не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным в Приложении.</span></p>
          <p><span style="font-weight: 400;">7.3. Администратор не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность.</span></p>
          <p><span style="font-weight: 400;">7.4. Администратор собирает и хранит только ту персональную информацию, которая необходима для предоставления функциональности Сайта, Приложения, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</span></p>
          <p><span style="font-weight: 400;">7.5. Персональная информация Пользователя обрабатывается в следующих целях:</span></p>
          <p><span style="font-weight: 400;">7.5.1. Авторизация Пользователя на сайте, в Приложении;</span></p>
          <p><span style="font-weight: 400;">7.5.2. Предоставление Пользователю персонализированного Приложения;</span></p>
          <p><span style="font-weight: 400;">7.5.3. Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Приложения;</span></p>
          <p><span style="font-weight: 400;">7.5.4. Улучшение качества Приложения, удобства его использования, разработка нового функционала Приложения;</span></p>
          <p><span style="font-weight: 400;">7.5.5. Таргетирование рекламных материалов;</span></p>
          <p><span style="font-weight: 400;">7.5.6. Исполнение условий Пользовательского соглашения Приложения, Сайта;</span></p>
          <p><span style="font-weight: 400;">7.5.7. Проведение статистических и иных исследований, на основе обезличенных данных.</span></p>
          <p><span style="font-weight: 400;">7.5.8. Для участия в марафоне &ldquo;ВЗНАНИЯ 2020&rdquo; и публикации на сайте</span><a href="http://www.vznaniya.ru/2020"><span style="font-weight: 400;"> www.vznaniya.ru/2020</span></a><span style="font-weight: 400;"> в рейтинге и разделе &ldquo;Победителей&rdquo;.</span></p>
          <p><span style="font-weight: 400;">7.5.9. Для участия Пользователя в ТОПах Сайта;</span></p>
          <p><span style="font-weight: 400;">7.5.10. При публикации отзывов Пользователя на Сайте и в социальных сетях Правообладателя;</span></p>
          <p><span style="font-weight: 400;">7.5.11 при ответах на вопросы Пользователя;</span></p>
          <p><span style="font-weight: 400;">7.6. Условия обработки персональной информации Пользователей и её передачи третьим лицам.</span></p>
          <p><span style="font-weight: 400;">7.6.1. Исполнитель хранит персональную информацию Пользователей в соответствии с внутренними регламентами Сайта, Приложения.</span></p>
          <p><span style="font-weight: 400;">7.6.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. Используя Приложение, Пользователь соглашается с тем, что определённая часть его персональной информации может становится общедоступной.</span></p>
          <p><span style="font-weight: 400;">7.7. Администратор и/или уполномоченный представитель (модератор), вправе передать персональную информацию Пользователя третьим лицам в случае если: Пользователь выразил согласие на такие действия; Передача необходима для использования Пользователем Приложения; Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры; Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации; В целях обеспечения возможности защиты прав и законных интересов Правообладателя или третьих лиц в случаях, когда Пользователь нарушает Пользовательское соглашение Приложения, либо существует угроза такого нарушения.&nbsp;</span></p>
          <p><span style="font-weight: 400;">В результате обработки персональной информации Пользователя путем ее обезличивания Администратор и/или уполномоченные представители (модератор) получают обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Администратора.</span></p>
          <p><span style="font-weight: 400;">7.8. При обработке персональных данных Пользователей Администратор руководствуется Федеральным законом РФ "О персональных данных".</span></p>
          <p><span style="font-weight: 400;">7.9. Пользователь может потребовать прекратить обработку его персональной информации в Приложении путем направления соответствующего сообщения с указанием наименования учетной записи по support@vznaniya.ru.</span></p>
          <p><span style="font-weight: 400;">7.10. Персональная информация о Пользователе обрабатывается на протяжении всего срока использования Пользователем Приложения с учетом требований, предусмотренных законодательством РФ.</span></p>
          <p><span style="font-weight: 400;">7.11. Персональные данные обрабатываются при помощи файлов Cookie и счетчиков. Файлы cookie, передаваемые оборудованию Пользователя, могут использоваться Администратором и/или уполномоченным представителем (модератором) для персонализации предоставления Пользователю информации в Приложении, в статистических и исследовательских целях, а также для улучшения работы Приложения.</span></p>
          <p><span style="font-weight: 400;">7.12. Структура файла cookie, его содержание и технические параметры определяются Администратором и могут изменяться без предварительного уведомления Пользователя.</span></p>
          <p><span style="font-weight: 400;">7.13. Счетчики, размещенные в Приложении, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической информации об использовании Приложения, а также для обеспечения работоспособности Сайта, Приложения в целом или их отдельных функций в частности. Технические параметры работы счетчиков определяются Правообладателем и могут изменяться без предварительного уведомления Пользователя.</span></p>
          <p><span style="font-weight: 400;">7.14. Администратор принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</span></p>
          <p><span style="font-weight: 400;">7.15. Пользователь при использовании Приложения может дополнительно приобретать платные функции. Администратор не хранит информацию о кредитной карте (имя, номер кредитной карты и срок действия) для любых покупок, сделанных в Приложении.</span></p>
          <ol start="8">
              <li><strong> Интеллектуальные права</strong></li>
          </ol>
          <p><span style="font-weight: 400;">8.1 Объекты, размещенные на Сайте, Приложении: элементы дизайна, текст, иллюстрации, изображения, видео, программы, программный код, скрипты, другие объекты (далее &ndash; Материал), являются объектами исключительного права Правообладателя.</span></p>
          <p><span style="font-weight: 400;">8.2 Результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации охраняются действующим гражданским законодательством об интеллектуальной собственности, а также международными конвенциями в области интеллектуальной собственности.&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">8.3 В соответствии с четвертой частью Гражданского кодекса Российской Федерации Материал не может быть воспроизведен (в т.ч. с помощью копирования), переработан, распространен, опубликован или использован иным образом (как целиком, так и по частям) без предварительного согласия Правообладателя, за исключением тех случаев, когда Правообладатель выразил свое согласие на свободное использование Материала любым лицом.&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">8.4 Сайту принадлежит исключительное право на все произведения (материалы), используемые в процессе оказания услуг и могут быть использованы Пользователем только в процессе получения соответствующей услуги. Любое другое использование возможно только с письменного согласия Правообладателя.&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">8.5 При разрешенном использовании информации Сайта Пользователь обязан указывать ссылку со следующими данными: ВЗНАНИЯ. Образовательная платформа..&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">8.6 Авторские материалы, размещенные на Сайте Пользователем, не возвращаются. Администрация вправе распоряжаться авторскими материалами Пользователя без получения предварительного согласия Пользователя и выплаты ему авторского вознаграждения.&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400;">8.7 Пользователь (законный представитель Пользователя) дает свое согласие на использование полученных в рамках творческого задания результатов интеллектуальной деятельности любыми способами, предусмотренными в статье 1270 Гражданского кодекса Российской Федерации, но с указанием имени Пользователя (автора).</span></p>
          <p><span style="font-weight: 400;">&nbsp;9.&nbsp;</span><strong>Заключительные положения</strong></p>
          <p><span style="font-weight: 400;">9.1. Соглашение (в том числе любые из его частей) может быть изменено Администратором в одностороннем порядке без какого-либо специального уведомления, новая редакция документа вступает в силу с момента размещения в сети Интернет при скачивании Приложения, если иное не предусмотрено новой редакцией Соглашения.</span></p>
          <p><span style="font-weight: 400;">9.2. К настоящему Соглашению и всем отношениям, связанным с использованием Приложения и Данных, подлежит применению право Российской Федерации.</span></p>
          <p><span style="font-weight: 400;">9.3. Начиная использовать Сайт или Приложение, либо пройдя процедуру регистрации, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не вправе использовать Приложение.&nbsp;</span></p>
          <p><span style="font-weight: 400;">9.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.</span></p>
          <p><span style="font-weight: 400;">9.5. Все запросы и уведомления о персональной информации и Политике, предусмотренные Федеральным законом "О персональных данных", Пользователь вправе направлять по электронной почте: support@vznaniya.ru.</span></p>
          <p><strong>Обратная связь: support@vznaniya.ru</strong></p>
        </div>
        <div class="flex justify-around mt-10">
          <div v-for="(button, buttonIndex) in params.buttons" :key="buttonIndex" :class="button.class" class="mr-2" @click="button.callback">{{ button.text }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { inject, ref } from 'vue';
import useEventBus from "@/composables/useEventBus.js";
export default {
  data() {
    return {
      params: {
        closeButton: true, // Показать крестик в углу
        closeOutside: true, // Закрыть модалку при нажатии за пределами окна
        text: 'Взнания', // Текст модалки
        buttons: [
          {
            text: this.$t('games.ok'),
            class: 'v-button v-button--gradient-red',
            callback: () => { this.show = false }
          }
        ], // Кнопки модалки
        size: 'md',
      },
    }
  },
  setup() {
    const emitter = useEventBus();
    const show = ref(false)
    emitter.on('v-policy', (params = {}) => {
      if (params.close) show.value = false;
      else show.value = true;
    })

    return {
      show
    }
  }
}
</script>

<style lang="scss" scoped>
.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__content {
    height: 100%;
    overflow: auto;

    p {
          margin: 0 0 1.25rem;
    }
  }

  &__wrapper {
    background-color: #fff;
    background: url(/images/modal-pattern.png);
    background-size: cover;
    border-radius: 12px;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 900px;
    height: 90vh;
    overflow: hidden;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    cursor: pointer;
  }
}
</style>
