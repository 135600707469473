import axios from 'axios';

const apiRequest = axios.create({
  baseURL: import.meta.env.VITE_APP_URL + '/api/v1/',
  headers: {
    Accept: 'application/json',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('token')}`;
      }
    }
  }
});

// if (token) {
//   apiRequest.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// }

apiRequest.interceptors.request.use(req => {
  // if (req.url.includes('timestamp')) {
  //   req.url.replace(/timestamp=\S+/, `timestamp=${new Date().getTime()}`);
  // } else {
  //   req.url = req.url + (req.url.includes('?') ? '&' : '?') + `timestamp=${new Date().getTime()}`;
  // }
  return req;
})


export default apiRequest;
