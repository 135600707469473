export default {
	title: 'Сообщения',
	search: 'Поиск',
	'share-success': 'Сообщения успешно отправлены!',
	input: {
		placeholder: 'Введите сообщение...',
	},
	errors: {
		'file-size': {
			message: 'Некоторые файлы превышают максимальный допустимый размер (20Мб)',
			'button-delete-unvalidated': 'Удалить файлы превышающие допустимый размер и продолжить',
		},
	},
	header: {
		'share-btn': 'Сделать рассылку',
		'groups-dropdown': 'Все группы',
		'messages-dropdown': 'Все сообщения',
		'messages-read': 'Непрочитанные',
		'messages-unanswered': 'Неотвеченные',
		modal: {
			title: 'Рассылка',
			'attach-files': 'Прикрепить файл',
		},
	},
	messages: {
		'rooms-empty': 'Добавьте учеников в группу',
		'room-empty': 'Выберите чат, чтобы начать общение',
		'new-messages': 'Новое сообщение',
	},
	actions: {
		edit: {
			name: 'editMessage',
			title: 'Редактировать',
			onlyMe: true,
		},
		delete: {
			name: 'deleteMessage',
			title: 'Удалить',
			onlyMe: true,
		},
	},
}
