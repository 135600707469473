<template>
  <div class="v-name" :class="{ active: value.length > length }" v-tooltip="{ trigger: 'hover', content: value.length > length ? `${value}` : null, classes: ['custom-tooltip'] }">
    {{ getShortedName }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    length: {
      type: Number,
      default: 9,
    },
    full: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getShortedName() {
      if (this.value.length > this.length) return this.value.substring(0, this.length) + '...'
      return this.value;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-name {
  cursor: default;

  &__full {
    position: absolute;
    padding: 10px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 0 20px -10px black;
    top: 0;
    transform: translateY(-120%);
    display: none;
    width: 100%;
    box-sizing: border-box;
    word-break: break-all;
    z-index: 10;
  }

  &.active:hover .v-name__full {
    display: block;
  }
}
</style>