<template>
  <div v-if="currentSets.length > 0" class="w-full flex flex-wrap justify-center">
    <div v-for="set in currentSets" :key="set.word.id" class="w-full p-1 mb-5">
      <div class="v-fill flex pt-5 items-center flex-wrap ">
        <lexical-word-card :big="true" class="w-full mb-10" :word="set.word" :useTranslate="true" :showTranslate="false" text="text-lg md:text-3xl" />

        <div class="w-full">
          <div class="w-full text-center v-text--desc" v-if="isKorean">Для перехода к следующему пропуску нажмите Enter (ввод)</div>
          <input type="text" autocomplete="on" autocapitalize="on" autocorrect="on" style="display: none"/>
          <FillWrapper
            :wrapper="!isTrueMobile"
            class="flex w-full lg:w-full px-5 lg:px-0 mx-auto justify-center flex-wrap"
            classes="p-1 flex"
            v-slot:default="{ word, wordIndex }"
            :source="set.getSplittedSource"
          >
            <div class="p-1" v-for="(letter, index) in word" :key="index + 'wordIndex' + wordIndex">
              <input
                :search="letter.index"
                v-if="letter.readonly"
                class="v-fill__cell letter Raleway-Medium"
                type="text"
                :readonly="true"
                :value="letter.value"
                maxlength="1"
                :ref="`cell-${set.word.id}`"
                autocomplete="off" autocapitalize="off" autocorrect="off"
              >
              <input v-else
                :readonly="set.sourceWord.filter(letter => !letter.readonly).every(isRight)"
                :search="letter.index"
                class="v-fill__cell Raleway-Medium"
                :class="{ right: isRight(letter) }"
                type="text"
                :ref="`cell-${set.word.id}`"
                :maxlength="maxInputSize"
                v-model="letter.userValue"
                @input="handleInput(letter.index, set)"
                @focus="letter.userValue = ''; set.lastFocusedLetter = letter"
                @keyup.enter="handleInput(letter.index, set, true)"
                @keydown.delete="handleBackspace($event, letter.userValue, letter.index, set)"
                autocomplete="off" autocapitalize="off" autocorrect="off"
              />
            </div>
          </FillWrapper>

          <SpecialCharacters class="mb-10 w-full" :lesson="currentLesson" @char="char => addChar(char, set)" />

        </div>
      </div>
      <div v-if="set.error" class="v-text--red text-lg text-center my-5">{{ $t('lesson.write_word') }}</div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';

import shuffle    from 'lodash/shuffle';
import cloneDeep  from 'lodash/cloneDeep'
import FillWrapper from '../FillGame/Wrapper.vue';
import LexicalWordCard from '@/components/LexicalWordCard.vue';
import SpecialCharacters from '@/components/lesson/SpecialCharacters.vue';
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import helpers from "@/helpers.js";

const props = defineProps({
  words: {
    type: Array,
    default: () => []
  }
})
const store = useStore();
const currentSets = ref([]);
const GAME_SLUG = 'insert_missed';
const specialCaptalize = ref(false);
const currentLesson = computed(() => store.getters.getCurrentLesson);
const isKorean = computed(() => currentLesson.language === 'KR' || currentLesson.language === 'CN')
const maxInputSize = computed(() => isKorean ? 4 : 1);
const isTrueMobile = computed(() => window.isTrueMobile);
const getTransProp = computed(() => currentLesson.with_pinyin ? 'pinyin' : 'text');
const instance = getCurrentInstance()
const refs = instance.proxy?.$refs
const needSpecialCharacter = computed(() => {
  const symbolsExists = !!helpers.specialSymbols[currentLesson.language];
  if (!symbolsExists) return false;
  return !(currentLesson.language === 'CN' && symbolMode);
})
const symbolMode = computed(() => this.currentLesson.with_pinyin);
const getSpecialCharacter = computed(() => {
  const type = specialCaptalize.value ? 'uppercase' : 'lowercase'
  return helpers.specialSymbols[currentLesson.language][type];
});

const validateTest = () => {
  currentSets.value.forEach(set => {
    $set(set, 'error', false);
  })

  return currentSets.value.every(set => {
    if (set.sourceWord.filter(el => !el.readonly).every(el => !!el.userValue)) {
      const answer = set.sourceWord.map(el => el.readonly ? el.value : el.userValue).join('');
      $set(set, 'answer', answer);
      return true;
    }
    $set(set, 'error', true);
    return false;
  })
}

const getTestData = () => {
  return currentSets.value.map(set => ({
    id: set.word.id,
    test_type: 'insert_missed',
    text: set.answer,
  }))
}

// Разделяет массив букв-объектов на подмассивы по пробелам
const getSplittedSource = (sourceWord) => {
  let res = [];
  let splitIndex = 0;
  let word = [];

  for (let letter of sourceWord) {
    if (letter.value.trim().length === 0) {
      res[splitIndex] = word;
      splitIndex += 1;
      word = [];
    }
    word.push(letter);
  }
  res[splitIndex] = word;

  return res;
}

const isRight = () =>{
  return false;
}

const addChar = (char, set) => {
  if (set.lastFocusedLetter) {
    set.lastFocusedLetter.userValue = char;
    handleInput(set.lastFocusedLetter.index, set);
  }
}

const handleBackspace = async (evt, value, index, set) => {
  if (value.length === 0) {
    const inputs = cloneDeep(refs[`cell-${set.word.id}`]).sort((a, b) => a.attributes.search.value - b.attributes.search.value);
    const newIndex = inputs.length - index - 1;
    const prevInputElement = cloneDeep(inputs).reverse().find((input, inputIndex) => inputIndex > newIndex && !input.classList.contains('letter'));
    if (prevInputElement) prevInputElement.focus();
  }
}

const initGame = (set) => {

  const wLength = set.word[getTransProp.value].length;

  if (wLength === 1) {
    set.sourceWord = set.word[getTransProp.value].split('').map((l, i) => {
      return { value: l, readonly: false, userValue: '' }
    })
  } else if (wLength === 2) {
    set.sourceWord = set.word[getTransProp.value].split('').map((l, i) => {
      if (i === 1) {
        return { value: l, readonly: true, userValue: '' }
      }
      return { value: l, readonly: false, userValue: '' }
    })
  } else if (wLength === 3) {
    const splitted = set.word[getTransProp.value].split('');
    const isSecondSpaceOrSpecial = splitted[1].trim().length === 0 || /[-!$%^&*()_+|~=`{}\[\]:";'´<>?,.\/]/.test(splitted[1]);

    set.sourceWord = splitted.map((l, i) => {
      if (i === 0 || (!isSecondSpaceOrSpecial && i === wLength - 1) || l.trim().length === 0 || /[-!$%^&*()_+|~=`{}\[\]:";'´<>?,.\/]/.test(l)) {
        return { value: l, readonly: true, userValue: '' }
      }
      return { value: l, readonly: false, userValue: '' }
    })
  } else if (wLength === 4) {
    set.sourceWord = set.word[getTransProp.value].split('').map((l, i) => {
      if (i === 0 || i === wLength - 1 || l.trim().length === 0 || /[-!$%^&*()_+|~=`{}\[\]:";'´<>?,.\/]/.test(l)) {
        return { value: l, readonly: true, userValue: '' }
      }
      return { value: l, readonly: false, userValue: '' }
    })
  } else if (wLength >= 5) {
    let index = 1;
    set.sourceWord = set.word[getTransProp.value].split('').map((l, i) => {
      if (i === 0 || i === wLength - 1) {
        // index += 1;
        return { value: l, readonly: true, userValue: '' }
      }

      if (index === 3 || l.trim().length === 0 || /[-!$%^&*()_+|~=`{}\[\]:";'´<>?,.\/]/.test(l)) {
        if ( index === 3) index = 1;
        return { value: l, readonly: true, userValue: '' }
      } else {
        index += 1;
        return { value: l, readonly: false, userValue: '' }
      }
    })
  }

  set.sourceWord = set.sourceWord.map((el, index) => {
    return {
      index,
      ...el,
    }
  })

  set.getSplittedSource = getSplittedSource(set.sourceWord);

  return set;

  // await this.$nextTick();
  // this.$refs.cell.find(el => !el.classList.contains('letter')).focus();
}

// После ввода переместить курсор на следующий элемент
const handleInput = (index, set, force = false) => {
  this.error = false;

  const inputs = cloneDeep(refs[`cell-${set.word.id}`]).sort((a, b) => a.attributes.search.value - b.attributes.search.value);
  console.dir(inputs);
  const nextInputElement = inputs.find((input, inputIndex) => inputIndex > index && !input.classList.contains('letter') && !input.value);
  const currentInputElement = inputs.find((input, inputIndex) => inputIndex == index);

  if (isKorean && force && currentInputElement.value.length === 0) return;
  if (isKorean && !force && currentInputElement.value.length < maxInputSize) return;

  if (nextInputElement) {
    nextInputElement.focus();
  } else if (currentInputElement) {
    currentInputElement.blur();
  }
}

onMounted(() => {
  currentSets.value = shuffle(props.words).map(word => {

    return {
      selected: null,
      word,
      lastFocusedLetter: null,
      sourceWord: [],
      answer: null,
    }
  })

  currentSets.value = currentSets.value.map(el => initGame(el))
})
</script>

<style lang="scss" scoped>
.v-fill {

  &__cell {
    width: 30px;
    padding-bottom: 6px;
    padding-top: 6px;
    border-radius: 6px;
    text-align: center;
    outline: none;
    font-family: 'Raleway-Medium' !important;


    &.letter {
      background-color: #d9dce6; // rgb(213, 209, 207);
      border: 1px solid transparent;
    }

    &.right {
      background-color: #1fc05d;
      color: #fff;
    }

    &:not(.letter) {
      border: 1px solid #d9dce6;
    }

    &:read-only {
      pointer-events: none;
    }
  }
}
</style>
