<template>
	<div class="relative">
		<svg v-if="count === 3" width="120%" height="120%" class="game-navbar__list__stage" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M70.9233 36C71.518 36 72.0017 35.5178 71.9839 34.9234C71.8362 29.9866 70.6741 25.1288 68.5654 20.6541C66.3036 15.8544 63.0088 11.614 58.9168 8.23634C54.8248 4.85871 50.0368 2.42736 44.8954 1.1163C40.1021 -0.105996 35.1121 -0.326389 30.2368 0.46431C29.6498 0.559513 29.268 1.1259 29.3807 1.7098C29.4935 2.2937 30.058 2.67388 30.6452 2.57979C35.2086 1.84858 39.8776 2.05917 44.3633 3.20304C49.1971 4.43567 53.6987 6.72157 57.546 9.89715C61.3932 13.0727 64.4909 17.0595 66.6173 21.5721C68.5907 25.7597 69.6824 30.304 69.8294 34.9234C69.8483 35.5178 70.3286 36 70.9233 36Z" :fill="getColor(done >= 1)"/>
			<path d="M18.5384 5.7556C18.241 5.2406 17.5815 5.06276 17.0757 5.37536C12.8741 7.97168 9.24827 11.407 6.42735 15.4706C3.40158 19.8292 1.37669 24.8028 0.497571 30.0353C-0.381543 35.2679 -0.0931382 40.6301 1.34215 45.7382C2.68026 50.5005 4.98438 54.9322 8.10681 58.759C8.48276 59.2197 9.16416 59.2672 9.61347 58.8776C10.0628 58.488 10.1098 57.8091 9.73469 57.3476C6.81971 53.7611 4.66762 49.6124 3.41537 45.1557C2.06594 40.3532 1.79479 35.3117 2.62132 30.3921C3.44784 25.4726 5.35161 20.7965 8.19638 16.6986C10.8363 12.8959 14.226 9.67822 18.1529 7.24124C18.6582 6.92767 18.8357 6.27061 18.5384 5.7556Z" :fill="getColor(done >= 2)" />
			<path d="M18.5384 66.2445C18.241 66.7595 18.4167 67.4195 18.9404 67.7013C23.2897 70.0418 28.0777 71.4642 33.0073 71.8754C38.2948 72.3165 43.6145 71.5834 48.5856 69.7284C53.5567 67.8734 58.0563 64.9426 61.7625 61.1455C65.2177 57.6055 67.9035 53.3943 69.6564 48.7768C69.8675 48.2208 69.5678 47.607 69.0058 47.4127C68.4438 47.2183 67.8323 47.5171 67.6202 48.0727C65.9717 52.3904 63.4548 56.3285 60.2214 59.6413C56.7369 63.2112 52.5065 65.9668 47.8328 67.7108C43.159 69.4548 38.1576 70.1441 33.1863 69.7294C28.573 69.3445 24.0916 68.0178 20.0177 65.8354C19.4935 65.5546 18.8357 65.7295 18.5384 66.2445Z" :fill="getColor(done >= 3)" />
		</svg>

		<svg v-if="count === 2" width="120%" height="120%" class="game-navbar__list__stage" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M66.2445 53.4616C66.7595 53.759 67.4196 53.5831 67.7015 53.0594C71.4932 46.0135 72.8344 37.894 71.494 29.9849C70.0936 21.7215 65.8539 14.2055 59.5057 8.73309C53.1576 3.26065 45.099 0.17465 36.7194 0.00719157C28.6991 -0.153085 20.8659 2.36983 14.4556 7.15845C13.9792 7.51434 13.9025 8.19318 14.2725 8.65875C14.6425 9.12432 15.3189 9.20023 15.796 8.84524C21.8133 4.36805 29.1574 2.01001 36.6763 2.16027C44.5547 2.31771 52.1313 5.21911 58.0996 10.3642C64.068 15.5093 68.0542 22.5756 69.3708 30.3448C70.6273 37.7595 69.3772 45.3709 65.8356 51.9822C65.5547 52.5064 65.7295 53.1643 66.2445 53.4616Z" :fill="getColor(done >= 1)" />
			<path d="M5.75565 18.5384C5.24065 18.2411 4.58052 18.417 4.29871 18.9406C0.50693 25.9866 -0.834194 34.106 0.506139 42.0151C1.90653 50.2786 6.1463 57.7945 12.4944 63.267C18.8425 68.7394 26.9012 71.8254 35.2808 71.9929C43.301 72.1532 51.1343 69.6302 57.5446 64.8416C58.021 64.4857 58.0977 63.8069 57.7277 63.3413C57.3577 62.8758 56.6812 62.7999 56.2041 63.1548C50.1869 67.632 42.8428 69.9901 35.3238 69.8398C27.4454 69.6824 19.8689 66.781 13.9005 61.6359C7.93214 56.4908 3.946 49.4245 2.62938 41.6553C1.37282 34.2406 2.62294 26.6291 6.1646 20.0179C6.44541 19.4937 6.27066 18.8358 5.75565 18.5384Z" :fill="getColor(done >= 2)" />
		</svg>

		<svg v-if="count === 1" width="120%" height="120%" class="game-navbar__list__stage" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36C0 16.1177 16.1177 0 36 0C55.8823 0 72 16.1177 72 36ZM2.15351 36C2.15351 54.6929 17.3071 69.8465 36 69.8465C54.6929 69.8465 69.8465 54.6929 69.8465 36C69.8465 17.3071 54.6929 2.15351 36 2.15351C17.3071 2.15351 2.15351 17.3071 2.15351 36Z" :fill="getColor(done >= 1)" />
		</svg>

		<div v-if="done >= count && count !== 0 && done !== 0" class="game-navbar__list__notification">
			<div class="game-navbar__list__complete">
				<svg width="20" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.27352 0C8.17381 0 8.07508 0.0197431 7.98303 0.0580909C7.89111 0.0963894 7.80739 0.152762 7.73723 0.223429L3.81798 4.14794L2.257 2.58192L2.25415 2.57917C2.18168 2.50916 2.09613 2.45412 2.00238 2.41717C1.90863 2.38023 1.80852 2.36211 1.70777 2.36385C1.60702 2.3656 1.5076 2.38717 1.41519 2.42733C1.32278 2.4675 1.23918 2.52547 1.16917 2.59794C1.09916 2.67042 1.04412 2.75597 1.00717 2.84972C0.970226 2.94347 0.952107 3.04357 0.95385 3.14432C0.955593 3.24507 0.977164 3.34449 1.01733 3.43691C1.05724 3.52872 1.11472 3.61184 1.18654 3.68157L3.28185 5.77688C3.35201 5.84753 3.43544 5.90362 3.52735 5.94191C3.6194 5.98026 3.71813 6 3.81784 6C3.91755 6 4.01628 5.98026 4.10833 5.94191C4.20024 5.90362 4.28398 5.84722 4.35413 5.77657L8.80647 1.32424C8.88158 1.2541 8.9416 1.16937 8.98284 1.07521C9.02469 0.979657 9.0463 0.876474 9.0463 0.772159C9.0463 0.667844 9.02469 0.564661 8.98284 0.469111C8.94161 0.374978 8.88161 0.290263 8.80653 0.22014C8.737 0.150891 8.65462 0.0958432 8.56401 0.0580909C8.47196 0.0197431 8.37324 0 8.27352 0Z" fill="#76D67D"/>
				</svg>
			</div>
		</div>

		<div v-if="lock" class="game-navbar__list__notification" v-tooltip="{content: $t('lesson.lock_game'), classes: ['custom-tooltip'], placement: 'auto'}">
			<IconDisplay iconPath="/images/lesson/icons/new/lock.svg" customClass="game-navbar__list__lock"/>
		</div>

		<slot />
	</div>
</template>

<script setup>
import IconDisplay from '@/components/IconDisplay.vue'

const props = defineProps({
	count: Number,
	done: Number,
	lock: {
		type: Boolean,
		default: false
	},
	activeColor: {
		type: String,
		default: '#76D67D'
	},
	forceColor: {
		type: String
	}
});

const getColor = (active) => {
	if (props.forceColor) return props.forceColor;
	return active ? props.activeColor : '#D7D9DE';
};
</script>

<style lang="scss" scoped>
	.game-navbar__list__stage {
		width: 84px;
		height: 84px;
		position: relative;
		z-index: 1;
		margin: 0 auto;
	}

	.game-navbar__list__notification{
		position: absolute;
		top: -5px;
		right: 0px;
		padding: 5px;
		background: #fff;
		border-radius: 5px;
		z-index: 1;
		box-shadow: 0px 10px 24px 0px rgba(177, 183, 187, 0.3);
	}

	.game-navbar__list__complete{
		width: 20px;
		height: 20px;
		border: 1px solid #3BC999;
		border-radius: 100%;
		background: #fff;
	}

	.game-navbar__list__complete svg{
		width: 12px;
		position: absolute;
		margin: auto;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
	}
</style>
