<template>
	<div class="access-restricted-modal">
		<div class="content">
			<div class="smile">
				<img src="../../public/images/sad_smile.png" alt="sad-smile" />
			</div>
			<button class="cross" @click.stop="onCloseClick">
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g opacity="0.6">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M8.08709 8.08709C8.4532 7.72097 9.0468 7.72097 9.41291 8.08709L21.9129 20.5871C22.279 20.9532 22.279 21.5468 21.9129 21.9129C21.5468 22.279 20.9532 22.279 20.5871 21.9129L8.08709 9.41291C7.72097 9.0468 7.72097 8.4532 8.08709 8.08709Z"
							fill="#141029"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M21.9129 8.08709C22.279 8.4532 22.279 9.0468 21.9129 9.41291L9.41291 21.9129C9.0468 22.279 8.4532 22.279 8.08709 21.9129C7.72097 21.5468 7.72097 20.9532 8.08709 20.5871L20.5871 8.08709C20.9532 7.72097 21.5468 7.72097 21.9129 8.08709Z"
							fill="#141029"
						/>
					</g>
				</svg>
			</button>

			<div class="title">{{ $t('lesson.access_restricted') }}</div>
			<div class="text">{{ $t('lesson.write_teacher_about_access') }}</div>

			<ButtonComponentNew class="button-write" :title="$t('lesson.write_teacher')" @click="onWriteTeacherClick" />
			<ButtonComponentNew class="button-close" :title="$t('home.login_modal.close')" transparent @click="onCloseClick" />

			<div v-if="isLoading" class="preloader-wrapper">
				<loader-brain-logo />
			</div>
		</div>
	</div>
</template>

<script>
import ButtonComponentNew from './ButtonComponentNew.vue'
import { mapState } from 'vuex'
import LoaderBrainLogo from './LoaderBrainLogo.vue'

export default {
	name: 'AccessRestrictedModal',
	components: { LoaderBrainLogo, ButtonComponentNew },
	props: {
		teacherUserId: Number,
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		...mapState({
			controller: (state) => state.user.chatController,
			profile: (state) => state.user.profile,
		}),
	},
	methods: {
		onCloseClick() {
			this.$emit('close')
		},
		async onWriteTeacherClick() {
			this.isLoading = true

			const payload = {
				message: this.$t('lesson.request_teacher_to_buy_subscription'),
				receiver: this.teacherUserId,
			}
			await this.controller.sendMessage(payload)

			this.isLoading = false
			this.$router.push({ name: 'chat', query: { teacher_user_id: String(this.teacherUserId) } })
		},
	},
}
</script>

<style scoped lang="scss">
.access-restricted-modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	cursor: default;
}

.cross {
	position: absolute;
	right: 30px;
	top: 30px;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	border-radius: 14px;
	background: var(--White, #fff);
	box-shadow: 0px 8px 60px 0px rgba(164, 172, 180, 0.3);
	padding: 48px;
	max-width: 446px;
	cursor: default;
}

.smile {
	img {
		width: 50px;
		height: 50px;
	}
}

.title {
	margin-top: 20px;

	color: var(--primary-typography-day, #141029);
	text-align: center;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 116.667% */
}

.text {
	margin-top: 14px;

	color: var(--primary-typography-day, #141029);
	text-align: center;
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */

	opacity: 0.8;
}

.button-write {
	margin-top: 30px;
}

.button-close {
	margin-top: 14px;
}

.preloader-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	border-radius: inherit;
}
</style>
