export default {
  enter_the_group_code: "Enter the group code",
  activate: "Activate",
  ask_teacher_code: "Ask your teacher for the code",
  your_groups: "Your groups",

  incorrect_group_code: 'Incorrect group code',
  well_done: 'Well done!',
  the_group_was_added: 'The group was added',
  go_to_your_lessons: 'Go to group lessons',
  the_group_already_exists: 'The group already exist!',
  sure_delete_group: 'Are you sure you want to delete the group',
  cancel: 'Cancel',
  remove: 'Remove',
  groups: 'Groups',
  enter_code: 'Enter the code!',
  check_activity_code: 'Invalid code "{code}". Check and try again',
}