<template>
	<transition name="fade">
		<div class="v-modal" v-if="show" @click.self="state.params.closeOutside ? (show = false) : ''">
			<div class="v-modal__wrapper" :class="state.params.size">
				<img
					v-if="state.params.closeButton"
					class="v-modal__close v-clickable"
					src="@/assets/close-xxxhdpi.png"
					alt=""
					@click="show = false"
				/>
				<div class="text-center text-xl v-text--bold mb-10" v-html="state.params.text"></div>
				<div v-if="!isOnline()" class="text-center mb-4 no-internet">
					{{ $t('games.no_internet') }}
				</div>
				<div class="flex justify-around">
					<template v-if="isOnline()">
						<div
							v-for="(button, buttonIndex) in state.params.buttons"
							:key="buttonIndex"
							:class="button.class"
							class="mr-2"
							@click="button.callback"
						>
							{{ $t(button.text) }}
						</div>
					</template>
					<div v-else class="mr-2 v-button v-button--raised-green" @click="show = false">
						{{ $t('groups.cancel') }}
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script setup>
import useEventBus from '@/composables/useEventBus.js'
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'

const emitter = useEventBus()
const store = useStore()

const buttonClass = () =>
	store.getters.getPlatform && (store.getters.getPlatform.id === 16 || store.getters.getPlatform.id === 17)
		? 'v-button v-button--novakid'
		: 'vzn-button'

const show = ref(false)
const state = reactive({
	params: {
		closeButton: true, // Показать крестик в углу
		closeOutside: true, // Закрыть модалку при нажатии за пределами окна
		text: 'Взнания', // Текст модалки
		buttons: [
			{
				text: 'games.ok',
				class: buttonClass(),
				callback: () => {
					show.value = false
				},
			},
		], // Кнопки модалки
		size: 'md',
	},
})

emitter.on('v-modal-in-component', (payload = {}) => {
	reset()
	state.params = {
		...state.params,
		...payload,
	}

	state.params.buttons = (state.params.buttons || []).filter((el) => !!el)
	show.value = !state.params.close
})

const reset = () => {
	state.params = {
		closeButton: true, // Показать крестик в углу
		closeOutside: true, // Закрыть модалку при нажатии за пределами окна
		text: 'Взнания', // Текст модалки
		buttons: [
			{
				// text: t('games.ok'),
				text: 'games.ok',
				class: buttonClass(),
				callback: () => {
					show.value = false
				},
			},
		], // Кнопки модалки
		size: 'md',
	}
}

const isOnline = () => {
	return window.navigator.onLine
}
</script>

<style lang="scss" scoped>
.v-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	&__wrapper {
		background-color: #fff;
		// background: url(/images/modal-pattern.png);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
		opacity: 0;
		animation: showup 0.3s ease;
		animation-fill-mode: forwards;

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		&.sm {
			width: 350px;
			min-height: 250px;
		}

		&.md {
			width: 450px;
			min-height: 250px;
		}

		&.lg {
			width: 600px;
			min-height: 350px;
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}

	.no-internet {
		color: red;
	}
}
</style>
