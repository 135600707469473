import memoryGame from '@/assets/activityIcons/Memory Game.png'
import quiz from '@/assets/activityIcons/Quiz.png'
import unscrambleWordsGames from '@/assets/activityIcons/Unscramble Words Games.png'
import matchingGame from '@/assets/activityIcons/Matching Game.png'
import matchingColumns from '@/assets/activityIcons/Matching Columns.png'
import labelledDiagram from '@/assets/activityIcons/Labelled diagram .png'
import bingo from '@/assets/activityIcons/Бинго.png'
import domino from '@/assets/activityIcons/домино.png'
import snakeFrom from'@/assets/activityIcons/змейка.png'
import whoAreYou from '@/assets/activityIcons/кто я_.png'
import blackWitch from '@/assets/activityIcons/Black Witch .png'
import jeopardy from '@/assets/activityIcons/jeopardy.png'
import vlockbusters from '@/assets/activityIcons/Blockbusters .png'
import boardGame from '@/assets/activityIcons/Board game.png'
import dobble from '@/assets/activityIcons/Dobble.png'
import fourInRow from'@/assets/activityIcons/Four in a row .png'
import spy from '@/assets/activityIcons/i spy.png'
import randomWheel from '@/assets/activityIcons/Random wheel .png'
import randomizer from '@/assets/activityIcons/Randomizer    .png'
import seaBattle from '@/assets/activityIcons/Sea battle .png'
import unscrambleWords from '@/assets/activityIcons/Unscramble Words.png'
import vocabularyGame from '@/assets/activityIcons/Vocabulary Game .png'
import wordSearch from '@/assets/activityIcons/Word search.png'
import flashcard from '@/assets/activityIcons/Flashcard games.png'
import answer from '@/assets/activityIcons/вопрос.png'

export default {
  memory_game: {
    type_id: 7,
    icon: memoryGame,
    name: 'Memory Game',
    words: {
      min: 5,
      max: 15,
    },
    settings: [
      { id: 1, text: 'Показать карточки на 5 секунд', prop_name: 'temp_show', value: false },
    ],
    components: ['words', 'background', 'clip_cards', 'avatars', 'progressbar',  'preview', 'settings']
  },
  quiz_game: {
    type_id: 12,
    icon: quiz,
    name: 'Quiz',
    words: {
      min: 5,
      max: 50,
    },
    components: ['words', 'background', 'avatars', 'progressbar',  'preview',],
  },
  unscramble_letters_game: {
    type_id: 16,
    icon: unscrambleWordsGames,
    name: 'Unscramble',
    words: {
      min: 5,
      max: 50,
    },
    components: ['words', 'background', 'avatars', 'progressbar', 'preview',],
  },
  matching_game: {
    type_id: 14,
    icon: matchingGame,
    name: 'Match',
    words: {
      min: 5,
      max: 50,
    },
    components: ['words', 'background', 'avatars', 'progressbar',  'preview',],
  },
  matching_columns_game: {
    type_id: 13,
    icon: matchingColumns,
    name: 'Match Columns',
    words: {
      min: 5,
      max: 50,
    },
    // extra: {
    //   min: 0,
    //   max: 5,
    // },
    components: ['words', /*'extra',*/ 'background', 'avatars', 'progressbar',  'preview',],
  },

  "crossword-game": {
    type_id: 32,
    icon: matchingColumns,
    name: 'Crossword Game',
    words: {
      min: 5,
      max: 50,
    },
    // extra: {
    //   min: 0,
    //   max: 5,
    // },
    components: ['words', /*'extra',*/ 'background', 'avatars', 'progressbar',  'preview',],
  },

  "activity-diamonds": {
    type_id: 33,
    icon: matchingColumns,
    name: 'Diamonds and bombs',
    words: {
      min: 5,
      max: 50,
    },
    components: ['words', /*'extra',*/ 'background', 'avatars', 'progressbar',  'preview',],
  },

  "activity-four-in-a-row": {
    type_id: 34,
    icon: matchingColumns,
    name: 'Four in a row',
    words: {
      min: 5,
      max: 50,
    },
    components: ['words', /*'extra',*/ 'background', 'avatars', 'progressbar',  'preview',],
  },

  labelled_diagram_game: {
    icon: labelledDiagram,
    name: 'Labelled diagram',
    disabled: true,
  },
  bingo_game: {
    icon: bingo,
    name: 'Bingo',
    disabled: true,
  },
  domino_game: {
    icon: domino,
    name: 'Domino',
    disabled: true,
  },
  snake_game: {
    icon: snakeFrom,
    name: 'Snake',
    disabled: true,
  },
  who_am_i_game: {
    icon: whoAreYou,
    name: 'Who am I?',
    disabled: true,
  },
  black_witch_game: {
    icon: blackWitch,
    name: 'Black Witch by Morozov',
    disabled: true,
  },
  jeopardy: {
    icon: jeopardy,
    name: 'Jeopardy',
  },
  blockbusters_game: {
    icon: vlockbusters,
    name: 'Blockbusters by Morozov',
    disabled: true,
  },
  board_game: {
    icon: boardGame,
    name: 'Board game',
    disabled: true,
  },
  dobble_game: {
    icon: dobble,
    name: 'Dobble',
    disabled: true,
  },
  four_in_a_row_game: {
    icon: fourInRow,
    name: 'Four in a row by Morozov',
    disabled: true,
  },
  i_spy: {
    icon: spy,
    name: 'I spy',
    disabled: true,
  },
  random_wheel_game: {
    icon: randomWheel,
    name: 'Random wheel',
    disabled: true,
  },
  randomizer_game: {
    icon: randomizer,
    name: 'Randomizer',
    disabled: true,
  },
  sea_battle: {
    icon: seaBattle,
    name: 'Sea battle',
    disabled: true,
  },
  unscramble_words_game: {
    icon: unscrambleWords,
    name: 'Unscramble Words Games',
    disabled: true,
  },
  vocabulary_game: {
    icon: vocabularyGame,
    name: 'Vocabulary Game',
    disabled: true,
  },
  word_search_game: {
    icon: wordSearch,
    name: 'Word search',
    disabled: true,
  },
  flashcard_game: {
    icon: flashcard,
    name: 'Flashcard',
    disabled: true,
  },
  question_support: {
    icon: answer,
    name: 'Вопрос',
    isQuestion: true,
  },
}