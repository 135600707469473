<template>
  <div class="relative">
    <img
      class="hidden lg:block v-rating__background"
      :src="background"
      alt=""
      v-if="background"
    />
    <div class="v-rating" :class="{ background: !!background }">
      <div class="v-rating__body">
        <StudentsProgress
          :progress="getSortedStudents"
          :progress_type="progress_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StudentsProgress from "./StudentsProgress.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: { StudentsProgress },
  props: {
    // students: {
    //   type: Array,
    //   default: () => ([])
    // },
    // background: String,
    // logo: String,
    // additional: [Object, Array],
    // count: Number,
    currentGroupMarathonData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getSortedStudents() {
      if (!this.currentGroupMarathonData.results) return [];

      let result = cloneDeep(this.currentGroupMarathonData.results)
        .sort((a, b) => a.position - b.position)
        .map((el) => ({
          ...el,
          percent: el.avg_result,
        }));

      if (
        this.currentGroupMarathonData.additional_info &&
        this.currentGroupMarathonData.additional_info.show_type !== undefined
      ) {
        switch (
          Number(this.currentGroupMarathonData.additional_info.show_type)
        ) {
          case 0:
            return result.slice(0, 3);
          case 1:
            return result.slice(0, 10);
          case 3:
            return result;
          default:
            return result;
        }
      }

      return result;
    },
    progress_type() {
      if (!this.currentGroupMarathonData.additional_info) return 1;
      return this.currentGroupMarathonData.additional_info.rating_type || 1;
    },
    background() {
      return this.currentGroupMarathonData.background;
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-logo {
  .logo {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    object-fit: cover;
  }
}

.v-rating {
  box-shadow: 0 0 10px -5px black;
  padding: 10px;
  border-radius: 12px;
  // height: 600px;
  // overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 19px;

  @media (min-width: 2014px) {
    height: 600px;
    overflow-y: auto;
  }

  &.background {
    color: #fff;
    background-color: transparent !important;
    text-shadow: 1px 1px 2px black;

    .upload-button {
      color: #fff !important;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}
</style>
