<template>
  <div class="bottom-nav" :class="{ inactive, frame, 'new-ui': isTempNewUI }" :style="{ paddingBottom: paddingBottom }" ref="bottombar">
    <div class="flex items-center mx-auto justify-between w-full">
      <img
        class="bottom-nav__icon"
        src="@/assets/mobile/profile.png"
        :class="{ active: $route.name === 'profile' }"
        @click="router.push({ name: 'profile' })"
      />
      <img
        class="bottom-nav__icon"
        src="@/assets/mobile/group.png"
        :class="{ active: $route.name === 'groups' }"
        @click="router.push({ name: 'groups' })"
      />
      <img
        class="bottom-nav__icon"
        src="@/assets/mobile/main.png"
        :class="{ active: $route.name === 'home' }"
        @click="router.push({ name: 'home' }); emitHomeButtonPressed()"
      />
      <img
        class="bottom-nav__icon"
        src="@/assets/mobile/mail.png"
        :class="{ active: $route.name === 'chat' }"
        @click="router.push({ name: 'chat' })"
      />
      <img
        class="bottom-nav__icon"
        src="@/assets/mobile/statistic.png"
        :class="{ active: $route.name === 'statistics' }"
        @click="router.push({ name: 'statistics' })"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import useEventBus from "@/composables/useEventBus.js";
import { useRouter } from "vue-router";

export default {
  props: {
    frame: Boolean,
  },
  setup() {
    const bottombar = ref(null);
    const emitter = useEventBus();
    const router = useRouter();

    onMounted(() => {
      window.flutterBottomBarHeight = bottombar.value.clientHeight;
    })

    return {
      bottombar,
      emitter,
      router
    }
  },
  computed: {
    paddingBottom() {
      return ((window.flutterPaddingBottom || 0) + (this.inactive ? 15 : 0)) / 2 + 'px';
    },
    inactive() {
      return !['profile', 'groups', 'home', 'chat', 'statistics'].includes(this.$route.name)
    },
    isTempNewUI() {
      return this.$route.name === 'games' || this.$route.name === 'lesson'
    }
  },
  methods: {
    emitHomeButtonPressed() {
      this.emitter.emit('home-button-pressed')
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  // height: 50px;
  // min-height: 55px;
  background-color: #00a447;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &.new-ui {
    background-color: var(--v-color-indigo);
  }
  // padding-top: 5px;

  & > div {
    max-width: 400px;
    padding: 0 20px;
  }

  &.inactive {
    padding-top: 10px;
  }

  &__icon {
    height: 30px;

    &.active {
      // height: 70%;
      transform: translateY(-10%);
      background: #00a447;
      padding: 10px;
      box-sizing: content-box;
      border-radius: 12px;
    }
  }

  &.frame {
    left: unset;
    right: 0;
    height: 100%;
    width: auto;

    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0;

    & > div {
      flex-direction: column;
      height: 100%;
      width: auto;
      max-height: 400px;
      padding: 10px 6px;
    }
  }
}
</style>
