<!-- 
  Moved from teacher.vznaniya
-->
<template>
  <div class="d-flex" v-if="pager.pageCount > 1">
    <ul class="pagination mx-auto">
      <template v-if="pager.pageNumber !== 1">
        <li class="page-item">
          <a href="#" class="page-link" @click.prevent="get(1, pager.pageSize)"
            >&laquo;&laquo;</a
          >
        </li>
        <li class="page-item">
          <a
            href="#"
            class="page-link"
            @click.prevent="get(pager.pageNumber - 1, pager.pageSize)"
            >&laquo;</a
          >
        </li>

        <li class="page-item" v-if="pager.pageNumber > 5">
          <a
            href="#"
            class="page-link"
            @click.prevent="get(pager.pageStart - 1, pager.pageSize)"
          >
            ...
          </a>
        </li>
      </template>
      <template v-else>
        <li class="page-item disabled">
          <a href="#" class="page-link">&laquo;&laquo;</a>
        </li>
        <li class="page-item disabled">
          <a href="#" class="page-link">&laquo;</a>
        </li>
      </template>

      <li
        v-for="(page, pageIndex) in pager.pages"
        :key="pageIndex"
        :class="page === pager.pageNumber ? 'page-item active' : 'page-item'"
      >
        <a
          href="#"
          class="page-link"
          @click.prevent="get(page, pager.pageSize)"
          >{{ page }}</a
        >
      </li>

      <template v-if="pager.pageNumber !== pager.pageCount">
        <li v-if="pager.pageEnd !== pager.pageCount" class="page-item">
          <a
            href="#"
            class="page-link"
            @click.prevent="get(pager.pageEnd + 1, pager.pageSize)"
          >
            ...
          </a>
        </li>

        <li class="page-item">
          <a
            href="#"
            class="page-link"
            @click.prevent="get(pager.pageNumber + 1, pager.pageSize)"
            >&raquo;</a
          >
        </li>
        <li class="page-item">
          <a
            href="#"
            class="page-link"
            @click.prevent="get(pager.pageCount, pager.pageSize)"
            >&raquo;&raquo;</a
          >
        </li>
      </template>
      <template v-else>
        <li class="page-item disabled">
          <a href="#" class="page-link">&raquo;</a>
        </li>
        <li class="page-item disabled">
          <a href="#" class="page-link">&raquo;&raquo;</a>
        </li>
      </template>
    </ul>
  </div>
</template>


<script>
export default {
  name: "pagination",
  props: ["hideUrl"],
  data() {
    return {
      pager: {
        pageNumber: null,
        pageSize: null,
        pageCount: null,
        pageStart: null,
      },
      pageSize: 15,
    };
  },
  methods: {
    get: function (pageNumber, pageSize) {
      this.$emit("getEmit", pageNumber, pageSize);
    },
    pageGenerate: function (pageNumber, pageCount, pageSize, prefix = "") {
      if (pageSize === undefined) {
        pageSize = this.pageSize;
      }

      let pager = {
        pageNumber: +pageNumber,
        pageSize: +pageSize,
        pageCount: +pageCount,
      };

      if (pager.pageCount <= 1) {
        this.pager = pager;
        return;
      }

      if (!this.hideUrl) {
        this.workWithTheUrl(pageNumber, prefix);
      }

      pager.pageStart =
        (pageNumber % 5 === 0
          ? pageNumber - 5
          : pageNumber - (pageNumber % 5)) + 1;
      pager.pageEnd = pager.pageStart + 4;
      if (pager.pageEnd > pageCount) pager.pageEnd = pageCount;
      pager.pages = [];

      for (let i = pager.pageStart; i <= pager.pageEnd; i++) {
        pager.pages.push(i);
      }

      this.pager = pager;
    },
    workWithTheUrl(pageNumber, prefix) {
      if (
        (pageNumber && pageNumber !== 1) ||
        this.$route.query[prefix + "page"]
      ) {
        this.$router.push({
          query: { ...this.$route.query, [prefix + "page"]: pageNumber },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.d-flex {
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  // padding: 0.5rem 0.75rem;
  // margin-left: -1px;
  // line-height: 1.25;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00a447;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #00a447;
  border-color: #00a447;
}

.page-item {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px;
  transition: all 0.2s linear;
  box-shadow: 0px 0px 10px -5px black;

  &:hover {
    filter: brightness(1.2);
  }

  &:not(.active) {
    transition: all 0.2s linear;
    &:hover {
      background: #75757517;
    }
    .page-link {
      background: transparent;
      background-color: transparent;
      color: #757575;
    }
  }

  .page-link {
    border-radius: 50%;
    border: unset;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
