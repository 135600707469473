<template>
  <div class="v-footer">
    <div class="container mx-auto flex justify-between h-full">
      <div class="w-3/12 px-3 flex flex-col justify-between h-full" style="align-items: flex-start">
        <img class="v-footer__logo" src="@/assets/vznaniya_logo_new.png" alt="" v-if="!getPlatform">
        <img class="v-footer__logo" style="height: 75px;width: auto;" :src="getPlatform.footer_logo" alt="" v-else>
        <div class="text-xl slogan" v-if="!getPlatform">
          {{ $t('home.learn_easily') }} <br> {{ $t('home.teach_effectively') }}
        </div>
        <!-- <img class="v-footer__logo h-10" style="transform: translateX(-10%)" src="/images/logo-vznania-slogan.svg" alt=""> -->
        <div class="v-footer__text" v-if="!getPlatform">© 2018-2023 vznaniya.ru</div>
      </div>
      <div v-if="!getPlatform" class="w-4/12 px-3 flex flex-wrap justify-between items-center">
        <a href="https://youtu.be/TJ3Rf5bkZPs" target="_blank" class="v-footer__button">{{ $t('home.video_guide') }}</a>
        <a href="https://docs.google.com/document/d/1hh9jROjM_L13qCo90V82Q23TdtVUFnH6P_i_vwWB4xM/edit" target="_blank" class="v-footer__button">{{ $t('home.faq') }}</a>
        <a href="https://vznaniya.ru/2020" target="_blank" class="v-footer__button w-full">{{ $t('home.marathon', { p1: 2020 }) }}</a>
      </div>
      <div class="w-4/12 px-3 h-full flex flex-col items-end" :class="{'justify-between': !getPlatform, 'justify-end': getPlatform }">
        <div class="v-footer__text" v-if="!getPlatform">{{ $t('home.do_the_sets') }}</div>
        <div class="flex justify-between" v-if="!getPlatform">
          <a class="w-1/2 cursor-pointer" href="https://apps.apple.com/ru/app/%D0%B2%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D1%8F/id1440122401"><img src="@/assets/appstore.png" alt=""></a>
          <a class="w-1/2 cursor-pointer" href="https://play.google.com/store/apps/details?id=com.vznaniya1&hl=ru"><img src="@/assets/googleplay.png" alt=""></a>
        </div>
        <div v-if="!isPlatformNovakid" class="v-footer__text v-footer__link" @click="$root.$emit('v-policy')">{{ $t('home.user_agreement') }}</div>
        <a v-else class="v-footer__text v-footer__link" href="/platforms/novakid/privacyPolicy.pdf" target="_blank">{{ $t('home.user_agreement') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  computed: {
    ...mapGetters(['getPlatform']),
    ...mapState({
      profile: state => state.user.profile,
    }),
    isPlatformNovakid() {
      return this.profile.platform_id === 16 || this.profile.platform_id === 17;
    }
  }
}
</script>

<style lang="scss" scoped>
.slogan {
  color: white;
  line-height: 1.2;
}

.v-footer {
  height: 200px;
  background-image: url('/images/footer_bg.png');
  background-size: cover;
  padding: 20px 10px;

  &__logo {
    filter: brightness(0) invert(1);
    width: 100%;
  }

  &__text {
    color: #fff;
  }

  &__link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 13px 32px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    border: 2px solid #fff;
    cursor: pointer;
    transition: all .15s linear;

    &:hover {
      background-color: #fff;
      color: #00a447;
      box-shadow: 0 3px 14px -5px rgba(0,0,0,.33);
    }
  }
}
</style>
