import { withParams } from '../helpers/apiHelper.js';
import apiRequest from './apiRequest.js';

export const getStudentsRating = async (mode = 'month') => {
  return await apiRequest.get('marathon/top-students' + `?mode=${mode}`);
}

export const getTeachersRating = async (mode = 'month') => {
  return await apiRequest.get('marathon/top-teachers' + `?mode=${mode}`);
}

export const getMarathonInfo = async () => {
  return await apiRequest.get('marathon/info');
}

export const payMarathon = async () => {
  return await apiRequest.post('payments/create-marathon');
}

export const getCertificate = async () => {
  return await apiRequest.get('certificate/my');
}

export const getWinners = async (paylaod) => {
  return await withParams(paylaod, async (query) => {
    return await apiRequest.get('marathon/winners' + query);
  })
}

export const getStudentCertificate = async (groupId, studentId, payload) => {
  const endpoint = `certificate/marathon/${groupId}/${studentId}`
  return await withParams(payload, async (query) => {
    return await apiRequest.get(endpoint + query);
  })
}
