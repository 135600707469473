<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.35419 12.9993C1.35419 6.56753 6.5682 1.35352 13 1.35352C19.4318 1.35352 24.6459 6.56753 24.6459 12.9993C24.6459 19.4312 19.4318 24.6452 13 24.6452C6.5682 24.6452 1.35419 19.4312 1.35419 12.9993ZM13 2.97852C7.46566 2.97852 2.97919 7.46499 2.97919 12.9993C2.97919 18.5337 7.46566 23.0202 13 23.0202C18.5344 23.0202 23.0209 18.5337 23.0209 12.9993C23.0209 7.46499 18.5344 2.97852 13 2.97852Z"
        fill="#141029"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7917 8.9375C17.2404 8.9375 17.6042 9.30127 17.6042 9.75V10.2917C17.6042 10.7404 17.2404 11.1042 16.7917 11.1042C16.343 11.1042 15.9792 10.7404 15.9792 10.2917V9.75C15.9792 9.30127 16.343 8.9375 16.7917 8.9375Z"
        fill="#141029"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.20831 8.9375C9.65704 8.9375 10.0208 9.30127 10.0208 9.75V10.2917C10.0208 10.7404 9.65704 11.1042 9.20831 11.1042C8.75958 11.1042 8.39581 10.7404 8.39581 10.2917V9.75C8.39581 9.30127 8.75958 8.9375 9.20831 8.9375Z"
        fill="#141029"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.93251 16.4228C9.73029 16.0251 9.24464 15.8646 8.84495 16.0645C8.44359 16.2652 8.28091 16.7532 8.48159 17.1546L9.20831 16.7912C8.48159 17.1546 8.48177 17.1549 8.48195 17.1553L8.48235 17.1561L8.48322 17.1578L8.48528 17.1619L8.49068 17.1723L8.50671 17.2025C8.51973 17.2265 8.53749 17.2583 8.56015 17.2968C8.6054 17.3736 8.67054 17.4775 8.75688 17.5994C8.92893 17.8423 9.18904 18.1621 9.54872 18.4818C10.2749 19.1273 11.4079 19.7704 13 19.7704C14.592 19.7704 15.725 19.1273 16.4512 18.4818C16.8109 18.1621 17.071 17.8423 17.2431 17.5994C17.3294 17.4775 17.3945 17.3736 17.4398 17.2968C17.4625 17.2583 17.4802 17.2265 17.4932 17.2025L17.5093 17.1723L17.5147 17.1619L17.5167 17.1578L17.5176 17.1561L17.518 17.1553C17.5182 17.1549 17.5184 17.1546 16.7916 16.7912L17.5184 17.1546C17.719 16.7532 17.5564 16.2652 17.155 16.0645C16.7553 15.8646 16.2697 16.0251 16.0674 16.4228L16.0644 16.4285C16.0602 16.4362 16.0521 16.451 16.0398 16.4718C16.0152 16.5135 15.9746 16.5789 15.917 16.6601C15.8013 16.8235 15.6213 17.0453 15.3716 17.2673C14.8791 17.7051 14.1162 18.1454 13 18.1454C11.8837 18.1454 11.1209 17.7051 10.6283 17.2673C10.3786 17.0453 10.1986 16.8235 10.0829 16.6601C10.0254 16.5789 9.98472 16.5135 9.96015 16.4718C9.94789 16.451 9.93973 16.4362 9.93556 16.4285L9.93251 16.4228ZM16.0676 16.4225C16.0676 16.4225 16.0676 16.4225 16.0676 16.4225L16.0676 16.4225Z"
        fill="#141029"
      />
    </g>
  </svg>
</template>
