<template>
  <div class="v-listen" v-if="currentSets.length > 0">
    <div v-for="set in currentSets" :key="set.word.id">
      <div class="flex items-center justify-center mb-2">
        <LexicalAudioButton :big="true" class="mx-3" @click="playSound(set.word)"/>
        <LexicalAudioButton :big="true" class="shadow-md mx-3" :slow="true" @click="playSound(set.word, true)" />
      </div>

      <transition-group class="flex flex-wrap w-full lg:w-full mx-auto mb-10" name="list-complete" tag="div">
        <div class="p-2 w-1/2 md:w-1/4 list-complete-item" v-for="word in set.words" :key="word.id">
          <LexicalWordCard
            :big="true"
            class="cursor-pointer v-clickable h-full"
            :word="word"
            :useTranslate="true"
            :showTranslate="false"
            :selected="set.selected == word.id"

            @click="handleClick(set.word, word)"
            :adaptiveVerticalIllustration="true"
            :verticalIllustration="true"
          />
        </div>
      </transition-group>

      <div v-if="set.error" class="v-text--red text-lg text-center my-5">{{ 'Выбери правильный ответ' }}</div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import shuffle from 'lodash/shuffle';
import sampleSize from 'lodash/sampleSize';
import LexicalWordCard from '../../../components/LexicalWordCard.vue';
import LexicalAudioButton from '../../../components/LexicalAudioButton.vue';
import {computed, onMounted, ref} from "vue";

const store = useStore();
const props = defineProps({
  words: {
    type: Array,
    default: () => []
  },
});

const GAME_SLUG = 'listen';
const currentSets = ref([]);

const currentWords = computed(() => store.getters.getCurrentWords);

const validateTest = () => {
  currentSets.value.forEach(set => {
    $set(set, 'error', false);
  })

  return currentSets.value.every(set => {
    if (set.selected) {
      return true;
    }
    $set(set, 'error', true);
    return false;
  })
}

const getTestData = () => currentSets.value.map(set => ({
  id: set.word.id,
  test_type: GAME_SLUG,
  answer_id: set.selected,
}))

const playSound = (word, slow) => {
  const audio = new Audio(word.audio);

  if (slow) {
    audio.playbackRate = 0.5;
  }

  audio.play();
}

const handleClick = (sourceWord, word) => {
  const currentSet = currentSets.value.find(el => el.word.id == sourceWord.id)
  currentSet.selected = word.id;
}

onMounted(() => {
  currentSets.value = shuffle(props.words).map(word => {

    return {
      error: false,
      selected: null,
      word,
      words: shuffle([
        word,
        ...sampleSize(currentWords.value.filter(_word => _word.id !== word.id), 3)
      ])
    }
  })
})
</script>

<style lang="scss" scoped>

</style>
