<template>
	<div class="v-root-mobile" v-if="blockMobileDevice()">
		<div>Ты можешь выполнить задания урока на нашем сайте с ПК или в мобильном приложении</div>
		<div class="flex mt-5" v-if="hide">
			<a
				v-if="!getPlatform"
				class="w-1/2 cursor-pointer"
				:href="'https://apps.apple.com/ru/app/%D0%B2%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D1%8F/id1440122401'"
				><img src="/images/appstore.png" alt=""
			/></a>
			<a v-else-if="getPlatform.apple" class="w-1/2 cursor-pointer" :href="getPlatform.apple"
				><img src="/images/appstore.png" alt=""
			/></a>
			<a
				v-if="!getPlatform"
				class="w-1/2 cursor-pointer"
				:href="'https://play.google.com/store/apps/details?id=com.vznaniya1&hl=ru'"
				><img src="/images/googleplay.png" alt=""
			/></a>
			<a v-else-if="getPlatform.google" class="w-1/2 cursor-pointer" :href="getPlatform.google"
				><img src="/images/googleplay.png" alt=""
			/></a>
		</div>
		<div class="w-full flex justify-center mt-10">
			<div class="v-button v-button--gradient-red" @click="logout">{{ $t('home.log_out') }}</div>
		</div>
	</div>
	<div v-else class="v-root flex flex-col">
		<div v-if="(isProfileLoaded || withSlug) && asyncFlag" class="flex flex-col flex-grow main-vzn-container">
			<v-header v-if="!showNewLessonHeader()" class="hidden lg:block no-print" />
			<LessonHeaderNew v-else />
			<div class="w-full flex flex-col items-center block lg:hidden" v-if="previewMode">
				<div class="preview-warning">{{ 'Режим предпросмотра. Прогресс и результат сохранены не будут!' }}</div>
			</div>
			<router-view class="flex-grow" />

			<v-footer v-if="!route.params.gname && !hideFooter" class="no-print hidden lg:block" />
		</div>
		<div v-else class="fullscreen flex-center">
			<loader />
		</div>

		<transition name="fade" mode="out-in">
			<div v-if="isSiteBusy" class="fullscreen-fixed">
				<loader />
			</div>
		</transition>

		<VPolicy />
		<VAuth />
		<VRegister />
		<VTempTeacherGroup />
		<ReviewModal />
		<BannerModal />
		<DonateModal />
		<CustomModal />

		<VModal />
	</div>
</template>

<script setup>
import VHeader from '@/components/Header.vue'
import VModal from '@/components/modals/MainModal.vue'
import VPolicy from '@/components/modals/PolicyModal.vue'
import VAuth from '@/components/modals/AuthModal.vue'
import VRegister from '@/components/modals/RegisterModal.vue'
import VTempTeacherGroup from '@/components/modals/TempTeacherAddToGroup.vue'
import ReviewModal from '@/components/modals/ReviewModal.vue'
import BannerModal from '@/components/modals/BannerModal.vue'
import DonateModal from '@/components/modals/DonateModal.vue'
import CustomModal from '@/components/modals/CustomModal.vue'
import { isMobile } from 'mobile-device-detect'
import { getSlug, getOnlypreview, clearLocalStorageButLessonTimers } from './helpers.js'
// import CarrotQuestWidget from '../public/CarrotQuestWidget.js'
import LessonHeaderNew from '@/components/LessonHeaderNew.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import { getUrlToken } from './helpers.js'
import useEventBus from '@/composables/useEventBus.js'
const emitter = useEventBus()

const checkMobile = isMobile
const route = useRoute()
const router = useRouter()
const store = useStore()
const hide = ref(false)
const withSlug = ref(false)
const allowedMobileRouteNames = ref(['assessment', 'video', 'lesson', 'games', 'challenge'])

/*if (
	window.location.protocol + '//' + window.location.host !== process.env.STUDENT_URL  &&
	window.location.host !== 'localhost:8083'
) {
	store.dispatch('GET_PLATFORM')

	if (store.getters.getPlatform) {
		document.title = store.getters.getPlatform.name
		let link = document.querySelector("link[rel~='icon']")
		if (!link) {
			link = document.createElement('link')
			link.rel = 'icon'
			document.getElementsByTagName('head')[0].appendChild(link)
		}
		link.href = store.getters.getPlatform.favicon
	}
}*/

hide.value = true

window.isTrueMobile = isTrueMobile()

getUrlToken()

const slug = getSlug()

const asyncFlag = ref(false)

if (!previewMode()) {
	console.log('FIRST IF')
	store.dispatch('GET_PROFILE_DATA')
	store.dispatch('UPDATE_MY_CHALLENGES')
} else if (!route.query.group_id) {
	router.replace({ name: route.name, params: { ...route.params }, query: { ...route.query, onlypreview: true } })
}

// Модалка eventbus
emitter.on('v-modal', (payload) => {
	emitter.emit('v-modal-in-component', { ...payload, size: 'md' })
})

withSlug.value = !!slug

onMounted(() => {
	if (!previewMode() && !store.getters.isProfileLoaded) {
		emitter.emit('auth', { forSaveTitle: true })
		return
	}
	setTimeout(() => (asyncFlag.value = true), 1000)
})

function blockMobileDevice() {
	if (
		allowedMobileRouteNames.value.includes(route.name) &&
		(window.FlutterLogChannel || window.ReactNativeWebView || window.flutter_inappwebview || previewMode())
	)
		return false
	return (checkMobile || isTrueMobile()) && process.env.VITE_DISABLE_MOBILE_BLOCKER !== 'true'
}

function previewMode() {
	return !!route.query.onlypreview || (!!route.query.slug && !route.query.group_id)
}
function showNewLessonHeader() {
	return route.name === 'challenge'
}
function hideFooter() {
	return route.name === 'challenge'
}
function isTrueMobile() {
	return typeof window.orientation !== 'undefined'
}

const logout = () => {
	clearLocalStorageButLessonTimers()
	// if (this.getPlatform) {
	//   location.href = this.getPlatform.domain + '/logoutNew?student=true';
	// } else {
	//   location.href = import.meta.env.VITE_APP_URL + '/logoutNew?student=true';
	// }
}

const isProfileLoaded = computed(() => store.getters.isProfileLoaded)
const isSiteBusy = computed(() => store.getters.isSiteBusy)
const profile = computed(() => store.getters.profile)
const isTeacher = computed(() => store.getters.isTeacher)
const getPlatform = computed(() => store.getters.isTeacher)
const GET_PLATFORM = () => store.dispatch('GET_PLATFORM')
const UPDATE_MY_CHALLENGES = () => store.dispatch('UPDATE_MY_CHALLENGES')
</script>

<style lang="scss">
@import './styles/variables.scss';
.preview-warning {
	width: 100%;
	background-color: #009fe3;
	text-align: center;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.v-root {
	background-color: #fff !important;
	min-height: 100vh;

	@media print {
		background-color: #fff !important;
	}
}
.bg-container {
	background-color: $bgMain;
}

.fullscreen-fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 99;

	background: rgba($color: #000000, $alpha: 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
}

.v-root-mobile {
	background-color: rgb(233, 233, 233);
	min-height: calc(100vh);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	text-align: center;
	flex-direction: column;
}
</style>

<style lang="scss">
@font-face {
	font-family: Mikado;
	src: url('/fonts/MikadoRegular.otf') format('opentype');
}
</style>
