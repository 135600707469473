<template>
  <div
    class="assessment-comment-component__chat"
    ref="chatContentContainerElement"
  >
    <div class="assessment-comment-component__chat-header">
      <div class="assessment-comment-component__chat-header__svg">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.8">
            <path
              d="M14.2082 10.3125H7.7915C7.41567 10.3125 7.104 10.0008 7.104 9.625C7.104 9.24917 7.41567 8.9375 7.7915 8.9375H14.2082C14.584 8.9375 14.8957 9.24917 14.8957 9.625C14.8957 10.0008 14.584 10.3125 14.2082 10.3125Z"
              fill="url(#paint0_linear_9042_59892)"
            />
            <path
              d="M14.6668 20.4616C14.3552 20.4616 14.0435 20.37 13.7777 20.1958L9.87265 17.5924H6.41683C3.2635 17.5924 1.146 15.4749 1.146 12.3216V6.82162C1.146 3.66828 3.2635 1.55078 6.41683 1.55078H15.5835C18.7368 1.55078 20.8543 3.66828 20.8543 6.82162V12.3216C20.8543 15.2366 19.0393 17.2716 16.271 17.5558V18.8575C16.271 19.4533 15.9501 19.9941 15.4276 20.2691C15.1893 20.3974 14.9235 20.4616 14.6668 20.4616ZM6.41683 2.91661C4.05183 2.91661 2.521 4.44744 2.521 6.81244V12.3124C2.521 14.6774 4.05183 16.2083 6.41683 16.2083H10.0835C10.221 16.2083 10.3493 16.2449 10.4685 16.3274L14.5477 19.0408C14.6485 19.105 14.7402 19.0775 14.786 19.05C14.8318 19.0225 14.9052 18.9674 14.9052 18.8483V16.8958C14.9052 16.5199 15.2168 16.2083 15.5927 16.2083C17.9577 16.2083 19.4885 14.6774 19.4885 12.3124V6.81244C19.4885 4.44744 17.9577 2.91661 15.5927 2.91661H6.41683Z"
              fill="url(#paint1_linear_9042_59892)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_9042_59892"
              x1="3.21393"
              y1="9.62398"
              x2="3.68387"
              y2="12.287"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#00FFAA" />
              <stop offset="0.530854" stop-color="#4579F5" />
              <stop offset="0.999815" stop-color="#9C42F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_9042_59892"
              x1="-8.69361"
              y1="10.9922"
              x2="10.1732"
              y2="30.6547"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#00FFAA" />
              <stop offset="0.530854" stop-color="#4579F5" />
              <stop offset="0.999815" stop-color="#9C42F5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="assessment-comment-component__chat-header__text">
        {{$t("lesson.comments_chat_title")}}
      </div>
    </div>
    <div
      v-if="comments && comments.length"
      class="assessment-comment-component__chat-content-container slider"
      ref="chatContentContainerElement"
    >
      <assessment-comment-chat-content
        v-for="comment in comments"
        :key="`examine-lesson-comment-${comment.id}`"
        :comment="comment"
        @delete="onDeleteComment"
      />
    </div>
    <div v-else class="assessment-comment-component__chat-content-placeholder">
      {{ $t("lesson.comments_chat_messages_placeholder") }}
    </div>
    <div class="assessment-comment-component__chat-inputs">
      <div class="assessment-comment-component__chat-inputs__input-container">
        <div class="assessment-comment-component__chat-inputs__input">
          <input
            :placeholder="$t('lesson.comments_input_placeholder')"
            v-model="inputValue"
            @keydown.enter="onSendComment"
          />
        </div>
<!--        <button>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                fill="#141029"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9V9.5C16.25 9.91421 15.9142 10.25 15.5 10.25C15.0858 10.25 14.75 9.91421 14.75 9.5V9C14.75 8.58579 15.0858 8.25 15.5 8.25Z"
                fill="#141029"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.5 8.25C8.91421 8.25 9.25 8.58579 9.25 9V9.5C9.25 9.91421 8.91421 10.25 8.5 10.25C8.08579 10.25 7.75 9.91421 7.75 9.5V9C7.75 8.58579 8.08579 8.25 8.5 8.25Z"
                fill="#141029"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.16866 15.1601C8.982 14.793 8.5337 14.6449 8.16475 14.8293C7.79427 15.0146 7.6441 15.4651 7.82934 15.8356L8.50016 15.5002C7.82934 15.8356 7.82951 15.8359 7.82968 15.8363L7.83005 15.837L7.83085 15.8386L7.83275 15.8423L7.83774 15.852L7.85254 15.8798C7.86456 15.902 7.88095 15.9314 7.90186 15.9669C7.94364 16.0378 8.00376 16.1337 8.08346 16.2462C8.24227 16.4704 8.48237 16.7656 8.81439 17.0607C9.48474 17.6566 10.5306 18.2502 12.0002 18.2502C13.4698 18.2502 14.5156 17.6566 15.1859 17.0607C15.518 16.7656 15.7581 16.4704 15.9169 16.2462C15.9966 16.1337 16.0567 16.0378 16.0985 15.9669C16.1194 15.9314 16.1358 15.902 16.1478 15.8798L16.1626 15.852L16.1676 15.8423L16.1695 15.8386L16.1703 15.837L16.1706 15.8363C16.1708 15.8359 16.171 15.8356 15.5002 15.5002L16.171 15.8356C16.3562 15.4651 16.2061 15.0146 15.8356 14.8293C15.4666 14.6449 15.0183 14.793 14.8317 15.1601L14.8289 15.1654C14.825 15.1725 14.8175 15.1861 14.8062 15.2053C14.7835 15.2438 14.7459 15.3042 14.6928 15.3792C14.586 15.5299 14.4199 15.7347 14.1894 15.9396C13.7347 16.3437 13.0306 16.7502 12.0002 16.7502C10.9698 16.7502 10.2656 16.3437 9.81094 15.9396C9.58045 15.7347 9.41431 15.5299 9.3075 15.3792C9.25438 15.3042 9.21685 15.2438 9.19417 15.2053C9.18286 15.1861 9.17532 15.1725 9.17147 15.1654L9.16866 15.1601ZM14.8318 15.1598C14.8319 15.1598 14.8319 15.1598 14.8319 15.1598L14.8318 15.1598Z"
                fill="#141029"
              />
            </g>
          </svg>
        </button>-->
      </div>
<!--      <voice-record-component-->
<!--        :duration-restriction="voiceRecordDuration"-->
<!--        is-min-->
<!--      />-->
    </div>
  </div>
</template>

<script>
import AssessmentCommentChatContent from "./AssessmentCommentChatContent.vue";
import {mapState} from "vuex";
import { getCurrentInstance } from 'vue'

export default {
  components: {AssessmentCommentChatContent},
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    comments: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    inputValue: "",
  	instance: getCurrentInstance(),
  }),
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
  },
  methods: {
    onSendComment() {
      if (
        typeof this.profile.student_id !== 'number' ||
        typeof this.question.id !== "number" ||
        !this.inputValue.trim().length
      ) {
        return;
      }
      this.$store.dispatch("SEND_CURRENT_LESSON_COMMENT", {
        student_id: this.profile.student_id,
        question_id: this.question.id,
        message: this.inputValue.trim()
      }).then(this.scrollToLastComment);
      this.inputValue = "";
    },
    onDeleteComment(id) {
      this.$store.dispatch("DELETE_CURRENT_LESSON_COMMENT", {id})
    },
    scrollToLastComment() {
		const refs = this.instance.proxy?.$refs
      if (!refs.chatContentContainerElement) return;
      const contentElements = refs.chatContentContainerElement.querySelectorAll(
        ".assessment-comment-component__chat-content"
      );
      if (!contentElements?.length) return;
      contentElements[contentElements.length - 1].scrollIntoView({
        block: "nearest",
      });
    },
  },
  mounted() {
    this.scrollToLastComment()
  }
}
</script>

<style lang="scss">
  .assessment-comment-component__chat {
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
    width: 330px;
    padding: 30px;
    border-radius: 8px;
    background: #ffffff;
    z-index: 5;
    &.left {
      left: unset;
      right: 80px;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 12px;
      &__text {
        color: #141029;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    &-content {
      margin-top: 18px;
      &-container {
        max-height: 200px;
        overflow-y: auto;
      }
      &-placeholder {
        margin: 10px 12px 0;
        color: #141029;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        opacity: 0.3;
      }
    }
    &-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
      &__avatar {
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;
        overflow: hidden;
        object-fit: contain;
        border-radius: 50%;
        background: #f2f2f4;

        img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
      }
      &__name {
        color: #141029;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    &-audio {
      margin-top: 8px;
    }
    &-message {
      margin-top: 8px;
      word-break: break-word;
      color: #141029;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    &-inputs {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      //padding: 12px 10px 12px 12px;

      &__input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        padding: 11px 12px;
        border-radius: 8px;
        border: 1px solid #eeeff2;
      }
      &__input {
        input {
          border: none;
          outline: none;
          &::placeholder {
            color: #141029;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            opacity: 0.3;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1810px) {
    .assessment-comment-component__chat {
      left: unset;
      right: 80px;
    }
  }
  @media screen and (max-width: 1024px) {
    .assessment-comment-component__chat {
      position: relative;
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;
      transform: translateY(0);
      width: calc(100% - 10px);
      margin: 0 5px;

      &-content {
        &-container {
          max-height: 350px;
        }
      }
      &-inputs {
        &__input-container {
          width: 100%;
        }
        &__input {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
  }
</style>