export const withFilters = async (payload = {}, callback) => {
  let { page, filter } = payload;
  if (!page && !filter) return await callback('');

  let query = '';
  
  if (filter && !page) {
    const params = new URLSearchParams(filter).toString();
    query = '/filter?' + params;
  } else if (!filter && page) {
    query = '/filter?page=' + page;
  } else if (filter && page) {
    filter = { ...filter, page };
    const params = new URLSearchParams(filter).toString();
    query = '/filter?' + params;
  }

  return await callback(query);
}

export const withParams = async (payload = {}, callback) => {
  const params = new URLSearchParams(payload).toString();
  const query = '?' + params;

  return await callback(query);
}

export const convertToFormData = (data, useDeepLevel = false) => {
  let formData = new FormData();
  let deepLevel = 0;
  formData = (function recurse(data, fd, prefix) {
      deepLevel++;
      for (let key in data) {
          if (data[key] !== undefined && data[key] !== null && (useDeepLevel && deepLevel === 1 ? data[key] !== 'null' : true)) {
              let fdKey = key;
              if (prefix) {
                  fdKey = `${prefix}[${key}]`;
              }

              if (typeof data[key] === 'object' && !(data[key] instanceof Blob) && !(data[key] instanceof File)) {
                  formData = recurse(data[key], formData, fdKey);
              } else {
                if ((data[key] instanceof Blob) && data[key].name) {
                  formData.append(fdKey, data[key], data[key].name);
                } else {
                  formData.append(fdKey, data[key]);
                }
              }
          }
      }
      return formData;
  })(data, formData);

  return formData;
}