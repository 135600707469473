import statistics from './statistics.js'
import groups from './groups.js'
import home from './home.js'
import messages from './messages.js'
import lesson from './lesson.js'
import games from './games.js'
import marathon from './marathon.js'
import challenge from './challenge.js'
import chat from './chat.js'

export default {
	home,
	groups,
	messages,
	statistics,
	lesson,
	games,
	marathon,
	challenge,
	chat,
}
