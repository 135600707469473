<template>
	<transition name="fade" mode="out-in">
	  <div class="v-modal" v-if="show" @click.self="show = false">
  		<div class="v-modal__wrapper">
  			<div class="lg:hidden flex items-center justify-between w-full mb-5">
          <div class="v-text--bold lext-lg">{{ $t('home.delete-account-title') }}</div>
          <img class="v-clickable" style="width: 25px" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false">
        </div>
        <img class="hidden lg:block v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false">
        <div class="textarea" >
          <textarea v-model="text" :placeholder="$t('home.what_you_want')"/>
          <div class="v-button v-button--raised-gray absolute flex items-center">
            <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="30.245" height="25" viewBox="0 0 30.245 30"><path d="M29.482.756a2.6,2.6,0,0,0-2.641-.63L1.8,8.188a2.572,2.572,0,0,0-.534,4.667,1.185,1.185,0,0,0,.213.1l7.668,2.692v3.729a1.177,1.177,0,0,0,1.181,1.172H14.3l2.876,7.987a1.158,1.158,0,0,0,.1.207A2.571,2.571,0,0,0,19.493,30a2.691,2.691,0,0,0,.3-.017,2.568,2.568,0,0,0,2.181-1.766L23,25.087a1.17,1.17,0,0,0-.758-1.477,1.183,1.183,0,0,0-1.489.752l-1.025,3.129a.24.24,0,0,1-.384.1L16.42,19.473l5.729-9.859a1.165,1.165,0,0,0-.177-1.4A1.188,1.188,0,0,0,20.564,8L10.217,13.537,2.422,10.8a.238.238,0,0,1,.106-.382L27.571,2.355a.223.223,0,0,1,.24.057.22.22,0,0,1,.058.24L22.676,18.5a1.17,1.17,0,0,0,.758,1.477,1.184,1.184,0,0,0,1.489-.752L30.116,3.378A2.554,2.554,0,0,0,29.482.756ZM14.43,18.2H11.509v-2.7l6.514-3.487Zm0,0" transform="translate(0 0.001)" fill="#aaa"></path></svg>
            <div @click="sendReview">{{ $t('home.login_modal.send') }}</div>
          </div>
        </div>
  		</div>
  	</div>
	</transition>
</template>

<script>
import { sendReview as sendReviewAPI } from '@/api/user.js';
import { ref, reactive } from 'vue';
import useEventBus from "@/composables/useEventBus.js";
export default {
  name: 'review-modal',
  setup() {
    const emitter = useEventBus();
    const show = ref(false);
    const text = ref('');
    const state = reactive({params: {}});

    emitter.on('review', (params = {}) => {
      show.value = true;
      state.params = { ...params };
      text.value = '';
    })

    const sendReview = async () => {
      if (text.value.length === 0) return;

      try {
        await sendReviewAPI({
          star: 5,
          message: text.value,
        });

        show.value = false;

        emitter.emit('v-modal', {
          text: this.$t('home.successfully_sent'),
          size: 'sm',
          closeOutside: false,
          closeButton: false,
          buttons: [{
            text: this.$t('games.ok'),
            class: 'v-button v-button--gradient-red',
            callback: () => {
              emitter.emit('v-modal', { close: true });
            }
          }]
        });
      } catch (err) {
        emitter.emit('v-modal', {
          text: this.$t('home.error_sent'),
          size: 'sm',
        });
      }
    }
    return {
      show,
      text,
      params: state.params,
      sendReview
    }
  },
};
</script>

<style scoped lang="scss">
.v-button--raised-gray {
  bottom: 10px;
  right: 10px;
}
.textarea {
  border: 1px solid gray;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 10px;
  padding-bottom: 70px;
  position: relative;

  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    background-color: transparent;
  }
}

.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  form {
    width: 90%;
  }

  &__wrapper {
    // background-color: #fff;
    background: url(/images/auth-bg.jpg);
    background-size: cover;
    border-radius: 12px;
    padding: 50px 25px;

    width: 600px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    @media (max-width: 1024px) {
      padding: 10px;
      width: 95vw;
      height: 50vh;
    }

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }

    &.sm {
      width: 350px;
      min-height: 250px;
    }

    &.md {
      width: 450px;
      min-height: 250px;
    }

    &.lg {
      width: 600px;
      min-height: 350px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
	.v-modal__form {
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.v-modal {
		// padding: 25px;
	}
	input::placeholder {
		font-size: 16px;
	}
}
</style>
