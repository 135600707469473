<template>
  <div class="v-expanded">
    <div @click="onHeaderClick">
      <slot 
        name="header" 
        v-bind:openState="openState" 
        v-bind:tabs="currentTabs" 
        v-bind:toggleTab="toggleTab" 
      />
    </div>
    <div class="v-expanded__body" :class="{ open: openState || (isTabMode && currentTabs.some(el => el)), mobile }">
      <slot name="body" v-bind:tabs="currentTabs" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    forceExpanded: Boolean,
    tabs: Number,
    mobile: Boolean,
  },
  mounted() {
    if (this.forceExpanded) {
      this.openState = true;
    }

    if (this.tabs && this.tabs > 0) {
      this.currentTabs = new Array(this.tabs).fill(false);
    }
  },
  data: () => ({
    openState: false,
    currentTabs: [],
  }),
  watch: {
    openState(value) {
      this.$emit('state', value)
    }
  },
  computed: {
    isTabMode() {
      return this.tabs !== undefined;
    }
  },
  methods: {
    onHeaderClick() {
      if (!this.isTabMode) {
        this.openState = !this.openState
      }
    },
    toggleTab(tabIndex) {
      if (this.currentTabs[tabIndex] === undefined) return;

      const currentState = this.currentTabs[tabIndex];
      this.currentTabs = this.currentTabs.map(el => false);
      if (!currentState) {
        this.currentTabs[tabIndex] = true;
      }
    },
    close() {
      this.openState = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expanded {

  &__header {
    padding-right: 75px;
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    // transition: all .5s linear;

    &.open {
      max-height: 1000px;
      overflow-y: auto;
    }

    &.mobile.open {
      max-height: initial;
      overflow-y: initial;
    }
  }
}
</style>