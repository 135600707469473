import '../bootstrap.js'

// Webkit fix for getUserMedia etc
import adapter from 'webrtc-adapter'
console.log('WEB RTC ENABLED', adapter)

import { createApp } from 'vue'
import FlutterBridge from './helpers/flutterBridge.js'
import './libs/WIRISplugins.js'
// AudioRecordPoly
// import AudioRecorder from 'audio-recorder-polyfill'
// window.MediaRecorder = AudioRecorder
// import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
// AudioRecorder.encoder = mpegEncoder
// AudioRecorder.prototype.mimeType = 'audio/mpeg'

import App from './App.vue'
import AppMobile from './screens/mobile/App.vue'
import '@/styles/index.scss';

const app = createApp(App)
import draggable from 'vuedraggable'
draggable.compatConfig = { MODE: 3 }
app.component('draggable', draggable)

import { isMobile } from 'mobile-device-detect'

const debugMobile = false
const isTrueMobile =
	debugMobile ||
	(typeof window.orientation !== 'undefined' && isMobile) ||
	window.FlutterLogChannel ||
	window.ReactNativeWebView ||
	window.flutter_inappwebview
const isNativeMobile = debugMobile || FlutterBridge.isFlutterApp()
const isNovakidApp = FlutterNovakidBridge.isFlutterApp()

// Директива для грязного фикса подсказок на андроиде
const onFocus = (evt) => {
	const sourceAttribute = evt.target.getAttribute('predioffSourceType') || 'text'
	evt.target.setAttribute('type', sourceAttribute)
}
app.directive('predioff', {
	mounted(el) {
		if (!(isNativeMobile || flutterNovakidBridge.isFlutterApp())) return

		if (el) {
			const sourceAttribute = el.getAttribute('type')
			el.setAttribute('type', 'password')
			el.setAttribute('predioffSourceType', sourceAttribute)
			el.addEventListener('focus', onFocus)
		}
	},
	unmounted(el) {
		el.removeEventListener('focus', onFocus)
	},
})

window.isTrueMobile = isTrueMobile
window.isNativeMobile = isNativeMobile
window.isNovakidApp = isNovakidApp

window.BASE_URL =
	import.meta.env.NODE_ENV === 'production'
		? 'https://vznaniya.ru'
		: import.meta.env.VITE_APP_URL
			? import.meta.env.VITE_APP_URL
			: 'https://develop.vznaniya.ru'

/*********************************************
 * ADD SERVICE WORKERS FOR PWA
 *********************************************/
// window.addEventListener('load', () => {
// 	if ('serviceWorker' in navigator) {
// 		navigator.serviceWorker.register('/pwa-service-worker.js');
// 	}
// })
/**
 * REMOVE ALL SERVICE WORKERS
 */
try {
	navigator.serviceWorker.getRegistrations().then(function (registrations) {
		for (let registration of registrations) {
			registration
				.unregister()
				.then(() => self.clients.matchAll())
				.then((clients) => {
					clients.forEach((client) => {
						if (client.url && 'navigate' in client) {
							client.navigate(client.url)
						}
					})
				})
		}
	})
} catch (e) {
	console.log('Unable to unregister service workers')
	console.log(e.message)
}
/*********************************************
 *********************************************
 *********************************************/

/***********************
 * JS/CSS LIBS SECTION *
 ***********************/

// Webkit fix for getUserMedia etc
// import adapter from 'webrtc-adapter';
// console.log('WEB RTC ENABLED', adapter)

// Lib for marathon countdown
import './libs/countdown/countDown.js'
import './libs/countdown/countDown.min.css'
import './libs/countdown/app.css'

// EVENT BUS
// export const eventBus = new Vue();
// window.vueEventBus = eventBus;

// i18n
import { setupI18n } from './i18n/index.js'

const i18nConf = setupI18n()
app.use(i18nConf)

// Plugins
import router from './router/index.js'
import { store } from './store/index.js'
app.use(router)

// For touch gestures
// import { VueHammer } from 'vue2-hammer'
// Vue.use(VueHammer)
import Vue2TouchEvents from 'vue2-touch-events'
app.use(Vue2TouchEvents, {
	swipeTolerance: 65,
})

// use Vue plugin with options
// Для оптимизации и более глубокого контроля над svg, переобразует файл svg в img теге в тег <svg></svg>
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";
app.use(VueSvgInlinePlugin, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});

import InfiniteLoading from 'vue-infinite-loading'
app.use(InfiniteLoading, {
	/* options */
})

// Компонент лодера
import Loader from './components/LoaderBrainLogo.vue'
app.component('loader', Loader)

// Компонент для сокращения слов
import Shortify from './components/NameShortify.vue'
app.component('shortify', Shortify)

// Футер (дял визуала пришлось переиспользовать его)
import VFooter from './components/Footer.vue'
app.component('v-footer', VFooter)

// Хелпер - функции
import helpers, { getOnlypreview } from './helpers.js'
app.use(helpers)

// Для того, что бы держать позицию чата внизу
import VueChatScroll from 'vue-chat-scroll'
app.use(VueChatScroll)

// Youtube api
import YouTube from 'vue3-youtube'
app.component('VueYoutube', YouTube)

// Toaster popup
import { VueToastr } from 'vue-toastr'
app.use(VueToastr, {})

// Custom scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
app.use(PerfectScrollbar)

// Автоширина для инпута
import VueInputAutowidth from 'vue-input-autowidth'
app.use(VueInputAutowidth)

// Youtube, Vimeo, Direct Video
import VuePlyr from '@skjnldsv/vue-plyr'
app.use(VuePlyr, {
	plyr: {},
})

// Masorny
// import {VueMasonryPlugin} from 'vue-masonry';
// Vue.use(VueMasonryPlugin)

// Vue Resize Text
import VueResizeText from 'vue-resize-text'
app.use(VueResizeText)

// Worker for inactive tab intervals
// import VueWorker from 'vue-worker'
// app.use(VueWorker)

// Tooltip
import VTooltip from 'v-tooltip'
app.use(VTooltip)

// Socket
import VueSocketIO from 'vue-socket.io'

// Vue.use(new VueSocketIO({
//     debug: process.env.NODE_ENV != 'production',
//     connection: process.env.NODE_ENV == 'production' ? 'https://vznaniya.ru:3443' : process.env.APP_URL ? process.env.APP_URL : 'https://develop.vznaniya.ru:3441'
// }));

// Vue.use(new VueSocketIO({
//     debug: process.env.NODE_ENV != 'production',
//     connection: process.env.NODE_ENV == 'production' ? 'https://vznaniya.ru:3443' : process.env.APP_URL ? process.env.APP_URL : 'https://develop.vznaniya.ru:3441'
// }));
// if (!getOnlypreview()) {
// 	app.app(new VueSocketIO({
// 		debug: true,
// 		connection: `${process.env.SOCKET_URL}/chat`,
// 		options: { useConnectionNamespace: true, namespaceName: 'chat' }
// 	}))
//
// 	app.app(new VueSocketIO({
// 		debug: true,
// 		connection: `${process.env.SOCKET_URL}/notification`,
// 		options: { useConnectionNamespace: true, namespaceName: 'notification' }
// 	}))
// }

import useSocketIo from './composables/useSocketIo.js'
const socket = useSocketIo()
socket.connection('chat')
socket.connection('notification')

import CKEditor from '@ckeditor/ckeditor5-vue';

app.use(CKEditor)

// stuff: (process.env.GAMES_URL) + '/stuff'

import VueGtm from '@gtm-support/vue2-gtm'
import flutterNovakidBridge from './helpers/flutterNovakidBridge.js'
import FlutterNovakidBridge from './helpers/flutterNovakidBridge.js'
import AppNovakid from './screens/mobile/AppNovakid.vue'
if (store.state.profile && (store.state.profile.platform_id === 16 || store.state.profile.platform_id === 17)) {
	app.use(VueGtm, {
		id: 'GTM-PW8L2WB', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
		// queryParams: {
		// 	// Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
		// 	gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
		// 	gtm_preview: 'env-4',
		// 	gtm_cookies_win: 'x',
		// },
		// defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
		// compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
		// nonce: '2726c7f26c', // Will add `nonce` to the script tag
		enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
		// debug: true, // Whether or not display console logs debugs (optional)
		// loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
		vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		// ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
		trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
	})
}

const getAppWrapper = () => {
	if (isNovakidApp) return AppNovakid
	else if (isNativeMobile) return AppMobile
	else return App
}

app.use(store)
router.isReady().then(() => {
	app.mount('#app')
})
