export default {
	'request-to-delete': 'Удалить',
	'delete-account-title': 'Удаление аккаунта',
	the_learnt_words: 'Выучено слов',
	completed_out: 'Выполнено {p1} из {p2} уроков',
	all_sets: 'Показать все',
	deadline: 'Крайний срок сдачи',
	current: 'Актуальные',
	completed: 'Выполненные',
	expired: 'Просроченные',
	all_groups: 'Все группы',
	search_by_name: 'Поиск',
	my_sets: 'Мои уроки',
	better_late: 'Лучше поздно, чем никогда!',
	score: 'Выполнен на',
	open: 'Открыть',
	continue: 'Продолжить',
	improve_the_result: 'Улучшить',
	done: 'Завершен',
	result: 'Результат',
	сhange_details: 'Редактировать',
	save: 'Сохранить',
	mobile_1: 'Выполняй игровые задания в любом месте и в любое время',
	mobile_2: 'Ты всегда сможешь улучшить свой результат, выполнив урок еще раз',
	mobile_3: 'Повышай свой рейтинг, участвуй в марафонах',
	mobile_4: 'Весело учиться',
	mobile_5: 'Эффективно учить',

	name_and_last: 'Ваши имя и фамилия',
	school: 'Ваша школа',
	city: 'Ваш город',
	cancel: 'Отменить',
	accept: 'Принять',
	change_the_password: 'Изменить пароль',
	current_password: 'Текущий пароль',
	new_password: 'Новый пароль',
	confirm_the_password: 'Подтвердить пароль',
	name: 'Имя',
	last_name: 'Фамилия',
	profile: 'Профиль',
	tariffs: 'Тариф',
	logout: 'Выход',
	logo_new: '/images/logo_new_ru.svg',
	logo: '/images/logo_ru_3d.svg',
	footer_logo: '/images/logo-vznania.svg',

	video_guide: 'Инструкция',
	faq: 'FAQs',
	marathon: 'Марафон "Взнания {p1}"',
	marathon_label: 'Марафон',
	learn_easily: 'Весело учиться',
	teach_effectively: 'Эффективно учить',
	do_the_sets: 'Выполняй уроки в любом месте в любое время',
	user_agreement: 'Пользовательское соглашение',
	add_a_group: 'Добавьте группу',
	upload: 'Загрузить',

	error_new_password_empty: 'Поле новый пароль обязательно для заполнения',
	error_passwords_match: 'Новый пароль и подтверждения пароля не совпадают',
	join_group: 'Присоединись к группе своего учителя, чтобы получить доступ к урокам',
	incorrect_email: 'Неверный email или пароль',
	log_in: 'Вход в кабинет',
	notifications: 'Уведомления',
	no_notifications: 'Нет уведомлений',
	copy_lesson: 'Скопировать урок',
	to_save_progress: 'Для сохранения результатов прохождения:',
	to_save_test_result: 'У вас еще есть возможность сохранить результаты прохождения:',
	no_lessons: 'Уроков нет',
	what_imporve: 'Что улучшить?',
	what_you_want: 'Сообщите о проблеме или напишите, что хотели бы улучшить на сайте',

	login_modal: {
		title: 'Вход в знания',
		login: 'Войти',
		email_placeholder: 'E-mail',
		password_placeholder: 'Введите пароль',
		forgot_password: 'Забыли пароль?',
		no_account: 'Нет аккаунта?',
		register_now: 'Зарегистрируйтесь!',
		email_label: 'Ваш e-mail',
		send: 'Отправить',
		email_instruction: 'Вам на почту отправлена инструкция, как поменять пароль.',
		close: 'Закрыть',
	},
	register_modal: {
		title: 'Регистрация',
		who_are_you: 'Кто вы?',
		choose_role: 'Выберите роль',
		role_teacher: 'Учитель',
		role_student: 'Ученик',
		name_label: 'Ваше имя',
		name_placeholder: 'Имя',
		tname_label: 'Ваши имя и отчество',
		tname_placeholder: 'Имя и отчество',
		surname_label: 'Ваша фамилия',
		surname_placeholder: 'Фамилия',
		city_label: 'Ваш город',
		city_placeholder: 'Город',
		school_label: 'Ваша школа',
		school_placeholder: 'Школа',
		email_label: 'Ваш e-mail',
		password_label: 'Придумайте пароль',
		password_placeholder: 'Не менее 8 знаков',
		confirm_password_label: 'Повторите пароль',
		confirm_password_placeholder: 'Повторный ввод',
		phone_label: 'Ваш телефон',
		phone_placeholder: 'Номер телефона',
		accepted_label: 'Согласие на сбор и обработку персональных данных',
		terms_of_use: 'Пользовательское соглашение',
		register: 'Зарегистрироваться',
	},

	month: 'нет месяцев | месяц | месяца | месяцев',
	year: 'нет лет | год | года | лет',
	mo: 'мес',
	rub: 'руб',

	read_all: 'Прочитать все',
	remove_all: 'Удалить все',

	preview_mode: 'Данный урок запущен в режиме предварительного просмотра. Прогресс и результат сохранены не будут!',

	data_does_not_exist: 'Запрашиваемых данных не существует',
	give_the_feedback: 'Удаление аккаунта',
	purchase_info: 'Инфо о покупках',
	log_out: 'Выйти',
	join_game: 'Присоединиться к игре',
	join: 'Присоединиться',
	enter_game_code: 'Введите код игры',
	filter: 'Фильтр',
	group: 'Группа',
	apply: 'Применить',
	rating_positions: 'Позиции в рейтингах',
	feedback: 'Отзыв',
	send: 'Запросить удаление',
	successfully_sent: 'Успешно отправлено',
	error_sent: 'Во время отправки произошла ошибка, повторите попытку позже',
	thx_subscribed: 'Вам активирован доступ к приложению.',
	native_request: 'Разрешите доступ из настроек приложения',
}
