import IO from 'socket.io-client';
import {ref} from "vue";

export default function useSocketIo() {
  const state = ref({})
  const connection = (type) => {
    const socket = IO(import.meta.env.DEV ? `/${type}` : `${import.meta.env.VITE_SOCKET_URL}/${type}`)
    state.value = { ...state.value, ...{[type]: socket}}
    return socket
  }

  return {
    connection,
    state: state.value
  }
}
