<template>
	<div class="assessment-comment-audio-component-wrap small">
		<div v-if="!isDecodingFailed" class="assessment-comment-audio-component small">
			<button class="play-pause" type="button" @click="onPlayPauseClick">
				<svg
					v-if="!isPlaying"
					width="46"
					height="46"
					viewBox="0 0 46 46"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.0835 40.7886C13.5693 40.7886 12.1318 40.4244 10.8668 39.6961C7.87685 37.9711 6.22852 34.4636 6.22852 29.8444V16.1786C6.22852 11.5402 7.87685 8.05189 10.8668 6.32689C13.8568 4.60189 17.7093 4.92772 21.7343 7.24689L33.5602 14.0702C37.566 16.3894 39.7893 19.5711 39.7893 23.0211C39.7893 26.4711 37.5852 29.6527 33.5602 31.9719L21.7343 38.7952C19.4152 40.1177 17.1535 40.7886 15.0835 40.7886ZM15.0835 8.09022C14.0485 8.09022 13.1285 8.32023 12.3043 8.79939C10.2343 9.98773 9.10352 12.6136 9.10352 16.1786V29.8252C9.10352 33.3902 10.2343 35.9969 12.3043 37.2044C14.3743 38.4119 17.211 38.0669 20.2968 36.2844L32.1227 29.4611C35.2085 27.6786 36.9143 25.3977 36.9143 23.0019C36.9143 20.6061 35.2085 18.3252 32.1227 16.5427L20.2968 9.71939C18.4185 8.64606 16.6552 8.09022 15.0835 8.09022Z"
						fill="url(#paint0_linear_981_16808)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_981_16808"
							x1="-10.5271"
							y1="22.9852"
							x2="24.9155"
							y2="56.4408"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#00FFAA" />
							<stop offset="0.530854" stop-color="#4579F5" />
							<stop offset="0.999815" stop-color="#9C42F5" />
						</linearGradient>
					</defs>
				</svg>
				<svg v-else width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.05 32.625H13.95C6.54 32.625 3.375 29.46 3.375 22.05V13.95C3.375 6.54 6.54 3.375 13.95 3.375H22.05C29.46 3.375 32.625 6.54 32.625 13.95V22.05C32.625 29.46 29.46 32.625 22.05 32.625ZM13.95 5.625C7.8 5.625 5.625 7.8 5.625 13.95V22.05C5.625 28.2 7.8 30.375 13.95 30.375H22.05C28.2 30.375 30.375 28.2 30.375 22.05V13.95C30.375 7.8 28.2 5.625 22.05 5.625H13.95Z"
						fill="url(#paint0_linear_981_16910)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_981_16910"
							x1="-11.2284"
							y1="17.9784"
							x2="17.9784"
							y2="47.1852"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#00FFAA" />
							<stop offset="0.530854" stop-color="#4579F5" />
							<stop offset="0.999815" stop-color="#9C42F5" />
						</linearGradient>
					</defs>
				</svg>
			</button>
			<div class="assessment-comment-audio-component__playback-time">
				{{ playbackProgress }}
			</div>
			<div class="assessment-comment-audio-component__control-track__container">
				<input
					class="assessment-comment-audio-component__control-track"
					type="range"
					max="100"
					min="0"
					step="any"
					:value="playbackPercent"
					@input="(e) => handleChangePlaybackTime(e.target.value)"
				/>
			</div>
			<button class="volume" :class="{ muted: isMuted }" type="button" @click="handleMute">
				<svg
					class="assessment-comment-audio-component__control-btn-svg"
					width="34"
					height="34"
					viewBox="0 0 34 34"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.7788 29.1685C16.6597 29.1685 15.4272 28.7719 14.1947 27.9927L10.058 25.4002C9.77467 25.2302 9.44884 25.131 9.12301 25.131H7.08301C3.65467 25.131 1.77051 23.2469 1.77051 19.8185V14.1519C1.77051 10.7235 3.65467 8.83938 7.08301 8.83938H9.10884C9.43467 8.83938 9.76051 8.74021 10.0438 8.57021L14.1805 5.97771C16.2488 4.68854 18.2605 4.44771 19.8472 5.32604C21.4338 6.20438 22.298 8.03188 22.298 10.4827V23.4735C22.298 25.9102 21.4197 27.7519 19.8472 28.6302C19.2238 28.9985 18.5155 29.1685 17.7788 29.1685ZM7.08301 10.9785C4.84467 10.9785 3.89551 11.9277 3.89551 14.166V19.8327C3.89551 22.071 4.84467 23.0202 7.08301 23.0202H9.10884C9.84551 23.0202 10.5538 23.2185 11.1772 23.6152L15.3138 26.2077C16.6738 27.0577 17.963 27.2844 18.8272 26.8027C19.6913 26.321 20.1872 25.1169 20.1872 23.516V10.4969C20.1872 8.88188 19.6913 7.67771 18.8272 7.21021C17.963 6.72854 16.6738 6.94104 15.3138 7.80521L11.163 10.3835C10.5538 10.7802 9.83134 10.9785 9.10884 10.9785H7.08301Z"
						fill="url(#paint0_linear_2230_59116)"
					/>
					<path
						d="M25.5004 23.7283C25.2737 23.7283 25.0612 23.6574 24.8629 23.5158C24.3954 23.1616 24.2962 22.4958 24.6504 22.0283C26.8746 19.0674 26.8746 14.9308 24.6504 11.9699C24.2962 11.5024 24.3954 10.8366 24.8629 10.4824C25.3304 10.1283 25.9962 10.2274 26.3504 10.6949C29.1271 14.4066 29.1271 19.5916 26.3504 23.3033C26.1379 23.5866 25.8262 23.7283 25.5004 23.7283Z"
						fill="url(#paint1_linear_2230_59116)"
					/>
					<path
						d="M28.0922 27.2725C27.8655 27.2725 27.653 27.2017 27.4547 27.06C26.9872 26.7059 26.888 26.04 27.2422 25.5725C31.0247 20.5292 31.0247 13.4742 27.2422 8.43085C26.888 7.96335 26.9872 7.29752 27.4547 6.94336C27.9222 6.58919 28.588 6.68836 28.9422 7.15586C33.2914 12.95 33.2914 21.0534 28.9422 26.8475C28.7439 27.1309 28.418 27.2725 28.0922 27.2725Z"
						fill="url(#paint2_linear_2230_59116)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_2230_59116"
							x1="-8.47808"
							y1="16.9686"
							x2="15.497"
							y2="37.1686"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#00FFAA" />
							<stop offset="0.530854" stop-color="#4579F5" />
							<stop offset="0.999815" stop-color="#9C42F5" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_2230_59116"
							x1="22.4428"
							y1="16.989"
							x2="29.7755"
							y2="19.1658"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#00FFAA" />
							<stop offset="0.530854" stop-color="#4579F5" />
							<stop offset="0.999815" stop-color="#9C42F5" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_2230_59116"
							x1="24.4457"
							y1="16.9863"
							x2="34.1633"
							y2="19.4343"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#00FFAA" />
							<stop offset="0.530854" stop-color="#4579F5" />
							<stop offset="0.999815" stop-color="#9C42F5" />
						</linearGradient>
					</defs>
				</svg>
			</button>
		</div>
		<div v-if="isDecodingFailed" class="default-player">
			<audio controls :src="src" />
		</div>
	</div>
</template>

<script>
import { delay } from '../../helpers/delay'
import { getTimeString } from '../../helpers/audio'

export default {
	props: {
		src: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		isPlaying: false,
		isLoading: false,
		isDecodingFailed: false,
		isMuted: false,
		audioDuration: 0,
		currentPlaybackTime: 0,
		audioController: {
			context: null,
			decodedAudioData: null,
			source: null,
			gainer: null,
			playback: {
				startedAt: 0,
				pausedAt: 0,
				volume: 0,
			},
		},
	}),
	computed: {
		playbackProgress() {
			const currentTime = this.getTimeString(this.currentPlaybackTime)
			const totalTime = this.getTimeString(this.audioDuration)

			return `${currentTime} / ${totalTime}`
		},
		playbackPercent() {
			const duration = this.audioDuration
			const currentTime = this.currentPlaybackTime

			if (duration === 0) {
				return 0
			}

			return (currentTime / duration) * 100
		},
	},
	methods: {
		async loadAudio(src) {
			this.audioController.context = new AudioContext()
			this.isLoading = true

			try {
				const response = await fetch(src)
				const ab = await response.arrayBuffer()

				this.audioController.decodedAudioData = await this.audioController.context.decodeAudioData(ab)
				this.audioDuration = this.audioController.decodedAudioData.duration

				this.isLoading = false
			} catch (e) {
				this.isDecodingFailed = true
				console.error(e)
			}
		},
		getTimeString(v) {
			return getTimeString(v)
		},
		initPlaybackFlow() {
			const { context } = this.audioController

			if (!context) {
				return
			}

			this.audioController.gainer = context.createGain()
			this.audioController.gainer.connect(context.destination)

			this.audioController.source = context.createBufferSource()
			this.audioController.source.buffer = this.audioController.decodedAudioData
			this.audioController.source.addEventListener('ended', this.handlePlaybackEnded)
			this.audioController.source.connect(this.audioController.gainer)
		},
		destroyPlaybackFlow() {
			if (!(this.audioController.gainer && this.audioController.source)) {
				return
			}

			this.audioController.source.disconnect()
			this.audioController.source.stop()
			this.audioController.source.removeEventListener('ended', this.handlePlaybackEnded)
			this.audioController.source = null

			this.audioController.gainer.disconnect()
			this.audioController.gainer = null
		},
		getCurrentPlaybackTime() {
			const {
				context,
				playback: { startedAt, pausedAt },
			} = this.audioController

			if (!context) {
				return 0
			}

			if (this.isPlaying) {
				return context.currentTime - startedAt
			}

			return pausedAt
		},
		async updateCurrentPlaybackTime() {
			if (!this.isPlaying) {
				return
			}

			this.currentPlaybackTime = this.getCurrentPlaybackTime()

			await delay(100)
			return this.updateCurrentPlaybackTime()
		},
		onPlayPauseClick() {
			if (!this.isPlaying) {
				return this.handlePlay()
			}
			return this.handlePause()
		},
		handlePlay() {
			const { context } = this.audioController
			if (!context) {
				return
			}

			this.initPlaybackFlow()

			const { gainer, source } = this.audioController
			if (!(gainer && source)) {
				return
			}

			gainer.gain.setValueAtTime(this.isMuted ? 0 : 1, context.currentTime)
			source.start(0, this.audioController.playback.pausedAt)

			this.audioController.playback.startedAt = context.currentTime - this.audioController.playback.pausedAt
			this.isPlaying = true

			this.updateCurrentPlaybackTime()
		},
		handlePause(time) {
			const { context } = this.audioController
			if (!context) {
				return
			}

			this.isPlaying = false
			this.audioController.playback.pausedAt =
				time === undefined ? context.currentTime - this.audioController.playback.startedAt : time

			this.destroyPlaybackFlow()
		},
		handlePlaybackEnded() {
			this.handlePause(0)
			this.currentPlaybackTime = 0
		},
		handleChangePlaybackTime(val) {
			if (this.audioDuration === 0) {
				return
			}

			const selectedTimestamp = (this.audioDuration / 100) * val

			this.handlePause(selectedTimestamp)
			this.currentPlaybackTime = selectedTimestamp
		},
		setVolume(volume) {
			const { gainer, context } = this.audioController
			if (!(context && gainer)) {
				return
			}

			gainer.gain.setValueAtTime(volume, context.currentTime)
		},
		handleMute() {
			this.isMuted = !this.isMuted

			if (this.isMuted) {
				this.setVolume(0)
			} else {
				this.setVolume(1)
			}
		},
	},
	async mounted() {
		await this.loadAudio(this.src)
	},
}
</script>

<style scoped lang="scss">
.assessment-comment-audio-component {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 20px;
	background: #ecf7ff;
	border-radius: 8px;

	&.video-player {
		background: transparent;
		padding: 10px 0;
	}

	&-wrap {
		width: 100%;
		display: flex;
		align-items: center;

		.btn {
			&.delete {
				margin-left: 20px;
			}
		}

		&.video-player {
			& .audio-component__control-track {
				height: 4px;
				background: #ebe9e9;
			}

			& .audio-component__playback-time__current-time,
			& .audio-component__playback-time__end-time {
				position: absolute;
				bottom: -25px;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
				text-align: center;
				background: linear-gradient(135deg, #00ffaa 0%, #4579f5 53.01%, #9c42f5 99.83%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				opacity: 0.8;
			}

			& .audio-component__playback-time__current-time {
				left: 0;
			}

			& .audio-component__playback-time__end-time {
				right: 0;
			}
		}

		&.small {
			.assessment-comment-audio-component {
				&__control {
					&-btn-svg {
						width: 20px;
						height: 20px;
					}
				}
			}

			.btn {
				&.delete {
					margin-left: 12px;
				}
			}
		}
	}

	.btn {
		&.play-pause {
			margin-right: 8px;
		}
	}

	.volume {
		position: relative;
		margin-left: 20px;

		&.muted {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				width: 2px;
				border-radius: 4px;
				background-color: red;
				rotate: 45deg;
			}
		}
	}

	&__control {
		&-btn-svg {
			width: 34px;
			height: 34px;

			&.video-player {
				width: 44px;
				height: 44px;
			}
		}

		&-track {
			&__container {
				position: relative;
				display: flex;
				align-items: center;
				width: 100%;
			}

			&-background {
				position: absolute;
				height: 14px;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				border-radius: 8px;
			}
		}
	}

	&__playback-time {
		flex-shrink: 0;
		margin-right: 14px;
		min-width: 90px;
		text-align: center;

		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #141029;
		opacity: 0.8;
	}

	input[type='range'] {
		padding: 0 !important;
		-webkit-appearance: none;
		width: 100%;
		background: #ffffff;
		border-radius: 8px;
		height: 14px;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			width: 20px;
			height: 20px;
			background: linear-gradient(135deg, #00ffaa 0%, #4579f5 53.01%, #9c42f5 99.83%);
			border-radius: 14px;
		}
	}

	&.small {
		padding: 4px 10px;

		.assessment-comment-audio-component {
			&__control {
				&-btn-svg {
					width: 20px;
					height: 20px;
				}
			}

			&__playback-time {
				font-size: 12px;
			}
		}
		.play-pause {
			svg {
				width: 20px;
				height: 20px;
			}
		}

		input[type='range'] {
			height: 12px;

			&::-webkit-slider-thumb {
				width: 16px;
				height: 16px;
			}
		}
	}
}
</style>
