<template>
	<div class="v-mode-switcher flex items-center">
		<div
			@click="setMode(_mode)"
			class="v-mode-switcher__mode cursor-pointer"
			:class="{ selected: isSelected(_mode) }"
			v-for="(_mode, index) in modes"
			:key="index"
		>
			<IconDisplay :iconPath="_mode.icon" />
		</div>
	</div>
</template>

<script>
export default {
	compatConfig: {
		COMPONENT_V_MODEL: false,
	},
}
</script>

<script setup>
import IconDisplay from '@/components/IconDisplay.vue'

const model = defineModel()
const props = defineProps({
	modes: {
		type: Array,
		default: () => [],
	},
})
const isSelected = (mode) => mode.key === model.value

const setMode = (mode) => {
	model.value = mode.key
}
</script>

<style lang="scss">
.v-mode-switcher__mode {

  svg path,
  svg circle {
    fill: #000;
  }

	&.selected svg path,
	&.selected svg circle {
		fill: #fff;
	}
}
</style>

<style lang="scss" scoped>
.v-mode-switcher {
	background-color: #fff;
	border-radius: 8px;
	overflow: hidden;
	min-width: 80px;
	width: 80px;
	border: 1px solid #f0f1f5;

	&__mode {
		padding: 8px;
		height: 100%;
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			img,
			object,
			svg {
				transform: scale(1.05);
			}
		}

		&.selected {
			background-color: #4579F5;
		}

		img,
		object,
		svg {
			transition: all 0.2s linear;
			width: 24px;
			height: 24px;
			object-fit: contain;
		}
	}
}
</style>
