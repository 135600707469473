<template>
    <div class="mobile-app" :class="{ bottombar: !$route.meta.auth && !$route.meta.frame, games: ['lesson', 'games'].includes($route.name) }" :style="{ paddingTop: paddingTop, marginBottom: paddingBottom }">
        <!-- <div>HEADER</div> -->
        <div class="mobile-app__main flex flex-col" >
            <transition name="fade" mode="out-in">
                <router-view v-if="isProfileLoaded" />
            </transition>
        </div>

        <VModal />
        <ReviewModal />
        <ImageViewer />

        <transition name="fade" mode="out-in">
            <div v-if="isSiteBusy || !isProfileLoaded" class="fullscreen-fixed" :class="{'loading-profile': !isProfileLoaded}">
                <loader />
            </div>
        </transition>
    </div>
</template>

<script>
import BottomNavBar from './BottomNavBar.vue'
import VModal from '@/components/modals/MainModal.vue';
import ReviewModal from '@/components/modals/ReviewModal.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import ImageViewer from '@/components/ImageViewer.vue';
import FlutterNovakidBridge from "@/helpers/flutterNovakidBridge.js";
export default {
  components: { BottomNavBar, VModal, ReviewModal, ImageViewer },
  async created() {
      this.enableNoScalingMode();

      this.emitter.on('app-paused', () => {
          document.querySelectorAll('audio').forEach(el => el.pause && el.pause());
          document.querySelectorAll('video').forEach(el => el.pause && el.pause());
      })
      this.emitter.on('v-modal-mobile', (payload) => {
          this.$root.$emit('v-modal', { ...payload, size: 'md' });
      })
      this.emitter.on('internet-status', (status) => {
          if (status === 'false' || status === false) {
              this.$root.$emit('v-modal', {
                  text: 'Отсутствует подключение к интернету! Повторите попытку позже',
                  error: true,
              })
          }
      })
      this.emitter.on('reload-user', () => {
          this.$store.dispatch('GET_PROFILE_DATA', true);
      })
      this.emitter.on('temp-sub-access', () => {
          this.$store.commit('OPEN_SUBSCRIPTION_ACCESS');
      })
      this.emitter.on('focus-into-view', async () => {
          await this.$nextTick();
          if (document.activeElement) {
              document.activeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
      })

      this.$root.$on('set-scaling-mode', (action) => {
        if (action) {
          this.disableNoScalingMode();
        } else {
          this.enableNoScalingMode();
        }
      })

    await this.GET_PLATFORM;
    // Токен авториации
    this.$helpers.getUrlToken();
    console.log('TOKNNNN', localStorage.getItem('token'))
    FlutterNovakidBridge.callService('FlutterTokenChannel', localStorage.getItem('token') || '');
    // window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage(localStorage.getItem('token') || '');

    document.onfullscreenchange = this.onFullScreenChange;

    await this.$store.dispatch('GET_PROFILE_DATA');
  },
  computed: {
    ...mapGetters(['isSiteBusy', 'isProfileLoaded']),
    ...mapState({
        showBottomBar: state => state.base.showBottomBar
    }),
    ...mapActions(["GET_PLATFORM"]),
    paddingTop() {
      console.log('window.flutterPaddingTop', window.flutterPaddingTop)
        return (window.flutterPaddingTop || 0) / 1.5 + 'px';
    },
    paddingBottom() {
      console.log('window.flutterPaddingBottom', window.flutterPaddingBottom)
        return ((window.flutterPaddingBottom || 0) + (this.inactive ? 5 : 0)) / 2 + 'px';
    },
  },
  methods: {
    enableNoScalingMode() {
      const vp = document.getElementById('meta-viewport');
      if (vp) {
        vp.setAttribute('content','width=device-width, initial-scale=1.0, maximun-scale=1.0, user-scalable=no');
      }
    },
    disableNoScalingMode() {
      const vp = document.getElementById('meta-viewport');
      if (vp) {
        vp.setAttribute('content','width=device-width, initial-scale=1.0');
      }
    },
    onFullScreenChange(event) {
        let elem = event.target;
        let isFullscreen = document.fullscreenElement === elem;
        console.log('isFullscreen', isFullscreen);
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-app {
    min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    background-color: #eaeaea;
    // overflow: hidden;
    position: relative;
    width: 100vw;

    &.games {
      background-color: #025AEF;
    }

    &__main {
        flex-grow: 2;
        height: 100%;
    }

    .bottombar {
        padding-bottom: 50px;
    }
}
</style>

<style lang="scss">
.mobile-app input {
    -webkit-appearance: none;
    // overflow: hidden;
}
.mobile-app input[type="checkbox"] {
    -webkit-appearance: checkbox;
    // overflow: initial;
}
.mobile-app .v-link-mobile {
    color:rgb(88, 157, 220) !important;
    text-decoration: underline;
}

.fullscreen-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99;

  background: rgba($color: #000000, $alpha: 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading-profile {
    background: #eaeaea;
  }
}

@media (max-width: 1024px) {
    body.fixed {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: fixed;
        // -webkit-overflow-scrolling : touch !important;

        .mobile-app {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
            // -webkit-overflow-scrolling : touch !important;

            & input, & textarea {
                -webkit-user-modify: read-write-plaintext-only !important;
            }

            & * {
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}
</style>
