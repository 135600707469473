<template>
    <div class="reset-screen flex flex-col pb-10">
        <img class="reset-screen__bottom-decor" src="@/assets/mobile/decor_login_bottom.png" alt="">
        <img class="reset-screen__top-decor" src="@/assets/mobile/decor_login_top.png" alt="">

        <div class="h-full flex flex-col items-center justify-center w-10/12 mx-auto" style="max-width: 400px">
            <img class="splash-logo mb-5" src="@/assets/mobile/logo_brain.png" alt="">

            <input type="email" class="v-input w-full text-center" v-model="email" placeholder="E-mail">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('email')">{{ isError('email') }}</div>

            <div class="mb-5 mt-10 v-text--red text-center w-full mt-5" v-if="customError">Произошла ошибка, обратитесь в службу поддержки!</div>

            <div class="s-button s-button--orange mb-5 mt-5" @click="send">Отправить</div>
        </div>

        <div class="flex flex-col items-center justify-center w-full mb-5">
            <div class="v-text--desc mb-2">Нет аккаунта?</div>
            <div class="v-text--bold v-text--gray" @click="$router.push({ name: 'register' })">Зарегистрируйтесь</div>
        </div>
    </div>
</template>

<script>
import { forgotPassword } from '../../api/user'
export default {
    data: () => ({
        email: '',
        errors: null,
        errorNames: {},
        customError: null,
    }),
    methods: {
        async send() {
            try {
                const { data } = await forgotPassword({ email: this.email });
                console.log('reset data', data);

                this.$root.$emit('v-modal', {
                    text: data.success
                        ? 'На вашу почту отправлена инструкция по восстановлению пароля.'
                        : 'Пользователь с таким email не зарегистрирован.',
                    size: 'sm',
                    closeButton: false,
                    closeOutside: false,
                    buttons: [
                        {
                            text: this.$t('games.ok'),
                            class: 'v-button v-button--gradient-red',
                            callback: () => {
                                this.$root.$emit('v-modal', { close: true });
                                this.$router.push({ name: 'login' })
                            }
                        }
                    ],
                })
            } catch (error) {
                this.handleError(error);
            }
        },
        handleError(err) {
            console.dir(err.response);
            if (err.response && err.response.data && err.response.data.errors) {
                this.errors = Object.values(err.response.data.errors).map(el => el[0]).join('<br>')
                this.errorNames = err.response.data.errors;

                for (let key in this.errorNames) {
                    this.errorNames[key] = this.errorNames[key][0];
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                    this.$root.$emit('v-modal', {
                        text: err.response.data.message,
                        size: 'sm',
                        closeButton: false,
                        closeOutside: false,
                        buttons: [
                            {
                                text: this.$t('games.ok'),
                                class: 'v-button v-button--gradient-red',
                                callback: () => {
                                    this.$root.$emit('v-modal', { close: true });
                                    // this.$router.push({ name: 'login' })
                                }
                            }
                        ],
                    })
            } else {
                this.customError = true;
            }
        },
        resetErrors() {
            this.errors = null;
            this.errorNames = {};
            this.customError = false;
        },
        isError(name) {
            return this.errorNames[name];
        },
    }
}
</script>

<style lang="scss" scoped>
.reset-screen {
    height: 100vh;

    &__bottom-decor,
    &__top-decor {
        position: absolute;
        width: 30vw;
        max-width: 80px;
        pointer-events: none;
    }

    &__bottom-decor {
        bottom: 0;
        left: 0;
    }
    &__top-decor {
        top: 0;
        right: 0;
    }

    .splash-logo {
        width: 100px;
        mix-blend-mode: multiply;
    }
}
</style>
