import axios from 'axios'
import { clearLocalStorageButLessonTimers, getSlug } from '@/helpers.js'
import locale from '@/i18n/index.js'
import FlutterBridge from '@/helpers/flutterBridge.js'
import { store } from '@/store/index.js'
import useEventBus from '@/composables/useEventBus.js'
import i18n from '../i18n/index.js'
const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('token') || localStorage.getItem('token')
const emitter = useEventBus()

const apiRequest = axios.create({
	baseURL: import.meta.env.VITE_APP_URL + '/api/v2/',
	headers: {
		Accept: 'application/json',
		Authorization: {
			toString() {
				return `Bearer ${localStorage.getItem('token')}`
			},
		},
	},
})

// if (token) {
//   apiRequest.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// }

apiRequest.interceptors.request.use((req) => {
	// if (req.method == 'get') {
	// 	//   if (req.url.includes('timestamp')) {
	// 	//     req.url.replace(/timestamp=\S+/, `timestamp=${new Date().getTime()}`);
	// 	//   } else {
	// 	//     req.url = req.url + (req.url.includes('?') ? '&' : '?') + `timestamp=${new Date().getTime()}`;
	// 	//   }
	// }
	return req
})

apiRequest.interceptors.response.use(
	(response) => response,
	async (error) => {
		// Slug доступен для урока по ссылке, авторизация не обязательна
		const slug = getSlug()
		if (error.response.status === 404) {
			emitter.emit('v-modal', {
				text: i18n.global.t('home.data_does_not_exist'),
				error: true,
				closeButton: false,
				closeOutside: false,
				buttons: [
					{
						text: i18n.global.t('games.ok'),
						class: 'v-button v-button--gradient-red',
						callback: () => {
							location.href = '/'
						},
					},
				],
			})
			return Promise.reject(error)
		}

		if (error.response.status === 401 && !slug && !window.isTrueMobile) {
			console.log('auth error')
			clearLocalStorageButLessonTimers()

			FlutterBridge.callService('FlutterTokenChannel', '')
			// window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage('');
			// console.log(process.env)
			console.log(2)
			console.log(error)
			// if (store.getters.getPlatform) {
			//   location.href = store.getters.getPlatform.domain + '/logoutNew?student=true';
			// } else {
			//   location.href = import.meta.env.VITE_APP_URL + '/logoutNew?student=true';
			// }

			return
		} else if (slug && !error.config.url.includes('auth')) {
			return
		}

		// Flutter auth errors handled manually
		if (error.config.url.includes('auth') && window.isNativeMobile) return Promise.reject(error)

		if (error.response.status === 422) {
			console.log('error.response.data.errors', error.response.data.errors)

			try {
				if (!error.response.data.errors) {
					emitter.emit('v-modal', {
						text: i18n.global.t('home.incorrect_email'),
						error: true,
					})
					return
				}

				let textErrors = '',
					errors = error.response.data.errors || []
				console.log(errors)
				for (let errKey in errors) {
					errors[errKey].forEach((text) => (textErrors += text + '<br>'))
				}

				console.log('text errors', textErrors)

				emitter.emit('v-modal', {
					text: textErrors,
					error: true,
				})
				emitter.emit('v-modal-mobile', {
					text: textErrors,
					error: true,
				})
			} catch (e) {
				return emitter.emit('v-modal', { text: 'Ошибка валидации', error: true })
			}
			return Promise.reject(error)
		}

		try {
			emitter.emit('v-modal', {
				text: 'Ошибка: \n' + error.response.data.message,
				error: true,
			})
		} catch (e) {
			console.error(e)
			emitter.emit('v-modal', {
				text: 'Ошибка',
				error: true,
			})
		}
	}
)

export default apiRequest
