<template>
  <div class="container mx-auto px-2 pb-20">
    <UserRow class="mb-3 lg:mb-10 mt-3 lg:mt-10 mobile-border" isProfile :statistic="isTrueMobile"/>

    <div class="flex w-full lg:w-1/4 items-center mb-5 lg:mb-10 mobile-border">
      <div class="v-reward mr-3">
        <img class="v-reward__background w-24" style="min-width: 4rem" src="/images/statLeftLogo-xxxhdpi.png" alt="">
        <img class="v-reward__icon" :src="getStatus.icon" alt="">
      </div>
      <div>
        <div class="v-text--bold text-xl">{{ getStatus.status }}</div>
        <div class="v-text--desc">{{ $t('statistics.status') }}</div>
      </div>
    </div>

    <div class="mobile-border mb-5">
      <transition name="fade" mode="out-in">
        <div class="flex justify-between flex-col lg:flex-row mb-5 lg:mb-20" v-if="!isEditing">
          <div class="w-full lg:w-1/2 mx-5 mb-3 lg:mb-0 mb">
            <div class="v-text--gray">E-mail</div>
            <div class="v-text--bold text-xl">{{ profile.email }}</div>
          </div>
          <div class="w-full lg:w-1/2 mx-5 mb-3 lg:mb-0 mb">
            <div class="v-text--gray">{{ $t('home.city') }}</div>
            <div class="v-text--bold text-xl">{{ profile.city }}</div>
          </div>
          <div class="w-full lg:w-1/2 mx-5 mb-3 lg:mb-0 mb">
            <div class="v-text--gray">{{ $t('home.school') }}</div>
            <div class="v-text--bold text-xl">{{ profile.school }}</div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-wrap">
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-5">
              <div class="v-text--gray text-md">{{ $t('home.name') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="profileClone.name" />
            </div>
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-0">
              <div class="v-text--gray text-md">{{ $t('home.last_name') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="profileClone.surname"/>
            </div>

            <div class="px-2 w-full lg:w-1/2 hidden lg:block"></div>

            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-5">
              <div class="v-text--gray text-md">E-mail</div>
              <input class="v-input w-full lg:w-auto" v-model="profileClone.email"/>
            </div>
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-0">
              <div class="v-text--gray text-md">{{ $t('home.city') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="profileClone.city"/>
            </div>
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-0">
              <div class="v-text--gray text-md">{{ $t('home.school') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="profileClone.school"/>
            </div>

            <div class="w-full text-left my-2 mt-5 v-text--bold">{{ $t('home.change_the_password') }}</div>

            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-5">
              <div class="v-text--gray text-md">{{ $t('home.current_password') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="current_password" @input="password_error = false"/>
            </div>
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-0">
              <div class="v-text--gray text-md">{{ $t('home.new_password') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="new_password" @input="password_error = false"/>
            </div>
            <div class="px-2 w-full lg:w-1/2 mb-2 lg:mb-0">
              <div class="v-text--gray text-md">{{ $t('home.confirm_the_password') }}</div>
              <input class="v-input w-full lg:w-auto" v-model="new_password_confirmation" @input="password_error = false" />
            </div>
            <div v-if="password_error" class="w-full v-text--red v-text--bold text-center">{{ $t('games.complete_all_fields') }}</div>
          </div>
        </div>
      </transition>

      <div class="flex lg:hidden justify-center mt-5">
        <div class="v-button v-button--gradient-red lg:mr-5" v-if="!isEditing" @click="isEditing = true">{{ $t('home.сhange_details') }}</div>
        <div class="v-button v-button--raised-red mr-5" v-if="isEditing" @click="cancelAndReset">{{ $t('groups.cancel') }}</div>
        <div class="v-button v-button--gradient-red" v-if="isEditing" @click="onDataSave">{{ $t('home.save') }}</div>
      </div>
    </div>

    <div class="w-full flex items-center justify-around lg:hidden mb-10">
      <div class="lang-item" :class="{ active: $i18n.locale === 'ru' }" @click="handleLanguage('ru')">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 40"
            width="25"
            height="20"
            class="mr-3"
        >
            <path fill="transparent" d="M.06,19.52c0-5.66.09-11.31,0-17C0,.62.21,0,2.43,0,17.54.13,32.65.1,47.76,0,49.59,0,50,.43,50,2.25c-.09,12.56,0,25.13,0,37.69H20.6c-1.31-2.45.83-3.53,2.08-4.81C29,28.66,35.53,22.35,41.91,15.92a17,17,0,0,0,3.63-4.52A4.8,4.8,0,0,0,44.07,5c-2.13-1.64-4.24-1.34-6.17.4C33.08,9.68,28.69,14.44,24,18.93c-5.16,5-5.49,5-11.23.62-3-2.28-6-3.34-9.23-.63C2.51,19.8,1.41,20.39.06,19.52Z"/>
            <path d="M.06,19.52C5.24,13,9,12.58,14.84,18.44c2.69,2.7,4.28,2.35,6.66-.21C26,13.36,30.78,8.7,35.56,4.08,39.27.49,43.2.23,46.39,3.07a7.53,7.53,0,0,1,.16,11.24C38,23,29.79,31.94,20.6,39.94H15.71A22.56,22.56,0,0,1,.06,24.38Z" fill="#fff"/>
            <path fill="transparent" d="M.06,24.38A120.46,120.46,0,0,0,15.71,39.94c-4.56,0-9.13-.1-13.69.05-1.71.06-2.07-.3-2-2C.16,33.46.06,28.92.06,24.38Z"/>
            <path fill="#10aa49ff" d="M43.65,8.78a4.47,4.47,0,0,1-1.72,2.82c-6.77,7-13.61,13.86-20.31,20.88-2.39,2.51-4.43,2.83-6.89.14-2.61-2.87-5.47-5.52-8.16-8.32-1-1.07-2-2.38-.56-3.8s2.66-.65,3.87.35c.49.41.94.88,1.41,1.33,6.76,6.42,6.76,6.41,13.21,0,4.69-4.69,9.38-9.4,14.09-14.07,1-1,2.12-2.15,3.81-1.23A2.24,2.24,0,0,1,43.65,8.78Z"/>
        </svg>
        Русский
      </div>
      <div class="lang-item" :class="{ active: $i18n.locale === 'en' }" @click="handleLanguage('en')">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 40"
            width="25"
            height="20"
            class="mr-4"
        >
            <path fill="transparent" d="M.06,19.52c0-5.66.09-11.31,0-17C0,.62.21,0,2.43,0,17.54.13,32.65.1,47.76,0,49.59,0,50,.43,50,2.25c-.09,12.56,0,25.13,0,37.69H20.6c-1.31-2.45.83-3.53,2.08-4.81C29,28.66,35.53,22.35,41.91,15.92a17,17,0,0,0,3.63-4.52A4.8,4.8,0,0,0,44.07,5c-2.13-1.64-4.24-1.34-6.17.4C33.08,9.68,28.69,14.44,24,18.93c-5.16,5-5.49,5-11.23.62-3-2.28-6-3.34-9.23-.63C2.51,19.8,1.41,20.39.06,19.52Z"/>
            <path d="M.06,19.52C5.24,13,9,12.58,14.84,18.44c2.69,2.7,4.28,2.35,6.66-.21C26,13.36,30.78,8.7,35.56,4.08,39.27.49,43.2.23,46.39,3.07a7.53,7.53,0,0,1,.16,11.24C38,23,29.79,31.94,20.6,39.94H15.71A22.56,22.56,0,0,1,.06,24.38Z" fill="#fff"/>
            <path fill="transparent" d="M.06,24.38A120.46,120.46,0,0,0,15.71,39.94c-4.56,0-9.13-.1-13.69.05-1.71.06-2.07-.3-2-2C.16,33.46.06,28.92.06,24.38Z"/>
            <path fill="#10aa49ff" d="M43.65,8.78a4.47,4.47,0,0,1-1.72,2.82c-6.77,7-13.61,13.86-20.31,20.88-2.39,2.51-4.43,2.83-6.89.14-2.61-2.87-5.47-5.52-8.16-8.32-1-1.07-2-2.38-.56-3.8s2.66-.65,3.87.35c.49.41.94.88,1.41,1.33,6.76,6.42,6.76,6.41,13.21,0,4.69-4.69,9.38-9.4,14.09-14.07,1-1,2.12-2.15,3.81-1.23A2.24,2.24,0,0,1,43.65,8.78Z"/>
        </svg>
        English
      </div>
    </div>

    <div class="w-full flex items-center justify-between lg:hidden">
       <div class="flex items-center" @click="$root.$emit('review')">
         <img src="/images/review-xxxhdpi.png" class="h-6 mr-2" alt="">
         <div class="v-text--green">{{ $t('home.give_the_feedback') }}</div>
       </div>
<!--       <div v-if="!getPlatform" class="flex items-center" @click="requestPurchase">-->
<!--         <img src="/images/cart-xxxhdpi.png" class="h-6 mr-2" alt="">-->
<!--         <div class="v-text&#45;&#45;blue">{{ $t('home.purchase_info') }}</div>-->
<!--       </div>-->
    </div>

    <div class="lg:hidden w-full flex justify-center mt-10">
      <div class="text-lg v-text--bold v-text--red" @click="logout">{{ $t('home.log_out') }}</div>
    </div>

    <AddGroup class="hidden lg:block" isProfile :isEditing="isEditing" @edit="isEditing = true" @cancel="cancelAndReset" @save="onDataSave"/>
  </div>
</template>

<script setup>
import UserRow from '@/components/UserRow.vue';
import AddGroup from '@/components/AddGroup.vue';

import {useStore} from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import FlutterBridge from '@/helpers/flutterBridge.js';
import {computed, ref, onBeforeMount, reactive} from "vue";
import {useRouter} from "vue-router";
import useEventBus from "../composables/useEventBus.js";

const store = useStore();
const router = useRouter();
const emitter = useEventBus();

const isEditing = ref(false);
const current_password = ref('');
const new_password = ref('');
const new_password_confirmation = ref('');
const password_error = ref(false);
const profileClone = ref({})

const groups = computed(() => store.getters.userGroups);
const profile = computed(() => store.getters.profile);
const getPlatform = computed(() => store.getters.getPlatform);
const isTrueMobile = computed(() => window.isTrueMobile);
const getStatus = computed(() => {
  const words = parseInt(profile.learned_word, 10);
  switch (true) {
    case words < 500: return { status: $t('statistics.newbie'), icon: '/images/achives/1.svg' };
    case words < 1000: return { status: $t('statistics.beginner'), icon: '/images/achives/2.svg' };
    case words < 2000: return { status: $t('statistics.intermediate_user'), icon: '/images/achives/3.svg' };
    case words < 3000: return { status: $t('statistics.upper_intermediate_user'), icon: '/images/achives/4.svg' };
    case words < 4000: return { status: $t('statistics.advanced_user'), icon: '/images/achives/5.svg' };
    case words >= 4000: return { status: $t('statistics.expert'), icon: '/images/achives/6.svg' };
  }
  return {}
})

onBeforeMount(() => {
  if(!groups) {
    store.dispatch('GET_GROUPS');
  }

  profileClone.value = cloneDeep(profile);
})

const handleLanguage = async (locale) => {
  try {
    await store.dispatch('SET_USER_LOCALE', locale);
  } catch (err) {
    console.log('err', err);
  }

  // this.$root.$i18n.locale = locale;
  FlutterBridge.callService('FlutterLangChannel', locale);
  // window.FlutterLangChannel && window.FlutterLangChannel.postMessage(locale);
}

const logout = () => {
  // window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage('');
  FlutterBridge.callService('FlutterTokenChannel', '');
  store.commit('LOGOUT');
  router.push({ name: 'splash' })
}

const requestPurchase = () => {
  console.log('this.profile.subscriptionAvailability', profile.subscriptionAvailability);
  if (profile.subscriptionAvailability) {
    console.log('HERE!');
    emitter.emit('v-modal', {
      text: $t('home.thx_subscribed'),
      size: 'sm',
    });
  } else {
    FlutterBridge.callService('FlutterPaymentChannel', 'request');
    // window.FlutterPaymentChannel.postMessage('request');
  }
}

// Очистка
const cancelAndReset = () => {
  profileClone.value = cloneDeep(profile);
  current_password.value = '';
  new_password.value = '';
  new_password_confirmation.value = '';

  password_error.value = false;
  isEditing.value = false;
}

// Сохранить данные
const onDataSave = async () => {

  // Поля, которые нужно отправить
  const fillable = ['name', 'surname', 'email', 'city', 'school'];
  let payload = {};

  // Берем из обновленных
  for (const key of fillable) {
    payload[key] = profileClone.value[key];
  }

  // Если есть это поле - пробуем сменить и пароль
  if (current_password.value || new_password_confirmation.value || new_password.value) {
    if (!this.validatePassword()) return;

    payload = {
      ...payload,
      current_password: current_password.value,
      password: new_password.value,
      password_confirmation: new_password_confirmation.value,
    }
  }

  await store.dispatch('UPDATE_STUDENT_INFO', payload);
  cancelAndReset();
}

const validatePassword = () => {
  let error_msg = '';

  if (!current_password.value || !new_password_confirmation.value || !new_password.value) {
    password_error.value = true;
    return false;
  }
  return true;
}
</script>

<style lang="scss" scoped>
.lang-item {
  border: 2px solid #10aa49ff;
  color: #10aa49ff;
  padding: 10px;
  border-radius: 30px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: none;
  }

  &.active {
    background: #10aa49ff;
    color: #fff;

    svg {
      display: block;
    }
  }
}

.v-reward {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }
}

.mobile-border {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;

  @media (min-width: 1024px) {
    border-width: 0px;
    padding-bottom: 0px;
  }

}
@media (max-width: 800px) {
  .mb {
    width: auto!important;
  }
}
</style>
