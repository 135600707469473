<template>
	<div
		class="v-folder-lesson py-2 px-3 cursor-pointer"
		@click="toggleLesson"
		:class="{
			active: lesson.isOpened,
			[getLessonProgressType]: true,
			locked: !isAvailable,
		}"
	>
		<div class="flex items-center justify-between">
			<div class="flex items-center mr-1 lg:mr-3">
				<div class="v-folder-lesson__octa mr-3">
          <div class="v-lesson__octa mr-3" :class="lesson.type">
            <icon-display :iconPath="`/images/new/lessonsType/${lesson.type}.svg`"/>
          </div>
				</div>
				<img
					:src="lesson.image || getCurrentActivityData.activity.icon || ''"
					class="img-lesson rounded-lg mr-2"
					@error="handleImageError"
				/>
				<div class="hidden lg:block v-folder-lesson__name">
					{{ lesson.name }}
				</div>
			</div>

			<div class="flex-grow lg:flex-grow-0 flex flex-col">
				<div class="lg:hidden v-folder-lesson__name">{{ lesson.name }}</div>
				<div class="flex items-center w-full" :class="getLessonProgressType === 'done' ? 'justify-end' : ''">
					<div
						class="flex items-center h-3 mr-5 flex-grow"
						v-if="getLessonProgressType !== 'done' && showDeadline"
					>
						<img
							class="h-full mr-2"
							src="@/assets/lesson-fire.png"
							alt=""
							v-if="getLessonProgressType === 'expired'"
						/>
						<div class="v-text--desc">
							{{ isTrueMobile ? 'До ' : `${$t('home.deadline')}:` }}
							{{ getDateInfo.date }}
						</div>
					</div>
					<div class="mr-3 h-5" v-if="getLessonProgressType === 'done'">
						<img class="h-full" src="@/assets/lesson-done.png" alt="" />
					</div>
					<div class="mr-3 h-5" v-if="getLessonProgressType === 'expired' && showDeadline">
						<img class="h-full" src="@/assets/lesson-wait.png" alt="" />
					</div>
					<div class="plus-flag" :class="{ active: lesson.isOpened }" v-if="isAvailable">+</div>
					<div v-else>
						<img src="@/assets/locked.png" alt="" />
					</div>
				</div>
			</div>
		</div>

		<div class="mt-5" v-if="lesson.isOpened">
			<div class="w-full lg:w-1/3">
				<ProgressBar :type="getLessonProgressType" :percent="getCurrentLessonProgressBarPercents" />
				<div class="w-full v-text--desc mt-2 mb-5">
					<span v-if="getCurrentLessonStatus === lessonStatuses.notCompleted">
						<div
							class="v-text--red v-text--bold text-xs"
							v-if="getLessonProgressType === 'expired' && showDeadline"
						>
							{{ $t('home.better_late') }}
						</div>
						<div>{{ $t('games.in_progress') }}</div>
					</span>

					<span
						v-else-if="getCurrentLessonStatus === lessonStatuses.inCheck"
						class="flex w-full justify-between"
					>
						<div class="v-text--bold flex items-center" style="font-wight: bold">
							<span class="mr-1" style="color: red; font-size: 20px">!</span>
							<span>{{ $t('lesson.teacher_check') }}</span>
						</div>
						<div></div>
					</span>

					<span v-else class="flex w-full justify-between">
						<div>{{ $t('home.done') }}</div>
						<div>
							{{ $t('home.result') }}
							<span class="v-text--bold">{{ getCurrentLessonPercents }}%</span>
						</div>
					</span>
				</div>
			</div>
			<div class="w-full flex justify-center" v-if="canOpen">
				<div class="v-button v-button--blue" @click.stop="handleOpen">
					{{
						lesson.test_result
							? $t('home.improve_the_result')
							: lesson.percent_progress
								? $t('home.continue')
								: $t('home.open')
					}}
				</div>
				<div
					class="v-button v-button--transparent ml-5"
					@click.stop="showTestResults = true"
					v-if="lesson.show_errors && lesson.pass_once_current > 0 && lesson.status_result !== 0"
				>
					<span>{{ $t('lesson.show_mistakes') }}</span>
				</div>
			</div>
		</div>

		<assessment-result-modal
			v-if="Boolean(showTestResults && lesson)"
			:lesson="lesson"
			@close="showTestResults = false"
		/>

		<AccessRestrictedModal
			v-if="showAccessRestrictedModal"
			:teacher-user-id="lesson.user_id"
			@close="showAccessRestrictedModal = false"
		/>
	</div>
</template>

<script setup>
import defaultIcon from '@/assets/img-stub.svg'

function handleImageError(event) {
	event.target.src = defaultIcon // Устанавливаем стандартный иконку
}
</script>

<script>
import {defineAsyncComponent, defineComponent} from "vue";
import { mapMutations, mapState } from 'vuex'
import activitySettings from '../../helpers/activitySettings'
import FlutterBridge from '../../helpers/flutterBridge'
const AssessmentResultModal = defineAsyncComponent(() => import('../modals/AssessmentResultModal.vue'));
import ProgressBar from '../ProgressBar.vue'
import moment from 'moment'
import AccessRestrictedModal from '../AccessRestrictedModal.vue'
import IconDisplay from '@/components/IconDisplay.vue'

export default defineComponent({
	components: { AccessRestrictedModal, ProgressBar, AssessmentResultModal, IconDisplay },
	props: {
		lesson: {
			type: Object,
			require: true,
		},
		group: {
			type: Object,
			require: true,
		},
	},
	data: () => ({
		showTestResults: false,
		lessonStatuses: {
			notCompleted: 0,
			completed: 1,
			inCheck: 2,
		},
		showAccessRestrictedModal: false,
	}),
	methods: {
		...mapMutations(['TOGGLE_LESSON']),

		toggleLesson() {
			if (!this.isAvailable) {
				return
			}
			if (!this.isTeacherAvailable) {
				this.showAccessRestrictedModal = true
				return
			}

			this.TOGGLE_LESSON({
				lesson: this.lesson,
				group: this.group,
				withSections: Number(this.group.view_type) === 1 && this.group.marathon_status === 'off',
			})
		},

		handleOpen() {
			if (!this.canOpen) return
			if (!this.profile.subscriptionAvailability && window.isNativeMobile) {
				FlutterBridge.callService('FlutterPaymentChannel', 'request')
				// window.FlutterPaymentChannel.postMessage('request');
				return
			}

			this.$store.commit('CLEAR_CURRENT_LESSON_FORCE')

			switch (this.lesson.type) {
				case 'lexical':
					return this.$router.push({
						name: 'lesson',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'assessment':
					return this.$router.push({
						name: 'assessment',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'video':
					return this.$router.push({
						name: 'video',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'challenge':
					return this.$router.push({
						name: 'challenge',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'activity': {
					let bearerToken = localStorage.getItem('token')
					let GAMES_URL = process.env.GAMES_URL
					let NAV_URL = `${GAMES_URL}/run/activities/${this.lesson.slug}/${
						this.lesson.group_id
					}/${this.getCurrentActivityData.slug.replaceAll('_', '-')}?token=${bearerToken}`

					if (window.isTrueMobile) {
						this.$router.push({
							name: 'activity-frame',
							params: { ...this.$route.params, link: NAV_URL },
						})
					} else {
						window.location.assign(NAV_URL)
					}
					break
				}
				default:
					alert('Invalid type')
			}
		},
	},
	computed: {
		...mapState({
			profile: (state) => state.user.profile,
		}),

		isTrueMobile() {
			return window.isTrueMobile
		},
		getLessonProgressType() {
			if (
				this.lesson.progress_type === 'done' ||
				(this.lesson.pass_once &&
					((this.lesson.type !== 'assessment' &&
						this.lesson.type !== 'video' &&
						this.lesson.type !== 'challenge') ||
						this.lesson.status > 0)) ||
				(this.lesson.type === 'challenge' && !!this.lesson.is_published_post)
			) {
				return 'done'
			} else if (
				this.lesson.progress_type === 'actual' ||
				(this.group && this.group.additional_info && Number(this.group.additional_info.dontUseDeadline) === 1)
			) {
				return 'actual'
			} else {
				return 'expired'
			}
		},

		getCurrentActivityData() {
			if (this.lesson.type !== 'activity') return { activity: {}, slug: null }

			const slug =
				Object.keys(activitySettings).find(
					(slug) => slug === this.lesson?.activity?.slug || slug === this.lesson?.activity_code
				) ?? ''
			return { activity: activitySettings[slug] || {}, slug }
		},
		getDateInfo() {
			return {
				//ПОЧЕМУ-ТО ЕСЛИ УРОК В СЕКЦИИ, ТО this.lesson?.expires_at - СТРОКА, А ЕСЛИ В ОБЩЕМ СКОУПЕ, ТО ЭТО ОБЪЕКТ {date: string}
				date: moment(
					typeof this.lesson?.expires_at === 'string'
						? this.lesson?.expires_at
						: this.lesson?.expires_at?.date
				).format('DD.MM.YYYY'),
				isExpired:
					new Date().getTime() > new Date(this.lesson.expires_at.date).getTime() &&
					!this.group &&
					!this.group.additional_info &&
					Number(this.group.additional_info.dontUseDeadline) !== 1,
			}
		},
		canOpen() {
			if (this.lesson.type === 'lexical' || this.lesson.type === 'video' || this.lesson.type === 'challenge')
				return true
			if (this.lesson.type === 'assessment') {
				return true
			}
			if (this.lesson.type === 'activity') {
				return true
				// return this.getCurrentActivityData.slug;
			}
		},
		showDeadline() {
			return (
				!['as_you_progress', 'manually'].includes(this.group.marathon_status) &&
				(!this.group.additional_info ||
					!this.group.additional_info.dontUseDeadline ||
					!(Number(this.group.additional_info.dontUseDeadline) === 1))
			)
		},
		isAvailable() {
			if (!this.group.structure) return true
			// if (!this.group.marathon_status || this.group.marathon_status === 'open' || this.group.marathon_status === 'off') return true
			// if (this.group.marathon_status === 'close') return !!this.lesson.is_active
			return !!this.lesson.availability
		},
		isTeacherAvailable() {
			return !!this.lesson.is_teacher_available
		},
		getCurrentLessonStatus() {
			if (
				(!this.lesson.test_result && this.lesson.pass_once_current === 0 && this.lesson.type !== 'challenge') ||
				(this.lesson.type === 'challenge' && !this.lesson.is_published_post)
			) {
				return this.lessonStatuses.notCompleted
			} else if (this.lesson.status_result === 0 && this.lesson.type !== 'challenge') {
				return this.lessonStatuses.inCheck
			} else {
				return this.lessonStatuses.completed
			}
		},
		getCurrentLessonPercents() {
			if (this.lesson.type === 'challenge') {
				return !!this.lesson.is_published_post ? 100 : 0
			}

			return this.lesson.test_result
		},
		getCurrentLessonProgressBarPercents() {
			if (this.lesson.type === 'challenge') {
				return !!this.lesson.is_published_post ? 100 : 0
			}

			return this.lesson.percent_progress || this.lesson.test_result
		},
	},
})
</script>

<style lang="scss" scoped>
.img-lesson {
	width: 3rem;
	min-width: 3rem;
}

.v-folder-lesson {
  font-family: Onest;
	background-color: #fff;
	border-radius: 12px;
	transition: all 0.15s ease;

	&:hover &__name {
		color: #589ddc;
	}

	&__name {
		line-height: 1;
		margin-bottom: 3px;
	}

	&.locked {
		pointer-events: none;
		opacity: 0.75;
		filter: grayscale(100);
	}

	&.active {
		background-image: linear-gradient(0deg, #f3f2f2b8 0%, rgb(255, 255, 255) 69%, rgb(255, 255, 255) 100%);
	}

	&__octa {
		min-width: 35px;
		width: 35px;
		position: relative;

		.hex-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 60%;
			z-index: 1;

			&.to-white {
				filter: brightness(0) invert(1);
			}
		}
	}

	&.actual {
		.v-folder-lesson__deadline {
			filter: brightness(1.1);
			color: #27a34c;
		}

		.v-folder-lesson__octa path {
			fill: #589ddc;
		}
	}

	&.expired {
		.v-folder-lesson__deadline {
			color: #cf6e49;
			filter: brightness(1.1);
		}

		.v-folder-lesson__octa path {
			fill: #cf6e49;
		}
	}

	&.done {
		.v-folder-lesson__octa path {
			fill: #27a34c;
			color: #27a34c;
		}
	}
}

.plus-flag {
	color: lightgray;
	font-size: 23px;
	transition: all 0.2s linear;

	&.active {
		transform: rotate(45deg);
		color: #000;
	}
}
</style>
