export default {
	learnt_out_of: 'Выучено {p1} из {p2}',
	learnt_out_of_short: '{p1} из {p2}',
	learnt_progress: 'Прогресс',
	i_dont_know: 'Не знаю',
	i_know: 'Знаю',
	hint: 'Подсказка',
	restart: 'Перезапуск',
	click_on: 'Кликни на слово, если не знаешь его перевод',
	ok: 'Хорошо',
	word: 'Слова',
	type_in_the_translation: 'Введи ответ',
	next: 'Далее',
	check: 'Проверить',
	send_for_checking: 'Отправить на проверку',

	complete_all_tasks: 'Выполни все задания и пройди тест',
	the_test_will_be: 'Доступ к тесту откроется только после прохождения всех заданий',
	teachers_note: 'Комментарий от учителя',
	all_the_tasks: 'все задания выполнены',
	the_test_will_be_available: 'Тест станет доступен, когда ты пройдешь все задания',
	correct_your_mistake: 'Неверно, исправь ошибку',
	complete_all_fields: 'Заполните все поля',
	are_you_sure: 'Уверены?',
	confirm: 'Подтвердить',
	// try_again: 'Поражение',
	to_improve_the_result: 'Чтобы улучшить результат, выполни все задания этого урока еще раз и сдай тест заново',
	improve_the_result: 'Улучшить результат',
	finish_the_lesson: 'Завершить урок',
	you_can_do_it_better: 'Ты можешь лучше!',
	test_results: 'Результаты теста',
	experience: 'опыт',
	you_are_the_best: 'Лучше некуда, продолжай так же упорно учиться!',
	perfect: 'Отлично!',
	well_done: 'Очень хорошо!',
	good_but: 'Хорошо, но ты можешь лучше!',
	if_you_get: 'Набрав более 90%, ты можешь улучшить результат, сдав тест еще раз, не проходя все задания заново',
	you_did_it: 'Получилось!',
	try_again: 'Попробуй еще раз',
	do_it_again: 'Пройти еще раз',
	next_task: 'Следующее задание',
	to_complete: 'Набери 90%, чтобы пройти задание',
	your_result: 'Результат задания',
	result: 'Результат',
	date: 'Дата',
	mistakes: 'Допущенные ошибки',
	no_mistakes: 'Ошибок нет',
	new_words: 'новых слов',
	click_on_the_letter: 'Кликни на буквы для возврата.',
	take_the_test: 'Пройти тест',
	enter_answer: 'Введите ответ',
	in_progress: 'Прогресс',
	sec: 'сек',
	no_internet: 'Ошибка загрузки. Отсутствует подключение к интернету.',
	oops_time_is_up: 'Упс, время на выполнение этого урока истекло!',
}
