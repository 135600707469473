<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9186 5.78532C19.6271 4.07676 22.3972 4.07678 24.1058 5.78532L23.4883 6.40277L24.1058 5.78532C25.8143 7.49385 25.8143 10.2639 24.1058 11.9725L24.1058 11.9725L13.7938 22.2845C11.4018 24.6764 7.52367 24.6764 5.13172 22.2845L5.75044 21.6657L5.13172 22.2845C2.73975 19.8925 2.73975 16.0144 5.13172 13.6224L13.7938 4.96035C14.1355 4.61864 14.6895 4.61864 15.0312 4.96035C15.3729 5.30206 15.3729 5.85608 15.0312 6.19779L6.36916 14.8598L5.75044 14.2411L6.36915 14.8598C4.66061 16.5684 4.66061 19.3385 6.36915 21.047L5.76129 21.6549L6.36915 21.047C8.07769 22.7556 10.8478 22.7555 12.5563 21.047L13.1338 21.6245L12.5563 21.047L22.8683 10.7351C22.8683 10.7351 22.8683 10.7351 22.8683 10.7351C23.8934 9.70991 23.8934 8.04785 22.8683 7.02277C21.8432 5.99761 20.1811 5.99763 19.156 7.02275L19.156 7.02276L8.84404 17.3347C8.50234 17.6764 8.50234 18.2305 8.84404 18.5722C9.18576 18.9139 9.73977 18.9139 10.0815 18.5722L18.7435 9.91008C19.0852 9.56837 19.6392 9.56836 19.981 9.91007C20.3227 10.2518 20.3227 10.8058 19.981 11.1475L11.3189 19.8096C10.2938 20.8347 8.63172 20.8347 7.60657 19.8096L7.60655 19.8096C6.5815 18.7844 6.5815 17.1224 7.60655 16.0973L7.60658 16.0973L17.9186 5.78533C17.9186 5.78533 17.9186 5.78533 17.9186 5.78532Z"
        fill="#141029"
      />
    </g>
  </svg>
</template>
