<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M14 18.959C10.9433 18.959 8.45831 16.474 8.45831 13.4173V7.00065C8.45831 3.94398 10.9433 1.45898 14 1.45898C17.0566 1.45898 19.5416 3.94398 19.5416 7.00065V13.4173C19.5416 16.474 17.0566 18.959 14 18.959ZM14 3.20898C11.9116 3.20898 10.2083 4.91232 10.2083 7.00065V13.4173C10.2083 15.5057 11.9116 17.209 14 17.209C16.0883 17.209 17.7916 15.5057 17.7916 13.4173V7.00065C17.7916 4.91232 16.0883 3.20898 14 3.20898Z"
        fill="#141029"
      />
      <path
        d="M14 23.0411C8.59834 23.0411 4.20001 18.6428 4.20001 13.2411V11.2578C4.20001 10.7795 4.59668 10.3828 5.07501 10.3828C5.55335 10.3828 5.95001 10.7795 5.95001 11.2578V13.2411C5.95001 17.6745 9.56668 21.2911 14 21.2911C18.4333 21.2911 22.05 17.6745 22.05 13.2411V11.2578C22.05 10.7795 22.4467 10.3828 22.925 10.3828C23.4033 10.3828 23.8 10.7795 23.8 11.2578V13.2411C23.8 18.6428 19.4017 23.0411 14 23.0411Z"
        fill="#141029"
      />
      <path
        d="M15.6217 8.37573C15.5283 8.37573 15.4233 8.36406 15.3183 8.32906C14.4667 8.01406 13.5333 8.01406 12.6817 8.32906C12.2267 8.4924 11.725 8.25906 11.5617 7.80406C11.3983 7.34906 11.6317 6.8474 12.0867 6.68406C13.3233 6.24073 14.6883 6.24073 15.925 6.68406C16.38 6.8474 16.6133 7.34906 16.45 7.80406C16.31 8.15406 15.9717 8.37573 15.6217 8.37573Z"
        fill="#141029"
      />
      <path
        d="M14.9333 10.8491C14.8517 10.8491 14.7817 10.8374 14.7 10.8141C14.2333 10.6857 13.755 10.6857 13.2883 10.8141C12.8217 10.9424 12.3433 10.6624 12.215 10.1957C12.0867 9.74073 12.3667 9.2624 12.8333 9.13406C13.5917 8.92406 14.4083 8.92406 15.1667 9.13406C15.6333 9.2624 15.9133 9.74073 15.785 10.2074C15.68 10.5924 15.3183 10.8491 14.9333 10.8491Z"
        fill="#141029"
      />
      <path
        d="M14 26.541C13.5217 26.541 13.125 26.1443 13.125 25.666V22.166C13.125 21.6877 13.5217 21.291 14 21.291C14.4783 21.291 14.875 21.6877 14.875 22.166V25.666C14.875 26.1443 14.4783 26.541 14 26.541Z"
        fill="#141029"
      />
    </g>
  </svg>
</template>
