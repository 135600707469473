<template>
	<div v-if="fromAssessment" class="w-full flex vzn-assessment__header" :style="getCustomQuestionOrder">
		<div class="flex justify-between w-full">
			<div class="flex">
				<div
					v-if="!isNovakid && !question.isChildren"
					class="mb-4 mr-2 v-text--bold text-2xl flex items-center"
					style="line-height: 0.9"
					:class="{ 'v-text--red v-text--bold': isInvalid }"
				>
					{{ index + 1 }}.
				</div>
				<div class="flex mb-5" v-if="question.description && !isStringEmpty(question.description)">
					<svg style="min-width: 21.5px" width="21.5px" height="21.5px" class="mr-2">
						<path
							data-v-57079a6d=""
							fill-rule="evenodd"
							stroke="rgb(0, 158, 224)"
							stroke-width="1px"
							stroke-linecap="butt"
							stroke-linejoin="miter"
							fill="rgb(0, 158, 224)"
							d="M10.493,19.820 C5.345,19.820 1.156,15.641 1.156,10.505 C1.156,5.369 5.345,1.190 10.493,1.190 C15.641,1.190 19.830,5.369 19.830,10.505 C19.830,15.641 15.641,19.820 10.493,19.820 ZM10.493,2.884 C6.281,2.884 2.854,6.303 2.854,10.505 C2.854,14.708 6.281,18.127 10.493,18.127 C14.705,18.127 18.132,14.708 18.132,10.505 C18.132,6.303 14.705,2.884 10.493,2.884 ZM10.493,7.400 C9.869,7.400 9.362,6.894 9.362,6.271 C9.362,5.649 9.869,5.142 10.493,5.142 C11.117,5.142 11.624,5.649 11.624,6.271 C11.624,6.894 11.117,7.400 10.493,7.400 ZM10.493,9.094 C10.962,9.094 11.342,9.473 11.342,9.940 L11.342,15.021 C11.342,15.489 10.962,15.868 10.493,15.868 C10.024,15.868 9.644,15.489 9.644,15.021 L9.644,9.940 C9.644,9.473 10.024,9.094 10.493,9.094 Z"
						></path>
					</svg>
					<div class="text-sm lg:text-lg" v-html="question.description.replace(/\n/g, '<br>')"></div>
				</div>
			</div>
			<div class="vzn-header__progress" v-if="!hideProgress">
				<div class="vzn-progress-bar">
					<ProgressBar :percent="getPercent" type="new" />
				</div>
				<div class="flex justify-between mt-2">
					<div class="vzn-progress-bar__title">{{ fromAssessment ? 'Выполнено' : 'Прогресс' }}</div>
					<div class="vzn-progress-bar__info">
						{{
							$t('games.learnt_out_of_short', {
								p1: currentLessonProgressData?.words?.length ?? 0,
								p2: currentWords?.length,
							})
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ProgressBar from '../components/ProgressBar.vue'
import { computed } from 'vue'
import { isStringEmpty } from '@/helpers.js'

const props = defineProps({
	fromAssessment: Boolean,
	question: {},
	index: Number,
	isInvalid: Boolean,
	currentWords: [],
	currentLessonProgressData: {},
	hideProgress: Boolean,
})

const getPercent = computed(() => {
	return (props.currentLessonProgressData?.words?.length / props.currentWords?.length) * 100
})
import { useStore } from 'vuex'

const store = useStore()
const getCustomQuestionOrder = computed(() => {
	return props.question.size && parseInt(props.question.size[0]) > 0 ? { order: 1 } : { order: 0 }
})

const isNovakid = computed(() => store.getters.isNovakid)
</script>
<style lang="scss">
.vzn-assessment__header {
	justify-content: space-between;
	.vzn-header__progress {
		// width: 300px;
		align-items: center;
		.vzn-progress-bar {
			width: 100%;
			min-width: 200px;
			height: fit-content;
			border-radius: 64px;
			margin-right: 20px;
		}
	}
}
</style>
