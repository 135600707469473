<template>
    <div class="update-screen flex flex-col pb-10">
        <img class="update-screen__bottom-decor" src="@/assets/mobile/decor_login_bottom.png" alt="">
        <img class="update-screen__top-decor" src="@/assets/mobile/decor_login_top.png" alt="">

        <div class="h-full flex flex-col items-center justify-center w-10/12 mx-auto" style="max-width: 400px">
            <!-- BUILD: {{ flutterAppBuildNumber }} -->
            <!-- VERSION: {{ flutterAppVersion }} -->
            <div class="mb-10 text-center">Необходимо обновить приложение, перейдите в {{ isIos ? 'App Store' : 'Play Store' }}</div>
            <div class="s-button s-button--orange" @click="goToStore">Перейти</div>
        </div>

        <div class="flex flex-col items-center justify-center w-full mb-5">

        </div>
    </div>
</template>

<script>
import FlutterBridge from '../../helpers/flutterBridge';

export default {
    computed: {
        isIos() {
            return window.flutterAppOs === 'ios';
        },
        flutterAppBuildNumber() {
            return window.flutterAppBuildNumber;
        },
        flutterAppVersion() {
            return window.flutterAppVersion;
        }
    },
    methods: {
        goToStore() {
            if (this.isIos) {
                FlutterBridge.callService('FlutterLinkChannel', 'https://apps.apple.com/ru/app/%D0%B2%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D1%8F/id1440122401');
                // window.FlutterLinkChannel && window.FlutterLinkChannel.postMessage('https://apps.apple.com/ru/app/%D0%B2%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D1%8F/id1440122401')
            } else {
                FlutterBridge.callService('FlutterLinkChannel', 'https://play.google.com/store/apps/details?id=com.vznaniya1');
                // window.FlutterLinkChannel && window.FlutterLinkChannel.postMessage('https://play.google.com/store/apps/details?id=com.vznaniya1')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.update-screen {
    height: 100vh;

    &__bottom-decor,
    &__top-decor {
        position: absolute;
        width: 30vw;
        max-width: 80px;
        pointer-events: none;
    }

    &__bottom-decor {
        bottom: 0;
        left: 0;
    }
    &__top-decor {
        top: 0;
        right: 0;
    }

    .splash-logo {
        width: 100px;
    }
}
</style>
