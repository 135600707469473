<script setup>
import icons from './iconsSvgList'
const props = defineProps({
	size: {
		type: Number,
		default: 24,
	},
	name: {
		type: String,
		default: 'play',
	},
	width: {
		type: Number,
	},
	height: {
		type: Number,
	},
	color: {
		type: String,
		default: 'fill',
	},
	viewBox: {
		type: String,
		default: 'fill',
	},
})
</script>
<template>
	<svg
		class="vz-icon"
		:width="`${width ? width : size ?? 24}`"
		:height="`${height ? height : size ?? 24}`"
		fill="none"
		:viewBox="viewBox ? viewBox : `0 0 ${width ? width : size ?? 24} ${height ? height : size ?? 24}`"
		:style="{ minWidth: `${width ? width : size ?? 24}px`, minHeight: `${height ? height : size ?? 24}px` }"
		v-html="icons[props.name]"
	></svg>
</template>

<style lang="scss">
.vzn-icon-lesson {
	height: fit-content;
	border-radius: 50%;
	border: 2px solid #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;

	&.activity {
		background-color: #f9cdcd;
		box-shadow: 0px 10px 16px -4px #f9cdcd99;
	}
	&.lexical {
		background-color: #d6f3d8;
		box-shadow: 0px 10px 16px -4px #76d67d80;
	}
	&.video {
		background-color: #e8d0f4;
		box-shadow: 0px 10px 16px -4px #f9cdcd99;
	}
	&.assessment {
		background-color: #b3c8fa;
		box-shadow: 0px 10px 16px -4px #4579f54d;
	}
	&.challenge {
		background-color: #fee9ca;
		box-shadow: 0px 10px 16px -4px #4579f54d;
	}
}
</style>
