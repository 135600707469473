<template>
	<transition name="fade" mode="out-in">
		<div class="v-modal" v-if="show" @click.self="show = false">
			<div class="v-modal__wrapper">
				<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false" />
				<div class="v-text--bold text-xl text-center">Выберите группу в которую хотите скопировать урок</div>
				<div v-if="!loading" class="flex flex-col items-center">
					<v-select
						class="mt-5"
						style="width: 250px"
						:options="getGroupsOptions"
						:selected="selectedGroup"
						v-if="groups && groups.length > 0"
					/>
					<div class="v-button v-button--gradient-red mt-5" @click="openCopyLink">Скопировать</div>
				</div>
				<div v-else>
					<loader />
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getSlug } from '@/helpers.js'
import VSelect from '@/components/Selector.vue'
import { useStore } from 'vuex'
import useEventBus from '@/composables/useEventBus.js'

export default {
	components: { VSelect },
	data: () => ({
		loading: false,
		show: false,
		selectedGroup: 0,
		groups: [],
	}),
	setup() {
		const emitter = useEventBus()
		const store = useStore()
		emitter.on('temp-teacher-groups', async () => {
			// this.loading = true;
			// this.show = true;
			// try {
			//   console.log('call groups')
			//   const { data } = await getCustomGroups(this.tempTeacherData.token, this.tempTeacherData.id);
			//   this.groups = data.data;
			// } catch (err) {
			//   console.log('err', err);
			// } finally {
			//   this.loading = false;
			// }

			// console.log(this.currentLesson)

			if (
				!store.state.lessons.currentLesson.author ||
				store.state.lessons.currentLesson.author.is_public ||
				store.state.lessons.currentLesson.public ||
				store.state.lessons.currentLesson.author.id === store.state.lessons.currentLesson.user_id
			) {
				this.openCopyLink()
			} else {
				emitter.emit('v-modal', {
					text: 'Этот урок недоступен для копирования!',
					error: true,
				})
			}
		})
	},
	computed: {
		...mapGetters(['getPlatform']),
		...mapState({
			tempTeacherData: (state) => state.user.tempTeacherData,
			currentLesson: (state) => state.lessons.currentLesson,
		}),
		optionsGroup() {
			return [{ key: 0, value: this.$t('home.all_groups') }]
		},

		getGroupsOptions() {
			if (!this.groups) return []
			return [...this.optionsGroup, ...this.groups.map((group, index) => ({ key: group.id, value: group.name }))]
		},
	},
	methods: {
		openCopyLink() {
			const teacherUrl = this.getPlatform ? this.getPlatform.domain : process.env.NEW_TEACHER_URL
			const lessonId = this.currentLesson.id

			if (!this.selectedGroup) {
				// const teacher = teacherUrl + `/${this.tempTeacherData.id}/create-lesson/base` + `?copySlug=${getSlug()}&token=${this.tempTeacherData.token}`;
				const teacher = teacherUrl + `/material/lessons` + `?lessonId=${lessonId}`
				location.href = teacher
			} else {
				// const teacher = teacherUrl + `/${this.tempTeacherData.id}/group/${this.selectedGroup}` + `?copySlug=${getSlug()}&token=${this.tempTeacherData.token}`;
				const teacher =
					teacherUrl + `/${this.tempTeacherData.id}/group/${this.selectedGroup}` + `?copySlug=${getSlug()}`
				location.href = teacher
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	animation: showup 0.3s ease;
	animation-fill-mode: forwards;

	&__wrapper {
		background-color: #fff;
		background: url(/images/modal-pattern.png);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;

		opacity: 0;
		width: 450px;
		min-height: 250px;

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}
}
</style>
