<template>
	<svg
		v-if="isSvg"
		v-bind="svgAttributes"
		v-html="svgContent"
		:class="customClass"
	/>
	<img v-else :src="imagePath" :class="customClass" alt="icon" />
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import axios from 'axios';
import { getCachedIcon, setCachedIcon } from '@/store/iconStorage';

const props = defineProps({
	iconPath: {
		type: String,
		required: true,
	},
	customClass: {
		type: String,
		default: '',
	},
});

const isSvg = computed(() => props.iconPath.endsWith('.svg'));
const imagePath = computed(() => {
	if (isSvg.value) return null;
	const cleanedPath = props.iconPath.includes('/images/')
		? props.iconPath.replace('/images/', '')
		: props.iconPath;
	return new URL(`/resources/assets/${cleanedPath}`, import.meta.url).href;
});

const svgContent = ref('');
const svgAttributes = ref({
	width: null,
	height: null,
	viewBox: null,
});

// Функция для загрузки или извлечения иконки из хранилища
const fetchSvg = async (path) => {
	const cachedSvg = getCachedIcon(path);
	if (cachedSvg) {
		return cachedSvg;
	}

	try {
		const response = await axios.get(path);
		const svg = response.data;

		// Сохраняем в хранилище
		setCachedIcon(path, svg);

		return svg;
	} catch (error) {
		throw error;
	}
};

// Следим за изменением иконки и загружаем её при необходимости
watchEffect(async () => {
	if (isSvg.value) {
		try {
			const cleanedPath = props.iconPath.includes('/images/')
				? props.iconPath.replace('/images/', '')
				: props.iconPath;
			const url = new URL(`/resources/assets/${cleanedPath}`, import.meta.url).href;

			const svg = await fetchSvg(url);

			// Парсим SVG и извлекаем атрибуты
			const parser = new DOMParser();
			const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
			const svgElement = svgDoc.documentElement;

			svgAttributes.value = {
				width: svgElement.getAttribute('width') || null,
				height: svgElement.getAttribute('height') || null,
				viewBox: svgElement.getAttribute('viewBox') || null,
			};

			svgContent.value = svg
				.replace(/<svg[^>]*>/, '')  // Убираем открывающий тег <svg>
				.replace(/<\/svg>/, '');   // Убираем закрывающий тег </svg>
		} catch (error) {
			svgContent.value = ''; // Если ошибка, очищаем содержимое
		}
	}
});
</script>