import apiRequest from './apiRequest.js'
import apiRequestV1 from './apiRequestV1.js'
import { convertToFormData, withFilters, withParams } from '../helpers/apiHelper.js'

export const getLessonDataSlug = async (id, slug, group_id) => {
	return await apiRequest.get(`lessons/show-by-slug/${slug}`, { params: { group_id } })
}

export const getLessonDataSlugPreview = async (id, slug) => {
	return await apiRequest.get(`lessons/lesson-preview/${slug}`)
}

/**
 * Список уроков
 * @param {Object} filters
 */
export const getLessonsDataFilter = async (filters) => {
	console.log('filters', filters)
	return await withFilters(filters, async (params) => {
		console.log('params', params)
		return await apiRequest.get('lessons' + params)
	})
}

/**
 * Список слов для урока
 * @param {Object} filters - in { lesson_id }
 */
export const getLessonWords = async (lesson_id) => {
	return await apiRequest.get(`lesson-words/filter?lesson_id=${lesson_id}&timestamp=${new Date().getTime()}`)
}

export const getLessonWordsFilters = async (filters) => {
	return await withParams(filters, async (params) => {
		return await apiRequest.get(`lesson-words/filter` + params)
	})
}

/**
 * Получить один урок
 * @param {Number} lesson_id
 */
export const getCurrentLesson = async (lesson_id, group_id) => {
	return await apiRequest.get(`lessons/${lesson_id}`, { params: { group_id } })
}

/**
 * Получить результаты тестов
 * @param {Number} lesson_id
 */
export const getLessonsTestResults = async (lesson_id) => {
	return await apiRequest.get(`test-results/filter?lesson_id=${lesson_id}`)
}

export const stageLessonResult = async ({ id, type, ...payload }) => {
	return await apiRequest.post(`lr/complete-task/${type}/${id}`, payload)
}

export const stageTestResult = async ({ id, ...payload }) => {
	return await apiRequest.post(`lr/complete-test/${id}`, {
		...payload,
	})
}

export const stageAssessmentResult = async (payload) => {
	return await apiRequest.post('lr/complete-assessment', payload)
}

export const stageVideoResult = async (payload) => {
	return await apiRequest.post('lr/complete-assessment', payload)
}

export const getActivityTypes = async () => {
	return await apiRequest.get('lessons/activity-types')
}

export const resetLexical = async (id, group_id) => {
	return await apiRequest.post(`lr/reset/${id}`, { group_id })
}

export const fullQuestions = async (lessonId) => {
	return await apiRequest.get(`lessons/questions?lesson_id=${lessonId}`)
}

export const onlyResultAssessment = async (payload) => {
	return await apiRequest.post(`lr/get-assessment-result`, payload)
}

export const getDrafts = async () => {
	return await apiRequest.get('drafts/get-by-user')
}
export const getDraftsNoTrans = async () => {
	return await apiRequest.get('drafts/get-by-user', { transformResponse: (r) => r })
}

export const deleteDraft = async (id) => {
	return await apiRequest.delete(`drafts/${id}`)
}
export const createDraft = async (payload) => {
	return await apiRequest.post('drafts', payload)
}
export const updateDraft = async ({ id, ...payload }) => {
	return await apiRequest.put(`drafts/${id}`, payload)
}
export const createDraftFD = async (payload) => {
	return await apiRequest.post('drafts', convertToFormData({ ...payload }), {
		headers: { 'Content-Type': 'multipart/form-data' },
	})
}
export const updateDraftFD = async ({ id, ...payload }) => {
	return await apiRequest.post(`drafts/${id}`, convertToFormData({ ...payload, _method: 'PUT' }), {
		headers: { 'Content-Type': 'multipart/form-data' },
	})
}
export const dropDraftKey = async ({ id, ...payload }) => {
	return await apiRequest.post(`drafts/drop-keys/${id}`, payload)
}
export const pushDraftElement = async ({ id, ...payload }) => {
	return await apiRequest.post(`/drafts/push/${id}`, payload)
}
export const pushDraftElementFD = async ({ id, ...payload }) => {
	return await apiRequest.post(`/drafts/push/${id}`, convertToFormData({ ...payload }), {
		headers: { 'Content-Type': 'multipart/form-data' },
	})
}

export const getIllustration = async (id) => {
	return await apiRequest.get(`illustrations/${id}`)
}

export const countingTest = async (payload) => {
	return await apiRequest.post(`lr/counting-test`, payload)
}

export const getLexicalResult = async (payload) => {
	return await apiRequest.post(`lr/get-lexical-result`, payload)
}

export const getCurrentLessonComments = async (lesson_id, student_id) => {
	return await apiRequest.get(`test-comments?lesson_id=${lesson_id}&student_id=${student_id}&perPage=1000`)
}

export const sendCurrentLessonComment = async (payload) => {
	return await apiRequest.post(`test-comments`, payload)
}

export const deleteCurrentLessonComment = async (id) => {
	return await apiRequest.delete(`test-comments/${id}`)
}

export const uploadIllustration = async (file) => {
	const fd = new FormData()
	fd.append('file', file)
	return await apiRequest.post('illustrations/upload', fd)
}

export const replaceIllustration = async (id, file) => {
	if (!id || !file) return
	const fd = new FormData()
	fd.append('file', file)
	return await apiRequest.post(`illustrations/replace/${id}`, fd)
}
