import apiRequest from './apiRequest.js';
import apiRequestV1 from './apiRequestV1.js';

import { withParams, withFilters } from '../helpers/apiHelper.js';

export const auth = async (payload) => {
  return await apiRequest.post('auth/login', payload);
}

export const register = async (payload) => {
  return await apiRequest.post('auth/register', payload);
}

export const forgotPassword = async (payload) => {
  return await apiRequest.post('auth/forgot-password', payload);
}

/**
 * Получить профиль юзера
 */
export const getProfileData = async () => {
  return await apiRequest.get('profile');
}

/**
 * Расширенные профиль ученика
 */
export const getStudentData = async () => {
  return await apiRequest.get('student-info').then(res => res.data);
}

/**
 * Обновить профиль юзера + смена пароля
 * @param {payload} in [surname, name, school ,phone, email, city] and [current_password, password, password_confirmation]
 */
export const updateProfileData = async (payload) => {
  const data = payload instanceof FormData
    ? payload
    : { _method: 'PUT', ...payload }
  return await apiRequest.post('profile/update', data);
}

/**
 * Установка локали на сервере для сообщений в ответе
 * @param {String} locale - ru | en
 */
export const setLocale = async (locale) => {
  return await apiRequest.put(`profile/setlocal/${locale}`);
}

/**
 * Получить список групп
 * @param {Number} student_id
 */
export const getGroups = async (student_id) => {
  return await apiRequest.get(`groups/filter?student_id=${student_id}`)
}

/**
 * Получить список групп
 * @param {Number} student_id
 */
export const getCustomGroups = async (token, teacher_id) => {
  return await apiRequest.get(`groups/filter?teacher_id=${teacher_id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

/**
 * Присоидениться к группе
 * @param {Number} student_id - id студента
 * @param {String} code - код группы
 */
export const joinGroup = async (payload) => {
  return await apiRequestV1.post(`student/addToGroup`, payload);
}

/**
 * Покинуть группу
 * @param {Number} student_id
 * @param {Number} group_id
 */
export const leaveGroup = async ({ student_id, group_id }) => {
  return await apiRequestV1.post(`group/leave/${group_id}/${student_id}`);
}

export const logoutNew = async () => {
  return await apiRequestV1.get('logoutNew?student=true');
}

export const getNotificationsCount = async () => {
  return await apiRequest.get('notifications/unread-count');
}

export const getNotifications = async (filters) => {
  return await withFilters(filters, async (params) => {
    return await apiRequest.get('notifications' + params);
  })
}

export const readNotifications = async (ids) => {
  return await apiRequest.post('notifications/read', { ids });
}

export const removeNotifications = async (ids) => {
  return await apiRequest.post('notifications/delete', { ids });
}

export const sendReview = async (payload) => {
  return await apiRequestV1.post('reviewSend', payload);
}

export const getLearnedWords = async (filters) => {
  return await withParams(filters, async (params) => {
    return await apiRequest.get('lesson-words/learned' + params);
  })
}

export const getSubjects = async () => {
  return await apiRequest.get('subjects')
}

export const getMarathonData = async (group_id, params = {}) => {
  return await apiRequest.get(`groups/info-student/${group_id}`, { params })
}

export const addToGroupStudent = async ({code}) => {
  return await apiRequest.post(`groups/add-student/${code}`)
}

export const getProfileSettings = async () => {
  return await apiRequest.get('profile/settings')
}

export const setProfileSettings = async (payload) => {
  return await apiRequest.post('profile/settings', payload)
}

export const inviteStudentByCode = async (code) => {
  return await apiRequest.post(`/groups/invite/link/${code}`);
}

export const getStudentRatingStars = async (id, date_from=null, date_to=null, country=null) => {
  return await apiRequest.get(`rating/user/${id}`, {params: {date_from, date_to, country_code: country}});
}

export const removeUser = async (id) => {
  return await apiRequestV1.delete('student/remove', {params: {id}});
}

export const getUserRefLink = async () => {
  return await apiRequest.get('profile/ref-link');
}
