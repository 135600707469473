<template>
	<div class="scroll-wrapper">
		<div
			v-if="state.isScrollable"
			class="scroll-button left"
			@click="scrollLeft"
			:disabled="state.isAtStart"
		>
			<IconDisplay iconPath="/images/cursor_type1.svg" customClass="scroll-button__ico"/>
		</div>

		<div
			class="scroll-container"
			:class="{ 'center-content': !state.isScrollable }"
			ref="scrollContainer"
			@scroll="checkScroll"
			@mousedown="onMouseDown"
			@mouseup="onMouseUp"
			@mouseleave="onMouseUp"
			@mousemove="onMouseMove"
		>
			<div class="scroll-inner">
				<slot />
			</div>
		</div>

		<div
			v-if="state.isScrollable"
			class="scroll-button right"
			@click="scrollRight"
			:disabled="state.isAtEnd"
		>
			<IconDisplay iconPath="/images/cursor_type1.svg" customClass="scroll-button__ico"/>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
import IconDisplay from '@/components/IconDisplay.vue';

const scrollContainer = ref(null);
const state = reactive({
	isAtStart: true,
	isAtEnd: false,
	isScrollable: false,
	isDragging: false,
	startX: 0,
	scrollLeft: 0,
});

const checkScroll = () => {
	if (scrollContainer.value) {
		const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;

		state.isAtStart = scrollLeft === 0;
		state.isAtEnd = scrollLeft + clientWidth >= scrollWidth;
		state.isScrollable = scrollWidth > clientWidth;
	}
};

const scrollLeft = () => {
	if (scrollContainer.value) {
		scrollContainer.value.scrollBy({ left: -200, behavior: 'smooth' });
	}
};

const scrollRight = () => {
	if (scrollContainer.value) {
		scrollContainer.value.scrollBy({ left: 200, behavior: 'smooth' });
	}
};

const onMouseDown = (event) => {
	if (!scrollContainer.value) return;

	state.isDragging = true;
	state.startX = event.pageX;
	state.scrollLeft = scrollContainer.value.scrollLeft;
	scrollContainer.value.style.scrollBehavior = 'auto'; // Отключить плавную прокрутку при перетаскивании
};

const onMouseUp = () => {
	state.isDragging = false;
	if (scrollContainer.value) {
		scrollContainer.value.style.scrollBehavior = 'smooth'; // Включить плавную прокрутку обратно
	}
};

const onMouseMove = (event) => {
	if (!state.isDragging || !scrollContainer.value) return;

	event.preventDefault();
	const x = event.pageX;
	const deltaX = state.startX - x; // Разница между начальной и текущей позицией
	scrollContainer.value.scrollLeft = state.scrollLeft + deltaX;
};

onMounted(() => {
	checkScroll();
	window.addEventListener('resize', checkScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('resize', checkScroll);
});
</script>

<style lang="scss" scoped>
.scroll-wrapper {
	display: flex;
	align-items: center;
	position: relative;
	gap: 20px;
}

.scroll-container {
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	width: 100%;
	scrollbar-width: none;
	cursor: grab;
	justify-content: flex-start;
}

.scroll-container.center-content {
	justify-content: center;
}

.scroll-container:active {
	cursor: grabbing;
}

.scroll-container::-webkit-scrollbar {
	display: none;
}

.scroll-button {
	cursor: pointer;
}

.scroll-button.left .scroll-button__ico {
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.scroll-button[disabled] {
	pointer-events: none;
	opacity: 0.5;
}

.scroll-inner {
	display: flex;
	width: max-content;
}
</style>
