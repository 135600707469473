<template>
  <div class="button-component-new" :class="customClasses" :style="customStyle" @click.stop="onClick">
    <button class="button-component-new__button"  :disabled="disabled" @click.stop="onClick">
      {{title}}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonComponentNew",
  props: {
    title: String,
    disabled: Boolean,
    flexShrink: {
      type: Number,
      default: 0
    },
    width: Number,
    height: Number,
    transparent: Boolean,
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit("click");
    }
  },
  computed: {
    customStyle() {
      const style = {};
      if (this.width) style.width = this.width + 'px';
      if (this.height) style.height = this.height + 'px';
      style.flexShrink = this.flexShrink;
      return style;
    },
    customClasses() {
      const classArr = [];
      if (this.transparent) classArr.push("transparent");
      return classArr;
    }
  }
}
</script>

<style scoped lang="scss">
.button-component-new {
  height: 50px;
  width: 100%;
  background: var(--Primary-Gradient, linear-gradient(134deg, #9C42F5 -27.75%, #4579F5 35.58%, #1DDF9F 109.17%, #20DD9E 109.17%));
  box-shadow: 0 10px 26px 0 rgba(22, 122, 234, 0.30);
  color: #FFF;
  border-radius: 8px;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */

  &.transparent {
    box-shadow: none;
    padding: 1px;

    button {
      color: var(--Blue, #4579F5);
      background: white;
      width: calc(100%);
      height: calc(100%);
    }
  }
}
button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
</style>