<template>
	<div class="lexical-lesson__header p-2 md:p-5" v-if="lesson">
		<div class="container mx-auto">
			<div class="w-full flex items-center justify-between">
				<div class="flex items-center">
					<div
						class="lesson-icon shadow-md rounded-full h-12 w-12 md:h-16 md:w-16 flex items-center justify-center"
						style="z-index: 2"
					>
						<svg class="h-4 md:h-8" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10 0H3.33333C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333V13.3333C0 14.0406 0.280952 14.7189 0.781049 15.219C1.28115 15.719 1.95942 16 2.66667 16H10C10.8837 15.9989 11.731 15.6474 12.3559 15.0225C12.9807 14.3976 13.3323 13.5504 13.3333 12.6667V3.33333C13.3323 2.4496 12.9807 1.60237 12.3559 0.97748C11.731 0.352588 10.8837 0.00105857 10 0V0ZM12 3.33333V10.6667H4V1.33333H10C10.5304 1.33333 11.0391 1.54405 11.4142 1.91912C11.7893 2.29419 12 2.8029 12 3.33333ZM2.66667 1.448V10.6667C2.19842 10.6663 1.7384 10.7898 1.33333 11.0247V3.33333C1.33339 2.91979 1.46164 2.51644 1.70042 2.1788C1.9392 1.84116 2.27678 1.58585 2.66667 1.448ZM10 14.6667H2.66667C2.31304 14.6667 1.97391 14.5262 1.72386 14.2761C1.47381 14.0261 1.33333 13.687 1.33333 13.3333C1.33333 12.9797 1.47381 12.6406 1.72386 12.3905C1.97391 12.1405 2.31304 12 2.66667 12H12V12.6667C12 13.1971 11.7893 13.7058 11.4142 14.0809C11.0391 14.456 10.5304 14.6667 10 14.6667Z"
								fill="#76D67D"
							/>
						</svg>
					</div>
					<div class="lesson-image shadow-md h-12 w-12 md:h-16 md:w-16 rounded-full" v-if="lesson.image">
						<img class="h-full rounded-full" :src="lesson.image" alt="" />
					</div>
					<div class="ml-5 text-base text-lg md:text-2xl">
						{{ lesson.name }}
					</div>
				</div>

				<div
					class="vzn-button"
					@click="showTestResults = true"
					v-if="currentLesson.pass_once_current > 0 && currentLesson.status_result !== 0"
				>
					{{ $t('lesson.show_mistakes') }}
				</div>
			</div>

			<div class="mt-3 md:mt-5 flex items-center justify-between flex-wrap md:flex-nowrap">
				<div class="flex items-center text-lg w-1/2 md:w-auto">
					<svg class="mr-3 h-5" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.6667 1.33333H8.31467C8.21156 1.33401 8.10965 1.31122 8.01667 1.26667L5.91267 0.210667C5.63493 0.0723634 5.32893 0.000257561 5.01867 0H3.33333C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 11.3333C0.00105857 12.2171 0.352588 13.0643 0.97748 13.6892C1.60237 14.3141 2.4496 14.6656 3.33333 14.6667H12.6667C13.5504 14.6656 14.3976 14.3141 15.0225 13.6892C15.6474 13.0643 15.9989 12.2171 16 11.3333V4.66667C15.9989 3.78294 15.6474 2.9357 15.0225 2.31081C14.3976 1.68592 13.5504 1.33439 12.6667 1.33333ZM3.33333 1.33333H5.01867C5.12177 1.33265 5.22368 1.35545 5.31667 1.4L7.42067 2.45267C7.69813 2.59212 8.00413 2.66537 8.31467 2.66667H12.6667C13.0654 2.66732 13.4548 2.78711 13.7849 3.01067C14.115 3.23424 14.3708 3.55136 14.5193 3.92133L1.33333 3.996V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333ZM12.6667 13.3333H3.33333C2.8029 13.3333 2.29419 13.1226 1.91912 12.7475C1.54405 12.3725 1.33333 11.8638 1.33333 11.3333V5.32933L14.6667 5.254V11.3333C14.6667 11.8638 14.456 12.3725 14.0809 12.7475C13.7058 13.1226 13.1971 13.3333 12.6667 13.3333Z"
							fill="black"
							fill-opacity="0.49"
						/>
					</svg>
					<div class="mt-1">{{ lesson.group_name }}</div>
				</div>
				<div class="flex items-center text-lg w-1/2 md:w-auto text-right justify-end">
					<svg class="mr-3 h-5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.6667 1.33333H12V0.666667C12 0.489856 11.9298 0.320286 11.8047 0.195262C11.6797 0.0702379 11.5101 0 11.3333 0C11.1565 0 10.987 0.0702379 10.8619 0.195262C10.7369 0.320286 10.6667 0.489856 10.6667 0.666667V1.33333H5.33333V0.666667C5.33333 0.489856 5.2631 0.320286 5.13807 0.195262C5.01305 0.0702379 4.84348 0 4.66667 0C4.48986 0 4.32029 0.0702379 4.19526 0.195262C4.07024 0.320286 4 0.489856 4 0.666667V1.33333H3.33333C2.4496 1.33439 1.60237 1.68592 0.97748 2.31081C0.352588 2.93571 0.00105857 3.78294 0 4.66667V12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H12.6667C13.5504 15.9989 14.3976 15.6474 15.0225 15.0225C15.6474 14.3976 15.9989 13.5504 16 12.6667V4.66667C15.9989 3.78294 15.6474 2.93571 15.0225 2.31081C14.3976 1.68592 13.5504 1.33439 12.6667 1.33333ZM1.33333 4.66667C1.33333 4.13623 1.54405 3.62753 1.91912 3.25245C2.29419 2.87738 2.8029 2.66667 3.33333 2.66667H12.6667C13.1971 2.66667 13.7058 2.87738 14.0809 3.25245C14.456 3.62753 14.6667 4.13623 14.6667 4.66667V5.33333H1.33333V4.66667ZM12.6667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V6.66667H14.6667V12.6667C14.6667 13.1971 14.456 13.7058 14.0809 14.0809C13.7058 14.456 13.1971 14.6667 12.6667 14.6667Z"
							fill="black"
							fill-opacity="0.49"
						/>
						<path
							d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44772 8.55229 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
							fill="black"
							fill-opacity="0.49"
						/>
						<path
							d="M4.66667 11C5.21895 11 5.66667 10.5523 5.66667 10C5.66667 9.44772 5.21895 9 4.66667 9C4.11438 9 3.66667 9.44772 3.66667 10C3.66667 10.5523 4.11438 11 4.66667 11Z"
							fill="black"
							fill-opacity="0.49"
						/>
						<path
							d="M11.3333 11C11.8856 11 12.3333 10.5523 12.3333 10C12.3333 9.44772 11.8856 9 11.3333 9C10.781 9 10.3333 9.44772 10.3333 10C10.3333 10.5523 10.781 11 11.3333 11Z"
							fill="black"
							fill-opacity="0.49"
						/>
					</svg>
					<div class="mt-1">{{ $helpers.dateformat(lesson.expires_at.date, 'dd.mm.yyyy') }}</div>
				</div>
				<div class="w-full md:w-1/3 mt-3 md:mt-0">
					<progress-bar class="v-progress__bar" :percent="lesson.percent_progress" type="new" revertedSlice />
					<div class="flex items-center justify-between mt-2">
						<div>{{ $t('lesson.progress') }}</div>
						<div>{{ lesson.percent_progress }}%</div>
					</div>
				</div>
			</div>
		</div>

		<LexicalTestResultModal v-if="showTestResults" @close="showTestResults = false" :lesson="currentLesson" />
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressBar from '../ProgressBar.vue'
import LexicalTestResultModal from './LexicalTestResultModal.vue'

const props = defineProps({
	lesson: Object,
})

const showTestResults = ref(false)

const store = useStore()

const currentLesson = computed(() => store.state.lessons.currentLesson)
const currentLessonTestResults = computed(() => store.state.lessons.currentLessonTestResults)

onMounted(() => console.log('AAAAAAAAAAAAAAAA', props))
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.lexical-lesson__header {
	background-color: $bgMain;
}
.lesson-icon {
	background: #d6f3d8;
	border: 2px solid #ffffff;
	// box-shadow: 0px 10px 16px -4px #003B9B;
}

.lesson-image {
	margin-left: -20px;
}
</style>
