<template>
	<div class="v-final-test pb-20 pt-10" ref="wrapper" v-if="canTest">
		<!-- TODO -->
		<!-- <div class="my-5 w-full" v-if="!multitestMode">
      <div class="flex flex-wrap w-full">
        <div class="w-full lg:w-1/3 p-2" v-for="word in shuffledWords" :key="word.id">
          <div class="mx-auto v-game-card v-game-card--adapted">
            <WordCard :wid="word.id" :word="word" input translate @input="onInput" ref="word" @focus="makeFocus(word.id)" :cnSymbol="true" />
          </div>
        </div>
      </div>
    </div> -->

		<div class="px-1">
			<div
				class="mb-5"
				v-for="task in multiTestList"
				:key="task.type"
				v-show="!isTrueMobile || (currentMultiTest && currentMultiTest.type === task.type)"
			>
				<div
					class="mb-2 v-text--bold text-2xl md:text-3xl text-center md:text-left w-full"
					v-if="multiTestList.length > 1"
				>
					{{ $t(task.title) }}
				</div>
				<component
					:is="task.component"
					:test="true"
					:words="task.words"
					ref="task"
					:single="multiTestList.length === 1"
				/>
			</div>

			<div class="w-full flex justify-center mt-5">
				<div
					:class="{ 'v-button--novakid': isNovakid, 'vzn-button': !isNovakid }"
					class="v-button mx-3"
					v-if="currentTestIndex >= 1"
					@click="currentTestIndex -= 1"
				>
					{{ $t('lesson.back') }}
				</div>
				<div
					class="v-button mx-3 text-center leading-none"
					:class="{ 'v-button--novakid': isNovakid, 'vzn-button': !isNovakid }"
					@click="
						currentTestIndex < multiTestList.length - 1 && isTrueMobile
							? (currentTestIndex += 1)
							: handleSend()
					"
				>
					{{
						currentTestIndex < multiTestList.length - 1 && isTrueMobile
							? $t('lesson.next')
							: $t('games.send_for_checking')
					}}
				</div>
			</div>
		</div>

		<div v-if="error" class="v-text--red text-xl text-center w-full my-5">
			{{ $t('games.complete_all_fields') }}
		</div>
	</div>
</template>

<script setup>
// import WordCard from '@/components/WordCard.vue';
import { useStore } from 'vuex'
import shuffle from 'lodash/shuffle'
import cloneDeep from 'lodash/cloneDeep'
import { getSlug } from '@/helpers.js'
import FindWord from '@/screens/games/FinalTestTasks/FindWord.vue'
import FindDefinition from '@/screens/games/FinalTestTasks/FindDefinition.vue'
import Quiz from '@/screens/games/FinalTestTasks/Quiz.vue'
import Listen from '@/screens/games/FinalTestTasks/Listen.vue'
import Unscramble from '@/screens/games/FinalTestTasks/Unscramble.vue'
import TypeIn from '@/screens/games/FinalTestTasks/TypeIn.vue'
import ListenType from '@/screens/games/FinalTestTasks/ListenType.vue'
import FillGame from '@/screens/games/FinalTestTasks/FillGame.vue'
import { computed, onMounted, ref, watch } from 'vue'
import useEventBus from '../../../composables/useEventBus.js'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const store = useStore()
const emit = defineEmits(['testProgress'])
const emitter = useEventBus()
const router = useRouter()
const route = useRoute()

const shuffledWords = ref(null)
const error = ref(false)
const canTest = ref(false)
const multitestMode = ref(false)
const currentTestIndex = ref(0)
const multiTestList = ref([
	{ title: 'lesson.find_words', component: FindWord, type: 'find_words', minWords: 5, words: [] },
	{ title: 'lesson.find_definition', component: FindDefinition, type: 'find_definition', minWords: 5, words: [] },
	{ title: 'lesson.quiz', component: Quiz, type: 'quiz', minWords: 1, words: [] },
	{ title: 'lesson.listen', component: Listen, type: 'listen', minWords: 1, words: [] },
	{ title: 'lesson.scrabble', component: Unscramble, type: 'scrabble', minWords: 1, words: [] },
	{ title: 'lesson.insert_missed', component: FillGame, type: 'insert_missed', minWords: 1, words: [] },
	{ title: 'lesson.listen_type', component: ListenType, type: 'listen_type', minWords: 1, words: [] },
	{ title: 'lesson.message', component: TypeIn, type: 'write_word', minWords: 1, words: [] },
])
const task = ref(null)

const currentLesson = computed(() => store.getters.getCurrentLesson)
const currentWords = computed(() => store.getters.getCurrentWords)
const currentTestProgressData = computed(() => store.getters.currentTestProgressData)
const getNotDoneTask = computed(() => store.getters.getNotDoneTask)
const getPlatform = computed(() => store.getters.getPlatform)
const isNovakid = computed(() => getPlatform.value && (getPlatform.value.id === 17 || getPlatform.value.id === 16))
const currentMultiTest = computed(() => multiTestList.value[currentTestIndex.value] || null)
const multitestDone = computed(() => multiTestList.value.length === currentTestIndex.value + 1)
const symbolMode = computed(() => currentLesson.value.with_pinyin)
const isChinese = computed(() => currentLesson.value.language === 'CN')
const isTrueMobile = computed(() => window.isTrueMobile)

const checkMultiTest = () => {
	const testTypes = (currentLesson.value?.additional_info && currentLesson.value?.additional_info.test_types) || [
		'write_word',
	]
	const testCounts = (currentLesson.value?.additional_info && currentLesson.value?.additional_info.testCounts) || {
		write_word: currentWords.value.length,
	}

	const activeTasksTypes = Object.values(testTypes)

	multiTestList.value = multiTestList.value.filter((game) => {
		return activeTasksTypes.includes(game.type)
	})

	const words = shuffle(cloneDeep(currentWords.value))

	multiTestList.value
		.slice()
		.reverse()
		.forEach((task, index) => {
			let taskWords = words.splice(0, testCounts[task.type])

			// if (taskWords.length < task.minWords) {
			//   taskWords = [
			//     ...taskWords,
			//     ...sampleSize(shuffle(this.currentWords.filter(el => !taskWords.map(word => word.id).includes(el.id))), task.minWords - taskWords.length)
			//   ]
			// }

			task.words = taskWords
		})
}

const handleSend = async () => {
	error.value = false

	const validationArr = task.value && task.value.map((task) => task.validateTest && task.validateTest())
	if (validationArr.filter((el) => el).length !== multiTestList.value.length) {
		error.value = true
		return emitter.emit('v-modal', {
			text: t('lesson.fillFields'),
		})
	} else if (getPlatform.value && (getPlatform.value.id === 16 || getPlatform.value.id === 16)) {
		saveAndSend(validationArr)
	} else {
		// if (this.isChinese && this.symbolMode) {
		//   if (this.$refs.word.some(el => el.chineseWriters.length !== el.done.length)) {
		//     this.error = true;
		//     return;
		//   }
		// } else if (this.currentTestProgressData.length !== this.currentWords.length || this.currentTestProgressData.some(el => !el.userWord || !el.userWord.trim())) {
		//   this.error = true;
		//   return;
		// }

		emitter.emit('v-modal', {
			text: `${t('games.are_you_sure')}`,
			size: 'sm',
			buttons: [
				{
					text: t('groups.cancel'),
					class: 'v-button v-button--raised-green',
					callback: () => {
						emitter.emit('v-modal', { close: true })
					},
				},
				{
					text: t('games.confirm'),
					class: 'v-button vzn-button',
					callback: () => {
						emitter.emit('v-modal', { close: true })
						saveAndSend(validationArr)
					},
				},
			],
		})
	}
}

const saveAndSend = () => {
	const testData = task.value && task.value.map((task) => task.getTestData && task.getTestData())
	emitter.emit('v-test-result', testData)
}

watch(currentTestIndex, (value) => {
	const wrapper = document.querySelector('.v-nav')
	wrapper && wrapper.scrollIntoView()

	emit('testProgress', (value * 100) / (multiTestList.value.length - 1))
})

onMounted(() => {
	// Multitest handle
	checkMultiTest()

	if (getNotDoneTask.value === 'FinalTest') {
		canTest.value = true
	} else {
		const slug = getSlug()
		router.push({ name: 'lesson', params: { id: route.params.id }, query: { ...route.query, slug } })
	}
})
</script>

<style lang="scss" scoped></style>
