
export default {
  marathon_info: 'International marathon for teachers and their students',
  avail_winners: 'The winners of the year',
  marathon_label: 'Patience and hard work will lead to victory!',
  action: 'Join',
  about_marathon: 'About the marathon',
  question_1: 'Who can join?',
  question_2: 'What is rated?',
  question_3: 'How to join <br> the marathon?',
  question_4: 'Overall results',
  pay: 'Pay',
  answer_1: 'Language teachers and their students - those, who use Vznaniya',
  answer_2: 'The number of learned words (max 50 per day) Compulsory tasks(not less than 5 in a lesson)',
  answer_3: ' the marathon fee  - 500 rubles, get the access to the statistics chart and get certificates and prizes if you enter the top 10 teachers or top-30 students',
  answer_4: 'The winners are determined every month, season and finally year. The marathon is held until 1.05.2021',
  prizes: 'Prizes',
  teahcer: 'Teacher',
  of_year: 'Teacher of the year',
  of_season: 'Teacher of the season',
  of_month: 'Teacher of the month',
  student: 'Student',
  diploma: 'Diploma',
  free_games_access: 'Free access to online games on GAMES.VZNANIYA.RU',
  year_access: 'for a year',
  season_access: 'for 3 months',
  month_access: 'for 1 month',
  playstation: '',
  free_app_access: 'Free access to app Vznaniya',
  certificate: 'Certificate',
  partners: 'Partners',
  rating: 'Rating',
  online_update: 'Updated in real time, reset on the first day of every month',
  top_teachers: 'Top 10 teachers',
  top_students: 'Top 30 students',
  in_marathon: 'participants',
  city: 'City',
  school: 'School',
  num_of_students: 'Number of students',
  num_of_words: 'Number of learned words',
  winners: 'Winners',
  results: 'Results',
  of_month: 'of the month',
  of_season: 'of the season',
  of_year: 'of the year',
  of_marathon: 'of the marathon',
  marathon: 'Marathon',
  summer: 'Summer',
  autumn: 'Autumn',
  winner: 'Winter',
  spring: 'Spring',
  teachers: 'Teachers',
  students: 'Students',
  learned_words: 'words learned',
  logo: '/images/marathon/logo_en.png',
  left_in: 'Time left in',
  place: '-st place',
  certificate: 'Сertificate of participation',
  loading: 'Loading...',
  pay_action: 'Pay ',
  ends_in: 'The results of the marathon are being processed',
}