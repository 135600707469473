<template>
	<div class="v-lesson" :class="type" @click="handleOpen">
		<div class="relative">

			<!-- Header -->
			<div class="flex justify-between pb-5">
				<div class="flex relative items-start items-center leading-5 w-10/12 overflow-hidden">
					<div class="v-lesson__octa mr-3" :class="lesson.type">
            <icon-display :iconPath="`/images/new/lessonsType/${lesson.type}.svg`"/>
					</div>
					<div class="v-text--bold">
						{{ lesson.name }}
					</div>
				</div>
				<div class="v-lesson__lang ml-2">
					{{ lesson.language }}
				</div>
			</div>

			<!-- Body -->
			<div class="v-lesson__body flex flex-col">
				<div class="flex">
					<div
						:class="{
							'w-2/3': lesson.image || getCurrentActivityData.activity.icon,
						}"
					>
						<div
							class="v-lesson__row v-lesson__deadline flex gap-2 items-center"
							v-if="
								!this.getCurrentGroup.additional_info ||
								!this.getCurrentGroup.additional_info.dontUseDeadline ||
								!(Number(this.getCurrentGroup.additional_info.dontUseDeadline) === 1)
							"
						>
              <icon-display iconPath="/images/new/calendar-new.svg"/>
							<div class="v-text--desc text-base">
								{{ getDateInfo.date }}
							</div>
						</div>
						<div class="v-lesson__row flex gap-2 items-center mt-2">
              <icon-display iconPath="/images/new/group.svg"/>
							<div class="v-text--desc text-base">{{ lesson.group_name }}</div>
						</div>
					</div>
					<img
						v-if="lesson.image || getCurrentActivityData.activity.icon"
						class="w-1/3 rounded mx-auto object-contain"
						:src="lesson.image || getCurrentActivityData.activity.icon"
						alt=""
					/>
				</div>
				<div class="v-lesson__row flex items-center mt-4">
					<ProgressBar :type="type" :percent="getCurrentLessonProgressBarPercents" />
				</div>
			</div>
		</div>

		<div class="v-lesson__body flex justify-between v-text--desc mt-1 mb-3">
			<div class="v-lesson__row v-lesson__row--additional w-full">
				<span v-if="getCurrentLessonStatus === lessonStatuses.notCompleted">
          <div class="flex items-center justify-between gap-2">
					<div>{{ $t('games.in_progress') }}</div>
            <div
                class="v-text--red"
                v-if="
							type === 'expired' &&
							(!this.getCurrentGroup.additional_info ||
								!this.getCurrentGroup.additional_info.dontUseDeadline ||
								!(Number(this.getCurrentGroup.additional_info.dontUseDeadline) === 1))
						"
            >
						{{ $t('home.better_late') }}
					</div>
            <!-- <div>{{ $t('home.score') }} {{ lesson.percent_progress }}%</div> -->
          </div>
				</span>

				<span v-else-if="getCurrentLessonStatus === lessonStatuses.inCheck" class="flex w-full justify-between">
					<div class="v-text--bold flex items-center" style="font-wight: bold">
						<span class="mr-1" style="color: red; font-size: 20px">!</span>
						<span>{{ $t('lesson.teacher_check') }}</span>
					</div>
					<div></div>
				</span>

				<span v-else class="flex w-full justify-between">
					<div>{{ $t('home.done') }}</div>
					<div>
						{{ $t('home.result') }}
						<span class="v-text--bold">{{ getCurrentLessonPercents }}%</span>
					</div>
				</span>
			</div>
		</div>

		<div class="w-full flex justify-center gap-6 mt-4" v-if="canOpen">
			<div
				class="v-button v-button--transparent v-lesson-button"
				@click.stop="showTestResults = true"
				v-if="
					(lesson.show_errors || lesson.type === 'lexical') &&
					lesson.pass_once_current > 0 &&
					lesson.status_result !== 0
				"
			>
				{{ $t('lesson.show_mistakes') }}
			</div>
			<div class="v-button v-button--blue v-lesson-button" @click.stop="handleOpen">
				{{
					lesson.test_result
						? $t('home.improve_the_result')
						: lesson.percent_progress
							? $t('home.continue')
							: $t('home.open')
				}}
			</div>
		</div>

		<assessment-result-modal
			v-if="lesson.type !== 'lexical' && Boolean(showTestResults && lesson)"
			:lesson="lesson"
			:isLessonCardParent="true"
			@close="showTestResults = false"
		/>
		<lexical-test-result-modal
			v-else-if="Boolean(showTestResults && lesson)"
			:lesson="lesson"
			@close="showTestResults = false"
		/>

		<AccessRestrictedModal
			v-if="showAccessRestrictedModal"
			:teacher-user-id="lesson.user_id"
			@close="showAccessRestrictedModal = false"
		/>
	</div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { mapGetters, mapState } from 'vuex'
import activitySettings from '@/helpers/activitySettings.js'
import FlutterBridge from '@/helpers/flutterBridge.js'
const AssessmentResultModal = defineAsyncComponent(() => import('@/components/modals/AssessmentResultModal.vue'))
import LexicalTestResultModal from '@/components/lesson/LexicalTestResultModal.vue'
import AccessRestrictedModal from '@/components/AccessRestrictedModal.vue'
import IconDisplay from '@/components/IconDisplay.vue'

export default defineComponent({
	components: { AccessRestrictedModal, ProgressBar, AssessmentResultModal, LexicalTestResultModal, IconDisplay },
	props: {
		lesson: {
			type: Object,
			default: () => ({}),
		},
		type: {
			type: String,
			default: 'actual',
		},
	},
	data: () => ({
		colors: {
			current: '#589ddc',
			deadline: '#cf6e49',
			done: '#27a34c',
		},
		showTestResults: false,
		lessonStatuses: {
			notCompleted: 0,
			completed: 1,
			inCheck: 2,
		},
		showAccessRestrictedModal: false,
	}),
	created() {
		this.$root.$on('home-button-pressed', () => {
			this.showTestResults = false
		})
	},
	computed: {
		...mapState({
			currentLesson: (state) => state.lessons.currentLesson,
			activityTypes: (state) => state.lessons.activityTypes,
			profile: (state) => state.user.profile,
			groups: (state) => state.folders.groups,
		}),
		...mapGetters(['getPlatform']),
		// group() {
		// return this.groups.filter((group) => group.id === this.lesson.group_id);
		// },
		getDateInfo() {
			return {
				date: this.$helpers.dateformat(this.lesson.expires_at.date, 'dd.mm.yyyy'),
				isExpired:
					new Date().getTime() > new Date(this.lesson.expires_at.date).getTime() &&
					!this.getCurrentGroup &&
					!this.getCurrentGroup.additional_info &&
					Number(this.getCurrentGroup.additional_info.dontUseDeadline) !== 1,
			}
		},
		getCurrentGroup() {
			return this.groups.filter((group) => group.id === this.lesson.group_id)[0]
		},
		canOpen() {
			if (this.lesson.type === 'lexical' || this.lesson.type === 'video' || this.lesson.type === 'challenge')
				return true
			if (this.lesson.type === 'assessment') {
				// if (this.lesson.test_result === 0) return true;
				// if (this.lesson.test_result > 0 && !this.lesson.pass_once) return true;
				// if (this.lesson.test_result > 0 && this.lesson.pass_once) return false;
				return true
			}
			if (this.lesson.type === 'activity') {
				return this.getCurrentActivityData.slug
			}
		},
		getCurrentActivityData() {
			if (!this.lesson.activity) return { activity: {}, slug: null }
			const slug = Object.keys(activitySettings).find((slug) => slug === this.lesson.activity.slug)
			return { activity: activitySettings[slug] || {}, slug }
		},
		getCurrentLessonStatus() {
			if (
				(!this.lesson.test_result && this.lesson.pass_once_current === 0 && this.lesson.type !== 'challenge') ||
				(this.lesson.type === 'challenge' && !this.lesson.is_published_post)
			) {
				return this.lessonStatuses.notCompleted
			} else if (this.lesson.status_result === 0 && this.lesson.type !== 'challenge') {
				return this.lessonStatuses.inCheck
			} else {
				return this.lessonStatuses.completed
			}
		},
		getCurrentLessonPercents() {
			if (this.lesson.type === 'challenge') {
				return !!this.lesson.is_published_post ? 100 : 0
			}

			return this.lesson.test_result
		},
		getCurrentLessonProgressBarPercents() {
			if (this.lesson.type === 'challenge') {
				return !!this.lesson.is_published_post ? 100 : 0
			}

			return this.lesson.percent_progress || this.lesson.test_result
		},
		isTeacherAvailable() {
			return !!this.lesson.is_teacher_available
		},
	},
	methods: {
		handleOpen() {
			if (!this.canOpen) return
			if (!this.isTeacherAvailable) {
				this.showAccessRestrictedModal = true
				return
			}
			if (!this.profile.subscriptionAvailability && window.isNativeMobile) {
				FlutterBridge.callService('FlutterPaymentChannel', 'request')
				// window.FlutterPaymentChannel.postMessage('request');
				return
			}

			this.$store.commit('CLEAR_CURRENT_LESSON_FORCE')

			switch (this.lesson.type) {
				case 'lexical':
					return this.$router.push({
						name: 'lesson',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'assessment':
					return this.$router.push({
						name: 'assessment',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'video':
					return this.$router.push({
						name: 'video',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'challenge':
					return this.$router.push({
						name: 'challenge',
						params: { id: this.lesson.id },
						query: { ...this.$route.query, group_id: this.lesson.group_id },
					})
				case 'activity': {
					let bearerToken = localStorage.getItem('token')
					let GAMES_URL = this.getPlatform ? this.getPlatform?.games_url : import.meta.env.VITE_GAMES_URL
					let NAV_URL = `${GAMES_URL}/run/activities/${this.lesson.slug}/${
						this.lesson.group_id
					}/${this.getCurrentActivityData.slug.replaceAll('_', '-')}?token=${bearerToken}`

					if (window.isTrueMobile) {
						this.$router.push({
							name: 'activity-frame',
							params: { ...this.$route.params, link: NAV_URL },
						})
					} else {
						window.location.assign(NAV_URL)
					}
					break
				}
				default:
					alert('Invalid type')
			}
		},
	},
})
</script>

<style lang="scss">
.v-button.v-lesson-button {
  padding: 14px 32px;
}

.v-lesson {
  &.actual {
    .v-lesson__deadline {
      svg path {
        fill: #27a34c;
      }
    }
  }

  &.expired {
    .v-lesson__deadline {
      svg path {
        fill: #EB5757;
      }
    }
  }

  &__octa {
    min-width: 42px;
    min-height: 42px;
    max-width: 42px;
    max-height: 42px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.lexical {
      background: #d6f3d8;
    }

    &.assessment {
      background: #b4c8fa;

      & svg path {
        fill: #4579f5;
      }
    }

    &.activity {
      background: #f9cdcd;

      & path {
        fill: #eb5757;
      }
    }

    &.challenge {
      background-color: #fee9ca;
    }

    &.video {
      background: #e8d0f4;
      & path {
          fill: #b55ad8;
      }

    }
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/variables.scss';
//.v-lesson-button {
//	font-size: 13px;
//	padding: 0 15px;
//	@media screen and (max-width: 768px) {
//		font-size: 10px;
//		padding: 0 12px;
//	}
//}

.v-lesson {
	font-family: Onest;
	min-height: 225px;
	border-radius: 10px;
	background-color: white;
	transition: all 0.2s linear;
	cursor: pointer;
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	border: 2px solid $lightGrey;
	@media (min-width: 1024px) {
		width: 320px;
	}

	&.actual {
		.v-lesson__deadline {
      .v-text--desc {
        filter: brightness(1.1);
        color: #27a34c;
      }
		}

		.v-lesson__octa path {
			fill: #589ddc;
		}
	}

	&.expired {
		.v-lesson__deadline {
      .v-text--desc {
        color: #EB5757;
      }
		}

		.v-lesson__octa path {
			fill: #EB5757;
		}
	}

	&.done {
		.v-lesson__octa path {
			fill: #27a34c;
			color: #27a34c;
		}
	}

	&:hover {
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.09);
	}

  &__body {
    img {
      max-height: 60px;
    }
  }

	&__dot {
		position: absolute;
		left: -4px;
		bottom: 0;
		min-width: 10px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #d0d0d0;
	}

	&__row {
		position: relative;
	}

	&__icon {
		height: 20px;
		width: 20px;
		object-fit: cover;
		margin-right: 10px;
	}

	&__leftside {
		position: absolute;
		width: 2px;
		background-color: #d0d0d0;
		left: 16px;
		top: 0;
		height: 100%;
	}

	&__lang {
		background-color: #deebf8;
		padding: 0 15px;
		color: #4579F5;
		font-size: 14px;
    font-weight: 500;
    line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-radius: 30px;
    white-space: nowrap;
	}
}
</style>
