import * as dateFormatLib from 'dateformat'
import dateFormat from 'dateformat'
import moment from 'moment/moment.js'

export const dayNicifier = (date) => {
	if (typeof date === 'string' && date.includes('-')) {
		return date.replace(/-/g, '/').replace(/\.\S+/, '')
	}
	return date
}

export const novakidStudentHosts = ['student.bg.novakid.pl', 'student.bg.novakid.tr']

dateFormatLib.i18n.dayNames = [
	'Вс',
	'Пн',
	'Вт',
	'Ср',
	'Чт',
	'Пт',
	'Сб',
	'Воскресенье',
	'Понедельник',
	'Вторник',
	'Среда',
	'Четверг',
	'Пятница',
	'Суббота',
]

dateFormatLib.i18n.monthNames = [
	'янв',
	'фев',
	'мар',
	'апр',
	'май',
	'июн',
	'июл',
	'авг',
	'сен',
	'окт',
	'ноя',
	'дек',
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
]

dateFormatLib.i18n.timeNames = ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM']

export const chunkify = (a, n, balanced) => {
	if (n < 2) return [a]

	let len = a.length,
		out = [],
		i = 0,
		size

	if (len % n === 0) {
		size = Math.floor(len / n)
		while (i < len) {
			out.push(a.slice(i, (i += size)))
		}
	} else if (balanced) {
		while (i < len) {
			size = Math.ceil((len - i) / n--)
			out.push(a.slice(i, (i += size)))
		}
	} else {
		n--
		size = Math.floor(len / n)
		if (len % size === 0) size--
		while (i < size * n) {
			out.push(a.slice(i, (i += size)))
		}
		out.push(a.slice(size * n))
	}

	return out
}

export const getSlug = () => {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get('slug')
}

export const getOnlypreview = () => {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get('onlypreview')
}

export function dateformat(date, format) {
	date = dayNicifier(date)
	return dateFormat(date, format)
}

export function getNotifyDate(date, outputFormat = 'dd mmm yyyy HH:MM') {
	const formattedDate = moment(date.replace(/\+03:00/gi, '+00:00')) // Исправлено экранирование
		.subtract(3, 'hours')
		.format('YYYY-MM-DD HH:mm:ss');
	return dateformat(formattedDate, outputFormat);
}


export function getRequirmentsArrayParts(arr) {
	let obj = {}
	arr.forEach((el, i) => {
		if (el) {
			obj[i] = el
		}
	})
	return obj
}

export function objectToArray(obj) {
	let arr = []
	Object.keys(obj).forEach((key) => {
		arr[key] = obj[key]
	})
	return arr
}

export const getUrlToken = () => {
	// Токен авториации
	const urlParams = new URLSearchParams(window.location.search)
	const token = urlParams.get('token')
	if (token) {
		localStorage.setItem('token', token)
		urlParams.delete('token')
		history.replaceState(null, '', '?' + urlParams)
	}
}

const transliterate = (text) => {
	text = text
		.replace(/\u201e/g, "'")
		.replace(/\u201c/g, "'")
		.replace(/\u201d/g, "'")
		.replace(/\u0022/g, "'")
		.replace(/\u00ab/g, "'")
		.replace(/\u00bb/g, "'")
		.replace(/\u2018/g, "'")
		.replace(/\u0060/g, "'")
		.replace(/\u2018/g, "'")
		.replace(/\u2019/g, "'")
		.replace(/\u0415/g, 'e')
		.replace(/\u0435/g, 'e')
		.replace(/\u0410/g, 'a')
		.replace(/\u0430/g, 'a')
		.replace(/\u041E/g, 'o')
		.replace(/\u043E/g, 'o')
		.replace(/\u2018/g, "'")
		.replace(/\u0027/g, "'")
		.replace(/\u2019/g, "'")
		.replace(/\u201B/g, "'")
		.replace(/\u275B/g, "'")
		.replace(/\u275C/g, "'")
		.replace(/\u1806/g, "'")
		.replace(/\u0027/g, "'")
		.replace(/\u0027/g, "'")
		.replace(/\u042C/g, "'")
		.replace(/\u044C/g, "'")
		.replace(/\u0060/g, "'")
		.replace(/\u043A/g, 'k')
		.replace(/\u041A/g, 'k')
		.replace(/\u0441/g, 'c')
		.replace(/\u0421/g, 'c')
		.replace(/\u0412/g, 'b')
		.replace(/\u041D/g, 'h')
		.replace(/\u0445/g, 'x')
		.replace(/\u0425/g, 'x')
		.replace(/\u041C/g, 'm')
		.replace(/\s/g, '')
		.replace(/\'+/g, "'")
		.replace(/\./g, '')
		.replace(/…/g, '')
		.replace('/', '')
		.replace('\\', '')
		.replace('´', '')
		.replace('`', '')
		.replace(`'`, '')
	return text.trim().toLowerCase()
}

const timeToIso = (time) =>
	'PT' +
	time
		.split(':')
		.map((el, i) => (el === '00' ? null : (el.indexOf('0') === 0 ? el.substring(1, 2) : el) + ['H', 'M', 'S'][i]))
		.filter((el) => el)
		.join('')

const clearToCompare = (word) => {
	let newWord = word
		.replace(/\s/g, '')
		.replace(/\u002E/g, '')
		.replace(/\u002C/g, '')
		.replace(/\u003F/g, '')
		.replace(/\u0021/g, '')
		.replace(/\u002D/g, '')
		.replace(/\u2013/g, '')
		.replace(/\u2014/g, '')
		.replace('!', '')
		.replace('ё', 'е')
		.replace('Ё', 'Е')
		.replace(/éé/g, 'e')
		.replace(/\u003A/g, '')
		.replace(/\u003B/g, '')
		.replace(/\u0451/g, 'e')
		.replace(/\./g, '')
		.replace(/…/g, '')
		.replace(/\u0401/g, 'E')
		.replace('é', 'e')
	return newWord.trim().toLowerCase()
}

// const transliterate = (text) => {
//   text = text
//       .replace(/\u0415/g, 'e')
//       .replace(/\u0435/g, 'e')
//       .replace(/\u0410/g, 'a')
//       .replace(/\u0430/g, 'a')
//       .replace(/\u041E/g, 'o')
//       .replace(/\u043E/g, 'o')
//       .replace(/\u2018/g, "'")
//       .replace(/\u0027/g, "'")
//       .replace(/\u2019/g, "'")
//       .replace(/\u201B/g, "'")
//       .replace(/\u275B/g, "'")
//       .replace(/\u275C/g, "'")
//       .replace(/\u0060/g, "'")
//       .replace(/\u043A/g, "k")
//       .replace(/\u041A/g, "k")
//       .replace(/\u0441/g, "c")
//       .replace(/\u0421/g, "c")
//       .replace(/\u0412/g, "b")
//       .replace(/\u041D/g, "h")
//       .replace(/\u0445/g, "x")
//       .replace(/\u0425/g, "x")
//       .replace(/\u041C/g, "m")
//       .replace(/\s/g, '');
//   return text.trim().toLowerCase();
// }

// const clearToCompare = (word) => {
//   let newWord = word.replace(/\s/g, '')
//       .replace(/\u002E/g, '')
//       .replace(/\u002C/g, '')
//       .replace(/\u003F/g, '')
//       .replace(/\u0021/g, '')
//       .replace(/\u002D/g, '')
//       .replace(/\u2013/g, '')
//       .replace(/\u2014/g, '')
//       .replace('!', '')
//       .replace('ё', 'е')
//       .replace('Ё', 'Е')
//       .replace(/\u003A/g, '')
//       .replace(/\u003B/g, '')
//       .replace(/\u0451/g, 'e')
//       .replace(/\u0401/g, 'E');
//   return newWord.trim().toLowerCase();
// }

export const normalizeWord = (word) => {
	if (String.prototype.normalize && false) {
		return transliterate(clearToCompare(word).normalize('NFKC'))
	} else {
		return transliterate(clearToCompare(word))
	}
}

const parseLinks = (text) => {
	if (!text) return ''
	return this.linkify(text)
}

const linkify = (inputText) => {
	let replacedText, replacePattern1, replacePattern2, replacePattern3

	//URLs starting with http://, https://, or ftp://
	// eslint-disable-next-line no-useless-escape
	replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
	replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

	//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
	// eslint-disable-next-line no-useless-escape
	replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
	replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

	//Change email addresses to mailto:: links.
	// eslint-disable-next-line no-useless-escape
	replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
	replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

	return replacedText
}

export const awaitCallback = () => {
	let condition = false
	let callback = null
	let resolver = null

	new Promise((resolve) => {
		resolver = resolve
	}).then(() => {
		callback && callback()
	})

	return {
		toggleCondition: () => {
			condition = true
			if (callback) {
				resolver && resolver()
			}
		},
		subscribe(_callback = () => {}) {
			callback = _callback
			if (condition) {
				resolver && resolver()
			}
		},
	}
}

export const iosFakeInput = () => {
	let fakeInput = document.querySelector('input[fake=true]')

	if (!fakeInput) {
		fakeInput = document.createElement('input')
		fakeInput.setAttribute('type', 'text')
		fakeInput.setAttribute('fake', 'true')
		fakeInput.style.position = 'absolute'
		fakeInput.style.opacity = 0
		fakeInput.style.height = 0
		// fakeInput.style.pointerEvents = 'none'
		fakeInput.style.fontSize = '16px' // disable auto zoom
		document.body.prepend(fakeInput)
	}

	// focus so that subsequent async focus will work
	fakeInput.focus()
}

/* EXPORT  */
/* EXPORT  */
/* EXPORT  */
const helpers = {
	iosFakeInput,
	getUrlToken,
	objectToArray,
	getRequirmentsArrayParts,
	normalizeWord,
	dateformat,
	linkify,
	dayNicifier,
	timeToIso,
	chunkify,
	awaitCallback,
	sleep: (time = 1000) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve()
			}, time)
		})
	},
	specialSymbols: {
		De: {
			// Немецкий
			lowercase: '⸚äößü'.split(''),
			uppercase: ['⸚', 'Ä', 'Ö', 'SS', 'Ü'],
		},
		Fr: {
			// Французкий
			lowercase: 'àâçéèêëîïôœùûü'.split(''),
			uppercase: 'ÀÂÇÉÈÊËÎÏÔŒÙÛÜ'.split(''),
		},
		Sp: {
			// Испанский
			lowercase: '¡¿°áéíñóúü'.split(''),
			uppercase: '¡¿°ÁÉÍÑÓÚÜ'.split(''),
		},
		It: {
			// Итальянский
			lowercase: 'àéèìóòù'.split(''),
			uppercase: 'ÀÉÈÌÓÒÙ'.split(''),
		},
		Fi: {
			//Finnish
			lowercase: 'åäöšž'.split(''),
			uppercase: 'ÅÄÖŠŽ'.split(''),
		},
		El: {
			//Greek
			lowercase: 'αβγδεζηθικλμνξοπρσςτυφχψω'.split(''),
			uppercase: 'ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ'.split(''),
		},
		CN: {
			//Greek
			lowercase: 'áàǎāéèěēíìǐīóòǒōúùǔüǘǜǚǖū',
			uppercase: 'ÁÀǍĀÉÈĚĒÍÌǏĪÓÒǑŌÚÙǓÜǗǛǙǕŪ',
		},
		Bas: {
			// Bashkir
			lowercase: 'ҡәүөңһҙҫғ',
			uppercase: 'ҠӘҮӨҢҺҘҪҒ',
		},
		// TODO:
		PL: {
			// Polish
			lowercase: 'ąćęłńóśźż',
			uppercase: 'ĄĆĘŁŃÓŚŹŻ',
		},
		TR: {
			// Turkish
			lowercase: 'çğıöşü',
			uppercase: 'ÇĞIÖŞÜ',
		},
		SE: {
			lowercase: 'åäö',
			uppercase: 'ÅÄÖ',
		},
		He: {
			lowercase: 'תּתשׁשׂשרקץצפּףפעסןנםמלכּךכיטחזוהדגבּבא',
			uppercase: 'תּתשׁשׂשרקץצפּףפעסןנםמלכּךכיטחזוהדגבּבא',
		},
		PT: {
			lowercase: 'áàâãçéêíóôõúü',
			uppercase: 'ÁÀÂÃÇÉÊÍÓÔÕÚÜ',
		},
		AR: {
			lowercase: 'يىوهنملكقفغعظطضصشسزرذدخحجثتةباء',
			uppercase: 'يىوهنملكقفغعظطضصشسزرذدخحجثتةباء',
		},
		SR: {
			lowercase: 'ćčđšž',
			uppercase: 'ĆČĐŠŽ',
		},
	},
	insertAtCursor: (myField, myValue) => {
		myField = Array.isArray(myField) ? myField[0] : myField
		if (!myField) return ''

		//IE support
		if (document.selection) {
			myField.focus()
			sel = document.selection.createRange()
			sel.text = myValue
		}
		//MOZILLA and others
		else if (myField.selectionStart || myField.selectionStart == '0') {
			let startPos = myField.selectionStart
			let endPos = myField.selectionEnd
			myField.value =
				myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length)

			myField.focus()
			myField.setSelectionRange(endPos + 1, endPos + 1)
		} else {
			myField.value += myValue
		}
		return myField.value
	},

	fixInput: {
		onBlur: (evt) => evt.target.addAttribute('readonly', true),
		onFocus: (evt) => evt.target.removeAttribute('readonly'),
	},

	hashCode: (str) => {
		var hash = 0,
			i,
			chr
		if (str.length === 0) return hash
		for (i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i)
			hash = (hash << 5) - hash + chr
			hash |= 0 // Convert to 32bit integer
		}
		return hash
	},
	observeDom: (node, callback) => {
		if (!node || !callback) return
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver

		if (node.nodeType !== 1) return

		if (MutationObserver) {
			const mutationObserver = new MutationObserver(callback)
			mutationObserver.observe(node, { childList: true, subtree: true })
			return mutationObserver
		} else if (window.addEventListener) {
			node.addEventListener('DOMNodeInserted', callback, false)
			node.addEventListener('DOMNodeRemoved', callback, false)
		}
	},
}

export default class {
	static install(app) {
		app.config.globalProperties.$helpers = helpers
	}
}

export const stripHtml = (str) => {
	if (!str) {
		return ''
	}
	return str.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ')
}

export const isStringEmpty = (str) => {
	if (!str || !str.length) return true
	return !stripHtml(str).replace(/\s/g, '')
}

export const setFontSizeForChildrenRecursive = (element, size) => {
	if (!element || !element.children) return
	element.children.forEach((child) => {
		child.style.fontSize = size
		setFontSizeForChildrenRecursive(child, size)
	})
}

export const getRandomInt = (max = 10) => {
	return Math.floor(Math.random() * max)
}

export const checkString = (str) => {
	const regexEmptyTag = /<[^>]+>\s*<\/[^>]+>/
	if (regexEmptyTag.test(str)) return false
	return true
}

export const clearLocalStorageButLessonTimers = () => {
	const timers = localStorage.getItem('lessonTimers')
	localStorage.clear()
	if (timers) {
		localStorage.setItem('lessonTimers', timers)
	}
}

export function convertBase64ToBlob(img) {
	const byteString = atob(img.split(',')[1])
	const mimeString = img.split(',')[0].split(':')[1].split(';')[0]
	const ab = new ArrayBuffer(byteString.length)
	const ia = new Uint8Array(ab)
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}
	const blob = new Blob([ab], { type: mimeString })
	return blob
}

export const preparedLessonForAssessment = (question) => {
	return Object.values(
		question?.answers?.reduce((acc, curr) => {
			const currNum = curr.number
			if (currNum || currNum === 0) {
				const findedElem = acc[currNum]
				if (findedElem) {
					if (findedElem.text && !findedElem.translate) findedElem.translate = curr.text
					if (findedElem.translate && !findedElem.text) findedElem.text = curr.translate
					if (findedElem?.illustration) findedElem.type = 0
					if (findedElem?.lexical_audio) findedElem.audio = findedElem.lexical_audio
					if (curr?.word_type === 'term') findedElem.id = curr.id
				} else {
					acc[currNum] = curr?.word_type === 'value' ? { ...curr, translateId: curr.id } : curr
				}
			}
			return acc
		}, {})
	)
}
