export default {
	title: 'Messages',
	search: 'Search',
	'share-success': 'Messages have been sent successfully!',
	input: { placeholder: 'Enter a message...' },
	errors: {
		'file-size': {
			message: 'Some files exceed the maximum allowed size (20Mb)',
			'button-delete-unvalidated': 'Delete files exceeding the allowed size and continue',
		},
	},
	header: {
		'share-btn': 'Make a newsletter',
		'groups-dropdown': 'All groups',
		'messages-dropdown': 'All messages',
		'messages-read': 'Unread',
		'messages-unanswered': 'Unanswered',
		modal: { title: 'Mailing', 'attach-files': 'Attach a file' },
	},
	messages: {
		'rooms-empty': 'Add students to the group',
		'room-empty': 'Select a chat to start chatting',
		'new-messages': 'New message',
	},
	actions: {
		edit: { name: 'editMessage', title: 'Edit' },
		delete: { name: 'deleteMessage', title: 'Remove' },
	},
}
