import { Vue } from "vue-property-decorator";
import {
  getFolderData,
  getGroupSections,
  getLessonsWithoutSections,
  getSectionLessons,
} from "../../api/folders.js";
import { getLessonsDataFilter } from "../../api/lessons.js";

const getAllFoldersRecurse = (_folders = [], allTypes = false) => {
  const getFolders = (data = []) => {
    return data.filter((el) => el.data_type !== "lesson" || allTypes);
  };
  let folders = getFolders(_folders);
  folders = [
    ...folders.map((el) => {
      el._checked = true;
      return el;
    }),
    ...folders.map((el) => getFolders(el.childs || [])).flat(),
  ];

  if (folders.some((el) => !el._checked)) {
    return getAllFoldersRecurse(folders, allTypes);
  }

  return folders.map((el) => {
    el._checked = false;
    return el;
  });
};

const getLessonType = (lesson) => {
  console.log("lesson type", new Date().getTime(), lesson.expires_at.date);
  if (lesson.test_result > 0 || lesson.pass_once_current > 0) return "done";
  if (new Date().getTime() > new Date(lesson.expires_at.date).getTime())
    return "expired";
  return "actual";
};

export default {
  state: {
    groups: [],
    currentFolder: null,
    folderPath: [],
    sections: [],
  },
  getters: {
    getAllFolders(state) {
      return getAllFoldersRecurse(state.groups);
    },
  },
  actions: {
    OPEN_FOLDER: (
      { commit, state },
      { folderId, isGroup = false, isTrueMobile, level }
    ) => {
      const folders = getAllFoldersRecurse(state.groups);
      const currentFolder = folders.find((el) =>
        isGroup ? el.id === folderId : el.folder_id === folderId
      );

      if (currentFolder && !currentFolder.isOpened) {
        commit("SET_OPEN_STATUS", { folder: currentFolder, status: true });
        // if (isTrueMobile && level > 1) {
        //     commit('SET_CURRENT_FOLDER', currentFolder)
        // }
      } else {
        commit("SET_OPEN_STATUS", { folder: currentFolder, status: false });
        commit("SET_FOLDER_CHILDS", {
          folder: currentFolder,
          reset: true,
        });
        commit("SET_CURRENT_FOLDER", null);
      }
    },
    LOAD_FOLDER_DATA: async (
      { commit, state },
      { folderId, isGroup = false, nextPage = false, structure, reset = false }
    ) => {
      const folders = getAllFoldersRecurse(state.groups);
      const currentFolder = folders.find((el) =>
        isGroup ? el.id === folderId : el.folder_id === folderId
      );

      if (currentFolder.data_type === "group") {
        let currentPage = currentFolder.meta
          ? currentFolder.meta.current_page
          : 1;
        if (nextPage) {
          currentPage += 1;
        }

        let data = null;

        // if (Number(currentFolder.view_type) === 1) {
        //   data = {
        //     data: [],
        //   };
        //   state.sections[currentFolder.id].forEach((section) => {
        //     section.lessons.forEach((lesson) => {
        //       lesson.expires_at = new Date(lesson.expires_at);
        //     });
        //     data.data.push(...section.lessons);
        //   });
        // } else {
        if (
          Number(currentFolder.view_type) !== 1 ||
          currentFolder.marathon_status !== "off"
        ) {
          data = (
            await getLessonsDataFilter({
              page: currentPage,
              filter: { group_id: folderId, structure },
            })
          ).data;
        }
        // }

        if (data) {
          commit("SET_FOLDER_META", {
            folder: currentFolder,
            meta: data.meta,
          });
          commit("SET_FOLDER_CHILDS", {
            folder: currentFolder,
            childs: data.data,
            type: "lesson",
          });
        }
      } else {
        let currentPage =
          currentFolder.meta && !reset ? currentFolder.meta.current_page : 1;
        if (nextPage) {
          currentPage += 1;
        }

        const { data } = await getFolderData(folderId, { page: currentPage });

        if (reset) {
          commit("SET_FOLDER_CHILDS", {
            folder: currentFolder,
            reset: true,
          });
        }

        commit("SET_FOLDER_CHILDS", {
          folder: currentFolder,
          childs: data.data.filter((el) => el.folderable_type === "folder"),
          type: "folder",
          folderable: true,
        });

        commit("SET_FOLDER_CHILDS", {
          folder: currentFolder,
          childs: data.data.filter((el) => el.folderable_type === "lesson"),
          type: "lesson",
          folderable: true,
        });

        console.log("data", data);
        commit("SET_FOLDER_META", {
          folder: currentFolder,
          meta: data.meta,
          section,
        });
      }
    },
    GET_SECTIONS: async ({ commit, state }, { groupId }) => {
      let sections = state.sections.find((section) => section[0] === groupId);

      if (sections && !!sections.length) {
        sections = sections[1];
      } else {
        sections = (await getGroupSections(groupId)).data.data;
      }

      for (const section of sections) {
        section.lessons = [];
        section.isOpen = false;
      }

      commit("SET_GROUP_SECTIONS", { sections, groupId });
    },
    GET_SECTION_LESSONS: async (
      { commit, state },
      { groupId, sectionId, isRootSection }
    ) => {
      let lessons = [];
      if (isRootSection) {
        lessons = (await getLessonsWithoutSections(groupId)).data.data;
      } else {
        lessons = (await getSectionLessons(groupId, sectionId)).data.data;
      }

      lessons = lessons.filter((lesson) => !!lesson.is_active);

      lessons.forEach((lesson) => {
        lesson.group_id = groupId;
        lesson.data_type = "lesson";
        lesson.pass_once_current = lesson.pass_once;
      });

      commit("SET_SECTION_LESSONS", { groupId, sectionId, lessons });
    },
  },
  mutations: {
    SET_CURRENT_FOLDER: (state, folder) => {
      console.log(
        "SET",
        !state.folderPath.some((el) => el.id === folder.id),
        state.folderPath
      );
      if (!state.folderPath.some((el) => el.id === folder.id)) {
        state.currentFolder = folder;
        state.folderPath = [...state.folderPath, currentFolder];
      } else if (state.folderPath.some((el) => el.id === folder.id)) {
        state.currentFolder = folder;
        state.currentFolder.meta.current_page = 1;
        const index = state.folderPath.findIndex((el) => el.id === folder.id);
        state.folderPath = state.folderPath.filter((el, i) => i <= index);
      } else if (
        this.groups
          .map((el) => el.folder_id || el.id)
          .some((id) => id === folder.id)
      ) {
        state.currentFolder = null;
        state.folderPath = [];
      }
    },
    SET_FOLDER_META: (state, { folder, meta }) => {
      console.log("set meta", meta);
      Vue.set(folder, "meta", meta);
    },
    TOGGLE_LESSON: (state, { lesson, withSections = false }) => {
      let allData = null;
      let currentLesson = null;

      if (!withSections) {
        allData = getAllFoldersRecurse(state.groups, true);
        currentLesson = allData.find(
          (el) => el.data_type === "lesson" && el.id === lesson.id
        );
      } else {
        allData = [];
        state.sections.forEach((sectionsArr) => {
          sectionsArr[1].forEach((section) => {
            allData.push(...section.lessons);
          });
        });
        currentLesson = allData.find((el) => el.id === lesson.id);
      }

      if (currentLesson) {
        allData
          .filter((el) => el.data_type === "lesson" && el.id !== lesson.id)
          .forEach((el) => {
            Vue.set(el, "isOpened", false);
          });
        Vue.set(currentLesson, "isOpened", !currentLesson.isOpened);
      }
    },
    SET_GROUPS: (state, groups) => {
      state.currentFolder = null;
      state.folderPath = [];
      state.groups = groups.map((el) => {
        const marathonEnabled =
          el.marathon_status && el.marathon_status !== "off";

        return {
          ...el,
          folder_id: !marathonEnabled ? el.folder_id : null,
          isOpened: false,
          childs: [],
          data_type: el.folder_id && !marathonEnabled ? "folder" : "group",
          structure: marathonEnabled ? "tree" : null,
        };
      });
    },
    SET_OPEN_STATUS: (state, { folder, status }) => {
      Vue.set(folder, "isOpened", status);
    },
    SET_FOLDER_CHILDS: (state, { folder, childs, type, reset, folderable }) => {
      console.log("set childs");

      if (reset) {
        Vue.set(folder, "childs", []);
        // folder.childs = []
        if (folder.meta) {
          folder.meta.current_page = 1;
        }

        if (state.currentFolder) {
          Vue.set(state.currentFolder, "childs", []);
        }
        return;
      }

      if (window.isTrueMobile) {
        if (!state.folderPath.some((el) => el.id === folder.id)) {
          state.currentFolder = folder;
          state.folderPath = [...state.folderPath, folder];
        } else if (state.folderPath.some((el) => el.id === folder.id)) {
          state.currentFolder = folder;
          const index = state.folderPath.findIndex((el) => el.id === folder.id);
          state.folderPath = state.folderPath.filter((el, i) => i <= index);
        } else if (
          this.groups
            .map((el) => el.folder_id || el.id)
            .some((id) => id === folder.id)
        ) {
          state.currentFolder = null;
          state.folderPath = [];
        }
      }

      if (type === "lesson") {
        childs = childs.map((el) => {
          Object.assign(el, {
            progress_type: getLessonType({
              ...el,
              expires_at: { date: el.expires_at },
            }),
            expires_at: { date: el.expires_at },
          });

          return el;
        });
      }

      if (folderable) {
        childs = childs.map((el) => {
          let newData = {};

          Object.assign(newData, el.folderable);
          delete el.folderable;
          Object.assign(newData, el);

          Vue.set(newData, "parent_id", el.folder_id);
          Vue.set(newData, "folder_id", el.folderable_id);

          if (type === "lesson") {
            Vue.set(newData, "id", el.folderable_id);
          }

          return newData;
        });
      }

      const _childs = [
        ...(folder.childs || []),
        ...childs.map((el) => ({ ...el, data_type: type })),
      ];

      Vue.set(folder, "childs", _childs);

      if (state.currentFolder) {
        Vue.set(folder, "childs", _childs);
      }
    },
    SET_GROUP_SECTIONS: (state, { sections, groupId }) => {
      state.sections.push([groupId, sections]);
    },
    SET_SECTION_LESSONS: (state, { groupId, sectionId, lessons }) => {
      const currentSection = state.sections
        .find((section) => section[0] === groupId)[1]
        .find((section) => section.id === sectionId);

      currentSection.lessons = lessons;
    },
  },
};
