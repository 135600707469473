<template>
  <div>
    <div  class="my-2 flex items-center justify-center text-gray-500">
      <img class="mr-2 h-5" src="@/assets/iii-xxxhdpi.png" alt="">
      <div>{{ `Проведи по полю для написания иероглифа` }}</div>
    </div>
    <div class="w-full flex flex-wrap justify-center">
      <div v-for="(symbol, symbolIndex) in text.split('')" :key="symbolIndex" :id="`character-target-div-${uid}-${symbolIndex}`" class="transparent mr-1 border-2 border-gray-300 rounded-lg"></div>
    </div>
  </div>
</template>

<script>
import HanziWriter from 'hanzi-writer';
export default {
  props: {
    text: String,
    uid: {
      type: [Number, String],
      default: 0,
    }
  },
  data() {
    return {
      chineseWriters: [],
      done: [],
      withError: false,
    }
  },
  watch: {
    async text() {
      await this.$nextTick();
      this.setChineseAndAnimate();
    }
  },
  mounted() {
    this.setChineseAndAnimate();
  },
  methods: {
    /**
     * Очистить все svg с ввода или показа символов
     */
    resetAllChildNodes() {
      this.done = [];
      document.querySelectorAll(`[id*='character-target-div-${this.uid}'] svg`).forEach(node => {
        node.remove();
      })
    },

    /**
     * Запустить режим рисования иероглифа для всех отрисованых символов
     */
    runChineseQuiz() {
      this.chineseWriters.forEach((e, i) => {
	  	if (typeof e.quiz === 'function') {
			e.quiz({
				onMistake: (strokeData) => {
					// if (strokeData.mistakesOnStroke >= 3) {
					//   this.withError = true;
					//   this.inputData = `{${this.word.text}}`;
					// }
				},
				onCorrectStroke: (strokeData) => {
				},
				onComplete: (summaryData) => {
					this.done.push(e);
					if (this.done.length === this.chineseWriters.length && !this.withError) {
						this.$emit('done');
						// this.handleInput(true);
						// TODO: Finish
					}
				}
			});
		}
      })
    },

    /**
     * Отрисовать символы
     */
    async setChineseAndAnimate() {
      // Сброс старых
      this.resetAllChildNodes();
      await this.$nextTick();

      // Ширина символа от ширины контейнера, по умоланию
      let width = 300 / this.text.split('').length;

      if (width > 100) width = 100; // Ширина не больше 100
      else if (width < 30) width = 50; // И не меньше 50

      // Если это тест - то 1/3 ~= 90
      if (this.input) {
        width = 140;
      }

      this.chineseWriters = this.text.split('').map((e, i) => {
		  // Проверяем, является ли символ числом
		  if (!isNaN(e)) {
			  return Number(e); // Возвращаем число, если это число
		  } else {
			  // Иначе продолжаем создавать HanziWriter
			  return HanziWriter.create(`character-target-div-${this.uid}-${i}`, e, {
				  width: width,
				  height: width,
				  padding: 5,
				  // strokeAnimationSpeed: 3,
				  delayBetweenStrokes: 5,
				  showCharacter: false,
				  showOutline: false,
			  });
		  }
      })

      this.runChineseQuiz();
    }
  }
}
</script>

<style>

</style>
