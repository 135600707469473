<template>
	<div class="v-snake pt-10">
		<!-- <div class="w-9/12 mx-auto">
      <div class="v-word-main" v-if="true">{{ currentWord.text }}</div>
      <div class="flex w-full justify-between flex-wrap w-full">
        <div class="v-word-item flex items-center justify-between flex-col" v-for="item in getSetOfWords" :key="item.id" :class="{ 'h-32': currentLesson.without_translation }">
          <div v-if="currentLesson.without_translation" class="py-2">
            <img class="rounded mx-auto object-contain" v-if="item.type === 0" :src="item.illustration" alt="" :class="{ 'h-20': !currentLesson.without_translation, 'h-24': currentLesson.without_translation }">
            <video class="rounded mx-auto object-contain" v-else-if="item.type === 1" :src="item.illustration" autoplay loop muted :class="{ 'h-20': !currentLesson.without_translation, 'h-24': currentLesson.without_translation }"></video>
          </div>
          <span v-if="!currentLesson.without_translation">{{ item.translate }}</span>
        </div>
      </div>
    </div> -->

		<div
			class="hidden lg:flex mb-5 justify-between w-full lg:w-9/12 px-5 lg:px-0 mx-auto v-text--gray flex-wrap lg:flex-nowrap"
		>
			<div class="flex items-center w-1/2 lg:w-auto mb-2 lg:mb-0 justify-center lg:justify-start">
				<img class="mr-3" src="/images/snake_top.png" alt="" />
				<div>Up</div>
			</div>
			<div class="flex items-center w-1/2 lg:w-auto mb-2 lg:mb-0 justify-center lg:justify-start">
				<img class="mr-3" src="/images/snake_down.png" alt="" />
				<div>Down</div>
			</div>
			<div class="flex items-center w-1/2 lg:w-auto mb-2 lg:mb-0 justify-center lg:justify-start">
				<img class="mr-3" src="/images/snake_left.png" alt="" />
				<div>Left</div>
			</div>
			<div class="flex items-center w-1/2 lg:w-auto mb-2 lg:mb-0 justify-center lg:justify-start">
				<img class="mr-3" src="/images/snake_right.png" alt="" />
				<div>Right</div>
			</div>
		</div>

		<div class="flex w-full justify-center items-center flex-col lg:flex-row mobb">
			<div class="w-full px-1 lg:px-5 lg:px-0 mr-0 lg:mr-10 flex flex-col justify-center">
				<div
					class="v-word-main mb-1 lg:mb-3 mr-5 lg:mr-0 leading-none text-lg v-text--bold pr-10"
					v-if="true"
					v-html="`${currentWord.text} ${currentWord.pinyin ? ' (' + currentWord.pinyin + ')' : ''}`"
				/>
				<!-- <marquee-text>asdasda asd asdas dasdnaskjdhkjashd kjahsdkj hkdsadhkashdakshdkash aksdhakjsdhakjshdkashdkjashdkh kasdhakshdkashdk</marquee-text> -->
				<div class="flex w-full justify-between flex-wrap lg:flex-nowrap lg:flex-col w-full">
					<!-- <div class="v-word-item flex items-center justify-between flex-col mb-1 lg:mb-3" v-for="item in getSetOfWords" :key="item.id" :class="{ 'h-32': currentLesson.without_translation }">
            <div v-if="isTrueMobile ? (!item.translate || currentLesson.without_translation) : true" class="py-1">
              <img class="rounded mx-auto object-contain" v-if="item.type === 0" :src="item.illustration" alt="" :class="{ 'h-20': !currentLesson.without_translation, 'h-24': currentLesson.without_translation }">
              <video class="rounded mx-auto object-contain" v-else-if="item.type === 1" :src="item.illustration" autoplay loop muted :class="{ 'h-20': !currentLesson.without_translation, 'h-24': currentLesson.without_translation }"></video>
            </div>
            <span v-if="!isTrueMobile || (item.translate + item.pinyin).length < 50">{{ item.translate }} {{ item.pinyin }}</span>
            <div v-else class="w-full"><marquee-text :key="item.id"><span v-if="!currentLesson.without_translation">{{ item.translate }}</span> {{ item.pinyin }}</marquee-text></div>
          </div> -->
					<div
						class="word-card relative w-full flex justify-center items-center v-text--bold text-md mx-auto leading-none p-1 mb-2"
						v-for="(item, index) in getSetOfWords"
						:key="item.id"
					>
						<div class="flex-grow pr-10 flex items-center justify-center">
							<div
								v-if="
									item.illustration &&
									(isTrueMobile
										? isStringEmpty(item.translate) || currentLesson.without_translation
										: true)
								"
								class="word-card__image mr-3 rounded-md flex items-center justify-center"
							>
								<img class="rounded" v-if="item.type === 0" :src="item.illustration" />
								<video
									disablePictureInPicture
									class="rounded"
									v-else-if="item.type === 1"
									:src="item.illustration"
									autoplay
									loop
									muted
								></video>
							</div>
							<div
								class="p-3 flex-grow flex flex-col items-center justify-center text-center v-text--bold"
								v-if="!isTrueMobile && item.translate.length > 0"
								v-html="item.translate"
							/>
							<template v-else-if="item.translate.length > 0">
								<span
									class="flex-col"
									v-if="!isTrueMobile || item.translate.length < 50"
									v-html="item.translate"
								/>
								<!--                TODO: Find a way to trim-->
								<!--                <marquee-text v-else :key="item.id">-->
								<span
									v-else-if="!currentLesson.without_translation"
									class="flex-col"
									v-html="item.translate"
								/>
								<!--                </marquee-text>-->
							</template>
						</div>
						<div class="food-icon">
							<IconDisplay :iconPath="`/images/snake/${index + 1}.png`" />
						</div>
						<!-- <span v-if="!isTrueMobile || (item.translate + item.pinyin).length < 50">{{ item.translate }} {{ item.pinyin }}</span>
            <div v-else class="w-full"><marquee-text :key="item.id"><span v-if="!currentLesson.without_translation">{{ item.translate }}</span> {{ item.pinyin }}</marquee-text></div> -->
					</div>
				</div>
			</div>

			<div class="v-snake__container">
				<div
					v-if="game_on"
					class="game"
					v-touch:swipe.left="() => changeDirection({ keyCode: 37 })"
					v-touch:swipe.top="() => changeDirection({ keyCode: 38 })"
					v-touch:swipe.right="() => changeDirection({ keyCode: 39 })"
					v-touch:swipe.bottom="() => changeDirection({ keyCode: 40 })"
				>
					<div class="v-snake__loader" v-if="!gameStarted">
						<div class="v-clickable vzn-button w-3/4 mb-10" @click="startGame(3)">Start easy</div>
						<div class="v-clickable vzn-button w-3/4" @click="startGame(6)">Start normal</div>
					</div>
					<span v-for="(x, xIndex) in list" :key="'x' + xIndex" :data-id="x" class="px">
						<span
							v-for="(y, yIndex) in list"
							:key="'y' + yIndex"
							:data-id="y"
							class="px col"
							:class="[
								{
									snake: isSnake(x, y),
									food: isFood(x, y),
									head: isHead(x, y),
								},
								`food-${isFood(x, y) && isFood(x, y).type}`,
							]"
						>
							<div v-if="isHead(x, y)" class="eye-out">
								<div class="eye-in"></div>
							</div>
							<div v-if="isHead(x, y)" class="eye-out" style="top: 50%">
								<div class="eye-in"></div>
							</div>
						</span>
					</span>
				</div>
				<!-- <p>Score: <b>{{ score }}</b></p> -->
			</div>
		</div>
	</div>
</template>

<script setup>
import IconDisplay from '@/components/IconDisplay.vue'
</script>

<script>
import WordCard from '@/components/WordCard.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import useEventBus from '../../../composables/useEventBus.js'
import shuffle from 'lodash/shuffle'
import sampleSize from 'lodash/sampleSize'
import MarqueeText from 'vue-marquee-text-component'
import { defineComponent } from '@vue/runtime-dom'
import { isStringEmpty } from '../../../helpers'

export default defineComponent({
	components: { MarqueeText },
	props: ['showNavigator'],
	data: () => {
		const emitter = useEventBus()

		const audio_wrong = new Audio('/images/snake/wrong.mp3')
		audio_wrong.volume = 0.25
		const audio_move = new Audio('/images/snake/move.mp3')
		audio_move.volume = 0.25

		const audio_result = new Audio('/images/snake/result.mp3')
		audio_result.volume = 0.25
		const audio_over = new Audio('/images/snake/over.mp3')
		audio_over.volume = 0.25

		return {
			total: 16,
			snake: [],
			snakeLenth: 3,
			snakeDirection: 'right',
			food: {},
			game_on: true,

			speed: 5,
			emitter,
			foods: [],
			gameStarted: false,
			interval: null,

			GAME_SLUG: 'snake',
			audio_wrong,
			audio_move,
			audio_result,
			audio_over,
		}
	},

	created() {
		this.init()
	},

	watch: {
		gameStarted(action) {
			if (window.isTrueMobile) {
				this.$emit('toggleNavigator', !action)
			}
		},
		showNavigator(value, oldValue) {
			if (!oldValue && value) {
				this.gameStarted = false
				clearInterval(this.interval)
			}
		},
	},

	unmounted() {
		window.removeEventListener('keyup', this.changeDirection)
		window.removeEventListener('keydown', this.clearArrows)
		clearInterval(this.interval)
		this.gameStarted = false
	},

	computed: {
		...mapState({
			currentLesson: (state) => state.lessons.currentLesson,
			currentWords: (state) => state.lessons.currentWords,
			currentLessonProgressData(state) {
				return state.lessons.currentLessonProgressData[this.GAME_SLUG]
			},
		}),

		isTrueMobile() {
			return window.isTrueMobile
		},

		list() {
			var x = []

			for (var i = 0; i <= this.total; i++) {
				x.push(i)
			}

			return x
		},
		score() {
			return this.snakeLenth - 1
		},

		// Слова которые юзер еще не прошел
		getShuffledAndFiltered() {
			if (!this.currentWords || !this.currentLessonProgressData) return []

			return shuffle(this.currentWords).filter((word) => !this.currentLessonProgressData.words.includes(word.id))
		},

		// Текущее слово для отгадывания
		currentWord() {
			if (!this.currentWords) return null
			return this.getShuffledAndFiltered[0] || this.currentWords[0]
		},

		// Набор из 4х слов
		getSetOfWords() {
			return shuffle([
				this.currentWord,
				...sampleSize(
					this.currentWords.filter((word) => word.id !== this.currentWord.id),
					2
				),
			])
		},
	},

	methods: {
		...mapMutations(['SAVE_TIP', 'SAVE_WORD', 'RESTART_TASK']),
		isStringEmpty,

		handleTop(evt) {
			console.log('EVT SWIPE', evt)
		},

		clearArrows(e) {
			// space and arrow keys
			if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
				e.preventDefault()
			}
		},

		generateFoods() {
			this.foods = []
			;[1, 2, 3].forEach((type) => {
				this.foods.push({
					type,
					...this.getRand(),
				})
			})
		},

		isHead(x, y) {
			if (this.snake[this.snake.length - 1].x == x && this.snake[this.snake.length - 1].y == y) {
				return true
			}
		},

		init() {
			this.newGame()
			window.addEventListener('keyup', this.changeDirection)
			window.addEventListener('keydown', this.clearArrows, false)
		},

		startGame(speed = this.speed) {
			this.newGame()
			// this.interval = setInterval(this.move, speed);
			this.gameStarted = true
			this.startFrame(speed)
		},

		startFrame(speed = this.speed) {
			let fps = speed
			let now
			let then = Date.now()
			let interval = 1000 / fps
			let delta

			const tick = () => {
				if (!this.gameStarted) return

				window.requestAnimationFrame(tick)

				now = Date.now()
				delta = now - then

				if (delta > interval) {
					then = now - (delta % interval)
					this.move()
				}
			}
			tick()
		},

		newGame() {
			var self = this

			this.snakeLenth = 3
			self.snake = []

			self.snake.push(self.getRand())

			this.generateFoods()
			// self.food = self.getRand();
		},

		move() {
			var self = this

			var last = self.snake[self.snake.length - 1]

			var x = last.x
			var y = last.y

			const eatenFood = this.foods.find((el) => el.x === x && el.y === y)
			if (eatenFood) {
				this.eat(eatenFood)
			}
			// if(x == this.food.x && y == this.food.y){
			// 	this.eat();
			// }

			switch (self.snakeDirection) {
				case 'up':
					y -= 1
					break

				case 'right':
					x += 1

					break

				case 'down':
					y += 1

					break

				case 'left':
					x -= 1
					break
			}

			if (y > self.total) {
				y = 0
			}

			if (x > self.total) {
				x = 0
			}

			if (y < 0) {
				y = self.total
			}

			if (x < 0) {
				x = self.total
			}

			// self bite
			for (let i in this.snake) {
				if (this.snake[i] != undefined && this.snake[i].x == x && this.snake[i].y == y) {
					this.gameOver()
					// this.newGame();
				}
			}

			self.snake.push({ x: x, y: y })

			if (self.snake.length > self.snakeLenth) {
				self.snake.shift()
			}
		},

		gameOver(win = false) {
			clearInterval(this.interval)
			this.gameStarted = false
			if (!win) {
				this.audio_over.play()
				this.$root.$emit('v-modal', {
					text: 'Game over',
					buttons: [
						{
							text: this.$t('games.ok'),
							class: 'v-button v-button--gradient-red',
							callback: () => {
								this.newGame()
								this.$store.commit('RESTART_TASK', {
									gameName: this.GAME_SLUG,
								})
								this.$root.$emit('v-modal', { close: true })
							},
						},
					],
					size: 'sm',
				})
			} else {
				this.audio_result.play()
			}
		},

		isSnake(x, y) {
			for (let i in this.snake) {
				if (this.snake[i].x == x && this.snake[i].y == y) {
					return true
				}
			}
		},

		isFood(x, y) {
			// if(this.food.x == x && this.food.y == y){
			// 	return true;
			// }

			return this.foods.find((el) => el.x === x && el.y === y)
		},

		async eat(food) {
			this.snakeLenth += 1
			// this.food = this.getRand();

			if (this.getSetOfWords[food.type - 1].id === this.currentWord.id) {
				this.SAVE_WORD({
					gameName: this.GAME_SLUG,
					wordId: this.currentWord.id,
				})

				if (this.getShuffledAndFiltered.length === 0) {
					clearInterval(this.interval)

					this.gameOver(true)
					this.emitter.emit('v-task-result', {
						gameName: this.GAME_SLUG,
					})
				}
			} else {
				this.audio_wrong.play()
			}

			this.foods = this.foods.filter((el) => el.type !== food.type)

			this.foods.push({
				type: food.type,
				...this.getRand(),
			})
		},

		changeDirection(e) {
			console.log('HERE SWIPER', e, this)

			e.preventDefault && e.preventDefault()
			e.stopPropagation && e.stopPropagation()

			const directions = {
				37: 'left',
				38: 'up',
				39: 'right',
				40: 'down',
			}

			this.audio_move.play()

			if (directions[e.keyCode] !== undefined) {
				if (
					(this.snakeDirection == 'right' && directions[e.keyCode] == 'left') ||
					(this.snakeDirection == 'left' && directions[e.keyCode] == 'right') ||
					(this.snakeDirection == 'down' && directions[e.keyCode] == 'up') ||
					(this.snakeDirection == 'up' && directions[e.keyCode] == 'down')
				) {
					return false
				}

				this.snakeDirection = directions[e.keyCode]
			}

			return false
		},

		getRand() {
			const generated = {
				x: Math.floor(Math.random() * this.total),
				y: Math.floor(Math.random() * this.total),
			}

			if (
				this.foods.some((el) => el.x == generated.x && el.y == generated.y) ||
				this.snake.some((el) => el.x == generated.x && el.y == generated.y)
			) {
				return this.getRand()
			}
			return generated
		},
	},
})
</script>

<style lang="scss" scoped>
.v-snake {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	&__loader {
		z-index: 2;
		position: absolute;
		width: 100%;
		height: 100%;
		// background-color: red;
		padding: 50px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		div {
			// background-color: rgb(84, 187, 0);
			// box-shadow: rgba(84, 187, 0, 0.75) 0px 2px 10.65px;
			// padding: 10px 25px;
			// margin-bottom: 50px;
			// width: 50%;
			// text-align: center;
			// border-radius: 20px;
			// cursor: pointer;
			// color: #fff;
			font-size: 20px;
			padding: 10px 20px;
		}
	}
}
.game {
	height: 425px;
	width: 425px;
	background-image: url('/resources/assets/grass.png');
	background-size: cover;
	border-radius: 20px;
	position: relative;
	display: flex;
	flex-wrap: nowrap;

	@media (max-width: 1023px) {
		width: 95vw;
		height: 95vw;
		user-select: none;
		touch-action: none;
	}
}

.px {
	width: 25px;
	height: 25px;
	// background: #e5e5e5;
	// border-bottom: 1px solid #fff;
	// border-right: 1px solid #fff;
	display: inline-block;

	@media (max-width: 1023px) {
		width: calc(95vw / 17);
		height: calc(95vw / 17);
	}
}
.px.col {
	display: block;
}

.snake {
	background: rgb(116, 252, 0);
	box-shadow: rgba(84, 187, 0, 0.75) 0px 2px 10.65px;
	border-radius: 50%;

	transform: unset !important;
	background-image: unset !important;
}

.food {
	// background: blue;
}

.head {
	background: rgb(116, 252, 0);
	position: relative;
}

.eye-in {
	position: absolute;
	top: 20%;
	left: 40%;
	background-color: #000;
	width: 50%;
	height: 50%;
	border-radius: 50%;
}

.eye-out {
	position: absolute;
	left: 40%;
	width: 30%;
	height: 30%;
	border-radius: 50%;
	background-color: #fff;
}

.food-1 {
	background-image: url('/resources/assets/snake/1.png');
	background-size: 100% 100%;
	filter: drop-shadow(0px 0px 10px #fe9300);
	// transform: scale(4);
}

.food-2 {
	background-image: url('/resources/assets/snake/2.png');
	background-size: 100% 100%;
	filter: drop-shadow(0px 0px 10px #6d7dff);
	// transform: scale(4);
}

.food-3 {
	background-image: url('/resources/assets/snake/3.png');
	background-size: 100% 100%;
	filter: drop-shadow(0px 0px 10px #ff5353);
	// transform: scale(4);
}

.v-word-item {
	border-radius: 25px;
	padding: 3px 15px;
	width: 100%;
	text-align: center;
	font-size: 14px;

	@media (min-width: 1024px) {
		font-size: 18px;
		padding: 5px 25px;
		min-height: 90px;
		font-size: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	&:nth-child(1) {
		box-shadow: 0px 0px 10px -5px #ffff00;
		background-color: #ffff00;
	}
	&:nth-child(2) {
		box-shadow: 0px 0px 10px -5px #0013ff;
		background-color: #0013ff;
		color: #fff;
	}
	&:nth-child(3) {
		box-shadow: 0px 0px 10px -5px #ff0081;
		background-color: #ff0081;
		color: #fff;
	}
}

.v-word-main {
	background-color: #fff;
	width: 100%;
	border-radius: 5px;
	padding: 3px 15px;
	text-align: center;
	// font-size: 14px;
	min-height: 90px;
	border: 2px solid #d9dce6;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 18px;

	@media (min-width: 1024px) {
		font-size: 18px;
		padding: 5px 25px;
	}

	@media (max-width: 768px) {
		min-height: auto;
	}
}

.word-card {
	background-color: #fff;
	// border-radius: 8px;
	// min-height: 90px;
	position: relative;
	// border-top: 3px solid rgb(0, 158, 224);
	border-bottom: 3px solid #d9dce6;

	&:hover {
		z-index: 10;
	}

	&__image {
		width: 90px;
		min-width: 90px;
		height: 100%;
		object-fit: contain;
		border-radius: 8px;
		overflow: hidden;
		transition: all 0.2s ease;
		background-color: #fff;
		transform-origin: 50% 0;

		&:hover {
			width: 180px;
			filter: drop-shadow(0, 0, 3px, black);
			border: 1px solid lightgray;
		}

		img {
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	&__listen {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	@media screen and (max-width: 768px) {
		// flex-direction: column;
		min-height: auto;

		&__image {
			height: 90px;
			min-height: 90px;
			width: 100%;
			margin-right: 0 !important;

			&:hover {
				width: auto !important;
				height: 180px;
			}

			img {
				// width: 100%;
				height: 100%;
			}
		}
	}

	&:nth-child(1) {
		// box-shadow: 0px 0px 10px -5px #ffff00;
		// border-color: #ffff00;
	}
	&:nth-child(2) {
		// box-shadow: 0px 0px 10px -5px #0013ff;
		// border-color: #0013ff;
		// color: #fff;
	}
	&:nth-child(3) {
		// box-shadow: 0px 0px 10px -5px #ff0081;
		// border-color: #ff0081;

		// .food-icon {
		//   img {
		//     left: 60%;
		//   }
		// }
		// color: #fff;
	}

	.food-icon {
		position: absolute;
		top: 50%;
		right: 10px;

		transform: translate(0, -60%);

		// width: 40px;
		// min-width: 40px;
		height: 100%;
		max-height: 40px;
		border-radius: 50%;
		// background-color: #fff;
		overflow: hidden;

		img {
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			min-width: 100%;
			object-fit: contain;
		}
	}
}
</style>
