<template>
	<div class="lexical-lesson flex-grow pb-20 lg:pb-0" v-if="currentLesson">
		<LexicalLessonHeader v-if="!isNewMobileApp" :lesson="currentLesson" />
		<div class="container mx-auto p-1 md:p-3">
			<div class="my-5" v-if="currentLesson.description">
				<div class="v-text--bold text-lg">{{ $t('games.teachers_note') }}:</div>
				<div
					class="mt-2 v-text--bold v-text--gray text-md v-link break-words"
					v-html="
						currentLesson.additional_info && currentLesson.additional_info.dontLinkifyComment
							? currentLesson.description
							: $helpers.linkify(currentLesson.description)
					"
				></div>
			</div>

			<LexicalExpanded class="mt-2 md:mt-5" type="lexical" :count="currentWords.length">
				<div class="w-full flex flex-wrap">
					<div class="w-full md:w-1/2 p-1" v-for="word in currentWords" :key="word.id">
						<lexical-word-card :word="word" :showAudioButton="true" :textCenter="false" />
					</div>
				</div>

				<template #extra-header>
					<div
						class="v-print-wrapper items-center hidden lg:flex"
						@click.stop="
							router.push({
								name: 'print',
								params: {
									id: currentLesson.id,
									group_id: route.query.group_id,
								},
							})
						"
					>
						<div class="v-button v-button--blue v-print-button flex items-center cursor-poiter v-clickable">
							<img src="@/assets/print-icon.svg" alt="" class="mr-2 w-8" />
							<div class="v-text--bold text-white">{{ $t('lesson.print') }}</div>
						</div>
					</div>
				</template>
			</LexicalExpanded>
			<LexicalExpanded type="tasks" :count="getActiveGames.length" :forceOpen="getActiveGames.length > 0">
				<div class="flex flex-wrap justify-center py-3">
					<div
						v-for="(game, gameIndex) in getGames"
						:key="gameIndex"
						:class="gameIndex === 0 || gameIndex % 3 === 0 ? 'w-full' : 'w-1/2'"
						v-show="!game.isTest"
						class="lexical-wrapper"
					>
						<div class="w-full flex justify-center items-center">
							<div
								class="lexical-game cursor-pointer v-clickable flex flex-col items-center"
								:class="{ disabled: getGameCount(game) === 0 }"
								@click="toForGame(game)"
							>
								<LexicalTaskIconRounded
									:count="getGameCount(game)"
									:done="getGameDone(game)"
									:activeColor="game.activeColor"
								>
									<div class="lexical-game__icon h-16 w-16 md:h-20 md:w-20">
										<IconDisplay :iconPath="`/images/lesson/icons/new/${game.slug}.svg`" :customClass="['game-navbar__list__icog', { active: getGameDone(game) >= 1 }]"/>
									</div>
								</LexicalTaskIconRounded>
								<div class="mt-3 mb-1 md:mb-0 text-center">
									{{ getTranslatedName(game) }}
								</div>
							</div>
						</div>
						<div
							class="flex justify-around mb-4 lg:mb-0 lexical-row"
							:class="{ padding: gameIndex === 0 || gameIndex % 3 === 0 }"
						>
							<div class="lexical-slash"></div>
							<div class="lexical-slash"></div>
						</div>
					</div>
				</div>
			</LexicalExpanded>
			<div @click="toForFinal">
				<LexicalExpanded type="test" :notExpanded="true">
					<div></div>
				</LexicalExpanded>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import LexicalExpanded from '../components/lesson/LexicalExpanded.vue'
import LexicalLessonHeader from '../components/lesson/LexicalLessonHeader.vue'
import LexicalTaskIconRounded from '../components/lesson/LexicalTaskIconRounded.vue'
import LexicalWordCard from '../components/LexicalWordCard.vue'
import sortBy from 'lodash/sortBy'
import { getOnlypreview, getSlug } from '../helpers'
import { useI18n } from 'vue-i18n'
import IconDisplay from '@/components/IconDisplay.vue';
const imgUrl = import.meta.env.VITE_IMG_URL
const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

import useEventBus from '@/composables/useEventBus.js'
const emitter = useEventBus()

const slug = ref(null)

const getImgPath = (game) => {
	return getIcon(`${game.icon_base}/${getGameDone(game) > 0 ? 'filled' : 'gray'}.svg`)
}

const getIcon = (path) => {
	const cleanedPath = path.includes('/images/') ? path.replace('/images/', '') : path;
	return new URL(`../assets/${cleanedPath}`, import.meta.url).href
}

const currentLesson = computed(() => store.state.lessons.currentLesson)
const currentWords = computed(() => store.state.lessons.currentWords || [])
const currentLessonTestResults = computed(() => store.state.lessons.currentLessonTestResults)
const games = computed(() => store.state.base.games)

const isTestAvailable = computed(() => store.getters.isTestAvailable)
const isNewMobileApp = computed(() => store.getters.isNewMobileApp)

const getGames = computed(() =>
	sortBy(games.value.slice(), (o) => (1 - getGameCount(o) > 0 ? 1 : 0)).filter((el) => !el.isTest)
)
const getActiveGames = computed(() => getGames.value.filter((el) => !!getGameCount(el)))

function toForGame(game) {
	const conf = {
		name: 'games',
		params: { id: route.params.id, gname: game.name },
		query: { ...route.query, slug: slug.value, force: true },
	}
	router.push(conf)
}

function toForFinal() {
	if (!isTestAvailable.value) return
	const confRoute = {
		name: 'games',
		params: { id: route.params.id, gname: 'FinalTest' },
		query: { ...route.query, slug: slug.value },
	}
	router.push(confRoute)
}

function getTranslatedName(game) {
	return t(`lesson.${game.slug}`)
}

function getGameCount({ slug }) {
	if (!currentLesson.value || !currentLesson.value.additional_info || !currentLesson.value.additional_info.tasks)
		return null

	return parseInt(currentLesson.value.additional_info.tasks[slug], 10) || 0
}

function getGameDone({ slug }) {
	if (
		!currentLesson.value ||
		!currentLesson.value.lr_additional_info ||
		!currentLesson.value.lr_additional_info.tasks
	)
		return null

	return parseInt(currentLesson.value.lr_additional_info.tasks[slug], 10) || 0
}

onMounted(async () => {
	const lessonSlug = getSlug()
	if (lessonSlug) {
		slug.value = lessonSlug
		await store.dispatch('GET_CURRENT_LESSON_SLUG', {
			id: route.params.id,
			slug: lessonSlug,
			onlypreview: getOnlypreview(),
			group_id: route.query.group_id,
		})
	} else {
		try {
			await store.dispatch('GET_CURRENT_LESSON', {
				id: route.params.id,
				group_id: route.query.group_id,
			})
		} catch (err) {
			console.log('Loading lesson error')
		}
	}

	await nextTick()
	if (!currentLesson.value.is_active && !route.query.onlypreview) {
		router.push({ name: 'home' })
	}

	if (currentLesson.value.percent_progress === 0) {
		emitter.emit('v-modal', {
			size: 'lg',
			text: `${store.$t('games.complete_all_tasks')} <br> ${store.$t('games.the_test_will_be')}`,
		})
	}
})
</script>

<style lang="scss">
@import '../styles/variables.scss';

.game-navbar__list__icog{
	width: 64px;
	height: 64px;
	-o-object-fit: contain;
	object-fit: contain;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	margin: auto;
	display: block;
}

.game-navbar__list__icog:not(.active) .rect {
	fill: $lightestGreyIco;
}
</style>

<style lang="scss" scoped>
.v-print-button {
	padding: 2px 15px !important;
	background: linear-gradient(0deg, #4271e6, rgb(180, 200, 245));

	&:hover {
		filter: none;
	}
}

.v-print-wrapper {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(-100%, -50%);
}

.lexical-wrapper:nth-last-child(2) .lexical-row,
.lexical-wrapper:nth-last-child(1) .lexical-row {
	display: none;
}

.lexical-row.padding {
	padding: 0 12vw;

	@media screen and (max-width: 768px) {
		padding: 0 12vw; // TODO
	}
}

.lexical-wrapper:nth-child(3n + 2) {
	.lexical-slash:nth-child(1) {
		opacity: 0;
	}
}

.lexical-wrapper:nth-child(3n) {
	.lexical-slash:nth-child(2) {
		opacity: 0;
	}
}

.lexical-slash {
	width: 20px;
	height: 20px;

	background: linear-gradient(
		to top left,
		rgba(206, 209, 220, 0) 0%,
		rgba(206, 209, 220, 0) calc(50% - 0.8px),
		rgba(206, 209, 220, 1) 50%,
		rgba(206, 209, 220, 0) calc(50% + 0.8px),
		rgba(206, 209, 220, 0) 100%
	);

	&:nth-child(2) {
		background: linear-gradient(
			to top right,
			rgba(206, 209, 220, 0) 0%,
			rgba(206, 209, 220, 0) calc(50% - 0.8px),
			rgba(206, 209, 220, 1) 50%,
			rgba(206, 209, 220, 0) calc(50% + 0.8px),
			rgba(206, 209, 220, 0) 100%
		);
	}
}

.lexical-lesson {
	background-color: #fff;
}

.lexical-game {
	&.disabled {
		filter: grayscale(100%);
		opacity: 0.5;
	}

	&__icon {
		// width: 90px;
		// height: 90px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffff;

		img {
			width: 60%;
			height: 60%;
			object-fit: contain;
			// border-radius: 50%;
		}
	}
}
</style>
