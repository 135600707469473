export default {
  no_messages: "No messages",
  mistakes: "Mistakes",
  not_mistakes: "nothing mistakes",
  choose_dialog: "Сhoose dialog",
  type_message: 'Type your message',
  send: 'Send',
  messages: 'Messages',
  not_supported: "Chat is not supported",
  delete: "Delete",
  edit: "Edit",
  new: "New",
  empty: "No messages",
  message_delete: "Message deleted",
  new_message: "New message",
  CONVERSATION_STARTED: 'Conversation started: ',
  TYPE_MESSAGE: 'Enter a message',
  IS_TYPING: 'Typing...',
  ROOMS_EMPTY: 'No dialogues',
}