import {novakidStudentHosts} from "../helpers.js";

export default {
    isFlutterApp() {
        return (window.FlutterNovakidLogChannel || window.flutter_inappwebview) && novakidStudentHosts.includes(window.location.host);
    },

    async callService(serviceName, message) {
        if (window.FlutterNovakidLogChannel) {
                window[serviceName].postMessage(message);
        } else if (window.get_flutter_inappwebview) {
            const handler = await window.get_flutter_inappwebview;
            try {
                handler.callHandler(serviceName, ...[message]);
            } catch (e) {
                console.log('bridge error', e);
            }
        } else if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            try {
                window.flutter_inappwebview.callHandler(serviceName, ...[message]);
            } catch (e) {
                console.log('bridge error', e);
            }
        }
    }
}
