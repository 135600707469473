import api from '../../api/messagesNew.js'
import { formatMessage } from '@/helpers/chat'

export default {
	state: {
		messagesList: null,
		messages: null,
		unreadCount: null,
		currentStudent: null,
		rooms: null,
		currentRoom: null,
		messagesFormatted: null,
		groups: null,
		lastRoomsPage: 1,
	},
	getters: {
		messages: (state) => state.messages,
		messagesList: (state) => state.messagesList,
		messagesFormatted: (state) => state.messagesFormatted,
		rooms: (state) => state.rooms,
		unreadCount: (state) => state.unreadCount,
		currentStudent: (state) => state.currentStudent,
		currentRoom: (state) => state.currentRoom,
		groupsChat: (state) => state.groups,
		lastRoomsPage: (state) => state.lastRoomsPage,
	},
	mutations: {
		setRooms: (state, rooms) => (state.rooms = rooms),
		setMessagesList: (state, messages) => (state.messagesList = messages),
		setMessages: (state, messages) => (state.messages = messages),
		setCurrentRoom: (state, currentRoom) => (state.currentRoom = currentRoom),
		setLastRoomsPage: (state, lastPage) => (state.lastRoomsPage = lastPage),
		pushRooms: (state, room) => state.rooms.push(room),
		clearMessagesFormatted: (state) => (state.messagesFormatted = null),
		setMessagesFormatted: (state, messagesFormatted) => (state.messagesFormatted = messagesFormatted),
	},
	actions: {
		async getUnreadCount() {
			api.messagesGetUnreadCount({ all: true }).then((response) => {
				this.$state.unreadCount = response.data
			})
		},
		async getList({ commit }, data) {
			const response = await api.messagesGetList(data)
			commit('setLastRoomsPage', response.meta.last_page)
			commit('setMessagesList', response?.data)
			return response
		},
		formatRooms({ dispatch }, rooms) {
			const roomsFormatted = []

			rooms.forEach((user) => {
				const room = {
					roomId: user.id,
					roomName: user.name + ' ' + user.surname,
					//  TODO: Remove the replace
					avatar: user.avatar,
					users: [
						{
							_id: user.id,
							username: user.name,
							avatar: user.avatar,
						},
					],
					groups: user.groups,
					read: !!user.unread_messages,
					unanswered: user?.unanswered,
					unread_count: user.unread_messages,
				}

				roomsFormatted.push(room)
			})

			return roomsFormatted
		},
		addRoomsToStore({ commit, state }, rooms) {
			if (!state.rooms?.length) return

			const addedRooms = state.rooms.map((room) => room.roomId)
			rooms.forEach((room) => {
				if (!addedRooms.includes(room.roomId)) {
					commit('pushRooms', room)
					addedRooms.push(room.roomId)
				}
			})
		},
		async getListRoomFormatted({ state, dispatch, commit }, data, reset = false) {
			const rooms = await dispatch('getList', data)
			const roomsFormatted = await dispatch('formatRooms', rooms?.data)

			if (!state.rooms?.length || data.group_id || data.text || data.sortMode || data.sortBy || reset) {
				commit('setRooms', roomsFormatted)
			} else {
				dispatch('addRoomsToStore', roomsFormatted)
			}

			return roomsFormatted
		},
		async getMessages({ state, commit }, user_id, page = 1) {
			const result = await api.getMessages(user_id, page)
			commit('setMessages', result?.data)

			return result?.data
		},
		async getMessagesNew({ state, commit }, { user_id, page = 1 }) {
			const result = await api.getMessages(user_id, page)
			commit('setMessages', result?.data)

			return result?.data
		},

		async getGroups({ dispatch, state }, teacherId) {
			const result = await api.getGroupsList({
				active: 'active',
				marathon_status: 'all',
			})
			state.groups = result
			return result
		},
		async getMessagesRoomFormatted({ dispatch, state, commit }, { user_id, page = 1 }) {

			console.log('page', page)

			const messages = await dispatch('getMessagesNew', {user_id, page})
			const result = [...messages.reverse()].map((message, index) => formatMessage(message, index))
			const oldMessages = state.messagesFormatted
			commit('setMessagesFormatted', oldMessages && oldMessages?.length ? [...result, ...oldMessages] : result)
			return result
		},
		async sendMessage({ dispatch }, data) {
			const result = await api.sendMessage(data)
			return result
		},
		async readMessages({ dispatch }, userId) {
			const result = await api.readMessage(userId)
			return result
		},
		async uploadFile({ dispatch }, file) {
			let filename = ''

			if (file.audio && file.type.split('/')[0] === 'audio') filename = `audio.${file.type.split('/')[1]}`
			else filename = `${file.name}.${file.extension}`

			const blob = new Blob([file.blob], { type: 'application/octet-stream' })
			const data = new FormData()
			data.append('file', blob, filename)

			const result = (await api.uploadFiles(data)).data
			return result
		},
		async deleteMessage({ dispatch }, messageId) {
			return await api.deleteMessage(messageId)
		},
		async editMessage({ dispatch }, messageId, text) {
			return await api.editMessage(messageId, text)
		},
	},
}
