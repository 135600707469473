<template>
	<div class="v-type-in flex items-center justify-center pt-5 flex-wrap" v-if="currentSets.length > 0">
		<div v-for="set in currentSets" :key="set.word.id" class="w-full lg:w-1/2 p-2 mb-5">
			<LexicalWordCard
				:big="true"
				class="w-full mb-10"
				:word="set.word"
				:useTranslate="true"
				:showTranslate="false"
				text="text-lg text-center justify-center md:text-2xl"
			/>

			<div class="w-full flex flex-col items-center">
				<div class="mx-auto w-full mb-10" v-if="!symbolMode">
					<input
						type="text"
						autocomplete="on"
						autocapitalize="on"
						autocorrect="on"
						spellcheck="true"
						style="display: none"
					/>
					<input
						class="w-full text-2xl text-center Raleway-Medium v-input-border"
						autocomplete="off"
						spellcheck="false"
						autocapitalize="off"
						autocorrect="off"
						type="text"
						:placeholder="$t('games.type_in_the_translation')"
						:ref="`input-${set.word.id}`"
						v-model="set.userWord"
						@paste.prevent
					/>
					<SpecialCharacters
						class="mb-10 w-full"
						:lesson="currentLesson"
						@char="(char) => addChar(char, set)"
					/>
				</div>
				<div v-else>
					<ChinesTypeChar :uid="set.word.id" :text="set.word.text" @done="set.userWord = set.word.text" />
				</div>
			</div>

			<div v-if="set.error" class="v-text--red text-lg text-center my-5">
				{{ $t('games.type_in_the_translation') }}
			</div>
		</div>
	</div>
</template>

<script setup>
import {useStore} from 'vuex'
import shuffle from 'lodash/shuffle'
import LexicalWordCard from '../../../components/LexicalWordCard.vue'
import SpecialCharacters from '../../../components/lesson/SpecialCharacters.vue'
import ChinesTypeChar from '../../../components/lesson/ChinesTypeChar.vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import helpers from "../../../helpers.js";

const props = defineProps({
  words: {
    type: Array,
    default: () => [],
  },
  single: Boolean,
});

const store = useStore();

const GAME_SLUG = 'write_word';
const specialCaptalize = ref(false);
const chineseWriters = ref([]);
const done = [];
const currentSets = ref([]);

const currentLesson = computed(() => store.getters.getCurrentLesson);
const needSpecialCharacter = computed(() => !!helpers.specialSymbols[currentLesson.language])
const getSpecialCharacter = computed(() => {
  const type = specialCaptalize ? 'uppercase' : 'lowercase'
  return helpers.specialSymbols[currentLesson.language][type]
})
const symbolMode = computed(() => !currentLesson.with_pinyin && isChinese)
const isChinese = computed(() => currentLesson.language === 'CN');
const getTransProp = computed(() => currentLesson.with_pinyin ? 'pinyin' : 'text')
const instance = getCurrentInstance()
const refs = instance.proxy?.$refs

const validateTest = () => {
  currentSets.value.forEach((set) => {
    $set(set, 'error', false)
  })

  return currentSets.value.every((set) => {
    if (!!set.userWord.trim()) {
      return true
    }
    $set(set, 'error', true)
    return false
  })
}

const getTestData = () => {
  return currentSets.value.map((set) => ({
    id: set.word.id,
    test_type: GAME_SLUG,
    text: set.userWord,
  }))
}

const addChar = (char, set) => {
  const value = helpers.insertAtCursor(refs[`input-${set.word.id}`], char)
  set.userWord = value

  // this.handleInput();
}

const handleInput = (forceResult = false) => {}

onMounted(() => {
  currentSets.value = shuffle(props.words).map((word) => {
    return {
      error: false,
      selected: null,
      word,
      userWord: '',
    }
  })
})
</script>

<style lang="scss" scoped>
[id*='character-write-div'] {
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 0 10px -5px black;
}

.v-input::placeholder {
	text-align: center;
}
.v-input {
	font-size: 21px;
	text-align: center;
	padding: 5px;
	font-family: 'Raleway-Medium' !important;
}
</style>

