<template>
    <div class="folders-tree w-full mt-5">
        <div class="w-full flex flex-wrap mb-3">
            <div @click="gotoHome" class="mr-3" v-if="currentFolder"><img src="@/assets/folder_home.png" alt=""></div>
            <div @click="gotoFolder(path)" class="mr-2" v-for="(path, pathIndex) in getFolderPath" :key="path.id">
                <span style="color: #35a5dd; text-decoration: underline">{{ path.name }}</span>
                <span style="color: #35a5dd;" >{{ pathIndex !== getFolderPath.length - 1 ? '/' : '' }}</span>
            </div>
        </div>
        <div class="mb-2 v-text--bold w-full text-center " v-if="currentFolder">{{ currentFolder.name }}</div>
        <FolderTreeNode class="mb-1" v-for="group in currentGroups" :key="group.id" :group="group" :level="currentFolder ? 100 : 1"/>
        <div class="mb-2" v-for="lesson in currentLessons" :key="`lesson-${lesson.id}`">
            <FolderLesson :lesson="lesson" :group="currentFolder" />
        </div>

        <infinite-loading @infinite="onScrollBottom" v-if="currentFolder && currentFolder.childs.length > 0 && currentFolder.meta && currentFolder.meta.current_page < currentFolder.meta.last_page">
            <div slot="spinner">
            <div class="w-full flex justify-center">
                <loader />
            </div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FolderLesson from './FolderLesson.vue'
import FolderTreeNode from './FolderTreeNode.vue'

export default {
    components: { FolderTreeNode, FolderLesson },
    computed: {
        ...mapState({
            groups: state => state.folders.groups,
            currentFolder: state => state.folders.currentFolder,
            folderPath: state => state.folders.folderPath
        }),
        getFolderPath() {
            if (!this.currentFolder) return []
            return this.folderPath.filter(el => el.id !== this.currentFolder.id)
        },
        isTrueMobile() {
            return window.isTrueMobile
        },
        currentGroups() {
            if (!window.isTrueMobile) return this.groups

            return this.currentFolder
                ? (this.currentFolder.childs || []).filter(el => el.data_type === 'folder' || el.data_type === 'group')
                : this.groups.filter(el => el.data_type === 'folder' || el.data_type === 'group')
        },
        currentLessons() {
            if (!window.isTrueMobile || !this.currentFolder) return []
            return this.currentFolder.childs.filter(el => el.data_type === 'lesson')
        }
    },
    methods: {
        ...mapActions(['OPEN_FOLDER', 'LOAD_FOLDER_DATA']),
        async onScrollBottom($state) {
            if (!this.currentFolder) return

            if (!this.currentFolder.meta) {
                $state.loaded()
                return;
            }

            if (this.currentFolder.meta.current_page < this.currentFolder.meta.last_page) {
                await this.LOAD_FOLDER_DATA({
                    folderId: this.currentFolder.folder_id || this.currentFolder.id,
                    isGroup: !this.currentFolder.folder_id,
                    nextPage: true,
                    structure: this.currentFolder.structure,
                });
                $state.loaded()
            } else if (this.currentFolder.meta.current_page === this.currentFolder.meta.last_page) {
                $state.complete();
            } else {
                $state.loaded()
            }
        },
        async gotoFolder(folder) {
            this.$store.commit('SET_BUSY_STATE', true)
            await this.$store.dispatch('LOAD_FOLDER_DATA', { folderId: folder.folder_id || folder.id, isGroup: !folder.folder_id, structure: folder.structure, reset: true })
            this.$store.commit('SET_BUSY_STATE', false)
        },
        async gotoHome() {
            this.$store.commit('SET_BUSY_STATE', true)
            await this.$store.dispatch('GET_GROUPS')
            this.$store.commit('SET_BUSY_STATE', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.folders-tree {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}
</style>
