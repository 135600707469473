<template>
  <div class="winners-section py-5">
    <div class="m-container mx-auto">
      <div class="relative inline-flex mb-10">
        <img src="@/assets/marathon/winners.svg" alt="">
        <div class="absolute text-4xl whitespace-no-wrap winners-section__title v-text--green">{{ $t('marathon.winners') }}</div>
      </div>

      <div class="flex justify-around">
        <div class="w-1/3 pr-10" >
          <selector :options="partTimeOptions" :currentOption="currentPartTimeOption" :withDisabled="true"/>
        </div>
        <div class="w-1/3 px-10" >
          <selector blue :options="dynamicSeasonOptions" :currentOption="currentSeasonOption" :withDisabled="true"/>
        </div>
        <div class="w-1/3 pl-10" >
          <selector :options="yearOptions" :currentOption="currentYearOption" :withDisabled="true"/>
        </div>
      </div>

      <div class="flex mt-10" v-if="!winnersLoading">
        <div class="w-1/3 pr-10 divider">
          <div class="text-center text-lg mb-5">{{ $t('marathon.teachers') }}</div>
          <div class="flex flex-col">
            <div class="flex mb-5" v-for="(teacher, i) in teacherWinners" :key="i">
              <img class="h-40 mr-5" src="@/assets/marathon/thumbnail-teacher.svg" alt="">
              <div class="flex flex-col justify-center">
                <div class="text-sm" :class="['v-text--red', 'v-text--blue', 'v-text--green'][i]">{{i + 1}}{{$t('marathon.place')}}</div>
                <div class="v-text--bold mb-3">{{ teacher.surname }} {{ teacher.name }}</div>

                <div class="text-sm">{{ teacher.school }}</div>
                <div class="text-sm mb-3">{{ teacher.city }}</div>

                <div class="v-text--bold">{{ teacher.learn_words }} {{ $t('marathon.learned_words') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/3 pl-10">
          <div class="text-center text-lg mb-5">{{ $t('marathon.students') }}</div>
          <div class="flex flex-col flex-wrap" style="max-height: 640px">
            <div class="flex mb-3 w-1/2" v-for="(student, i) in studentWinners" :key="i">
              <img class="h-24 mr-5" src="@/assets/marathon/thumbnail-student.svg" alt="">
              <div class="flex flex-col justify-center">
                <div class="v-text--bold text-sm mb-1">{{ student.surname }} {{ student.name }}</div>

                <div class="text-xs">{{ student.school }}</div>
                <div class="text-xs mb-1">{{ student.city }}</div>

                <div class="v-text--bold text-sm">{{ student.learn_words }} {{ $t('marathon.learned_words') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center py-20">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { getWinners } from '@/api/marathon.js';
import Selector from '@/screens/marathon/Selector.vue';

import capitalize from 'lodash/capitalize';
import { mapState } from 'vuex';

export default {
  components: { Selector },
  data() {
    let partTimeOptions = [
      { key: 0, value: 'Итоги месяца ', disabled: false },
      { key: 1, value: 'Итоги сезона ', disabled: true },
      { key: 2, value: 'Итоги марафона ', disabled: false },
    ];
    let currentPartTimeOption = partTimeOptions[2];

    let yearOptions = [
      { key: 0, value: 'Итоги года' },
    ];
    let currentYearOption = yearOptions[yearOptions.length - 1];

    // Установятся динамически
    let dynamicSeasonOptions = [];
    let currentSeasonOption = {};

    return {
      winnersLoading: false,

      teacherWinners: [],
      studentWinners: [],

      partTimeOptions,
      currentPartTimeOption,
      dynamicSeasonOptions,
      currentSeasonOption,
      yearOptions,
      currentYearOption,

      winners2020: {
        teachers: [
          { name: 'Германова Лариса Валентиновна', school: 'МБУ ДО "ДДиЮ "Дом знаний"', city: 'г. Череповец', learn_words: 139103 },
          { name: 'Якимова Елена Ивановна', school: 'МОУ гимназия имени А.Л. Кекина', city: 'г. Ростов Великий', learn_words: 82895 },
          { name: 'Борисова Наталья Сергеевна', school: 'МБОУ СОШ 6', city: 'г. Переславль Залесский', learn_words: 48247 },
        ],
        students: [
          {
            "name": "Мария Пурова",
            "school": "МОУ Шатковская СШ",
            "city": "р.п Шатки",
            "learn_words": "7619"
          },
          {
            "name": "Роман Дедаев",
            "school": "МОУ Шатковская СШ",
            "city": "р.п Шатки",
            "learn_words": "4518"
          },
          {
            "name": "Евдокия Якимова",
            "school": "Гимназия имени А.Л. Кекина",
            "city": "Ростов Великий",
            "learn_words": "3755"
          },
          {
            "name": "Александра Кузьмина",
            "school": "Гимназия имени А.Л. Кекина",
            "city": "Ростов Великий",
            "learn_words": "3622"
          },
          {
            "name": "Майя Крицкая",
            "school": "Высшие курсы иностранных языков им. Рабиндраната Тагора",
            "city": "Волжск",
            "learn_words": "3219"
          },
          {
            "name": "Пётр Бакуров",
            "school": "ГБОУ Школа 1482",
            "city": "Москва",
            "learn_words": "2829"
          },
          {
            "name": "Анфиса Богачёва",
            "school": "Гимназия имени А.Л. Кекина",
            "city": "Ростов Великий",
            "learn_words": "2142"
          },
          {
            "name": "Артём Дрянигин",
            "school": "Гимназия имени А.Л. Кекина",
            "city": "Ростов Великий",
            "learn_words": "2044"
          },
          {
            "name": "Владислав Зубян",
            "school": "Гимназия имени А.Л. Кекина",
            "city": "Ростов Великий",
            "learn_words": "1912"
          },
          {
            "name": "Глеб Воронцов",
            "school": "МОУ \"Шатковская СШ\"",
            "city": "р.п.Шатки",
            "learn_words": "1791"
          }
        ]
      },
    }
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    })
  },
  created() {
    this.updateSeasonOptions(this.currentPartTimeOption.key);
    this.updateWinners();
  },
  watch: {
    '$i18n.locale'() {
      this.updateSeasonOptions(this.currentPartTimeOption.key);
    },
    'currentPartTimeOption.key'(key) {
      this.updateSeasonOptions(key);
    },
    currentSeasonOption(value) {
      this.updateWinners();
    }
  },
  methods: {
    async updateWinners() {
      this.winnersLoading = true;

      const { type, year, month } = this.currentSeasonOption;

      const clearFromUndefined = (payload = {}) => {
        for (let key in payload) {
          if (!payload[key]) delete payload[key]
        }
        return payload;
      }

      const { data: teacherWinners } = await getWinners(clearFromUndefined({ role: 'teacher', type, year, number: month }));
      const { data: studentWinners } = await getWinners(clearFromUndefined({ role: 'student', type, year, number: month }));

      this.teacherWinners = teacherWinners.data;
      this.studentWinners = studentWinners.data;
      this.winnersLoading = false;
    },
    updateSeasonOptions(key) {
      switch (key) {
        case 0: this.dynamicSeasonOptions = this.setMonthOptions(); break;
        case 1: this.dynamicSeasonOptions = this.setSeasonOptions(); break;
        case 2: this.dynamicSeasonOptions = this.setMarathonOptions(); break;
      }
      this.currentSeasonOption = this.dynamicSeasonOptions[this.dynamicSeasonOptions.length - 1] || {};
    },
    setMonthOptions() {
      const getMonthName = (numOf) => {
        const d = new Date();
        d.setDate(1);
        d.setMonth(numOf);
        const locale = this.$i18n.locale;
        return capitalize(d.toLocaleString(locale, { month: "long" }));
      }

      return [
        { month: 10, year: 2020, value: `${getMonthName(9)} 2020`, showDate: new Date('2020/11/1'), type: 'month' },
        { month: 11, year: 2020, value: `${getMonthName(10)} 2020`, showDate: new Date('2020/12/1'), type: 'month' },
        { month: 12, year: 2020, value: `${getMonthName(11)} 2020`, showDate: new Date('2021/1/1'), type: 'month' },

        { month: 1, year: 2021, value: `${getMonthName(0)} 2020`, showDate: new Date('2021/2/1'), type: 'month' },
        { month: 2, year: 2021, value: `${getMonthName(1)} 2020`, showDate: new Date('2021/3/1'), type: 'month' },
        { month: 3, year: 2021, value: `${getMonthName(2)} 2020`, showDate: new Date('2021/4/1'), type: 'month' },
        { month: 4, year: 2021, value: `${getMonthName(3)} 2020`, showDate: new Date('2021/5/1'), type: 'month' },
      ].filter(month => new Date(this.$helpers.dateformat(this.profile.current_date, 'yyyy/mm/dd HH:MM')) - month.showDate >= 0)
    },
    setSeasonOptions() {
      return [
        { key: 1, value: 'Весна' },
        { key: 2, value: 'Зима' }
      ]
    },
    setMarathonOptions() {
      return [
        { key: 0, value: `${this.$t('marathon.marathon')} 2020`, type: 'year', year: 2020 },
      ]
    },
  }
}
</script>

<style lang="scss">
.winners-section {
  &__title {
    bottom: 25px;
    right: 25px;
    transform: translateX(100%);
    background-color: #fff;
  }
}

.divider {
  border-right: 2px solid rgba(128, 128, 128, 0.418);
}
</style>
