<template>
	<transition name="fade" mode="out-in">
		<div class="v-modal" v-if="show" @click.self="show = false">
			<div class="v-modal__wrapper">
				<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false" />
				<div class="vzn-modal__label mb-10">{{ $t('home.register_modal.title') }}</div>

				<div class="flex flex-wrap md:flex-no-wrap w-full md:my-5 justify-between items-center">
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label
							class="v-modal-text v-modal-text--header text-left"
							style="text-align: left !important"
							>{{ $t('home.register_modal.who_are_you') }}</label
						>
					</div>
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<select
							class="vzn-select"
							:style="{ 'border-color': !formData.role ? 'red' : '#009ee0' }"
							v-model="formData.role"
						>
							<option value selected disabled>{{ $t('home.register_modal.choose_role') }}</option>
							<option value="teacher">{{ $t('home.register_modal.role_teacher') }}</option>
							<option value="student">{{ $t('home.register_modal.role_student') }}</option>
						</select>
					</div>
				</div>

				<div class="flex md:my-5 w-full flex-wrap md:flex-no-wrap">
					<div v-if="formData.role === 'student'" class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label js-lb-change">{{ $t('home.register_modal.name_label') }}</label>
						<input
							class="vzn-input js-pl-change jq-user-name"
							type="text"
							:placeholder="$t('home.register_modal.name_placeholder')"
							v-model="formData.name"
						/>
					</div>
					<div v-else class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label js-lb-change">{{ $t('home.register_modal.tname_label') }}</label>
						<input
							class="vzn-input js-pl-change jq-user-name"
							type="text"
							:placeholder="$t('home.register_modal.tname_placeholder')"
							v-model="formData.name"
						/>
					</div>
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label js-label-change">{{
							$t('home.register_modal.surname_label')
						}}</label>
						<input
							class="vzn-input js-placeholder-change jq-user-surname"
							type="text"
							:placeholder="$t('home.register_modal.surname_placeholder')"
							v-model="formData.surname"
						/>
					</div>
				</div>

				<div class="flex md:my-5 w-full flex-wrap md:flex-no-wrap">
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label">{{ $t('home.register_modal.city_label') }}</label>
						<input
							class="vzn-input jq-user-city"
							type="text"
							:placeholder="$t('home.register_modal.city_placeholder')"
							v-model="formData.city"
						/>
					</div>
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label">{{ $t('home.register_modal.school_label') }}</label>
						<input
							class="vzn-input jq-user-school"
							type="text"
							:placeholder="$t('home.register_modal.school_placeholder')"
							v-model="formData.school"
						/>
					</div>
				</div>

				<div class="flex flex-wrap md:mb-5">
					<div class="flex flex-col w-full mb-10 md:my-5 px-2">
						<label class="vzn-modal__label">{{ $t('home.register_modal.email_label') }}</label>
						<input
							class="vzn-input jq-user-email"
							type="email"
							placeholder="_____@____.__"
							v-model="formData.email"
						/>
					</div>
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label">{{ $t('home.register_modal.password_label') }}</label>
						<input
							class="vzn-input jq-user-password"
							type="password"
							:placeholder="$t('home.register_modal.password_placeholder')"
							v-model="formData.password"
						/>
					</div>
					<div class="flex flex-col w-full md:w-1/2 mb-10 md:mb-0 px-2">
						<label class="vzn-modal__label">{{ $t('home.register_modal.confirm_password_label') }}</label>
						<input
							class="vzn-input jq-user-password-conform"
							type="password"
							:placeholder="$t('home.register_modal.confirm_password_placeholder')"
							v-model="formData.password_confirmation"
						/>
					</div>
				</div>

				<div v-if="formData.role === 'teacher'" class="flex w-full my-5 px-2">
					<div class="flex flex-col w-full">
						<label class="vzn-modal__label">{{ $t('home.register_modal.phone_label') }}</label>
						<input
							minlength="10"
							maxlength="18"
							class="vzn-input jq-user-phone"
							type="tel"
							:placeholder="$t('home.register_modal.phone_placeholder')"
							v-model="formData.phone"
						/>
					</div>
				</div>

				<div class="flex flex-col">
					<div class="flex flex-col items-center">
						<div class="vzn-modal__group mb-3">
							<input
								class="styled-checkbox jq-user-accept"
								id="styled-checkbox"
								type="checkbox"
								v-model="formData.accepted"
							/>
							<label for="styled-checkbox">{{ $t('home.register_modal.accepted_label') }}</label>
						</div>
						<label>
							<a
								class="v-modal-text v-modal-text--soft-link"
								href="#"
								@click.prevent="$root.$emit('v-policy')"
								>{{ $t('home.register_modal.terms_of_use') }}</a
							>
						</label>
					</div>
					<div class="vzn-modal__errors jq-validation-errors"></div>
					<div class="flex flex-col my-8">
						<button type="submit" class="v-form__submit" @click="send">
							{{ $t('home.register_modal.register') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getSlug } from '../../helpers'
import { ref, reactive } from 'vue'
import useEventBus from '@/composables/useEventBus.js'

export default {
	name: 'register-modal',
	data() {
		return {
			BASE_URL: BASE_URL,
			formData: {
				role: '',
				name: '',
				surname: '',
				city: '',
				school: '',
				phone: '',
				email: '',
				password: '',
				password_confirmation: '',
				accepted: false,
			},
		}
	},
	setup() {
		const emitter = useEventBus()
		const state = reactive({ params: {} })
		const show = ref(false)
		emitter.on('register', (params = {}) => {
			state.params = params
			show.value = true
		})

		return {
			show,
			params: state.params,
		}
	},
	computed: {
		...mapState({
			tempTeacherData: (state) => state.user.tempTeacherData,
		}),
	},
	methods: {
		...mapActions(['REGISTER']),
		async send() {
			const slug = getSlug()

			try {
				await this.REGISTER({
					...this.formData,
					slug,
					id: this.$route.params.id,
					group_id: this.$route.query.group_id,
					onlyteacher: this.params.onlyteacher,
				})
				this.show = false

				if (this.tempTeacherData.id && this.params.copy) {
					emitter.emit('temp-teacher-groups')
				}
			} catch (err) {
				console.log('register error')
				console.dir(err)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.v-modal {
	// max-width: 768px;
	position: relative;
}
.v-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	form {
		width: 90%;
	}

	&__wrapper {
		// background-color: #fff;
		background: url(/images/auth-bg.jpg);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px;

		width: 100%;
		max-width: 768px;
		// height: 500px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
		opacity: 0;
		animation: showup 0.3s ease;
		animation-fill-mode: forwards;

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}
}
.v-modal__cross {
	position: absolute;
	top: -10px;
	right: 10px;
	font-size: 40px;
	cursor: pointer;
	font-weight: bold;
	transform: scaleY(0.9);
}

@media screen and (max-width: 768px) {
	.v-modal {
		padding: 20px;
		height: calc(100% + 1px);
		max-height: unset !important;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media screen and (max-width: 400px) {
	.v-form__submit {
		font-size: 16px;
		padding: 10px;
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
.vzn-input {
	font-family: FreeSetLightC !important;
	font-size: 24px;
	line-height: normal;
	background: 0 0;
	border: 1px solid #009ee0;
	border-width: 0 0 2px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 100%;
	min-height: 2.8125rem;
	padding: 0.625rem 0.9375rem;
}

.vzn-modal__label {
	font-family: FreeSetBoldC !important;
	font-size: 1.125rem;
	line-height: normal;
	color: #041227;
}

.vzn-select {
	font-family: FreeSetLightC;
	font-size: 24px;
	line-height: normal;
	background: 0 0;
	border: 1px solid #009ee0;
	border-width: 0 0 2px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 100%;
	min-height: 2.8125rem;
	padding: 0.625rem 0.9375rem;
	background: transparent url(https://vznaniya.ru/img/svg/custom-select.svg) no-repeat 95% 50%;
}

.vzn-modal__label {
	font-family: FreeSetBoldC !important;
	font-size: 1.125rem;
	line-height: normal;
	color: #041227;
}

.v-modal-text {
	&--header {
		font-family: FreeSetDemiC !important;
		text-transform: uppercase;
		font-size: 2rem;
		line-height: 1;
		text-align: center;
	}

	&--link {
		font-weight: 700;
		font-family: FreeSetC;
		cursor: pointer;
		text-decoration: underline;
	}
	&--soft-link {
		font-family: FreeSetC;
		cursor: pointer;
		text-decoration: underline;
	}
}

.v-form {
	&__input {
		position: relative;
	}

	&__input input {
		height: 55px;
		width: 100%;
		background: #e4ecf2;
		padding: 0.8125rem 1.25rem;
		font-family: FreeSetLightC;
		font-size: 24px;
	}

	&__submit {
		display: block;
		width: 100%;
		background: #d7513f;
		border-radius: 35px;
		font-family: FreeSetDemiC;
		font-size: 1.375rem;
		text-transform: uppercase;
		padding: 1.0625rem 2.1875rem;
		transition: all 0.2s linear;
		text-align: center;
		color: #fff;
		cursor: pointer;

		&:hover {
			background: #fff;
			color: #d7513f;
		}
	}

	.password-eye {
		width: 55px;
		height: 55px;
		position: absolute;
		top: 0;
		right: 0;
		background: #bccee3;
		cursor: pointer;
		transition: filter 0.25s linear;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			filter: brightness(1.05);
		}
	}
}
</style>
