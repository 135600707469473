<template>
	<div class="container mx-auto mb-20 lg:mb-0 px-2 lg:px-0">
		<UserRow statistic />

		<!-- <div class="v-switcher mb-20">
      <div class="v-switcher__item active">{{ $t('statistics.personal_statistics') }}</div>
    </div> -->

		<div class="flex justify-between items-center mb-20 flex-wrap lg:flex-nowrap">
			<div class="flex w-1/2 lg:w-1/4 items-center">
				<div class="v-reward mr-3">
					<img class="v-reward__background h-12 lg:h-24" src="/images/statLeftLogo-xxxhdpi.png" alt="" />
					<img class="v-reward__icon" :src="getStatus.icon" alt="" />
				</div>
				<div>
					<div class="v-text--bold text-xl">{{ getStatus.status }} </div>
					<div class="v-text--desc">{{ t('statistics.status') }}</div>
				</div>
			</div>

			<div class="w-1/2 lg:w-1/4">
				<Progress :percent="getNextLevelProgress" type="done" style="background: #bbdcc9" revertedSlice />
				<div class="v-text--desc mt-2">{{ t('statistics.words_left') }}: {{ getNextLevelWords }}</div>
			</div>

			<div class="w-1/2 lg:w-1/6 mt-2 lg:mt-0">
				<div class="flex items-center">
					<img class="h-8 lg:h-12 mr-2" src="/images/logo-xxxhdpi.png" alt="" />
					<div class="v-text--bold text-3xl">{{ profile.learned_word }}</div>
				</div>
				<div class="v-text--desc icon-offset w-full lg:w-3/6 leading-none">
					{{ t('statistics.words_learnt') }}
				</div>
			</div>

			<div class="w-1/2 lg:w-1/6 mt-2 lg:mt-0">
				<div class="flex items-center">
					<img class="h-8 lg:h-12 mr-2 items-center" src="/images/logo-xxxhdpi.png" alt="" />
					<div class="v-text--bold text-3xl">{{ profile.lessons_complete }}</div>
				</div>
				<div class="v-text--desc icon-offset w-full lg:w-3/6 leading-none mb">
					{{ t('statistics.sets_completed') }}
				</div>
			</div>
		</div>

		<!-- <div class="flex justify-between">
      <div class="v-status">
        <div class="v-status__card flex items-center px-5">
          <img class="h-8 mr-5" src="/images/logo-xxxhdpi.png" alt="">
          <div class="v-text--bold text-2xl mr-5">45%</div>
          <div class="v-text--desc">{{ $t('statistics.superpower') }}</div>
        </div>
        <div class="flex mt-4 items-start">
          <img class="h-6 mr-3" src="/images/iii-xxxhdpi.png" alt="">
          <div class="v-text--desc">{{ $t('statistics.your_average') }}</div>
        </div>
      </div>
      <div class="v-status">
        <div class="v-status__card flex items-center px-5">
          <img class="h-8 mr-5" src="/images/logo-xxxhdpi.png" alt="">
          <div class="v-text--bold text-2xl mr-5">+15%</div>
          <div class="v-text--desc">{{ $t('statistics.superpowers') }}</div>
        </div>
        <div class="flex mt-4 items-start">
          <img class="h-6 mr-3" src="/images/iii-xxxhdpi.png" alt="">
          <div class="v-text--desc">{{ $t('statistics.the_percentage') }}</div>
        </div>
      </div>
    </div> -->
		<div>
			<div class="v-text--bold text-2xl text-center">{{ t('home.rating_positions') }}</div>
			<div class="my-3 flex items-center" v-if="getMarathonGroups.length > 0 && !groupsLoading">
				<img class="mr-2 h-5" src="/images/iii-xxxhdpi.png" alt="" />
				<div class="v-text--desc">{{ t('statistics.realtime_rating_update') }}</div>
			</div>
			<div v-if="getMarathonGroups.length === 0 && !groupsLoading" class="v-text--desc text-xl text-center mt-5">
				{{ t('statistics.not_marathons') }}
			</div>
			<Expanded
				mobile
				class="my-3"
				v-for="(group, index) in getMarathonGroups"
				:key="group.id"
				ref="expanded"
				@state="(state) => onState(state, index)"
				:forceExpanded="getMarathonGroups.length === 1"
			>
				<template v-slot:header="props">
					<div class="v-expanded-header v-expanded--white flex items-center relative h-16">
						<div class="h-full mr-5 relative">
							<img class="h-full" src="/images/blueFigure.png" alt="" />
							<div
								class="absolute v-text--bold text-2xl text-white"
								style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
							>
								{{ group.position }}
							</div>
						</div>
						<div>{{ group.name }}</div>
						<button
							v-if="isNovakid && groupIdsToShowDownloadCertificate.includes(group.id)"
							class="download-certificate-button v-button v-button--gradient-red v-lesson-button"
							@click="
								(e) => {
									e.stopPropagation()
									onDownloadCertificateClick(group, index)
								}
							"
						>
							Certificate
						</button>
						<img
							class="v-expanded__arrow"
							src="/images/arrow-little.png"
							alt=""
							:class="{ active: props.openState }"
						/>
					</div>
				</template>
				<template v-slot:body>
					<div class="mt-2" style="border-radius: 25px; overflow: hidden">
						<MarathonRating
							v-if="currentGroupMarathonData"
							:currentGroupMarathonData="currentGroupMarathonData"
						/>
						<div v-else class="flex justify-center items-center h-32"><loader /></div>
					</div>
				</template>
			</Expanded>
			<!-- <div class="group-toggle-row p-5 flex items-center my-3" ></div> -->
		</div>
	</div>
</template>

<script setup>
import UserRow from '@/components/UserRow.vue'
import Progress from '@/components/ProgressBar.vue'
import Expanded from '@/components/Expanded.vue'
import MarathonRating from '@/components/MarathonRating.vue'

import { useStore } from 'vuex'
import { getMarathonData } from '@/api/user.js'
import { getStudentCertificate } from '@/api/marathon.js'
import { computed, ref, watch } from 'vue'
import { onBeforeMount } from '@vue/runtime-dom'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const store = useStore()

const currentGroupMarathonData = ref(null)
const groupsLoading = ref(true)
const groupIdsToShowDownloadCertificate = ref([])
const expanded = ref(null)

const profile = computed(() => store.getters.profile)
const groups = computed(() => store.getters.userGroups)
const getMarathonGroups = computed(() => {
	return (groups.value || []).filter(
		(group) =>
			group.marathon_status !== 'off' &&
			(!group.additional_info || parseInt(group.additional_info.show_type, 10) !== 3)
	)
})
const getStatus = computed(() => {
	const words = parseInt(profile.value.learned_word, 10)
	switch (true) {
		case words < 500:
			return { status: t('statistics.newbie'), icon: '/images/achives/1.svg', words: 0, next: 500 }
		case words < 1000:
			return { status: t('statistics.beginner'), icon: '/images/achives/2.svg', words: 500, next: 1000 }
		case words < 2000:
			return {
				status: t('statistics.intermediate_user'),
				icon: '/images/achives/3.svg',
				words: 1000,
				next: 2000,
			}
		case words < 3000:
			return {
				status: t('statistics.upper_intermediate_user'),
				icon: '/images/achives/4.svg',
				words: 2000,
				next: 3000,
			}
		case words < 4000:
			return { status: t('statistics.advanced_user'), icon: '/images/achives/5.svg', words: 3000, next: 4000 }
		case words >= 4000:
			return { status: t('statistics.expert'), icon: '/images/achives/6.svg', words: 4000, next: 0 }
    default:
      return { status: null, icon: null, words: null, next: 0}
	}
})
const getNextLevelWords = computed(() =>
	getStatus.value.next - profile.value.learned_word < 0 ? 0 : getStatus.value.next - profile.value.learned_word
)
const getNextLevelProgress = computed(() => (profile.value.learned_word * 100) / getStatus.value.next)
const isNovakid = computed(() => profile.value?.platform_id === 16)

onBeforeMount(async () => {
	if (!groups.value) {
		store.dispatch('GET_GROUPS')
		groupsLoading.value = false
	}

	if (getMarathonGroups.value.length) {
		setGroupsToDownloadCertificate()
	}
})

watch(getMarathonGroups.value, (value) => {
	if (value.length) {
		setGroupsToDownloadCertificate()
	}
})

const onState = async (state, index) => {
	if (state) {
		currentGroupMarathonData.value = null
		if (Array.isArray(expanded.value)) {
			expanded.value.forEach((ref, i) => {
				if (i !== index) {
					ref.close && ref.close()
				}
			})
		}

		const group = getMarathonGroups.value[index]

		const is_active = parseInt((group && group.additional_info && group.additional_info.is_active_flag) || 0, 10)
		const params = is_active ? { is_active } : {}

		const { data } = await getMarathonData(group.id, params)
		currentGroupMarathonData.value = data.data
		return data.data
	}
}

const onDownloadCertificateClick = async (group, index) => {
	const marathonData = await onState(true, index)
	if (!marathonData || !marathonData.results?.length) return

	const groupId = Number(group.id)
	const studentId = profile.value.id
	const payload = {
		position: 0,
		learnedWords: 0,
		completeLessons: 0,
		avgResult: 0,
	}

	marathonData.results.forEach((item, index) => {
		if (item.id === studentId) {
			payload.position = item.position ? item.position : 0
			payload.learnedWords = item.learn_words ? item.learn_words : 0
			payload.completeLessons = item.complete_lessons ? item.complete_lessons : 0
			payload.avgResult = item.avg_result ? item.avg_result : 0
		}
	})

	const result = await getStudentCertificate(groupId, studentId, payload)
	if (result.status === 200) {
		window.open(result.data)
	}
}

const setGroupsToDownloadCertificate = async () => {
	const studentId = profile.value.id
	for (const group of getMarathonGroups.value) {
		const is_active = parseInt((group && group.additional_info && group.additional_info.is_active_flag) || 0, 10)
		const params = is_active ? { is_active } : {}
		const { data } = await getMarathonData(group.id, params)

		if (!data || !data.data || !data.data.results || !data.data.results.length) continue

		const studentResult = data.data.results.find((item) => item.id === studentId)
		if (studentResult) {
			if (Number(studentResult.complete_lessons) === group.lessons_count) {
				groupIdsToShowDownloadCertificate.value.push(group.id)
			} else if (groupIdsToShowDownloadCertificate.value.includes(group.id)) {
				groupIdsToShowDownloadCertificate.value.splice(
					groupIdsToShowDownloadCertificate.value.indexOf(group.id, 1)
				)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.v-expanded {
	position: relative;

	&__arrow {
		position: absolute;
		top: 50%;
		right: 15px;
		width: 10px;
		transform: translateY(-50%) rotate(180deg);
		transition: all 0.2s linear;
		filter: brightness(0);

		&.active {
			transform: translateY(-50%) rotate(0);
		}
	}
}
.v-status {
	width: 48%;

	&__card {
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
		height: 70px;
	}
}

.v-reward {
	position: relative;

	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
		height: 50%;
	}
}

.v-switcher {
	width: 100%;
	display: flex;

	&__item {
		width: 100%;
		text-align: center;
		padding-bottom: 5px;
		cursor: pointer;

		&.active {
			border-bottom: 5px solid #00a447;
			color: #00a447;
		}
	}
}

.download-certificate-button {
	margin-left: auto;
	margin-right: 10px;
}

.icon-offset {
	margin-left: 2.5rem;

	@media (min-width: 1024px) {
		margin-left: 3.5rem;
	}
}

@media (max-width: 800px) {
	.mb {
		width: auto !important;
	}
}
</style>
