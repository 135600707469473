import { dateformat } from "../../helpers.js"
// import mime from 'mime-types';
import i18n from "../../i18n/index.js";

const getType = (url) => {
    console.log('url', url)
    return url|| ''
}


const getMessage = (key) => {
    return i18n.global.t(key);
}

export default class AdvancedChatAdapter {
    static toMessage({
        id = null,
        message = '',
        user = {
            name: '',
            surname: '',
            avatar: '',
        },
        created_at = new Date().toString(),
        readed = false,
        isNew = true,
        user_from_id = null,
        fileUrl,
        current_user_id,
    }, index) {
        let files = []

        if (fileUrl) {
            files = [
                {
                    name: (fileUrl.match(/(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/) || [])[0] || 'file',
                    // size: 67351,
                    type: (fileUrl.includes('.mp3') || fileUrl.includes('.mpeg')) ? 'audio/mp3' : getType(fileUrl),
                    audio: fileUrl.includes('.mp3') || fileUrl.includes('.mpeg'),
                    // duration: 14.4,
                    url: fileUrl,
                    // preview: 'data:image/png;base64,iVBORw0KGgoAA...'
                }
            ]
        }
        return {
            _id: id,
            indexId: index,
            content: message,
            senderId: user_from_id,
            username: user.name + ' ' + user.surname,
            avatar: user_from_id === current_user_id ? null : (user.avatar || user.avatar_150x150 || '/images/stub_icon.png').replace('https://develop.vznaniya.ru/storage/users/images/default/user_90x90.jpg', '/images/stub_icon.png'),
            date: dateformat(created_at, 'dd.mm'),
            timestamp: dateformat(created_at, 'HH:MM'),
            saved: true,
            distributed: true,
            deleted: false,

            seen: !!readed,
            new: !!isNew,

            disableActions: false,
            disableReactions: false,

            files,
        }
    }

    static toRoom(payload, index) {
        return {
            roomId: payload.id,
            roomName: payload.name + ' ' + payload.surname,
            avatar: payload.avatar || payload.avatar_150x150 || '/images/stub_icon.png',
            // unreadCount: payload.unread_messages,
            // index,
            lastMessage: payload.unread_messages ? {
                content: getMessage('messages.new_message'),
                senderId: payload.id,
                username: payload.name + ' ' + payload.surname,
                // timestamp: '10:20',
                saved: true,
                distributed: false,
                seen: false,
                new: true,
                countOfNewMessages: payload.unread_messages,
            } : null,
            typingUsers: [],
            users: [
                {
                    _id: payload.id,
                    username: payload.name + ' ' + payload.surname,
                    avatar: payload.avatar || payload.avatar_150x150 || '/images/stub_icon.png',
                    status: {
                        state: 'online',
                    }
                },
            ],
            user: {
                id: payload.id,
                username: payload.name + ' ' + payload.surname,
                avatar: payload.avatar || payload.avatar_150x150 || '/images/stub_icon.png',
                status: {
                    state: 'online',
                }
            }
        }
    }

    static chatConfig = {
        'show-add-room': false,
        'load-first-room': false,
        'show-search': false,
        'text-messages': {
            ROOMS_EMPTY: getMessage('messages.ROOMS_EMPTY'),
            ROOM_EMPTY: getMessage('messages.choose_dialog'),
            NEW_MESSAGES: getMessage('messages.new'),
            MESSAGE_DELETED: getMessage('messages.message_delete'),
            MESSAGES_EMPTY: getMessage('messages.empty'),
            CONVERSATION_STARTED: getMessage('messages.CONVERSATION_STARTED'),
            TYPE_MESSAGE: getMessage('messages.TYPE_MESSAGE'),
            // SEARCH: 'Rechercher',
            // IS_ONLINE: 'est en ligne',
            // LAST_SEEN: 'dernière connexion ',
            IS_TYPING: getMessage('messages.IS_TYPING')
        },
        'show-reaction-emojis': false,
        'message-actions': [
            {
                name: 'editMessage',
                title: getMessage('messages.edit'),
                onlyMe: true
            },
            {
                name: 'deleteMessage',
                title: getMessage('messages.delete'),
                onlyMe: true
            }
        ],
    }

}
