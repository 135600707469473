<template>
	<div
		class="mobile-app"
		:class="{
			bottombar: !$route.meta.auth && !$route.meta.frame,
			games: ['lesson', 'games'].includes($route.name),
		}"
		:style="{
			paddingTop: paddingTop,
			marginBottom: paddingBottom,
			backgroundColor: isNewMobileApp && 'transparent',
		}"
	>
		<!-- <div>HEADER</div> -->
		<div v-if="isProfileLoaded || !isNewMobileApp" class="mobile-app__main flex flex-col" :style="{ minHeight: isNewMobileApp && '100vh' }">
			<transition name="fade" mode="out-in">
				<router-view />
			</transition>
		</div>
		<BottomNavBar
			v-if="!$route.meta.auth && !$route.meta.nobar && showBottomBar && !isNewMobileApp"
			:frame="$route.meta.frame"
		/>

		<VModal />
		<ReviewModal />
		<ImageViewer />

		<transition name="fade" mode="out-in">
			<div
				v-if="isSiteBusy || (!isProfileLoaded && isNewMobileApp)"
				class="fullscreen-fixed"
				:class="{ white: isNewMobileApp }"
			>
				<loader />
			</div>
		</transition>
	</div>
</template>

<script>
import BottomNavBar from '@/screens/mobile/BottomNavBar.vue'
import VModal from '@/components/modals/MainModal.vue'
import ReviewModal from '@/components/modals/ReviewModal.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import FlutterBridge from '@/helpers/flutterBridge.js'
import ImageViewer from '@/components/ImageViewer.vue'
import { defineComponent } from '@vue/runtime-dom'

export default defineComponent({
	components: { BottomNavBar, VModal, ReviewModal, ImageViewer },
	created() {
		this.enableNoScalingMode()
		this.setIsNewMobileAppInUrl()

		this.emitter.on('app-paused', () => {
			document.querySelectorAll('audio').forEach((el) => el.pause && el.pause())
			document.querySelectorAll('video').forEach((el) => el.pause && el.pause())
		})
		this.emitter.on('v-modal-mobile', (payload) => {
			this.$root.$emit('v-modal', { ...payload, size: 'md' })
		})
		this.emitter.on('internet-status', (status) => {
			if (status === 'false' || status === false) {
				this.$root.$emit('v-modal', {
					text: 'Отсутствует подключение к интернету! Повторите попытку позже',
					error: true,
				})
			}
		})
		this.emitter.on('reload-user', () => {
			this.$store.dispatch('GET_PROFILE_DATA', true)
		})
		this.emitter.on('temp-sub-access', () => {
			this.$store.commit('OPEN_SUBSCRIPTION_ACCESS')
		})
		this.emitter.on('focus-into-view', async () => {
			await this.$nextTick()
			if (document.activeElement) {
				document.activeElement.scrollIntoView({ block: 'center', behavior: 'smooth' })
			}
		})

		this.$root.$on('set-scaling-mode', (action) => {
			if (action) {
				this.disableNoScalingMode()
			} else {
				this.enableNoScalingMode()
			}
		})

		// Токен авториации
		this.$helpers.getUrlToken()
		FlutterBridge.callService('FlutterTokenChannel', localStorage.getItem('token') || '')
		// window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage(localStorage.getItem('token') || '');

		document.onfullscreenchange = this.onFullScreenChange

		if (this.$route.name !== 'splash' && !this.isNewMobileApp) {
			this.$router.push({ name: 'splash' })
		} else if (this.isNewMobileApp) {
			this.newAppUpdateProfileData()
		}
	},
	computed: {
		...mapGetters(['isSiteBusy', 'isProfileLoaded', 'isNewMobileApp']),
		...mapState({
			showBottomBar: (state) => state.base.showBottomBar,
			profile: (state) => state.user.profile,
		}),
		paddingTop() {
			console.log('window.flutterPaddingTop', window.flutterPaddingTop)
			if (this.$route.meta.auth || this.$route.meta.frame) return 0

			return (window.flutterPaddingTop || 0) / 1.5 + 'px'
		},
		paddingBottom() {
			if (this.$route.meta.auth) return 0

			return ((window.flutterPaddingBottom || 0) + (this.inactive ? 5 : 0)) / 2 + 'px'
		},
	},
	methods: {
		...mapMutations(['SET_IS_NEW_MOBILE_APP_IN_URL']),
		enableNoScalingMode() {
			const vp = document.getElementById('meta-viewport')
			if (vp) {
				vp.setAttribute('content', 'width=device-width, initial-scale=1.0, maximun-scale=1.0, user-scalable=no')
			}
		},
		disableNoScalingMode() {
			const vp = document.getElementById('meta-viewport')
			if (vp) {
				vp.setAttribute('content', 'width=device-width, initial-scale=1.0')
			}
		},
		onFullScreenChange(event) {
			let elem = event.target
			let isFullscreen = document.fullscreenElement === elem
			console.log('isFullscreen', isFullscreen)
		},
		async newAppUpdateProfileData() {
			await this.$store.dispatch('GET_PROFILE_DATA')

			if (!this.isProfileLoaded) {
				this.isChecking = false
				localStorage.clear()
				FlutterBridge.callService('FlutterTokenChannel', '')
			} else {
				this.currentLanguageKey = this.profile.locale || 'en'
				this.$root.$i18n.locale = this.currentLanguageKey

				FlutterBridge.callService('FlutterLangChannel', this.currentLanguageKey)
				// window.FlutterLangChannel && window.FlutterLangChannel.postMessage(this.currentLanguageKey);

				// this.$router.push({ name: 'home' })
			}
		},
		setIsNewMobileAppInUrl() {
			const isNewMobileAppInUrl = this.$route.query && this.$route.query.isNewMobileApp === 'true'
			this.SET_IS_NEW_MOBILE_APP_IN_URL(isNewMobileAppInUrl)
		},
	},
})
</script>

<style lang="scss" scoped>
.mobile-app {
	min-height: 100vh;
	// display: flex;
	// flex-direction: column;
	background-color: #eaeaea;
	// overflow: hidden;
	position: relative;
	width: 100vw;

	&.games {
		background-color: #025aef;
	}

	&__main {
		position: relative;
		flex-grow: 2;
		height: 100%;
	}

	.bottombar {
		padding-bottom: 50px;
	}
}
</style>

<style lang="scss">
.mobile-app input {
	-webkit-appearance: none;
	// overflow: hidden;
}

.mobile-app input[type='checkbox'] {
	-webkit-appearance: checkbox;
	// overflow: initial;
}

.mobile-app .v-link-mobile {
	color: rgb(88, 157, 220) !important;
	text-decoration: underline;
}

.fullscreen-fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 99;

	background: rgba($color: #000000, $alpha: 0.75);
	display: flex;
	justify-content: center;
	align-items: center;

	&.white {
		background: #ffffff !important;
		opacity: 1 !important;
	}
}

@media (max-width: 1024px) {
	body.fixed {
		height: 100%;
		width: 100%;
		overflow: hidden;
		position: fixed;
		// -webkit-overflow-scrolling : touch !important;

		.mobile-app {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			// -webkit-overflow-scrolling : touch !important;

			& input,
			& textarea {
				-webkit-user-modify: read-write-plaintext-only !important;
			}

			& * {
				-webkit-tap-highlight-color: transparent;
			}
		}
	}
}
</style>
