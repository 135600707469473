<template>
	<div>
		<div class="w-full p-2" :style="disabled ? 'pointer-events: none' : ''">
			<div class="w-full flex justify-center items-center mt-2 mb-3 v-text--desc">
				<AppSvgIcon class="mr-2 mb-1" size="20" name="info" />
				<div style="margin-top: 1px">{{ `Cоедини озвучку и ${useTranslate ? 'определение' : 'слово'}` }}</div>
			</div>

			<div class="w-full flex justify-center flex-wrap mb-3">
				<div v-for="word in shuffledAudios" :key="word.id" class="p-1 lg:mb-2">
					<div
						class="card v-clickable cursor-pointer w-full flex justify-center items-center text-center"
						:class="{ 'list-complete-item-h': !isTrueMobile, selected: leftSideSelectedId == word.id }"
						:ref="`text-${word.id}`"
						@click="
							() => {
								listen(word)
								leftSideSelectedId = word.id
							}
						"
					>
						<AppSvgIcon size="40" name="listen" class="h-8 v-clickable cursor-pointer" />

						<!-- <img src="/images/listen.svg" class="h-8 v-clickable cursor-pointer" alt="" /> -->
					</div>
				</div>
			</div>

			<div class="w-full flex flex-wrap">
				<div class="w-1/2 p-1" v-for="word in shuffledWords" :key="word.id">
					<LexicalWordCard
						class="cursor-pointer h-full"
						:class="{ 'v-clickable': !getSelectedAudio(word) }"
						:word="word"
						:showTranslate="false"
						:useTranslate="useTranslate"
						:showIllustration="useTranslate"
						@click="!getSelectedAudio(word) && leftSideSelectedId ? (rightSideSelectedId = word.id) : null"
						:selected="rightSideSelectedId == word.id || isTestSelected(word.id)"
						:noPulse="true"
						:adaptiveVerticalIllustration="true"
						:dynamicAudio="getSelectedAudio(word)"
						@cancelDynamic="cancelDynamic(word)"
					/>
				</div>
			</div>
		</div>
		<div v-if="error" class="v-text--red text-lg text-center my-5">{{ 'Соедини все элементы' }}</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex'
import shuffle from 'lodash/shuffle'

import WordCard from '@/components/WordCard.vue'
import LexicalWordCard from '@/components/LexicalWordCard.vue'
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue'

const props = defineProps({
	useTranslate: Boolean,
	noFilter: Boolean,
	words: {
		type: Array,
		default: () => [],
	},
})
const store = useStore()
import AppSvgIcon from '@/components/helperComponents/AppSvgIcon.vue'

const error = ref(false)
const disabled = ref(false)
const shuffledWords = ref([])
const shuffledAudios = ref([])
const resultWords = ref([])
const leftSideSelectedId = ref(null)
const rightSideSelectedId = ref(null)
const wrongLeftSide = ref(false)
const wrongRightSide = ref(false)
const instance = getCurrentInstance()
const refs = instance.proxy?.$refs

const GAME_SLUG = props.useTranslate ? 'find_definition' : 'find_words'

const currentLesson = computed(() => store.getters.getCurrentLesson)
const currentWords = computed(() => store.getters.getCurrentWords)
const currentLessonProgressData = computed(() => store.getters.getCurrentLessonProgressData)
const isTrueMobile = () => window.isTrueMobile

const validateTest = () => {
	error.value = false

	if (resultWords.value.length === props.words.length) {
		return true
	}
	error.value = true
	return false
}

const getTestData = () =>
	resultWords.value.map((result) => ({
		id: result.wordId,
		test_type: GAME_SLUG,
		answer_id: result.audioId,
	}))

const restart = (force = false) => {
	shuffledWords.value = shuffle(props.words)
	shuffledAudios.value = shuffle(props.words)

	resultWords.value = []
	leftSideSelectedId.value = null
	rightSideSelectedId.value = null
	wrongLeftSide.value = false
	wrongRightSide.value = false
	disabled.value = false
}

const isTestSelected = (wordId) => resultWords.value.some(({ wordId: id }) => wordId == id)

const getSelectedAudio = (word) => {
	const curWord = resultWords.value.find(({ wordId: id }) => id === word.id)
	if (!curWord) return

	const audioWord = props.words.find((el) => el.id === curWord.audioId)
	if (!audioWord) return

	return audioWord.audio
}

const cancelDynamic = (word) => {
	const wordId = word.id
	const result = resultWords.value.find((el) => el.wordId == wordId)

	shuffledAudios.value.push(props.words.find((el) => el.id === result.audioId))
	resultWords.value = resultWords.value.filter((el) => el.wordId !== wordId)
}

const getRefByWordId = (wordId, ref = 'text') =>
	Array.isArray(refs[`${ref}-${wordId}`]) ? refs[`${ref}-${wordId}`][0] : refs[`${ref}-${wordId}`]

const handleMatch = () => {
	if (leftSideSelectedId.value && rightSideSelectedId.value) {
		resultWords.value.push({ audioId: leftSideSelectedId.value, wordId: rightSideSelectedId.value })
		shuffledAudios.value = shuffledAudios.value.filter((el) => el.id != leftSideSelectedId.value)
		leftSideSelectedId.value = null
		rightSideSelectedId.value = null
	}
}

const listen = async (word) => {
	try {
		const audio = new Audio()
		audio.src = word.audio
		await audio.play()
	} catch (error) {
		console.log('err', error)
	}
}

watch(leftSideSelectedId.value, () => {
	handleMatch()
})

watch(rightSideSelectedId.value, () => {
	handleMatch()
})

onMounted(() => {
	restart()
})
</script>

<style lang="scss" scoped>
.card {
	padding: 10px;
	background: linear-gradient(45deg, #00b54e, #66d981);
	border-radius: 50%;
	width: 60px;
	min-width: 60px;
	height: 60px;

	&.selected {
		border: 4px solid rgba(243, 212, 6, 1);
		animation: pulse 1s infinite;
		@keyframes pulse {
			0% {
				box-shadow: 0 0 0 0 rgba(243, 212, 6, 0.4);
			}
			70% {
				box-shadow: 0 0 0 20px rgba(243, 212, 6, 0);
			}
			100% {
				box-shadow: 0 0 0 0 rgba(243, 212, 6, 0);
			}
		}
	}

	&.wrong {
		background-color: #ec591d !important;
	}

	&.right {
		background-color: rgb(0, 164, 71) !important;
		pointer-events: none !important;
	}

	img {
		filter: brightness(0) invert(100%);
	}
}
</style>
