<template>
  <div class="modal-view" v-if="show" @click.self="show = false; src = ''">
    <div class="modal-view__content">
      <pinch-zoom class="w-full h-full flex justify-center items-center">
        <img class="w-full max-h-full" :src="src" alt="">
      </pinch-zoom>
      <div class="modal-view__close" @click="show = false; src = ''">
        <img class="w-full h-full" src="@/assets/close-xxxhdpi.png">
      </div>
    </div>
  </div>
</template>

<script>
import 'pinch-zoom-element';

export default {
  data() {
    return {
      src: '',
      show: false,
    }
  },
  // watch: {
  //   show(value) {
  //     this.$root.$emit('set-scaling-mode', value);
  //   }
  // },
  created() {
    this.$root.$on('v-image-view', (src = '') => {
      this.src = src;
      this.show = true;
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 100vw;
    height: 90vh;
    position: relative;
    touch-action: none;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      touch-action: pinch-zoom pan-x pany-y;
    }
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;

    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
