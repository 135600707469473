<template>
	<div class="vue-advanced-chat-container">
		<vue-advanced-chat
			v-if="userId"
			:current-user-id="userId.id"
			:rooms="JSON.stringify(roomsFromStore ?? [])"
			:messages="JSON.stringify(messagesFormatted ?? [])"
			.text-messages="textMessages"
			:loading-rooms="isLoaded.loadingRooms"
			:rooms-loaded="isLoaded.allRoomsLoaded"
			:room-list-opened="true"
			:load-first-room="!!router.currentRoute.value.params?.user_id"
			:messages-loaded="isLoaded.messagesLoaded"
			show-reaction-emojis="false"
			.message-actions="messageActions"
			show-add-room="false"
			show-new-messages-divider="true"
			.styles="chatStyles"
			:height="(mobileHeight || 700) + 'px'"
			ref="chatElement"
			@fetch-messages="onGetMessages"
			@fetch-more-rooms="onFetchMoreRooms"
			@send-message="onSendMessage"
			@edit-message="onEditMessage"
			@delete-message="onDeleteMessage"
			@open-file="onOpenFile"
			@typing-message="onTypingMessage"
		>
			<!-- TODO: Find a way to use vue-advanced-chat without disabling es-lint -->
			<!-- eslint-disable -->
			<chat-list-item
				v-for="(room, index) in roomsFromStore"
				:key="`room-${room.roomId}`"
				:slot="`room-list-item_${room.roomId}`"
				:avatar="room.avatar"
				:name="room.roomName"
				:message="room.read"
				:unread-count="room.unread_count"
				:highlight="(highlightUnansweredItems && room.unanswered) || (highlightUnreadItems && room.read)"
				:room="roomElements[index]"
				@click="onRoomEnter"
			/>
			<!-- <input-field
				class="chat-list__search"
				slot="rooms-list-search"
				:placeholder="$t('chat.search')"
				search
				use-clear
				v-model="searchInput"
				@input="searchRooms"
				@clear="searchRooms"
			/> -->
			<svg-microphone slot="microphone-icon" />
			<svg-emoji slot="emoji-picker-icon" />
			<svg-attach slot="paperclip-icon" :style="{ margin: '0 10px' }" />
			<svg-send class="chat-body__input__send-btn" slot="send-icon" :style="{ top: '-4px' }" />
			<div slot="toggle-icon">
				<svg-fold />
			</div>
			<template v-for="message in messagesFormatted" :key="`message-${message._id}`">
				<div :slot="`audio-play-icon_${message._id}`" class="chat-list__message__svg-play">
					<img :src="SvgPlay" />
				</div>
				<div class="chat-list__message__svg-pause" :slot="`audio-pause-icon_${message._id}`">
					<img :src="SvgPause" />
				</div>
			</template>
		</vue-advanced-chat>
		<a v-show="false" ref="hiddenLink" href="#"></a>
	</div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch, nextTick } from 'vue'
import { register } from 'vue-advanced-chat'
import { useStore } from 'vuex'
import useEventBus from '@/composables/useEventBus.js'

import ChatListItem from './chat/ChatListItem.vue'
// import InputField from '@/components/common/InputField.vue'

import SvgMicrophone from '@/assets/chat/SvgMicrophone.vue'
import SvgEmoji from '@/assets/chat/SvgEmoji.vue'
import SvgAttach from '@/assets/chat/SvgAttach.vue'
import SvgSend from '@/assets/chat/SvgSend.vue'
import SvgFold from '@/assets/chat/SvgFold.vue'
import SvgPlay from '@/assets/chat/SvgPlay.svg'
import SvgPause from '@/assets/chat/SvgPause.svg'
import { formatMessage, validateFiles as checkValidFiles } from '@/helpers/chat'
import debounce from 'lodash/debounce'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const emitter = useEventBus()
const store = useStore()
const { t } = useI18n()
register()
const mobileHeight = ref(0)
const props = defineProps({
	deleteUnvalidated: Boolean,
	sortedRooms: null,
	highlightUnreadItems: {
		type: Boolean,
		default: false,
	},
	highlightUnansweredItems: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(['fileSizeError', 'validatedFilesSent'])

const chatElement = ref(null)
const rooms = ref([])
const roomsFromStore = computed(() => store.getters.rooms)
const messagesFormatted = computed(() => store.getters.messagesFormatted)
const unreadCount = computed(() => store.getters.unreadCount)
const currentRoom = computed(() => store.getters.currentRoom)
const chatController = computed(() => store.state.user.chatController)
const searchInput = ref('')
const hiddenLink = ref(null)
const currentMessage = ref('')
const validatedFiles = ref([])
const isLoaded = ref({
	loadingRooms: true,
	messagesLoaded: false,
	allRoomsLoaded: false,
})
const messageActions = ref([
	{
		name: 'editMessage',
		title: computed(() => `${t('chat.actions.edit.title')}`),
		onlyMe: true,
	},
	{
		name: 'deleteMessage',
		title: computed(() => `${t('chat.actions.delete.title')}`),
		onlyMe: true,
	},
])
const currentPage = ref(1)
const currentPageChat = ref(0)

const chatStyles = {
	footer: {
		background: '#ffffff',
	},
	message: {
		backgroundMe: '#caf7ec',
	},
	sidemenu: {
		backgroundActive: '#ECF7FF',
	},
}

const router = useRouter()
const textMessages = computed(() => ({
	ROOMS_EMPTY: t('chat.messages.rooms-empty'),
	ROOM_EMPTY: t('chat.messages.room-empty'),
	NEW_MESSAGES: t('chat.messages.new-messages'),
}))

const roomElements = ref([])

// Обработка событий сокетов
const onIncomingMessage = (evt) => {
	controller.value.onIncomingMessage(evt)
}

const onRead = (evt) => {
	console.log('read', evt)
}

const onReadAll = (evt) => {
	console.log('readAll', evt)
}

const onPrints = (evt) => {
	controller.value.setPrinting(evt.sender)
}

const onRecipientRead = (evt) => {
	if (evt.messageIds) {
		controller.value.markSeen(evt.userId, evt.messageIds)
	} else {
		controller.value.markSeenAll(evt.userId)
	}
}

const onUpdate = (payload) => {
	controller.value.onEditMessage(payload)
}

const onDelete = ({ messageId }) => {
	controller.value.onDeleteMessage(messageId)
}


const onGetMessages = async (event) => {
	currentPageChat.value++

	await store.dispatch('getMessagesRoomFormatted', {
		user_id: event.detail[0].room.roomId,
		page: currentPageChat.value
	});

	const lengthMessages = messagesFormatted.value ? messagesFormatted.value.length : 0
	isLoaded.value.messagesLoaded = Boolean(lengthMessages % 20) || lengthMessages === 0
	store.commit('setCurrentRoom', event.detail[0].room)

	if (unreadCount.value?.unread_count > 0) {
		await store.dispatch('readMessages', event.detail[0].room.roomId)
		if (roomsFromStore.value) {
			store.commit(
				'setRooms',
				roomsFromStore.value.map((room) => {
					if (currentRoom.value && room.roomId === currentRoom.value.roomId) {
						room.read = false
					}
				})
			)
		}
		store.dispatch('getUnreadCount')
	}
}

const userId = computed(() => store.state.user.profile)

const moveRoomToTop = (roomId) => {
	if (roomsFromStore.value) {
		store.commit('setRooms', [
			...roomsFromStore.value.filter((room) => room.roomId === roomId),
			...roomsFromStore.value.filter((room) => room.roomId !== roomId),
		])
	}
}

const sendFiles = async (files) => {
	if (!currentRoom.value) return
	const validateFiles = checkValidFiles(files)
	if (validateFiles.unvalidated.length > 0) {
		validatedFiles.value = validateFiles.validated
		emit('fileSizeError', TranslateService.global.t('chat.errors.file-size.message'))
		return
	}
	for (const file of files) {
		const uploadFileResponse = await store.dispatch('uploadFile', file)
		const illustrationId = uploadFileResponse.id
		const data = {
			illustration_id: illustrationId,
			receiver: currentRoom.value.roomId,
		}
		const res = await store.dispatch('sendMessage', data)
		if (messagesFormatted.value) {
			store.commit('setMessagesFormatted', [
				...messagesFormatted.value,
				formatMessage(res.data, messagesFormatted.value.length + 1),
			])
		}
	}
}

const sendTextMessage = async (message) => {
	if (!currentRoom.value) return
	const data = {
		message,
		receiver: currentRoom.value.roomId,
	}
	const res = await store.dispatch('sendMessage', data)
	if (messagesFormatted.value) {
		store.commit('setMessagesFormatted', [
			...messagesFormatted.value,
			formatMessage(res.data, messagesFormatted.value?.length + 1),
		])
	}
}

const onSendMessage = async (event) => {
	if (event.detail[0].files && event.detail[0].files.length > 0) {
		await sendFiles(event.detail[0].files)
		moveRoomToTop(event.detail[0].roomId)
	}
	if (event.detail[0].content) {
		await sendTextMessage(event.detail[0].content)
		moveRoomToTop(event.detail[0].roomId)
	}
}

const onTypingMessage = (event) => {
	if (event.detail[0]) currentMessage.value = event.detail[0].message
}

const onDeleteMessage = async (event) => {
	const result = await store.dispatch('deleteMessage', event.detail[0].message._id)
	if (result.success && messagesFormatted.value) {
		const tmp = []
		messagesFormatted.value.forEach((message) => {
			if (message._id !== event.detail[0].message._id) tmp.push(message)
		})
		store.commit('setMessagesFormatted', tmp)
	}
}

const onEditMessage = async (event) => {
	const result = await store.dispatch('editMessage', event.detail[0].messageId, event.detail[0].newContent)
	if (result.data && messagesFormatted.value) {
		const tmp = []
		messagesFormatted.value.forEach((message) => {
			if (message._id === event.detail[0].messageId) tmp.push(formatMessage(result.data))
			else tmp.push(message)
		})
		store.commit('setMessagesFormatted', tmp)
	}
}

const onOpenFile = async (event) => {
	if (event.detail[0].file.file) {
		if (event.detail[0].file.action === 'download' && hiddenLink.value) {
			const downloadUrl = (url) => {
				const link = document.createElement('a')
				link.href = url
				link.target = '_blank'
				link.download = Date.now().toString()
				link.click()
			}
			const fileObj = await fetch(event.detail[0].file.file.url)
			const blob = await fileObj.blob()
			const blobUrl = URL.createObjectURL(blob)
			downloadUrl(blobUrl)
			URL.revokeObjectURL(blobUrl)
		}
	}
}

const searchRooms = debounce(() => {
	if (!searchInput.value) {
		store.dispatch('getListRoomFormatted', { page: 1 })
		return
	}
	store.dispatch('getListRoomFormatted', { page: 1, text: searchInput.value })
}, 1000)

const onFetchMoreRooms = async () => {
	if (isLoaded.value.allRoomsLoaded || router.currentRoute.value.params?.user_id) {
		return
	}
	currentPage.value++
	await await store.dispatch('getListRoomFormatted', {
		page: currentPage.value,
	})
	if (currentPage.value >= store.getters.lastRoomsPage) isLoaded.value.allRoomsLoaded = true
}

const getRooms = async (roomId) => {
	await store.dispatch(
		'getListRoomFormatted',
		roomId
			? {
					page: currentPage.value,
					user_id: roomId,
				}
			: { page: currentPage.value }
	)

	isLoaded.value.loadingRooms = false
	if (store.getters.rooms) rooms.value = store.getters.rooms
}

const onRoomEnter = () => {
	console.log('onRoomEnter')
	store.commit('clearMessagesFormatted')
	isLoaded.value.messagesLoaded = false
	currentPageChat.value = 0
}

const init = async (roomId) => {
	store.messagesFormatted = null
	store.rooms = null
	if (roomId) {
		await store.dispatch('getMessagesRoomFormatted', roomId)
		store.commit('setCurrentRoom', {
			roomId: roomId,
			read: false,
			roomName: '',
			unanswered: true,
			users: {
				_id: roomId,
				avatar: '',
				username: '',
			},
		})
	}
	getRooms(roomId)
}

watch()
// () => props.deleteUnvalidated,
// (value, oldValue) => {
// 	if (value && value !== oldValue && validatedFiles.value.length > 0) {
// 		sendFiles(validatedFiles.value)
// 		emit('validatedFilesSent')
// 	}
// }

watch(
	() => store.getters.rooms,
	() => {
		if (!chatElement.value) roomElements.value = []
		setTimeout(() => {
			if (chatElement.value && chatElement.value.shadowRoot) {
				roomElements.value = chatElement.value.shadowRoot.querySelectorAll('.vac-room-item')
			}
		}, 200)
	}
)

watch(
	() => props.sortedRooms,
	(value, oldValue) => {
		if (value !== oldValue) rooms.value = value
	}
)

const isTrueMobile = computed(() => window.isTrueMobile)
onMounted(async () => {
	init(router.currentRoute.value.params?.user_id ? Number(router.currentRoute.value.params?.user_id) : undefined)
	if (isTrueMobile.value) {
		mobileHeight.value = window.innerHeight - window.flutterBottomBarHeight * 1.5
	}
})

// Подключение сокетов
onMounted(() => {
	emitter.on('chat:incomingMessage', onIncomingMessage)
	emitter.on('chat:read', onRead)
	emitter.on('chat:readAll', onReadAll)
	emitter.on('chat:prints', onPrints)
	emitter.on('chat:recipientRead', onRecipientRead)
	emitter.on('chat:update', onUpdate)
	emitter.on('chat:delete', onDelete)
})

// Очистка событий при уничтожении компонента
onUnmounted(() => {
	emitter.off('chat:incomingMessage', onIncomingMessage)
	emitter.off('chat:read', onRead)
	emitter.off('chat:readAll', onReadAll)
	emitter.off('chat:prints', onPrints)
	emitter.off('chat:recipientRead', onRecipientRead)
	emitter.off('chat:update', onUpdate)
	emitter.off('chat:delete', onDelete)
})


</script>

<style lang="scss" scoped>
.chat-filter {
	width: 260px;
	// padding: 10px;
	background-color: #fff;
	border-radius: 8px;
	overflow: hidden;

	&__item {
		padding: 10px;
		cursor: pointer;

		&.active {
			border-bottom: 3px solid #4da8df;
			color: #4da8df;
		}

		&:hover {
			color: #4da8df;
		}
	}
}
</style>
<style lang="scss">
.chat-new-circle {
	width: 20px;
	height: 20px;
	background-color: #4da8df;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 1;
	border-radius: 50%;
	color: #fff;
	margin-left: 5px;
}

.chat-new-rect {
	// width: 20px;
	// height: 20px;
	padding: 3px 5px;
	background-color: #4da8df;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 1;
	border-radius: 4px;
	font-size: 14px;
	color: #fff;
}

.v-chat {
	@media (max-width: 900px) {
		padding-bottom: 0 !important;
		align-items: inherit;
		.w-full.relative {
			display: flex;
		}
		.vac-card-window {
			display: flex !important;
			height: auto !important;
			margin-right: 0;
		}
		.vac-card-window .vac-chat-container {
			width: 100%;
		}
		.vac-rooms-container {
			height: 100vh !important;
		}
		.vac-chat-container {
			height: 100%;
			align-items: inherit;
		}
		.vac-col-messages {
			height: 847px;
			max-height: calc(100% - 47px);
			justify-content: inherit;
		}
	}

	.vac-textarea {
		max-height: 200px;
		overflow: auto;
	}

	.vac-progress-time {
		// margin: -18px 0 0 40px;
	}
}
</style>
