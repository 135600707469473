export default {
    join_challenge: 'Join the challenge',
    comment: 'Comment',
    create: 'Submit work',
    edit: 'Edit work',
    upload_media: 'Drag and drop or click here to upload an image or video',
    less_than: 'less than',
    write_title: 'Write a title',
    write_description: 'Work description',
    save: "Save",
    delete: "Delete",
    edit_post: "Edit",
    name_field: "Name",
    text_field: "Text",
    empty_posts: "The works of participants will be added here",
    input_radio: {
        title: "Works of the participants",
        latest: "Latest",
        popular: "Popular"
    },
    validation_error: {
        title: "The field should contain 3 or more characters",
        post: "The field cannot be empty",
        file: "File size is to large",
        upload: "The file size is too big! Use files of 15MB or less!",
        extension: "The file extension is not allowed! Use files of the following extensions: jpg, jpeg, png, mp4",
        file_empty: "File is not selected! Please, select a file.",
    },
    show_more: "Show more",
    hide_text: "Hide",
}
