import {getPlatform} from "../../api/platform.js";

export default {
    state: {
        platform: null
    },
    getters: {
        getPlatform: (state) => {
            return state.platform
        },
        isNovakid: (state) => {
            console.log(1)
            const novakidDomains = ["student.bg.novakid.pl", "student.bg.novakid.tr",  "student.bg.novakid.com.tr"];
            return !!(state.platform && novakidDomains.includes(window.location.host));
        }
    },
    actions: {
        GET_PLATFORM: async ({ commit }) => {
            try {
                const { data } = await getPlatform()
                commit('SET_PLATFORM', data);
                return data
            } catch (err) {
                console.log('err', err);
            }
        },
    },
    mutations: {
        SET_PLATFORM: (state, platform) => {
            state.platform = platform.data;
        },
    },
};
