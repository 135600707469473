<template>
	<transition name="fade" mode="out-in">
		<div class="v-modal" v-if="show" @click.self="$emit('close')">
			<div class="v-modal__wrapper">
				<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false" />

				<template v-if="!passwordResetMode">
					<span class="v-modal-text v-modal-text--header">{{ $t('home.login_modal.title') }}</span>
					<span v-if="state.params.forSaveTitle" class="mt-5">{{ $t('home.to_save_progress') }}</span>
					<span v-if="state.params.forTestTitle" class="mt-5">{{ $t('home.to_save_test_result') }}</span>
					<form class="v-modal__form v-form" @submit.prevent="send">
						<div class="v-form__input my-10">
							<span></span>
							<input
								type="email"
								:placeholder="$t('home.login_modal.email_placeholder')"
								v-model="formData.email"
							/>
						</div>
						<div class="v-form__input mt-5 mb-10">
							<span></span>
							<input
								:type="passwordType"
								:placeholder="$t('home.login_modal.password_placeholder')"
								v-model="formData.password"
							/>
							<span class="password-eye" @click="togglePasswordType">
								<svg
									v-if="passwordType == 'password'"
									width="24"
									height="24"
									xmlns="http://www.w3.org/2000/svg"
									fill-rule="evenodd"
									clip-rule="evenodd"
								>
									<path
										fill="#fff"
										d="M12.01 20c-5.065 0-9.586-4.211-12.01-8.424 2.418-4.103 6.943-7.576 12.01-7.576 5.135 0 9.635 3.453 11.999 7.564-2.241 4.43-6.726 8.436-11.999 8.436zm-10.842-8.416c.843 1.331 5.018 7.416 10.842 7.416 6.305 0 10.112-6.103 10.851-7.405-.772-1.198-4.606-6.595-10.851-6.595-6.116 0-10.025 5.355-10.842 6.584zm10.832-4.584c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z"
									/>
								</svg>
								<svg v-else width="24" height="24" fill-rule="evenodd" clip-rule="evenodd">
									<path
										fill="#fff"
										d="M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z"
									/>
								</svg>
							</span>
						</div>
						<button type="submit" class="v-form__submit">{{ $t('home.login_modal.login') }}</button>
					</form>

					<div class="v-modal__footer mt-10 flex flex-col items-center">
						<!-- <a class="v-modal-text v-modal-text--link mb-2" href="#" @click="passwordResetMode = true">{{ $t('home.login_modal.forgot_password') }}</a> -->
						<p>
							{{ $t('home.login_modal.no_account') }} —
							<a
								class="v-modal-text v-modal-text--link"
								href="#"
								@click="
									() => {
										show = false
										$root.$emit('register', state.params)
									}
								"
								>{{ $t('home.login_modal.register_now') }}</a
							>
						</p>
					</div>
				</template>

				<template v-else>
					<div v-if="!successSended" class="w-full">
						<img
							class="v-modal__close v-clickable"
							src="@/assets/close-xxxhdpi.png"
							alt=""
							@click="show = false"
						/>
						<span class="v-modal-text v-modal-text--header mb-10">{{
							$t('home.login_modal.forgot_password')
						}}</span>
						<div class="flex flex-col w-full mb-10 p-10">
							<label class="vzn-modal__label">{{ $t('home.login_modal.email_label') }}</label>
							<input
								class="vzn-input jq-user-email"
								type="email"
								placeholder="_____@____.__"
								v-model="passwordResetEmail"
							/>
						</div>
						<button class="v-form__submit" @click="sendResetPassword">
							{{ $t('home.login_modal.send') }}
						</button>
					</div>
					<div v-else class="w-full">
						<div class="mb-10" style="font-size: 22px">{{ $t('home.login_modal.email_instruction') }}</div>
						<button class="v-form__submit" @click="show = false">{{ $t('home.login_modal.close') }}</button>
					</div>
				</template>
			</div>
		</div>
	</transition>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { getSlug } from '@/helpers.js'
import useEventBus from '@/composables/useEventBus.js'
import { useRoute } from 'vue-router'

const emitter = useEventBus()
const store = useStore()

const show = ref(false)
const state = reactive({ params: {} })
const passwordResetMode = ref(false)
const successSended = ref(false)
const passwordResetEmail = ref('')
const passwordType = ref('password')
const formData = reactive({
	email: '',
	password: '',
})

const tempTeacherData = computed(() => store.state.user.tempTeacherData)

emitter.on('auth', (params = {}) => {
	show.value = true
	state.params = { ...params }
})
const route = useRoute()

const send = async () => {
	try {
		const slug = getSlug()
		await store.dispatch('AUTH', {
			...formData,
			slug,
			id: route.params.id, // Type assertion for access to $route
			group_id: route.query.group_id,
			onlyteacher: state.params.onlyteacher,
		})

		show.value = false

		if (state.params.copy) {
			emitter.emit('temp-teacher-groups')
		}
	} catch (err) {
		console.log('EEEEEEEEEEEEEEEEE', err)
	}
}

const togglePasswordType = () => {
	passwordType.value = passwordType.value === 'text' ? 'password' : 'text'
}
</script>

<style scoped lang="scss">
.v-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	form {
		width: 90%;
	}

	&__wrapper {
		// background-color: #fff;
		background: url(/images/auth-bg.jpg);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px;

		width: 600px;
		height: 500px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
		opacity: 0;
		animation: showup 0.3s ease;
		animation-fill-mode: forwards;

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		&.sm {
			width: 350px;
			min-height: 250px;
		}

		&.md {
			width: 450px;
			min-height: 250px;
		}

		&.lg {
			width: 600px;
			min-height: 350px;
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}
}

@media screen and (max-width: 700px) {
	.v-modal__form {
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.v-modal {
		padding: 25px;
	}
	input::placeholder {
		font-size: 16px;
	}
}

.vzn-input {
	font-family: FreeSetLightC !important;
	font-size: 24px;
	line-height: normal;
	background: 0 0;
	border: 1px solid #009ee0;
	border-width: 0 0 2px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 100%;
	min-height: 2.8125rem;
	padding: 0.625rem 0.9375rem;
}

.vzn-modal__label {
	font-family: FreeSetBoldC !important;
	font-size: 1.125rem;
	line-height: normal;
	color: #041227;
}

.v-modal-text {
	&--header {
		font-family: FreeSetDemiC !important;
		text-transform: uppercase;
		font-size: 2rem;
		line-height: 1;
		text-align: center;
	}

	&--link {
		font-weight: 700;
		font-family: FreeSetC;
		cursor: pointer;
		text-decoration: underline;
	}
	&--soft-link {
		font-family: FreeSetC;
		cursor: pointer;
		text-decoration: underline;
	}
}

.v-form {
	&__input {
		position: relative;
	}

	&__input input {
		height: 55px;
		width: 100%;
		background: #e4ecf2;
		padding: 0.8125rem 1.25rem;
		font-family: FreeSetLightC;
		font-size: 24px;
	}

	&__submit {
		display: block;
		width: 100%;
		background: #d7513f;
		border-radius: 35px;
		font-family: FreeSetDemiC;
		font-size: 1.375rem;
		text-transform: uppercase;
		padding: 1.0625rem 2.1875rem;
		transition: all 0.2s linear;
		text-align: center;
		color: #fff;
		cursor: pointer;

		&:hover {
			background: #fff;
			color: #d7513f;
		}
	}

	.password-eye {
		width: 55px;
		height: 55px;
		position: absolute;
		top: 0;
		right: 0;
		background: #bccee3;
		cursor: pointer;
		transition: filter 0.25s linear;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			filter: brightness(1.05);
		}
	}
}
</style>
