<template>
    <div class="splash-screen flex flex-col pb-10">
        <img class="splash-screen__bottom-decor" src="@/assets/mobile/auth_top_left.png" alt="">
        <img class="splash-screen__top-decor" src="@/assets/mobile/auth_bottom_right.png" alt="">

        <div class="flex-grow flex flex-col justify-center items-center h-full">
<!--            <img class="splash-logo mb-5" src="/images/mobile/logo_brain.png" alt="">-->
<!--            <img class="splash-label mb-10" src="/images/mobile/logo_label.png" alt="">-->
            <div class="splash-carousel">
                <carousel :perPage="1" :paginationEnabled="false" @pageChange="onPageChange">
                    <slide class="splash-slide">
                        {{$t('home.mobile_1')}}
                    </slide>
                    <slide class="splash-slide">
                        {{$t('home.mobile_2')}}
                    </slide>
                    <slide class="splash-slide">
                        {{$t('home.mobile_3')}}
                    </slide>
                    <slide class="splash-slide">
                        <div class="v-text--blue text-xl v-text--bold">{{$t('home.mobile_4')}}</div>
                        <div class="v-text--green text-xl v-text--bold">{{$t('home.mobile_5')}}</div>
                    </slide>
                </carousel>
            </div>
        </div>

        <div class="flex flex-col w-full items-center justify-center mb-5">
            <div class="s-button s-button--orange mb-3" @click="!isChecking && next()">{{ isChecking ? '...' : $t('home.login_modal.login') }}</div>
            <template v-if="!isChecking">
                <div class="mb-5 v-text--gray" @click="$router.push({ name: 'register' })">{{$t('home.register_modal.register')}}</div>
                <div class="splash-dots">
                    <div class="splash-dot" v-for="i in 4" :key="i" :class="{ active: i === currentSlide }"></div>
                </div>
            </template>
        </div>
        <!-- {{ isChecking ? 'Проверка...' : '' }} -->
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import FlutterBridge from '../../helpers/flutterBridge';
import { clearLocalStorageButLessonTimers } from '../../helpers'

export default {
    components: {
        Carousel, Slide,
    },
    data: () => ({
        isChecking: true,
        currentSlide: 1,
    }),
    async created() {
        console.log('local', localStorage.getItem('token'))
        await this.$store.dispatch('GET_PROFILE_DATA');

        if (!this.isProfileLoaded) {
            this.isChecking = false;

			clearLocalStorageButLessonTimers()

            FlutterBridge.callService('FlutterTokenChannel', '');
        } else {
            this.currentLanguageKey = this.profile.locale || 'en';
            this.$root.$i18n.locale = this.currentLanguageKey;

            FlutterBridge.callService('FlutterLangChannel', this.currentLanguageKey);
            // window.FlutterLangChannel && window.FlutterLangChannel.postMessage(this.currentLanguageKey);

            this.$router.push({ name: 'home' })
        }
    },
    computed: {
        ...mapGetters(['isProfileLoaded']),
        ...mapState({
            profile: state => state.user.profile,
        })
    },
    methods: {
        next() {
            this.$router.push({ name: 'login' })
        },
        onPageChange(slideIndex) {
            this.currentSlide = slideIndex + 1;
        }
    }
}
</script>

<style scoped lang="scss">
.splash-dots {
    display: flex;
    justify-content: center;
}
.splash-dot {
    width: 10px;
    height: 10px;
    background-color: rgb(214, 100, 78);
    opacity: 0.5;
    border-radius: 50%;
    margin: 0 5px;

    &.active {
        opacity: 1;
    }
}
.splash-screen {
    height: 100vh;

    &__bottom-decor,
    &__top-decor {
        position: absolute;
        width: 50vw;
        max-width: 200px;
        pointer-events: none;
    }

    &__bottom-decor {
        bottom: 0;
        right: 0;
    }
    &__top-decor {
        top: 0;
        left: 0;
    }

    .splash-logo {
        width: 100px;
        mix-blend-mode: multiply;
    }

    .splash-label {
        max-width: 270px;
    }

    .splash-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        text-align: center;
        flex-direction: column;
        box-sizing: border-box;
    }

    .splash-carousel {
        width: 100%;
    }
}
</style>
