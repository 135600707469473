import Vue from 'vue'
import shuffle from 'lodash/shuffle'
import isEqual from 'lodash/isEqual'
// import { shuffle, flatten, isEqual } from 'lodash'

import {
	getLessonsDataFilter,
	getCurrentLesson,
	getLessonWords,
	getLessonsTestResults,
	stageLessonResult,
	stageTestResult,
	getLessonDataSlug,
	getLessonDataSlugPreview,
	getActivityTypes,
	resetLexical,
	countingTest,
	getLexicalResult,
	getCurrentLessonComments,
	sendCurrentLessonComment,
	deleteCurrentLessonComment,
} from '@/api/lessons.js'
import locale from '@/i18n/index.js'

/**
 * Создает запись клона текущего прогресса в локал сторейдж
 * @param {State} state - стейт
 */
const serializeLessonProgress = (state) => {
	const data = {
		currentLesson: state.currentLesson,
		currentLessonProgressData: state.currentLessonProgressData,
		currentTestProgressData: state.currentTestProgressData,
		currentWords: state.currentWords,
	}
	localStorage.setItem('lesson-persist', JSON.stringify(data))
}

/**
 * Вытягивает запись урока из локал сторейджа
 */
const deserializeLessonProgress = () => {
	return JSON.parse(localStorage.getItem('lesson-persist'))
}

/**
 * Восстановление данных из локал сторейджа в стейт
 * @param {State} state - стейт
 * @param {Object} persist - восстановленные данные
 */
const restoreLessonProgress = (state, persist) => {
	Vue.set(state, 'currentLessonProgressData', persist.currentLessonProgressData)
	// state.currentLessonProgressData = persist.currentLessonProgressData;
	for (let game in state.sonProgressData) {
		state.currentLessonProgressData[game].reseted = false
	}

	state.currentTestProgressData = persist.currentTestProgressData
	state.currentWords = persist.currentWords
}

/**
 * Очистить локал сторейдж
 */
const clearLessonProgressPersist = () => {
	localStorage.removeItem('lesson-persist')
}

export default {
	state: {
		lessons: [], // Уроки на домашнем экране
		currentLesson: null, // Текущий открыйтый урок
		currentWords: null, // Слова по открытому уроку
		currentLessonTestResults: null, // Результаты тестов по открытому уроку
		currentLessonProgressData: {}, // Объект с прогрессом по уроку (локальным)
		currentTestProgressData: [], // Объект с прогрессом по тесту (локальным)
		maxLessonsPage: 1, // Устанавливается из мета данных пагинации загрузки уроков
		activityTypes: [],
		currentLessonComments: [],
	},
	getters: {
		/**
		 * Возвращает name следующего не выполненного задания или FinalTest, если доступен тест
		 */
		getNotDoneTask(state, getters, rootState) {
			if (!state.currentLesson) return null
			const nextGame = rootState.base.games.find(({ slug }) => {
				if (
					!state.currentLesson.additional_info ||
					!state.currentLesson.additional_info.tasks ||
					!state.currentLesson.lr_additional_info ||
					!state.currentLesson.lr_additional_info.tasks
				)
					return false

				const tasks = state.currentLesson.additional_info.tasks
				const lr_tasks = state.currentLesson.lr_additional_info.tasks

				const repeat = parseInt(tasks[slug], 10) || 0
				const count = parseInt(lr_tasks[slug], 10) || 0

				return count < repeat
			})

			return nextGame ? nextGame.name : 'FinalTest'
		},

		// Доступен ли тест (все задания выполнены)
		isTestAvailable(state, getters) {
			return getters.getNotDoneTask === 'FinalTest'
		},

		isCurrentLessonForeignLanguage(state, getters, rootState) {
			if (!state.currentLesson || !state.currentLesson.id) return false

			const currentSubject = rootState.user.subjects.find(
				(subject) => subject.id === state.currentLesson.subject_id
			)
			if (currentSubject) {
				return currentSubject.slug === 'foreign_languages'
			}
			return false
		},
		getCommentsForCurrentQuestion: (state) => (questionId) => {
			return state.currentLessonComments
				.filter((item) => item.question_id === questionId)
				.sort((a, b) => (new Date(a.updated_at) > new Date(b.updated_at) ? 1 : -1))
		},
		getCurrentLesson: (state) => state.currentLesson,
		getCurrentLessonComments: (state) => state.currentLessonComments,
		getCurrentWords: (state) => state.currentWords,
		getCurrentLessonProgressData: (state) => (slug) => state.currentLessonProgressData[slug],
		getLessons: (state) => state.lessons,
		maxLessonsPage: (state) => state.maxLessonsPage,
		currentLessonTestResults: (state) => state.currentLessonTestResults,
		currentTestProgressData: (state) => state.currentTestProgressData,
	},
	actions: {
		GET_ACTIVITY_TYPES: async ({ commit }) => {
			const { data } = await getActivityTypes()
			commit('SET_ACTIVITY_TYPES', data.data)
		},
		GET_CURRENT_LESSON_SLUG: async ({ commit, rootState, rootGetters }, { id, slug, onlypreview, group_id }) => {
			const { data } = onlypreview
				? await getLessonDataSlugPreview(id, slug)
				: await getLessonDataSlug(id, slug, group_id)
			const lessonData = data.data

			// Заплатка
			if (!rootGetters.isProfileLoaded) {
				rootState.base.games
					.filter((el) => el.isGame)
					.forEach(({ slug }) => {
						if (lessonData.lr_additional_info && lessonData.lr_additional_info.tasks) {
							lessonData.lr_additional_info.tasks[slug] = 0
						}
					})
			}

			commit('SET_CURRENT_LESSON', { data: lessonData, group_id })
			commit('SET_CURRENT_WORDS', lessonData.words)
			commit('SET_CURRENT_TEST_RESULTS', lessonData.testResults || [])
			// Обработка данных
			commit('LESSON_INIT', { games: rootState.base.games, onlypreview })
			commit('RESTORE_SLUG_PROGRESS', slug)
		},
		/**
		 * Next page lessons load
		 * @param {Object} filters - filters in { page, isComplete, name, ...etc }
		 */
		FILTER_LESSONS_DATA: async ({ commit }, filters = { page: 1 }) => {
			// Очистка урока
			commit('CLEAR_CURRENT_LESSON')

			try {
				const { data } = await getLessonsDataFilter(filters)
				if (filters.page === 1) {
					commit('SET_LESSONS_DATA', data.data)
				} else {
					commit('ADD_LESSONS_DATA', data.data)
				}
				commit('SET_MAX_PAGE', data.meta.last_page)
			} catch (err) {
				console.log('Lessons catch err', err)
			}
		},

		/**
		 * Загрузить все данные по уроку
		 * @param {Number} lesson_id - id урока
		 */
		GET_CURRENT_LESSON: async ({ commit, rootState }, { id: lesson_id, group_id }) => {
			try {
				// Данные урока
				const { data: ld } = await getCurrentLesson(lesson_id, group_id)
				let lessonData = ld.data
				let previewLessonData = null

				if (lessonData.slug) {
					// Для корректной работы таймера необходимо брать данные из превью урока
					const { data: pld } = await getLessonDataSlugPreview(lesson_id, lessonData.slug)
					previewLessonData = pld.data
				}
				if (previewLessonData && previewLessonData.timer) {
					lessonData.timer = previewLessonData.timer
				}

				commit('SET_CURRENT_LESSON', { data: lessonData, group_id })
				console.log('данные лекции установлены')

				// Слова урока
				const { data: wordsData } = await getLessonWords(lesson_id)
				commit('SET_CURRENT_WORDS', wordsData.data)
				console.log('данные слов установлены')

				// Результаты теста урока TODO
				if (lessonData.type == 'lexical') {
					const { data: testData } = await getLessonsTestResults(lesson_id)
					commit('SET_CURRENT_TEST_RESULTS', testData.data)
					console.log('данные тестов установлены')
				}

				// Обработка данных
				commit('LESSON_INIT', { games: rootState.base.games })
				console.log('данные урока установлены')
			} catch (err) {
				if (err && err.response && err.response.data && err.response.data.errors) {
					console.log('HEREOM', err.response.data.errors.group_id)
					if (err.response.data.errors.group_id) {
						this.emitter.emit('v-modal', {
							text: locale.t('home.data_does_not_exist'),
							error: true,
							closeButton: false,
							closeOutside: false,
							buttons: [
								{
									text: locale.t('games.ok'),
									class: 'v-button v-button--gradient-red',
									callback: () => {
										location.href = '/'
									},
								},
							],
						})
					}
				}
				console.dir(err)
			}
		},

		/**
		 * Отправить на сервер результаты прохождения задания
		 * @param {Number} count - количетсво пройденных раз, которое нужно установить
		 * @param {String} type - slug имя задания
		 */
		STAGE_LESSON_RESULT: async ({ commit, state, rootState, dispatch }, { count, type, group_id }) => {
			try {
				commit('SET_BUSY_STATE', true)
				const { data } = await stageLessonResult({
					id: state.currentLesson.id,
					// student_id: rootState.user.profile.student_id,
					// count,
					type,
					group_id,
				})

				// Обновление урока
				const { data: lessonData } = await getCurrentLesson(state.currentLesson.id, group_id)
				commit('SET_CURRENT_LESSON', { data: lessonData.data, group_id })
				commit('SET_APPROVED_LESSON_TASK', type)
				commit('SET_BUSY_STATE', false)
			} catch (err) {
				throw err
			}
		},

		STAGE_LEXICAL_RESULT: async ({ commit }, { onlypreview, ...payload }) => {
			try {
				commit('SET_BUSY_STATE', true)
				const api = onlypreview ? getLexicalResult : countingTest
				const { data } = await api(payload)
				return data
			} catch (err) {
				console.log('err', err)
			} finally {
				commit('SET_BUSY_STATE', false)
			}
		},

		/**
		 * Отправить на сервер результаты прохождения теста
		 */
		STAGE_TEST_RESULT: async ({ commit, state, rootState }, payload) => {
			try {
				commit('SET_BUSY_STATE', true)
				const { data } = await stageTestResult(payload)
				return data
			} catch (err) {
				console.log('err', err)
			} finally {
				commit('SET_BUSY_STATE', false)
			}
		},

		/**
		 * Для каждого задания на сервер отпраляется значение 0
		 */
		RESET_LESSON_PROGRESS: async ({ commit, state, dispatch, rootState }) => {
			const games = rootState.base.games

			try {
				commit('SET_BUSY_STATE', true)
				await resetLexical(state.currentLesson.id, state.currentLesson.group_id)
				// for await (let game of games.filter(el => el.isGame)) {
				//   stageTestResult({
				//     type: game.slug,
				//     count: 0,
				//     id: state.currentLesson.id,
				//     student_id: rootState.user.profile.student_id,
				//   })
				// }
				commit('RESET_TEST_PROGRESS')

				const lesson_id = state.currentLesson.id
				const group_id = state.currentLesson.group_id

				await dispatch('GET_CURRENT_LESSON', { id: lesson_id, group_id })
			} catch (err) {
				console.log('err', err)
			} finally {
				commit('SET_BUSY_STATE', false)
			}
		},
		// RESET_TEST_PROGRESS: async ({ state, dispatch, commit }) => {
		//   clearLessonProgressPersist();
		//   const lesson_id = state.currentLesson.id;
		//   await dispatch('GET_CURRENT_LESSON', lesson_id);
		// },
		UPDATE_CURRENT_LESSON_COMMENTS: async ({ commit }, { lesson_id, student_id }) => {
			const { data } = await getCurrentLessonComments(lesson_id, student_id)
			if (!data || data.error || !data.data || data.data.error) return
			commit('SET_CURRENT_LESSON_COMMENTS', data.data)
		},
		SEND_CURRENT_LESSON_COMMENT: async ({ commit, state }, { student_id, question_id, message }) => {
			if (!state.currentLesson) return
			const payload = {
				lesson_id: state.currentLesson.id,
				student_id: student_id,
				group_id: state.currentLesson.group_id,
				question_id: question_id,
				message: message,
			}
			const { data } = await sendCurrentLessonComment(payload)
			if (!data || data.error || !data.data || data.data.error) return
			commit('ADD_CURRENT_LESSON_COMMENT', data.data)
		},
		DELETE_CURRENT_LESSON_COMMENT: async ({ commit }, { id }) => {
			const { data } = await deleteCurrentLessonComment(id)
			if (!data.success) return
			commit('REMOVE_CURRENT_LESSON_COMMENT', id)
		},
	},
	mutations: {
		RECALCULATE_COORDINATES: (state, params) => {
			if (!params || !params.coef) return

			state.currentWords = state.currentWords.map((el) => ({
				...el,
				default_coordinates: el.default_coordinates || el.coordinates,
				coordinates: (el.default_coordinates || el.coordinates).map((coord) => coord * params.coef),
			}))
		},
		UPDATE_CURRENT_LESSON: (state, payload = {}) => {
			if (state.currentLesson) {
				state.currentLesson = {
					...state.currentLesson,
					...payload,
				}
			}
		},
		SET_ACTIVITY_TYPES: (state, types) => {
			state.activityTypes = types
		},
		RESTORE_SLUG_PROGRESS: (state, slug) => {
			let slugData = localStorage.getItem('slug-data')
			if (!slugData) return

			slugData = JSON.parse(slugData)
			for (let type in slugData[slug]) {
				if (state.currentLesson.lr_additional_info && state.currentLesson.lr_additional_info.tasks) {
					state.currentLesson.lr_additional_info.tasks[type] = slugData[slug][type].count
				}
			}
		},
		STAGE_LOCAL_DATA_FOR_SLUG: (state, { type, count, slug }) => {
			let slugData = localStorage.getItem('slug-data')
			let newSlugData = {}

			if (!slugData) {
				newSlugData[slug] = {
					[type]: { count },
				}
			} else {
				slugData = JSON.parse(slugData)
				newSlugData[slug] = {
					...slugData[slug],
					[type]: { count },
				}
			}

			localStorage.setItem('slug-data', JSON.stringify(newSlugData))

			if (!state.currentLesson.lr_additional_info) {
				state.currentLesson.lr_additional_info = {
					tasks: {},
				}
			}

			state.currentLesson.lr_additional_info.tasks = {...state.currentLesson.lr_additional_info.tasks, ...{[type]: count}}
			// Vue.set(state.currentLesson.lr_additional_info.tasks, type, count)
		},
		RESET_TEST_PROGRESS: (state) => {
			state.currentTestProgressData = []
			clearLessonProgressPersist()
		},
		SET_APPROVED_LESSON_TASK: (state, type) => {
			state.currentLessonProgressData[type].approved = true
			serializeLessonProgress(state)
		},
		SET_MAX_PAGE: (state, page) => {
			state.maxLessonsPage = page
		},
		SET_LESSONS_DATA: (state, lessons) => {
			// state.lessons = flatten(lessons.map(lesson => {
			//   return lesson.groups.map(group => ({ ...lesson, group_name: group.name, expires_at: { date: group.pivot.expires_at }, group_id: group.id, ...(lesson.type === 'lexical' ? {...group.pivot} : {}) }))
			// }));
			state.lessons = lessons.map((el) => ({
				...el,
				expires_at: { date: el.expires_at },
			}))
		},
		ADD_LESSONS_DATA: (state, lessons) => {
			state.lessons = [
				...state.lessons,
				...lessons.map((el) => ({
					...el,
					expires_at: { date: el.expires_at },
				})),
			]
		},
		SET_CURRENT_LESSON: (state, payload) => {
			if (!payload) {
				state.currentLesson = null;
				return;
			}

			const { data } = payload;

			if (!data.lr_additional_info || !data.lr_additional_info.tasks) {
				data.lr_additional_info = { tasks: {} };
			}

			state.currentLesson = {
				...data,
				expires_at: { date: data.expires_at },
				size: data.size || [0, 300],
			}
		},
		SET_CURRENT_WORDS: (state, words) => {
			state.currentWords = words.map((word) => {
				// if (word.coordinates && window.isTrueMobile) {
				//   const coef = 450 > (window.innerWidth - 15) * 0.9 ? (window.innerWidth - 15) * 0.9 / 450 : 1;
				//   word.coordinates = word.coordinates.map(el => el * coef);
				// }
				return word
			})
		},
		SET_CURRENT_TEST_RESULTS: (state, results) => {
			state.currentLessonTestResults = results
		},
		CLEAR_CURRENT_LESSON: (state) => {
			state.currentWords = null
			state.currentLessonTestResults = null
			state.currentTestProgressData = []
			clearLessonProgressPersist()
		},
		CLEAR_CURRENT_LESSON_FORCE: (state) => {
			state.currentLesson = null
			state.currentWords = null
			state.currentLessonTestResults = null
			state.currentTestProgressData = []
			clearLessonProgressPersist()
		},

		LESSON_INIT: (state, { games, onlypreview = false }) => {
			// Попытка восстановить данные
			const persist = deserializeLessonProgress()
			if (
				persist &&
				persist.currentLesson.id === state.currentLesson.id &&
				!onlypreview &&
				persist.currentWords.length === state.currentWords.length
			) {
				// && persist.currentLesson.updated_at.date === state.currentLesson.updated_at.data
				if (
					persist.currentWords.every((_word) => {
						// Слово с полученных по апи данных
						const pulledWord = state.currentWords.find((word) => word.id === _word.id)
						if (!pulledWord) return false

						return isEqual(pulledWord, _word)
					})
				) {
					return restoreLessonProgress(state, persist)
				}
				clearLessonProgressPersist()
			} else {
				clearLessonProgressPersist()
				console.log('Lesson progress data is not valid')
			}

			const onlyGames = games.filter((game) => game.isGame)
			for (const game of onlyGames) {
				state.currentLessonProgressData[game.slug] = {
					words: [],
					tips: [],
					approved: false,
				}
			}
		},

		RESTART_TASK: (state, payload) => {
			Vue.set(state.currentLessonProgressData, payload.gameName, {
				words: [],
				tips: [],
				approved: false,
			})
			state.currentWords = shuffle(state.currentWords)
			serializeLessonProgress(state)
		},

		SAVE_WORD: (state, payload) => {
			if (!state.currentLessonProgressData[payload.gameName].words.includes(payload.wordId)) {
				state.currentLessonProgressData[payload.gameName].words.push(payload.wordId)
				serializeLessonProgress(state)
			}
		},

		SAVE_TIP: (state, payload) => {
			if (!state.currentLessonProgressData[payload.gameName].tips.includes(payload.wordId)) {
				state.currentLessonProgressData[payload.gameName].tips.push(payload.wordId)

				serializeLessonProgress(state)
			}
		},

		SAVE_TEST_WORD: (state, payload) => {
			const currentIndex = state.currentTestProgressData.findIndex((el) => el.id === payload.id)
			if (currentIndex !== -1) {
				Vue.set(state.currentTestProgressData, currentIndex, payload)
			} else {
				state.currentTestProgressData.push(payload)
			}
			serializeLessonProgress(state)
		},

		SET_CURRENT_LESSON_COMMENTS: (state, comments) => {
			state.currentLessonComments = comments
		},
		ADD_CURRENT_LESSON_COMMENT: (state, comment) => {
			state.currentLessonComments.push(comment)
		},
		REMOVE_CURRENT_LESSON_COMMENT: (state, id) => {
			state.currentLessonComments = state.currentLessonComments.filter((item) => item.id !== id)
		},
	},
}
