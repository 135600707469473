<template>
  <FindWord ref="task" :words="words" :useTranslate="true" :noFilter="noFilter" @done="$emit('done')" />
</template>

<script setup>
import FindWord from '@/screens/games/FinalTestTasks/FindWord.vue';

const props = defineProps({
  noFilter: Boolean,
  words: {
    type: Array,
    default: () => []
  }
})
const task = ref(null);

const validateTest = () => task.value.validateTest();

const getTestData = () => task.value.getTestData();
</script>

<style lang="scss" scoped>

</style>
