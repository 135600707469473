<template>
	<AssessmentVocabularyHeader
		:index="index"
		:question="question"
		:fromAssessment="fromAssessment"
		:currentWords="currentWords"
		:currentLessonProgressData="currentLessonProgressData"
	/>
	<div
		class="v-scramble flex-wrap px-2 pt-5 lg:flex-nowrap"
		:class="currentLesson.interactive_image ? ['flex', 'items-center'] : []"
		:style="{ pointerEvents: pageDisabled ? 'none' : 'all' }"
	>
		<template v-if="!currentLesson.interactive_image || test">
			<lexical-word-card
				:big="true"
				class="w-10/12 lg:w-8/12 mb-10 mx-auto"
				:word="currentWord"
				:useTranslate="true"
				:showTranslate="false"
				text="text-lg md:text-3xl"
			/>
		</template>
		<div class="v-diagram mr-10 relative" v-else>
			<img
				:src="currentLesson.interactive_image"
				alt=""
				ref="diagram"
				@load="onImageLoad"
				style="max-width: 450px"
			/>
			<div
				class="v-diagram__sign absolute"
				:class="{ active: currentWord && word.id === currentWord.id }"
				:style="{
					top: (word.coordinates ? word.coordinates[1] : 0) + 'px',
					left: (word.coordinates ? word.coordinates[0] : 0) + 'px',
				}"
				v-for="(word, wordIndex) in currentWords"
				:key="word.id"
			></div>
		</div>

		<div class="w-full">
			<div v-if="error" class="v-text--red text-lg text-center my-5">{{ $t('games.click_on_the_letter') }}</div>

			<transition-group
				class="w-full lg:w-full px-5 lg:px-0 flex flex-wrap mx-auto justify-center mb-5"
				:class="{ 'w-full': currentLesson.interactive_image }"
				name="list-item-fast"
				tag="div"
			>
				<div class="p-1" v-for="(letter, letterIndex) in currentWordLetters" :key="'w' + letterIndex">
					<div
						class="v-cell"
						:class="{
							'v-cell--empty': !letter,
							'v-cell--wrong': letter && !isRight(letter, letterIndex),
							'v-cell--right': isRight(letter, letterIndex),
						}"
						@click="remove(letter, letterIndex)"
					>
						{{ letter && letter.value }}
					</div>
				</div>
			</transition-group>

			<transition-group
				class="w-full lg:w-full px-5 lg:px-0 flex flex-wrap mx-auto justify-center"
				:class="{ 'w-full': currentLesson.interactive_image }"
				name="list-complete-fast"
				tag="div"
			>
				<div
					class="p-1 list-complete-fast-item"
					v-for="(letterObject, index) in currentWordSplit"
					:key="'w' + index"
					@click="move(index)"
				>
					<div class="v-cell v-cell--letter v-clickable">{{ letterObject.value }}</div>
				</div>
			</transition-group>

			<div class="flex items-center justify-between my-10 text-gray-500 px-3 md:px-0" v-if="!test">
				<div class="flex items-center cursor-pointer v-clickable" @click="restart(true)">
					<svg width="20" class="mr-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7.96935 1.33349C8.85367 1.33639 9.72863 1.51464 10.5436 1.85793C11.3586 2.20122 12.0974 2.70273 12.7172 3.33344H10.636C10.4592 3.33344 10.2896 3.40368 10.1646 3.5287C10.0395 3.65372 9.96931 3.82329 9.96931 4.00009C9.96931 4.1769 10.0395 4.34646 10.1646 4.47149C10.2896 4.59651 10.4592 4.66674 10.636 4.66674H13.3979C13.7262 4.66657 14.041 4.53608 14.2731 4.30396C14.5052 4.07183 14.6357 3.75705 14.6359 3.42877V0.666835C14.6359 0.490028 14.5656 0.320463 14.4406 0.195442C14.3156 0.0704203 14.146 0.000183968 13.9692 0.000183968C13.7924 0.000183968 13.6228 0.0704203 13.4978 0.195442C13.3728 0.320463 13.3026 0.490028 13.3026 0.666835V2.05214C12.2007 1.05979 10.8443 0.393781 9.38539 0.12868C7.92643 -0.136421 6.42247 0.00985838 5.04194 0.551136C3.66142 1.09241 2.45882 2.00732 1.56884 3.19339C0.678859 4.37947 0.136627 5.78988 0.00286931 7.26668C-0.00574158 7.35952 0.00507977 7.45313 0.0346439 7.54155C0.064208 7.62998 0.111867 7.71127 0.174583 7.78026C0.237299 7.84925 0.313698 7.90442 0.398912 7.94225C0.484126 7.98008 0.576286 7.99975 0.669521 8C0.832576 8.00208 0.990536 7.94323 1.11248 7.83497C1.23443 7.72671 1.31158 7.57683 1.32884 7.41468C1.47724 5.75522 2.2408 4.21122 3.46951 3.086C4.69822 1.96079 6.30327 1.33568 7.96935 1.33349Z"
							fill="#9C9FAD"
						/>
						<path
							d="M15.2699 8C15.1068 7.99792 14.9488 8.05677 14.8269 8.16503C14.7049 8.27329 14.6278 8.42317 14.6105 8.58532C14.5003 9.85411 14.0285 11.0647 13.251 12.0734C12.4735 13.0821 11.423 13.8466 10.2241 14.2762C9.02515 14.7059 7.72816 14.7826 6.48695 14.4973C5.24575 14.2119 4.11244 13.5766 3.22146 12.6666H5.30275C5.47955 12.6666 5.64912 12.5963 5.77414 12.4713C5.89916 12.3463 5.9694 12.1767 5.9694 11.9999C5.9694 11.8231 5.89916 11.6535 5.77414 11.5285C5.64912 11.4035 5.47955 11.3333 5.30275 11.3333H2.54081C2.37821 11.3332 2.21719 11.3651 2.06696 11.4273C1.91672 11.4895 1.78021 11.5807 1.66524 11.6957C1.55026 11.8106 1.45908 11.9471 1.3969 12.0974C1.33471 12.2476 1.30275 12.4086 1.30284 12.5712V15.3332C1.30284 15.51 1.37308 15.6795 1.4981 15.8046C1.62312 15.9296 1.79268 15.9998 1.96949 15.9998C2.1463 15.9998 2.31586 15.9296 2.44088 15.8046C2.56591 15.6795 2.63614 15.51 2.63614 15.3332V13.9479C3.738 14.9402 5.09436 15.6062 6.55332 15.8713C8.01228 16.1364 9.51623 15.9901 10.8968 15.4489C12.2773 14.9076 13.4799 13.9927 14.3699 12.8066C15.2598 11.6205 15.8021 10.2101 15.9358 8.73332C15.9444 8.64048 15.9336 8.54687 15.9041 8.45844C15.8745 8.37002 15.8268 8.28873 15.7641 8.21974C15.7014 8.15075 15.625 8.09558 15.5398 8.05775C15.4546 8.01992 15.3624 8.00025 15.2692 8H15.2699Z"
							fill="#9C9FAD"
						/>
					</svg>
					<div>{{ $t('lesson.restart') }}</div>
				</div>
				<div class="flex items-center cursor-pointer v-clickable" @click="hint">
					<svg width="20" class="mr-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346631 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46143 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.40619 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39252 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76751 14.6647 8 14.6667Z"
							fill="#9C9FAD"
						/>
						<path
							d="M8.478 3.37533C8.09355 3.30529 7.69841 3.32059 7.32053 3.42016C6.94265 3.51973 6.59127 3.70113 6.29126 3.95153C5.99125 4.20194 5.74994 4.51521 5.58441 4.8692C5.41887 5.22318 5.33316 5.60922 5.33334 6C5.33334 6.17681 5.40357 6.34638 5.5286 6.4714C5.65362 6.59643 5.82319 6.66666 6 6.66666C6.17681 6.66666 6.34638 6.59643 6.47141 6.4714C6.59643 6.34638 6.66667 6.17681 6.66667 6C6.6665 5.80385 6.70961 5.61008 6.79293 5.4325C6.87625 5.25493 6.99772 5.09792 7.14868 4.97268C7.29964 4.84745 7.47638 4.75706 7.66628 4.70796C7.85619 4.65887 8.05459 4.65228 8.24733 4.68866C8.51069 4.73979 8.75284 4.86826 8.94283 5.05766C9.13283 5.24706 9.26205 5.4888 9.314 5.752C9.36647 6.02827 9.33024 6.31405 9.2105 6.56849C9.09077 6.82293 8.89365 7.033 8.64733 7.16866C8.23945 7.40497 7.90239 7.74634 7.67128 8.1572C7.44018 8.56806 7.32347 9.03337 7.33333 9.50466V10C7.33333 10.1768 7.40357 10.3464 7.5286 10.4714C7.65362 10.5964 7.82319 10.6667 8 10.6667C8.17681 10.6667 8.34638 10.5964 8.47141 10.4714C8.59643 10.3464 8.66667 10.1768 8.66667 10V9.50466C8.6583 9.27267 8.7112 9.04257 8.82005 8.83753C8.92889 8.63248 9.08983 8.45973 9.28667 8.33666C9.76968 8.07138 10.1586 7.66297 10.4001 7.1676C10.6415 6.67223 10.7235 6.11424 10.6349 5.57035C10.5462 5.02646 10.2912 4.52339 9.905 4.13033C9.51877 3.73726 9.02025 3.47351 8.478 3.37533Z"
							fill="#9C9FAD"
						/>
						<path
							d="M8.66667 12C8.66667 11.6318 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.6318 7.33333 12C7.33333 12.3682 7.63181 12.6667 8 12.6667C8.36819 12.6667 8.66667 12.3682 8.66667 12Z"
							fill="#9C9FAD"
						/>
					</svg>
					<div>{{ $t('lesson.hint') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import WordCard from '@/components/WordCard.vue'
import LexicalWordCard from '@/components/LexicalWordCard.vue'
import { isStringEmpty, preparedLessonForAssessment, stripHtml } from '@/helpers.js'
const props = defineProps({
	test: Boolean,
	fromAssessment: Boolean,
	question: {},
	index: Number,
})
const getCustomQuestionOrder = computed(() => {
	return props.question.size && parseInt(props.question.size[0]) > 0 ? { order: 1 } : { order: 0 }
})
const preparedWords = computed(() => preparedLessonForAssessment(props.question))
import AssessmentVocabularyHeader from '@/screens/AssessmentVocabularyHeader.vue'

import { computed, ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import shuffle from 'lodash/shuffle'
import cloneDeep from 'lodash/cloneDeep'
import useEventBus from '../../../composables/useEventBus.js'
const emitter = useEventBus()
const isNovakid = computed(() => store.getters.isNovakid)
const isInvalid = ref(false)

const store = useStore()

const currentWordLetters = ref([])
const currentWordSplit = ref([])
const currentWordSplitSource = ref([])

const GAME_SLUG = 'scrabble'
const error = ref(false)
const pageDisabled = ref(false)

const imageLoaded = ref(false)
const imageWidth = ref(0)
const imageHeight = ref(0)

const currentLesson = computed(() => store.state.lessons.currentLesson)
const currentWords = computed(() => (props.fromAssessment ? preparedWords.value : store.state.lessons.currentWords))
const currentLessonProgressData = computed(() => store.state.lessons.currentLessonProgressData[GAME_SLUG])

const getShuffledAndFiltered = computed(() => {
	if (!currentWords.value || !currentLessonProgressData.value) return []
	return shuffle(currentWords.value).filter((word) => !currentLessonProgressData.value.words.includes(word.id))
})

const currentWord = computed(() => getShuffledAndFiltered.value[0] || currentWords.value[0])
const getTransProp = computed(() => (currentLesson.value.with_pinyin ? 'pinyin' : 'text'))

const emit = defineEmits()

onMounted(() => initGame())

watch(currentWord, () => initGame(), { deep: true })

function initGame() {
	currentWordSplitSource.value = stripHtml(currentWord.value[getTransProp.value])
		.split('')
		.map((l, i) => ({ value: l, rightIndex: i }))
	currentWordSplit.value = shuffle(cloneDeep(currentWordSplitSource.value))
	currentWordLetters.value = new Array(currentWordSplit.value.length).fill(null)
}

const getTestData = () => {
	isInvalid.value = false

	const valid = !getShuffledAndFiltered.value.length

	if (!valid) isInvalid.value = true
	return {
		answers_pair: [],
		type: 'lexical_check_scramble',
		id: props.question.id,
		empty: !valid,
	}
}

defineExpose({ getTestData })

async function onImageLoad(evt) {
	imageLoaded.value = true
	imageWidth.value = evt.target.width
	imageHeight.value = evt.target.height

	await nextTick()

	registerObserver(evt.target)
	recalculateCoordinates(currentLesson.value.interactive_image_width)
}

function registerObserver(target) {
	if (window.ResizeObserver) {
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (imageWidth.value === entry.contentRect.width) return

				imageWidth.value = entry.contentRect.width
				imageHeight.value = entry.contentRect.height

				setTimeout(() => {
					recalculateCoordinates(currentLesson.value.interactive_image_width)
				}, 500)
			}
		})

		observer.observe(target)
	}
}

function recalculateCoordinates(baseWidth) {
	const coef = imageWidth.value / baseWidth / 0.5
	store.commit('RECALCULATE_COORDINATES', { coef })
}

function listen() {
	const audio = new Audio(currentWord.value.audio)
	audio.play()
}

function isRight(letter, index) {
	return letter && currentWordSplitSource.value[index].value === letter.value
}

function move(i) {
	error.value = false

	const freeIndex = currentWordLetters.value.findIndex((obj) => !obj)
	currentWordLetters.value[freeIndex] = cloneDeep(currentWordSplit.value[i])

	currentWordSplit.value.splice(i, 1)

	if (currentWordLetters.value.every((l, i) => isRight(l, i))) {
		listen()
		pageDisabled.value = true
		setTimeout(async () => {
			store.commit('SAVE_WORD', {
				gameName: GAME_SLUG,
				wordId: currentWord.value.id,
			})

			if (getShuffledAndFiltered.value.length === 0) {
				emitter.emit('v-task-result', { gameName: GAME_SLUG })
				// const { emit } = getCurrentInstance()
				// emit('v-task-result', { gameName: GAME_SLUG })
			}
			pageDisabled.value = false
		}, 500)
	} else if (currentWordSplit.value.length === 0) {
		error.value = true
	}
}

function remove(letter, letterIndex) {
	error.value = false

	if (!letter) return

	currentWordSplit.value.push(cloneDeep(letter))
	currentWordLetters.value[letterIndex] = null
}

function restart() {
	store.commit('RESTART_TASK', { gameName: GAME_SLUG })
	initGame()
}

function hint() {
	store.commit('SAVE_TIP', { wordId: currentWord.value.id, gameName: GAME_SLUG })
	//const { emit } = getCurrentInstance()
	emit('hint', currentWord.value[getTransProp.value])
}
</script>

<style lang="scss" scoped>
.v-cell {
	width: 45px;
	height: 45px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&--empty {
		background-color: #d9dce6; // rgb(195, 195, 195);
	}

	&--wrong,
	&--letter {
		background-color: #fff;
		border: 1px solid #d9dce6;
	}

	&--right {
		background-color: #1fc05d;
		color: #fff;
	}
}

.word-card {
	background-color: #fff;
	border-radius: 8px;
	min-height: 180px;
	position: relative;
	// border-top: 3px solid rgb(0, 158, 224);
	border: 2px solid #d9dce6;

	&:hover {
		z-index: 10;
	}

	&__image {
		width: 180px;
		min-width: 180px;
		height: 100%;
		object-fit: contain;
		border-radius: 8px;
		overflow: hidden;
		transition: all 0.2s ease;
		background-color: #fff;
		transform-origin: 50% 0;

		&:hover {
			transform: scale(2);
			filter: drop-shadow(0, 0, 3px, black);
			border: 1px solid lightgray;
		}

		img {
			// height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	&__listen {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
		min-height: 180px;

		&__image {
			height: 180px;
			min-height: 180px;
			width: 100%;
			margin-right: 0 !important;
			justify-content: center;

			img {
				// width: 100%;
				height: 100%;
			}
		}

		.fullsize-button {
			display: none !important;
		}
	}
}
</style>
