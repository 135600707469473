<template>
  <div class="lesson-header-new-component">
    <div>
      <img class="lesson-header-new__logo" src="../assets/logo_new_ru.png" alt="" />
    </div>
    <div v-if="!isPreview" class="lesson-header-new__close" @click="onCloseClick">
      <div>
        {{$t('home.login_modal.close')}}
      </div>
      <div>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.08709 8.08709C8.4532 7.72097 9.0468 7.72097 9.41291 8.08709L21.9129 20.5871C22.279 20.9532 22.279 21.5468 21.9129 21.9129C21.5468 22.279 20.9532 22.279 20.5871 21.9129L8.08709 9.41291C7.72097 9.0468 7.72097 8.4532 8.08709 8.08709Z" fill="#141029"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9129 8.08709C22.279 8.4532 22.279 9.0468 21.9129 9.41291L9.41291 21.9129C9.0468 22.279 8.4532 22.279 8.08709 21.9129C7.72097 21.5468 7.72097 20.9532 8.08709 20.5871L20.5871 8.08709C20.9532 7.72097 21.5468 7.72097 21.9129 8.08709Z" fill="#141029"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {useRoute, useRouter} from "vue-router";
export default {
  name: "LessonHeaderNew",
  setup() {
    const route = useRoute();
    const router = useRouter()
    return {
      route,
      router
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
    isPreview() {
      const query = this.route.query;
      return !!(query && query.onlypreview === 'true' && query.slug && query.slug.length);
    },
  },
  methods: {
    ...mapActions(['SET_USER_LOCALE']),
    onCloseClick() {
      this.router.push({name: "home"});
    },
    async handleLanguage({ key }) {
      try {
        await this.SET_USER_LOCALE(key);
      } catch (err) {
        console.log('err', err);
      }

      // this.$root.$i18n.locale = key;
    }
  },
  created() {
    this.handleLanguage({key: this.profile.locale || 'ru'});
    const domainsToSetEnglish = ["vznaniya.com", "http://vznaniya.com", "https://vznaniya.com"];
    if (this.getPlatform && domainsToSetEnglish.includes(this.getPlatform.domain)) {
      this.handleLanguage({key: 'en'})
    }
  }
}
</script>

<style scoped lang="scss">
.lesson-header-new-component {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 60px;
  font-family: Montserrat, Comfortaa-Regular, serif !important;

  * {
    font-family: Montserrat, Comfortaa-Regular, serif !important;
  }

  .lesson-header-new {
    &__logo {
      width: 167px;
      height: 38px;
    }
    &__close {
      display: flex;
      align-items: center;
      gap: 13px;
      cursor: pointer;
      user-select: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .lesson-header-new-component {
    padding: 26px 20px;
  }
}
</style>
