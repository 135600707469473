const STORAGE_KEY = 'iconDisplay';

// Функция получения хранилища из localStorage
const loadIconStorage = () => {
	const storedData = localStorage.getItem(STORAGE_KEY);
	return storedData ? JSON.parse(storedData) : {};
};

// Инициализация хранилища
let iconStorage = loadIconStorage();

// Функция сохранения в localStorage
const saveIconStorage = () => {
	localStorage.setItem(STORAGE_KEY, JSON.stringify(iconStorage));
};

export const getCachedIcon = (path) => {
	return iconStorage[path] || null;
};

export const setCachedIcon = (path, svgData) => {
	iconStorage[path] = svgData;
	saveIconStorage(); // Сохраняем обновленное хранилище
};