<template>
  <div class="assessment-comment-component__chat-content">
    <div class="assessment-comment-component__chat-user">
      <div class="assessment-comment-component__chat-user__info">
        <div class="assessment-comment-component__chat-user__avatar">
          <img v-if="userData.avatar" :src="userData.avatar" alt="" />
        </div>
        <div class="assessment-comment-component__chat-user__name">
          {{ userData.name }}
        </div>
      </div>
      <button
        v-if="showDeleteButton"
        class="assessment-comment-component__chat-user__delete-button"
        @click="$emit('delete', comment.id)"
      >
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.75 7.28906C22.7283 7.28906 22.6958 7.28906 22.6633 7.28906C16.9325 6.71489 11.2125 6.49822 5.54664 7.07239L3.33664 7.28906C2.88164 7.33239 2.4808 7.00739 2.43747 6.55239C2.39414 6.09739 2.71914 5.70739 3.1633 5.66406L5.3733 5.44739C11.1366 4.86239 16.9758 5.08989 22.8258 5.66406C23.27 5.70739 23.595 6.10822 23.5516 6.55239C23.5191 6.97489 23.1616 7.28906 22.75 7.28906Z"
            fill="#9C42F5"
          />
          <path
            d="M9.20784 6.19797C9.1645 6.19797 9.12117 6.19797 9.067 6.18714C8.63367 6.1113 8.33034 5.6888 8.40617 5.25547L8.6445 3.8363C8.81784 2.7963 9.05617 1.35547 11.5803 1.35547H14.4187C16.9537 1.35547 17.192 2.85047 17.3545 3.84714L17.5928 5.25547C17.6687 5.69964 17.3653 6.12214 16.932 6.18714C16.4878 6.26297 16.0653 5.95964 16.0003 5.5263L15.762 4.11797C15.6103 3.17547 15.5778 2.9913 14.4295 2.9913H11.5912C10.4428 2.9913 10.4212 3.14297 10.2587 4.10714L10.0095 5.51547C9.9445 5.9163 9.59784 6.19797 9.20784 6.19797Z"
            fill="#9C42F5"
          />
          <path
            d="M16.4766 24.6475H9.52162C5.74078 24.6475 5.58912 22.5567 5.46995 20.8667L4.76578 9.9575C4.73328 9.51333 5.07995 9.12334 5.52412 9.09084C5.97912 9.06917 6.35828 9.405 6.39078 9.84917L7.09495 20.7583C7.21412 22.405 7.25745 23.0225 9.52162 23.0225H16.4766C18.7516 23.0225 18.795 22.405 18.9033 20.7583L19.6075 9.84917C19.64 9.405 20.03 9.06917 20.4741 9.09084C20.9183 9.12334 21.265 9.5025 21.2325 9.9575L20.5283 20.8667C20.4091 22.5567 20.2575 24.6475 16.4766 24.6475Z"
            fill="#9C42F5"
          />
          <path
            d="M14.7989 18.6875H11.1914C10.7472 18.6875 10.3789 18.3192 10.3789 17.875C10.3789 17.4308 10.7472 17.0625 11.1914 17.0625H14.7989C15.2431 17.0625 15.6114 17.4308 15.6114 17.875C15.6114 18.3192 15.2431 18.6875 14.7989 18.6875Z"
            fill="#9C42F5"
          />
          <path
            d="M15.7077 14.3555H10.291C9.84685 14.3555 9.47852 13.9871 9.47852 13.543C9.47852 13.0988 9.84685 12.7305 10.291 12.7305H15.7077C16.1518 12.7305 16.5202 13.0988 16.5202 13.543C16.5202 13.9871 16.1518 14.3555 15.7077 14.3555Z"
            fill="#9C42F5"
          />
        </svg>
      </button>
    </div>
    <div v-if="!isAudio && comment.message.length" class="assessment-comment-component__chat-message">
      {{ comment.message }}
    </div>
    <div v-else class="assessment-comment-component__chat-audio">
      <assessment-comment-audio :src="audioSrc"/>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AssessmentCommentAudio from "../AssessmentCommentAudio.vue";

export default {
  components: {AssessmentCommentAudio},
  props: {
    comment: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
    userData() {
      const data = {
        name: "Username",
        avatar: "",
      };
      if (this.comment.user_info.name || this.comment.user_info.surname) {
        data.name = `${this.comment.user_info.name ?? ""} ${
          this.comment.user_info.surname ?? ""
        }`.trim();
      }
      if (this.comment.user_info.avatar_150x150?.length) {
        data.avatar = this.comment.user_info.avatar_150x150;
      }
      return data;
    },
    showDeleteButton() {
      return this.comment.user_id === this.profile.id
    },
    isAudio() {
      return (
        (!this.comment.message || !this.comment.message.trim().length) &&
        this.comment.illustrations && this.comment.illustrations.length === 1
      );
    },
    audioSrc() {
      if (!this.comment || !this.comment.illustrations || !this.comment.illustrations.length) return "";
      const audio =
        this.comment.illustrations.find((item) => {
          const pathSplit = item.path.split(".");
          return pathSplit[pathSplit.length - 1] === "mp3";
        }) || [];
      return audio ? audio.path : "";
    }
  }
}
</script>

<style scoped lang="scss">

</style>