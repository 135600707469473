<template>
  <div class="flex about-section py-5">
    <div class="about-section__bottom"></div>
    <div class="m-container mx-auto">

      <div class="relative inline-flex mb-10">
        <img src="@/assets/marathon/about.svg" alt="">
        <div class="absolute text-4xl whitespace-no-wrap about-section__title v-text--blue">{{ $t('marathon.about_marathon') }}</div>
      </div>

      <div class="flex items-center block-move-top">
        <img class="w-1/2 pr-10 w-5/12" src="@/assets/marathon/photo_kids.png" alt="">
        <div class="flex flex-col items-center w-7/12">
          <div class="flex w-full justify-between items-center mb-5">
            <div class="w-5/12 mr-5 text-2xl text-right whitespace-no-wrap" v-html="$t('marathon.question_1')"></div>
            <div class="w-1/12 relative flex justify-center mr-5">
              <img src="https://vznaniya.ru/20200/img/svg/about-icons/romb.svg" alt="">
              <img class="absolute-center" src="https://vznaniya.ru/20200/img/svg/about-icons/question.svg" alt="">
            </div>
            <div class="w-6/12">{{ $t('marathon.answer_1') }}</div>
          </div>
          <div class="flex w-full justify-between items-center mb-5">
            <div class="w-5/12 mr-5 text-2xl text-right whitespace-no-wrap" v-html="$t('marathon.question_2')"></div>
            <div class="w-1/12 relative flex justify-center  mr-5">
              <img src="https://vznaniya.ru/20200/img/svg/about-icons/romb.svg" alt="">
              <img class="absolute-center" src="https://vznaniya.ru/20200/img/svg/about-icons/attention.svg" alt="">
            </div>
            <div class="w-6/12">{{ $t('marathon.answer_2') }}</div>
          </div>
          <div class="flex w-full justify-between items-center mb-5">
            <div class="w-5/12 mr-5 text-2xl text-right whitespace-no-wrap" v-html="$t('marathon.question_3')"></div>
            <div class="w-1/12 relative flex justify-center  mr-5">
              <img src="https://vznaniya.ru/20200/img/svg/about-icons/romb.svg" alt="">
              <img class="absolute-center" src="https://vznaniya.ru/20200/img/svg/about-icons/roket.svg" alt="">
            </div>
            <div class="w-6/12"><a :class="profile.marathon_is_available ? [] : ['cursor-pointer', 'link']" @click="createMarathonPayment">{{ $t('marathon.pay') }}</a> {{ $t('marathon.answer_3') }}</div>
          </div>
          <div class="flex w-full justify-between items-center mb-5">
            <div class="w-5/12 mr-5 text-2xl text-right whitespace-no-wrap" v-html="$t('marathon.question_4')"></div>
            <div class="w-1/12 relative flex justify-center  mr-5">
              <img src="https://vznaniya.ru/20200/img/svg/about-icons/romb.svg" alt="">
              <img class="absolute-center" src="https://vznaniya.ru/20200/img/svg/about-icons/flag.svg" alt="">
            </div>
            <div class="w-6/12">{{ $t('marathon.answer_4') }}</div>
          </div>

          <div class="m-button cursor-pointer v-clickable text-xl self-end mr-5 mt-5" v-if="!profile.marathon_is_available" @click="createMarathonPayment">{{ $t('marathon.action') }}</div>
          <div v-else class="s-button s-button--orange self-end mr-5 mt-5 mb-10" @click="handleGetCertificate">
            {{ loadingCerificate ? $t('marathon.loading') : $t('marathon.certificate') }}
            <svg height="40px" class="ml-2 py-2" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fff"
              d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { payMarathon, getCertificate } from '../../api/marathon';

export default {
  data: () => ({
    loadingCerificate: false,
  }),
  methods: {
    async handleGetCertificate() {
      try {
        this.loadingCerificate = true;
        const { data: path } = await getCertificate();
        window.open(BASE_URL + '/storage/' + path)
      } catch (err) {
        console.log('err', err);
      } finally {
        this.loadingCerificate = false;
      }
    },
    async createMarathonPayment() {
      try {
        if (this.profile.marathon_is_available) return;

        const { data } = await payMarathon();
        window.location.href = data.data.confirmation_url;
      } catch (err) {
        console.log('Payment create error; Type: marathon; err: ', err);
      }
    },
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    })
  }
}
</script>

<style lang="scss" scoped>
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-section {
  // min-height: 100vh;
  background-color: #DBDAD8;
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    bottom: 25px;
    right: 25px;
    transform: translateX(100%);
    background-color: #DBDAD8;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    transform: translateY(100%);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: 0;
      transform: scaleY(-1);
      border-bottom: 50px solid #DBDAD8;
      border-left: 400px solid transparent;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      transform: scaleY(-1);
      border-bottom: 50px solid #DBDAD8;
      border-right: 400px solid transparent;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    border-bottom: 50px solid #DBDAD8;
    border-left: 400px solid transparent;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    transform: translateY(-100%);
    border-bottom: 50px solid #DBDAD8;
    border-right: 400px solid transparent;
  }
}

.m-button {
  padding: 10px 25px;
  background-color: #EC591D;
  color: #fff;
  border-radius: 20px;

  &:hover {
    background-color: #27A34C;
  }
}

.link {
  color: rgb(0, 174, 255);
}

.block-move-top {
  transform: translateY(-5%);
}
</style>
