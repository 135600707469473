import apiRequest from "./apiRequest.js";

import { withParams, withFilters } from "../helpers/apiHelper.js";

export const getFolderData = async (folderId, params = {}) => {
  return apiRequest.get(`folders/data/${folderId}`, { params });
};

export const getGroupSections = async (groupId) => {
  return apiRequest.get(`groups/${groupId}/section`);
};

export const getSectionLessons = async (groupId, sectionId) => {
  return await apiRequest.get(`groups/${groupId}/section/${sectionId}/lesson`);
};

export const getLessonsWithoutSections = async (groupId) => {
  return await apiRequest.get(`groups/${groupId}/lesson?withoutSection=true`);
};
