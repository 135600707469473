// import Vuex from 'vuex';
import { createStore } from 'vuex'
import Vue from 'vue'
// import { createLogger } from '../plugins/logger';

import user from './modules/user.js'
import lessons from './modules/lessons.js'
import base from './modules/base.js'
import folders from './modules/folders.js'
import platform from './modules/platform.js'
import challenge from './modules/challenge.js'
import messages from './modules/messages.js'

const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
	modules: {
		user,
		lessons,
		base,
		folders,
		platform,
		challenge,
		messages,
	},
	strict: false, // debug,
	// plugins: debug ? [createLogger()] : [],
})
