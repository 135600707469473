<template>
	<div class="w-full">
		<div class="v-listen p-1" v-if="currentSets.length > 0">
			<div v-for="set in currentSets" :key="set.word.id">
				<div class="w-full flex justify-center my-2">
					<LexicalWordCard
						class="w-10/12 lg:w-8/12"
						:word="set.word"
						:showTranslate="false"
						text="text-lg md:text-3xl"
						:showIllustration="false"
					/>
				</div>

				<transition-group class="flex flex-wrap w-full lg:w-full mx-auto mb-10" name="list-complete" tag="div">
					<div class="p-1 lg:p-2 w-1/2 md:w-1/4 list-complete-item" v-for="word in set.words" :key="word.id">
						111111111
						<LexicalWordCard
							:big="true"
							class="cursor-pointer v-clickable h-full"
							:word="word"
							:useTranslate="true"
							:showTranslate="false"
							:adaptiveVerticalIllustration="true"
							:verticalIllustration="true"
							:selected="set.selected == word.id"
							@click="handleClick(set.word, word)"
						/>
					</div>
				</transition-group>

				<div v-if="set.error" class="v-text--red text-lg text-center my-5">{{ $t('lesson.choose') }}</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import LexicalWordCard from '../../../components/LexicalWordCard.vue'

import { useStore } from 'vuex'
import shuffle from 'lodash/shuffle'
import sampleSize from 'lodash/sampleSize'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
	words: {
		type: Array,
		default: () => [],
	},
})

const store = useStore()

const isListen = ref(false)
const isShowListen = ref(false)
const GAME_SLUG = 'quiz'
const currentSets = ref([])

const currentWords = computed(() => store.getters.getCurrentWords)

const validateTest = () => {
	currentSets.value.forEach((set) => {
		$set(set, 'error', false)
	})

	return currentSets.value.every((set) => {
		if (set.selected) {
			return true
		}
		$set(set, 'error', true)
		return false
	})
}

const getTestData = () => {
	return currentSets.value.map((set) => ({
		id: set.word.id,
		test_type: GAME_SLUG,
		answer_id: set.selected,
	}))
}

const playSound = () => {
	const audio = new Audio(getCurrentSet.word.audio)
	audio.play()
}

const handleClick = (sourceWord, word) => {
	const currentSet = currentSets.value.find((el) => el.word.id == sourceWord.id)
	currentSet.selected = word.id
}

onMounted(() => {
	currentSets.value = shuffle(props.words).map((word) => {
		return {
			error: false,
			selected: null,
			word,
			words: shuffle([
				word,
				...sampleSize(
					currentWords.value.filter((_word) => _word.id !== word.id),
					3
				),
			]),
		}
	})
})
</script>

<style lang="scss" scoped></style>
