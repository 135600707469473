export default {
  personal_statistics: "Personal statistics",
  status: "Status",
  words_left:
    "Words left to take it to the next level",
  words_learnt: "Words learnt",
  sets_completed: "sets completed",
  superpower: "Superpower",
  your_average: "Your average test result",
  superpowers: "Superpowers",
  the_percentage:
    "The percentage of improving your test results",


  newbie: 'Newbie',
  beginner: 'Beginner',
  intermediate_user: 'Intermediate user',
  upper_intermediate_user: 'Upper-intermediate user',
  advanced_user: 'Advanced user',
  expert: 'Expert',
  statistics: 'Statistics',
  realtime_rating_update: "The rating updates in real time",
  not_marathons: 'No active marathons'
}