<script>
import SvgClose from "@/components/modals/FinalModalNovakid/icons/SvgClose.vue";
// import SvgPrize from "@/components/modals/FinalModalNovakid/icons/SvgPrize.vue";

export default {
  components: {SvgClose},
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
}
</script>

<template>
  <div class="v-modal-wrapper novakid-modal__wrapper">
    <div class="v-modal novakid-modal">
      <span class="v-modal__cross" @click="$emit('close')">
        <svg-close />
      </span>
      <div class="novakid-modal__body">
        <div class="novakid-modal__header">Congratulations!</div>
        <div class="novakid-modal__header">Congratulations!</div>
        <div class="novakid-modal__description">You won {{count}} stars for this task! Keep it up and let’s do the next lesson!</div>
        <span @click="$emit('next')" class="novakid-modal__button">
          Finish
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v-modal-wrapper {
  transform: translate3d(0,0,0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
}

.v-modal {
  transform: translate3d(0,0,0);
  width: 100%;
  max-width: 700px;
  padding: 40px 50px 50px;
  background: #f1f1f1 url(https://vznaniya.ru/img/modal/modal-bg.jpg) repeat 50%;
  max-height: 90vh;
  overflow-y: scroll;
  display: block;
  -webkit-overflow-scrolling: touch;

  &__form {
    padding: 0 3.4375rem;
    width: 100%;
  }

  &__footer {
    font-size: 1.25rem;
    line-height: 1.1;
    text-align: center;
  }
}


.novakid-modal {
  border-radius: 12px;
  background: url('/images/final-modal-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  padding-top: 0;
  width: 900px;
  height: 320px;

  & .v-modal__cross {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(226, 225, 231, 1);
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 9;
  }

  &__header {
    color: #FAFAFA;
    font-family: Mikado;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 20px;
  }

  &__description {
    max-width: 60%;
    color: #FAFAFA;
    font-family: Mikado;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 70px;
  }

  &__body {
    padding: 60px 40px;
    min-height: 100%;
  }

  &__button {
    cursor: pointer;
    padding: 20px 120px;
    border-radius: 8px;
    background: var(--main-yellow, #FFE60A);
    color: var(--main-purple, #6E46FF);
    text-align: center;
    font-family: Mikado;
  }
}

@media screen and (max-width: 690px) {
  .novakid-modal {
    &__description {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .novakid-modal {
    background-size: 100%;

    & .v-modal__cross {
      width: 30px;
      height: 30px;
      top: 20px;
      right: 25px;
    }

    &__body {
      padding: 38px 23px;
      min-height: 100%;
    }

    &__header {
      font-size: 20px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__button {
      padding: 10px 50px;
    }
  }
}

@media screen and (max-width: 500px) {
  .novakid-modal {
    background-size: 100% 77%;

    &__body {
      padding: 38px 0;
      min-height: 100%;
    }

    &__header {
      font-size: 18px;
      //margin-bottom: 10px;
    }

    &__description {
      max-width: 75%;
      font-size: 12px;
      margin-bottom: 10px;
    }

    &__button {
      padding: 10px 50px;
    }
  }
}
</style>
