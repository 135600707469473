<template>
	<div
		class="v-word px-2 py-2 border-2 bg-white rounded-xl border-gray-x flex flex-col justify-between w-full"
		:class="{
			medium,
			right,
			wrong,
			selected,
			big,
			onlyimage: !(useTranslate ? word.translate : word.text),
			'adaptive-vertical': adaptiveVerticalIllustration,
			vertical: verticalIllustration,
			'no-pulse': noPulse,
			mobile: isNativeMobile,
			'from-assessment': fromAssessment,
		}"
	>
		<div
			class="w-full flex align-center v-word__wrapper flex-grow"
			:class="{
				'mb-2': showTranslate && word.translate,
				'justify-center': !(useTranslate ? word.translate : word.text),
			}"
		>
			<div class="relative v-word__ill-wrapper mr-2">
				<div class="v-word__back flex justify-center items-center" v-if="word.illustration && showIllustration">
					<img class="rounded" v-if="word.type === 0" :src="word.illustration" />
					<video
						disablePictureInPicture
						class="rounded"
						v-else-if="word.type === 1"
						:src="word.illustration"
						autoplay
						loop
						muted
					></video>
				</div>
				<div
					class="v-word__ill relative flex justify-center items-center"
					v-if="word.illustration && showIllustration"
				>
					<img class="rounded" v-if="word.type === 0" :src="word.illustration" />
					<video
						disablePictureInPicture
						class="rounded"
						v-else-if="word.type === 1"
						:src="word.illustration"
						autoplay
						loop
						muted
					></video>
				</div>
			</div>
			<div
				class="v-word__text flex-grow items-center"
				style="display: flex; align-content: center"
				v-if="useTranslate ? word.translate : word.text"
				:class="(text ? text : 'text-lg') + ` ${textCenter ? 'text-center justify-center' : ''}`"
				v-html="
					useTranslate
						? word.translate
						: word.text + (showPinyin && word.pinyin ? ' (' + word.pinyin + ')' : '')
				"
			></div>
			<LexicalAudioButton
				:src="word.audio"
				:disabled="showAudioButtonShadow"
				class="v-word__audio-button"
				:class="fromAssessment ? 'ml-auto' : 'ml-2'"
				v-if="
					(!word.illustration || !showIllustration || !word.translate) &&
					(!showTranslate || !word.translate) &&
					(showAudioButton || showAudioButtonShadow)
				"
			/>
		</div>
		<div
			class="flex items-center text-gray-500 mt-1 v-word__footer"
			v-if="(showTranslate && word.translate) || dynamicAudio"
		>
			<div class="flex items-center flex-grow v-word__footer-translate" v-if="showTranslate">
				<svg
					v-if="checkTranslateInTag(word.translate)"
					width="22"
					class="mr-1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 24 24"
				>
					<path
						d="M20 5h-9.12L10 2H4c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h7l1 3h8c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zM7.17 14.59c-2.25 0-4.09-1.83-4.09-4.09s1.83-4.09 4.09-4.09c1.04 0 1.99.37 2.74 1.07l.07.06l-1.23 1.18l-.06-.05c-.29-.27-.78-.59-1.52-.59c-1.31 0-2.38 1.09-2.38 2.42s1.07 2.42 2.38 2.42c1.37 0 1.96-.87 2.12-1.46H7.08V9.91h3.95l.01.07c.04.21.05.4.05.61c0 2.35-1.61 4-3.92 4zm6.03-1.71c.33.6.74 1.18 1.19 1.7l-.54.53l-.65-2.23zm.77-.76h-.99l-.31-1.04h3.99s-.34 1.31-1.56 2.74c-.52-.62-.89-1.23-1.13-1.7zM21 20c0 .55-.45 1-1 1h-7l2-2l-.81-2.77l.92-.92L17.79 18l.73-.73l-2.71-2.68c.9-1.03 1.6-2.25 1.92-3.51H19v-1.04h-3.64V9h-1.04v1.04h-1.96L11.18 6H20c.55 0 1 .45 1 1v13z"
						fill="currentColor"
					></path>
				</svg>
				<div class="flex-grow text-md" v-html="word.translate"></div>
			</div>
			<div v-if="dynamicAudio" class="mt-2 flex w-full justify-center">
				<div class="vzn-button" @click.stop="$emit('cancelDynamic')">Отменить</div>
			</div>
			<LexicalAudioButton
				:src="dynamicAudio || word.audio"
				:class="{ dynamic: dynamicAudio }"
				:disabled="showAudioButtonShadow"
				class="ml-2"
				v-if="(showTranslate && showAudioButton) || dynamicAudio"
			/>
		</div>
	</div>
</template>

<script>
import LexicalAudioButton from './LexicalAudioButton.vue'
import { checkString } from '../helpers.js'
export default {
	components: { LexicalAudioButton },
	props: {
		word: Object,

		showTranslate: {
			type: Boolean,
			default: true,
		},
		showText: {
			type: Boolean,
			default: true,
		},
		showIllustration: {
			type: Boolean,
			default: true,
		},
		noPulse: Boolean,
		hideTranslate: Boolean,
		useTranslate: Boolean,
		showAudioButton: Boolean,
		showAudioButtonShadow: Boolean,
		verticalIllustration: Boolean,
		adaptiveVerticalIllustration: Boolean,
		statusMode: Boolean,
		showPinyin: {
			type: Boolean,
			default: false,
		},
		textCenter: {
			type: Boolean,
			default: true,
		},

		medium: Boolean,
		big: Boolean,

		right: Boolean,
		wrong: Boolean,
		selected: Boolean,
		text: String,

		dynamicAudio: [Boolean, String],
		fromAssessment: Boolean,
	},
	computed: {
		symbolMode() {
			return this.lesson && !this.lesson.with_pinyin && this.isChinese
		},

		isChinese() {
			return this.lesson && this.lesson.language === 'CN'
		},

		isNativeMobile() {
			return window.isNativeMobile
		},
	},
	methods: {
		checkTranslateInTag(str) {
			return checkString(str)
		},
	},
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
	.v-word {
		.v-word__text {
			font-size: 16px !important;
		}
	}
}
.dynamic {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(25%, -25%);
	z-index: 1;
}
.v-word {
	padding-bottom: 25px;
	line-height: 1.1;
	// transition: all .3s ease;
	min-height: 100px;
	position: relative;
	&__audio-button {
		position: absolute;
		right: 8px;
		bottom: 8px;
	}
	.v-word__wrapper {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.v-word__text {
		text-align: center;
		max-width: 100%;
		word-wrap: break-word;
		font-size: clamp(14px, 3vw, 20px);
		padding-right: 30px;
	}
	.v-word__footer {
		&-translate {
			svg {
				min-width: 22px;
			}
		}
	}
	&.from-assessment {
		height: 100%;
		.v-word__wrapper {
			flex-direction: column;
			.v-word__ill,
			.v-word__ill img {
				width: 150px;
				height: 150px;
				max-width: 150px;
				max-height: 150px;
				object-fit: contain;
			}
		}
		.v-word__footer {
			width: 100%;
			justify-content: space-between;
			&-translate {
				max-width: 70%;
			}
		}
	}
	&.from-assessment {
		height: 100%;
		.v-word__wrapper {
			flex-direction: column;
			.v-word__ill,
			.v-word__ill img {
				width: 150px;
				height: 150px;
				max-width: 150px;
				max-height: 150px;
				object-fit: contain;
			}
		}
		.v-word__footer {
			width: 100%;
			justify-content: space-between;
			&-translate {
				max-width: 70%;
			}
		}
	}

	img,
	video {
		user-select: none;
		pointer-events: none;
	}

	&__back,
	&__ill {
		width: 200px;
		height: 200px;

		img,
		video {
			width: 200px;
			height: 200px;
		}
	}

	&__back {
		position: absolute;
		top: 0;
		left: 0;
		filter: blur(2px);
		opacity: 0.3;

		img,
		video {
			max-width: unset;
			max-height: unset;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.medium {
		padding: 4px !important;
		.v-word__ill {
			width: 170px;
			height: 170px;

			img,
			video {
				max-width: 170px;
				max-height: 170px;
			}
		}

		.v-word__back {
			width: 170px;
			height: 170px;
		}

		.v-word__text {
			font-size: 14px;
		}
	}

	&.big {
		@media screen and (min-width: 768px) {
			min-height: 170px;

			.v-word__ill {
				width: 250px;
				height: 250px;

				img,
				video {
					max-width: 250px;
					max-height: 250px;
				}
			}

			.v-word__back {
				// width: 250px;
				// height: 250px;
				display: none;
			}
		}
	}

	&.onlyimage {
		.v-word__ill {
			width: auto;
			img,
			video {
				max-width: 100%;
			}
		}

		.v-word__back {
			width: auto;
			display: none;
		}
	}

	@mixin forvertical {
		.v-word__wrapper {
			flex-direction: column;
		}
		.v-word__ill-wrapper {
			margin-right: 0px;
			margin-bottom: 5px;
			width: 100%;

			.v-word__ill,
			.v-word__back {
				width: 100%;
				display: none;
				img,
				video {
					max-height: 100%;
					max-width: 100%;
				}
			}
		}
	}

	&.vertical {
		@include forvertical;
	}
	&.adaptive-vertical {
		@media screen and (max-width: 768px) {
			@include forvertical;
		}
	}

	@mixin pulse($name, $color, $count: 1) {
		animation: #{$name} 2s $count;
		@keyframes #{$name} {
			0% {
				box-shadow: 0 0 0 0 rgba($color: $color, $alpha: 0.4);
			}
			70% {
				box-shadow: 0 0 0 20px rgba($color: $color, $alpha: 0);
			}
			100% {
				box-shadow: 0 0 0 0 rgba($color: $color, $alpha: 0);
			}
		}
	}

	&.selected {
		$orange: rgba(243, 212, 6, 1);
		border: 3px solid $orange;

		&:not(.no-pulse):not(.mobile) {
			@include pulse(pulse-selected, $orange, infinite);
		}
	}

	&.wrong {
		$red: rgb(236, 103, 68);
		border: 3px solid $red;

		&:not(.no-pulse):not(.mobile) {
			@include pulse(pulse-wrong, $red, 1);
		}
	}

	&.right {
		$green: #1fc05d;
		border: 3px solid $green;

		&:not(.no-pulse):not(.mobile) {
			@include pulse(pulse-right, $green, 1);
		}
	}
}
</style>

<style lang="scss">
.vzn-height-inherit {
	height: inherit;
}
</style>
