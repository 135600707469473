<template>
  <div class="v-selector" :class="{ active, blue }">
    <div class="v-selector__header flex justify-between items-center" @click="active ^= true">
      <div class="v-text--gray text-lg">{{ syncCurrentOption.value }}</div>
      <img class="h-3" src="@/assets/marathon/arrow.svg" alt="">
    </div>
    <div class="v-selector__body" v-if="active">
      <div class="v-selector__body-item" :class="{ disabled: withDisabled && option.disabled }" v-for="(option, index) in options" :key="index" @click="select(option)">{{ option.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: [],
    },
    currentOption: {
      type: Object,
      default: () => ({}),
    },
    blue: Boolean,
    withDisabled: Boolean,
  },
  data: () => ({
    active: false,
  }),
  computed: {
    syncCurrentOption: {
      get() { return this.currentOption },
      set(value) { this.$emit('update:currentOption', value) }
    }
  },
  methods: {
    select(option) {
      this.syncCurrentOption = option;
      this.active = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-selector {
  width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  z-index: 100;

  &.active {
    background-color: #DADADA;
  }

  &.blue &__header {
    border-bottom-color: #1D92DB;
  }

  &__header {
    border-bottom: 5px solid rgba(128, 128, 128, 0.753);
    padding-bottom: 10px;
    cursor: pointer;
  }

  &__body {
    background-color: #DADADA;
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    padding: 0 0 10px;
    color: gray;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__body-item {
    padding: 10px 15px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &:hover {
      background-color: #1D92DB;
      color: #fff;
    }
  }
}
</style>
