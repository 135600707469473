export default {
    join_challenge: 'Присоединиться к челленджу',
    comment: 'Комментарий',
    create: 'Отправка работы',
    edit: 'Редактирование работы',
    upload_media: 'Перетащите или нажмите сюда для загрузки изображения или видео',
    less_than: 'не более',
    write_title: 'Напишите название',
    write_description: 'Описание работы',
    save: "Сохранить",
    delete: "Удалить",
    edit_post: "Редактировать",
    name_field: "Название",
    text_field: "Текст",
    empty_posts: "Здесь будут добавлены работы участников",
    input_radio: {
        title: "Работы участников",
        latest: "Последние",
        popular: "Популярные"
    },
    validation_error: {
        title: "Поле должно содержать 3 или более символов",
        post: "Поле не может быть пустым",
        file: "Размер файла слишком большой",
        upload: "Размер файла слишком велик! Используйте файлы размером не более 15 МБ!",
        extension: "Расширение файла запрещено! Используйте файлы следующих расширений: jpg, jpeg, png, mp4",
        file_empty: "Файл не выбран! Пожалуйста, выберите файл."
    },
    show_more: "Показать еще",
    hide_text: "Скрыть",
}