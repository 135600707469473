<template>
	<div class="flex items-center justify-center pt-5 flex-wrap" v-if="currentSets.length > 0">
		<div v-for="set in currentSets" :key="set.word.id" class="w-full lg:w-1/2 p-1 mb-5">
			<div class="flex items-center justify-center mb-5">
				<LexicalAudioButton :big="true" class="mx-3" @click="handleAudio(set.word)" />
				<LexicalAudioButton
					:big="true"
					class="shadow-md mx-3"
					:slow="true"
					@click="handleAudio(set.word, true)"
				/>
			</div>
			<div class="w-full flex jsutify-center p-2">
				<div class="w-full flex items-center mx-auto">
					<input
						type="text"
						autocomplete="on"
						autocapitalize="on"
						autocorrect="on"
						spellcheck="true"
						style="display: none"
					/>
					<input
						v-if="!symbolMode"
						autocomplete="off"
						spellcheck="false"
						autocapitalize="off"
						autocorrect="off"
						class="w-full text-2xl text-center Raleway-Medium v-input-border"
						type="text"
						placeholder="Введите, что слышите"
						:ref="`input-${set.word.id}`"
						v-model="set.answer"
						@paste.prevent
					/>
					<ChinesTypeChar
						v-else
						:uid="set.word.id"
						:text="set.word.text"
						@done="set.answer = set.word.text"
					/>
				</div>
			</div>

			<SpecialCharacters class="mb-10 w-full" :lesson="currentLesson" @char="(char) => addChar(char, set)" />

			<div v-if="set.error" class="v-text--red text-lg text-center my-5">
				{{ $t('games.type_in_the_translation') }}
			</div>
		</div>
	</div>
</template>

<script setup>
import {useStore} from 'vuex'
import shuffle from 'lodash/shuffle'
import LexicalAudioButton from '../../../components/LexicalAudioButton.vue'
import SpecialCharacters from '../../../components/lesson/SpecialCharacters.vue'
import ChinesTypeChar from '../../../components/lesson/ChinesTypeChar.vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import helpers from "@/helpers.js";

const store = useStore();
const props = defineProps({
  words: {
    type: Array,
    default: () => [],
  },
})

const GAME_SLUG = 'listen_type';
const currentSets = ref([]);

const currentLesson = computed(() => store.getters.getCurrentLesson);
const needSpecialCharacter = computed(() => helpers.specialSymbols[currentLesson.language])
const getSpecialCharacter = computed(() => {
  const type = needSpecialCharacter ? 'uppercase' : 'lowercase'
  return helpers.specialSymbols[currentLesson.language][type]
})
const symbolMode = computed(() => currentLesson.with_pinyin && isChinese);
const isChinese = computed(() => currentLesson.language === 'CN');
const getTransProp = computed(() => currentLesson.with_pinyin ? 'pinyin' : 'text');
const isTrueMobile = computed(() => window.isTrueMobile);
const instance = getCurrentInstance()
const refs = instance.proxy?.$refs

const validateTest = () => {
  currentSets.value.forEach((set) => {
    $set(set, 'error', false)
  })

  return currentSets.value.every((set) => {
    if (!!set.answer.trim()) {
      return true
    }
    $set(set, 'error', true)
    return false
  })
}

const getTestData = () => currentSets.value.map((set) => ({
  id: set.word.id,
  test_type: GAME_SLUG,
  text: set.answer,
}))

const handleAudio = (word, slow) => {
  if (slow) listen(word, true)
  else listen(word)
}

const listen = (word, slow = false) =>  {
  const audio = new Audio(word.audio)
  if (slow) {
    audio.playbackRate = 0.5
  }
  audio.play()
}

const addChar = (char, set) => {
  const value = helpers.insertAtCursor(refs[`input-${set.word.id}`], char)
  set.answer = value

  // this.handleInput();
}

onMounted(() => {
  currentSets.value = shuffle(props.words).map((word) => {
    return {
      error: false,
      selected: null,
      word,
      answer: '',
    }
  })
})
</script>

<style lang="scss" scoped>
.word-card {
	height: 130px;
	padding: 5px;
	background-color: #fff;
	border-radius: 8px;
	// border-top: 3px solid #009ee0;
	// border: 2px solid #d9dce6;
	border-radius: 5px;

	textarea {
		resize: none;
		border-radius: 4px;
		border: 2px solid #eee;
		border-radius: 12px;
		// border-top-right-radius: 25px;
		// border-bottom-left-radius: 25px;
		background: #fafafa;
		outline: none !important;
		padding: 5px;
		color: #3f4243;
	}
}
</style>
