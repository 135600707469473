import { onBeforeUnmount } from "vue";
import { TinyEmitter } from "tiny-emitter";

const eventEmitter = new TinyEmitter();

export default function useEventBus() {
  const eventHandlers = [];

  // Очистка всех обработчиков при уничтожении компонента
  onBeforeUnmount(() => {
    eventHandlers.forEach(({ event, handler }) => {
      eventEmitter.off(event, handler);  // Убираем обработчики при уничтожении
    });
  });

  return {
    // Подписка на событие
    on(event, handler) {
      // Добавляем новый обработчик, если его нет в списке
      const existingHandler = eventHandlers.find(
          (entry) => entry.event === event && entry.handler === handler
      );
      if (!existingHandler) {
        eventHandlers.push({ event, handler });  // Добавляем обработчик в список
        eventEmitter.on(event, handler);  // Подписываемся на событие
      }
    },

    // Удаление обработчика события
    off(event, handler) {
      const index = eventHandlers.findIndex(
          (entry) => entry.event === event && entry.handler === handler
      );
      if (index !== -1) {
        eventEmitter.off(event, handler);  // Отписываемся от события
        eventHandlers.splice(index, 1);  // Удаляем обработчик из списка
      }
    },

    // Генерация события
    emit(event, payload) {
      eventEmitter.emit(event, payload);  // Генерируем событие
    }
  };
}
