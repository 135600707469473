<template>
    <div class="activity-frame">
        <iframe :src="link + `&flutter='true'` + `&timestamp=${new Date().getTime()}`" v-if="link" :style="{
            paddingBottom: ($route.meta.frame ? 0 : paddingBottomBar) + 'px',
            paddingRight: ($route.meta.frame ? paddingBottomBar : 0) + 'px'
        }"></iframe>
    </div>
</template>

<script>
import FlutterBridge from '../../helpers/flutterBridge';

export default {
    data: () => ({
        link: null,
        paddingBottomBar: window.flutterBottomBarHeight,
    }),
    created() {
        this.link = this.$route.params.link;
        console.log('LINK GAMES', this.link);
        FlutterBridge.callService('FlutterLessonChannel', 'landscape_on');
        // window.FlutterLessonChannel && window.FlutterLessonChannel.postMessage('landscape_on');
    },
  beforeUnmount() {
        FlutterBridge.callService('FlutterLessonChannel', 'landscape_exit');
        // window.FlutterLessonChannel && window.FlutterLessonChannel.postMessage('landscape_exit');
    }
}
</script>

<style lang="scss" scoped>
.activity-frame {
    width: 100vw;
    height: 100vh;

    iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
