export default {
  project_from: 'Проект от преподавателя английского языка и специалиста в области внедрения IT-систем. ЮЛИИ и НАДЕЖДЫ ВЕРШИННИКОВЫХ',
  marathon_info: 'Международный марафон среди учителей иностранных языков и их учеников',
  avail_winners: 'Стали известны победители года',
  marathon_label: 'Терпение и труд к победе приведут!',
  action: 'Участвовать',
  about_marathon: 'О марафоне',
  question_1: 'Кто участвует?',
  question_2: 'Что оценивается?',
  question_3: 'Как начать?',
  question_4: 'Когда итоги?',
  pay: 'Оплатить',
  answer_1: 'Учителя и их ученики - пользователи платформы "ВЗНАНИЯ"',
  answer_2: 'Количество выученных слов(засчитывается не более 50 в день) В уроке должно быть не менее 5 обязательных заданий',
  answer_3: ' стоимость участия - 500 руб, тогда вы сможете следить за ходом марафона, получать сертификаты и призы',
  answer_4: 'Победители определяются каждый месяц, а также по итогам сезона и года. Финал - 1 мая 2021 г',
  prizes: 'Призы',
  teahcer: 'Учитель',
  of_year: 'года',
  of_season: 'сезона',
  of_month: 'месяца',
  student: 'Ученик',
  diploma: 'Диплом',
  free_games_access: 'Бесплатный доступ к GAMES.VZNANIYA.RU',
  year_access: 'на 1 год',
  season_access: 'на 3 месяца',
  month_access: 'на 1 месяц',
  playstation: 'Игровая консоль',
  free_app_access: 'Бесплатный доступ к приложению ВЗНАНИЯ',
  certificate: 'Сертификат',
  partners: 'Партнеры',
  rating: 'Рейтинг',
  online_update: 'Обновляется в режиме реального времени, обнуляется по итогам месяца',
  top_teachers: 'Топ-10 учителей',
  top_students: 'Топ-30 учеников',
  in_marathon: 'Участвует',
  city: 'Город',
  school: 'Школа',
  num_of_students: 'Количество учеников',
  num_of_words: 'Количество выученных слов',
  winners: 'Победители',
  results: 'Итоги',
  of_month: 'месяца',
  of_season: 'сезона',
  of_year: 'года',
  of_marathon: 'марафона',
  marathon: 'Марафон',
  summer: 'Лето',
  autumn: 'Осень',
  winner: 'Зима',
  spring: 'Весна',
  teachers: 'Учителя',
  students: 'Ученики',
  learned_words: 'выученных слов',
  logo: '/images/marathon/logo_ru.png',
  left_in: 'До результатов за',
  place: '-e место',
  certificate: 'Сертификат участника',
  loading: 'Загрузка...',
  pay_action: 'Оплатите',
  ends_in: 'Идёт обработка результатов и определение победителей',
}