import apiRequest from './apiRequest.js';

const base = 'challenges';

export const joinChallenge = async (payload) => {
  const endpoint = `${base}/attach`;
  return await apiRequest.patch(endpoint, payload);
}

export const addPostToChallenge = async (payload) => {
  const endpoint = `${base}/posts`;
  return await apiRequest.post(endpoint, payload);
}

export const updateChallengePost = async (payload) => {
  const endpoint = `${base}/posts`;
  return await apiRequest.put(endpoint, payload);
}

export const addImageChallenge = async (payload) => {
  const endpoint = `${base}/posts/image`;
  const formData = new FormData();
  formData.set('image', payload.image);
  return await apiRequest.post(endpoint, formData);
}

export const getMyChallenges = async () => {
  const endpoint = `${base}/my`;
  return await apiRequest.get(endpoint);
}

export const getAllPosts = async () => {
  const endpoint = `${base}/posts`;
  return await apiRequest.get(endpoint);
}

export const getCurrentChallengePosts = async (challengeId, filter= {sort: 0, my: 0}) => {
  let endpoint = `${base}/posts`;
  return await apiRequest.get(endpoint, {params: {challengeId: challengeId, ...filter}});
}

export const getAllChallengesForStudent = async () => {
  const endpoint = base;
  return await apiRequest.get(endpoint);
}

export const challengePostToggleLike = async (postId) => {
  const endpoint = `${base}/posts/${postId}/like`;
  return await apiRequest.post(endpoint)
}

export const challengeUnauthorizedLike = async (postId) => {
  const endpoint = `posts/${postId}/like`;
  return await apiRequest.post(endpoint)
}

export const challengePostComment = async (postId, commentText) => {
  const endpoint = `${base}/posts/${postId}/comment`;
  return await apiRequest.post(endpoint, {body: commentText});
}

export const challengePostGetComments = async (postId) => {
  const endpoint = `${base}/posts/${postId}/comment`;
  return await apiRequest.get(endpoint);
}

export const challengePostGetCommentsNoAuth = async (postId) => {
  const endpoint = `posts/${postId}/comment`;
  return await apiRequest.get(endpoint);
}

export const deleteChallengePost = async (postId) => {
  const endpoint = `${base}/posts/${postId}`;
  return await apiRequest.delete(endpoint)
}

export const challengeGetUnattached = async () => {
  const endpoint = `${base}/diff`;
  return await apiRequest.get(endpoint);
}

export const getChallengeDataFromShareLink = async (post_id) => {
  const endpoint = `posts?id=${post_id}`;
  return await apiRequest.get(endpoint);
}

export const getChallengeDataFromShareLinkTeacher = async (lessonId) => {
  const endpoint = `${base}/get?lessonId=${lessonId}`;
  return await apiRequest.get(endpoint);
}

export const commentChallengePostUnauthorized = async (postId, payload) => {
  const endpoint = `posts/${postId}/comment`;
  return await apiRequest.post(endpoint, payload);
}
