<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7336 8.34907C16.9777 8.59315 16.9777 8.98888 16.7336 9.23296L12.1755 13.791L16.7336 18.3491C16.9777 18.5932 16.9777 18.9889 16.7336 19.233C16.4895 19.477 16.0938 19.477 15.8497 19.233L10.8497 14.233C10.6056 13.9889 10.6056 13.5932 10.8497 13.3491L15.8497 8.34907C16.0938 8.105 16.4895 8.105 16.7336 8.34907Z"
      fill="url(#paint0_linear_820_11590)"
    />
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="27"
      rx="7.5"
      stroke="url(#paint1_linear_820_11590)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_820_11590"
        x1="7.54628"
        y1="13.7827"
        x2="17.0841"
        y2="19.0815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FFAA" />
        <stop offset="0.530854" stop-color="#4579F5" />
        <stop offset="0.999815" stop-color="#9C42F5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_820_11590"
        x1="-13.9793"
        y1="13.9793"
        x2="13.9793"
        y2="41.938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FFAA" />
        <stop offset="0.530854" stop-color="#4579F5" />
        <stop offset="0.999815" stop-color="#9C42F5" />
      </linearGradient>
    </defs>
  </svg>
</template>
