export default {
  "request-to-delete": "Request to delete",
  "delete-account-title": "Delete Account",
  the_learnt_words: 'The learnt words',
  completed_out: 'Completed {p1} out of {p2} sets',
  all_sets: 'All sets',
  deadline: 'Deadline',
  current: 'Current',
  completed: 'Completed',
  expired: 'Expired',
  all_groups: 'All groups',
  search_by_name: 'Search',
  my_sets: 'My sets',
  better_late: "Better late than never!",
  score: "Score",
  open: "Open",
  continue: "Continue",
  improve_the_result: "Improve the result",
  done: "Done",
  result: 'Result',
  сhange_details: 'Change your details',
  save: 'Save',
  to_save_progress: 'To save the results of the test:',
  to_save_test_result: 'You still have the opportunity to save the results of your test:',
  "mobile_1" :"Complete game tasks anywhere and anytime",
  "mobile_2" :"You can always improve your result by completing the lesson again.",
  "mobile_3" :"Increase your rating, participate in marathons",
  "mobile_4" :"fun to learn",
  "mobile_5" :"Teach Effectively",

  name_and_last: "Name and last name",
  school: "School",
  city: "City",
  cancel: "Cancel",
  accept: "Accept",
  change_the_password: "Change the password",
  current_password: "Current password",
  new_password: "New password",
  confirm_the_password: "Confirm the password",
  name: 'Name',
  last_name: 'Last name',
  profile: 'Profile',
  logout: 'Logout',
  tariffs: 'Payments',
  logo_new: '/images/logo_new_en.png',
  logo: '/images/logo_en_3d.png',
  footer_logo: '/images/logo_int.svg',

  video_guide: "Video guide",
  faq: "FAQs",
  marathon: 'Marathon Vznanya {p1}"',
  marathon_label: 'Marathon',
  learn_easily: "Learn easily",
  teach_effectively: "Teach effectively",
  do_the_sets: "Do the sets anytime anywhere",
  user_agreement: 'User agreement',
  add_a_group: 'Add a group',
  upload: 'Upload',

  error_new_password_empty: 'The new password field is required',
  error_passwords_match: 'The new password and password confirmation does not match',
  join_group: 'Join your Teacher’s group to get the access to the lessons',
  incorrect_email: 'Incorrect email or password',
  log_in: 'Log in',
  notifications: 'Notifications',
  no_notifications: 'No notifications',
  copy_lesson: 'Copy lesson',
  no_lessons: 'No lessons',
  what_imporve: 'What to improve?',
  what_you_want: 'Let us know about a problem or write what you would like to improve on the website',
  
  "login_modal": {
    "title": "Log in to vznaniya",
    "login": "Log in",
    "email_placeholder": "E-mail",
    "password_placeholder": "Enter your password",
    "forgot_password": "Forgot your password?",
    "no_account": "Don't have an account?",
    "register_now": "Sign in",
    "email_label": "Your e-mail",
    "send": "Send",
    "email_instruction": "You may find the instruction in your e-mail box",
    "close": "Close"
  },
  "register_modal": {
    "title": "Registration",
    "who_are_you": "Who are you?",
    "choose_role": "Choose your role",
    "role_teacher": "Teacher",
    "role_student": "Student",
    "name_label": "Your name",
    "name_placeholder": "Name",
    "tname_label": "First name",
    "tname_placeholder": "First name",
    "surname_label": "Last name",
    "surname_placeholder": "Last name",
    "city_label": "City",
    "city_placeholder": "City",
    "school_label": "School",
    "school_placeholder": "School",
    "email_label": "Your e-mail",
    "password_label": "Create a password",
    "password_placeholder": "Not less than 8 characters",
    "confirm_password_label": "Confirm your password",
    "confirm_password_placeholder": "Confirm your password",
    "phone_label": "Phone number",
    "phone_placeholder": "Phone number",
    "accepted_label": "I agree to collection and processing of personal data",
    "terms_of_use": "Terms of use",
    "register": "Sign in"
  },

  month: 'month | months',
  year: 'year | years',
  mo: 'mo',
  rub: 'rub',

  read_all: 'Read all',
  remove_all: 'Remove all',
  preview_mode: 'This lesson is in the preview mode. Your progress and results will not be saved',

  data_does_not_exist: 'The requested data does not exist',
  give_the_feedback: 'Delete account',
  purchase_info: 'Purchase info',
  log_out: 'Log out',
  join_game: 'Join the game',
  join: 'Join',
  enter_game_code: 'Enter game code',
  filter: 'Filter',
  group: 'Group',
  apply: 'Apply',
  rating_positions: 'Rating',
  feedback: 'Feedback',
  send: 'Send',
  successfully_sent: 'Successfully sent',
  error_sent: 'An error occurred while sending, please try again later',
  thx_subscribed: 'Thanks a lot! You are subscribed to Vznaniya',
  native_request: 'Permission is permanently denied. Go to app settingd and allow permission'
}