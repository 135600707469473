import {getMyChallenges} from "../../api/challenge.js";

export default {
    state: {
        myChallenges: []
    },
    getters: {
    },
    actions: {
        UPDATE_MY_CHALLENGES: async ({commit}) => {
            const data = await getMyChallenges();
            if (!data || data.status !== 'OK') return;
            commit("SET_MY_CHALLENGES", data.data);
            return data
        }
    },
    mutations: {
        SET_MY_CHALLENGES: (state, challenges) => {
            state.myChallenges = challenges.sort((a,b) => a.id < b.id ? 1 : -1);
        }
    }
};
