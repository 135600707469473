export default {
  no_messages: "Сообщения отсутствуют",
  choose_dialog: "Выберите диалог",
  type_message: 'Введите ваше сообщение',
  send: 'Отправить',
  messages: 'Сообщения',
  not_mistakes: "Ошибок нет",
  mistakes: "Ошибки",
  not_supported: "Чат не поддерживается",
  delete: "Удалить",
  edit: "Редактировать",
  new: "Новые",
  empty: "Нет сообщений",
  message_delete: "Сообщение удалено",
  new_message: "Новое сообщение",
  CONVERSATION_STARTED: 'Диалог начат: ',
  TYPE_MESSAGE: 'Введите сообщение',
  IS_TYPING: 'Печатает...',
  ROOMS_EMPTY: 'Нет диалогов',
}