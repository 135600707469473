<template>
    <div class="login-screen flex flex-col pb-10">
        <img class="login-screen__bottom-decor" src="@/assets/mobile/decor_login_bottom.png" alt="">
        <img class="login-screen__top-decor" src="@/assets/mobile/decor_login_top.png" alt="">

        <div class="h-full flex flex-col items-center justify-center w-10/12 mx-auto" style="max-width: 400px">
<!--            <img class="splash-logo mb-5" src="/images/mobile/logo_brain.png" alt="">-->

            <input type="email" class="v-input w-full text-center" placeholder="E-mail" v-model="form.email">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('email')">{{ isError('email') }}</div>

            <input type="password" class="v-input mt-3 w-full text-center" placeholder="Пароль" v-model="form.password">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('password')">{{ isError('password') }}</div>

            <!-- <div class="mb-5 v-text--red text-center mt-3 w-full" v-html="errors" v-if="errors"></div> -->
            <div class="mb-5 mt-10 v-text--red text-center w-full mt-10" v-if="customError">Произошла ошибка, обратитесь в службу поддержки!</div>

            <div class="s-button s-button--orange mb-5 mt-5" @click="login">Войти</div>
            <div class="v-text--desc" @click="$router.push({ name: 'reset' })">Забыли пароль?</div>
        </div>

        <div class="flex flex-col items-center justify-center w-full mb-5">
            <div class="v-text--desc mb-2">Нет аккаунта?</div>
            <div class="v-text--bold v-text--gray" @click="$router.push({ name: 'register' })">Зарегистрируйтесь</div>
        </div>

        <div class="flex items-center justify-center absolute h-full w-full bg-black bg-opacity-50" v-if="isBusy">
            <loader />
        </div>
    </div>
</template>

<script>
import { auth } from '../../api/user';
import FlutterBridge from '../../helpers/flutterBridge';

export default {
    data: () => ({
        errors: null,
        form: {
            email: '',
            password: '',
        },
        isBusy: false,
        errorNames: {},
        customError: false,
    }),
    methods: {
        isError(name) {
            return this.errorNames[name];
        },
        async login() {
            this.resetErrors();

            try {
                this.isBusy = true;

                const { data } = await auth(this.form);

                if (data.data.role === 'teacher') {
                    return this.handleTeacherAuth(data.data.access_token);
                }

                localStorage.setItem('token', data.data.access_token);
                // window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage(data.data.access_token);
                FlutterBridge.callService('FlutterTokenChannel', data.data.access_token);

                this.currentLanguageKey = data.data.locale || 'ru';
                this.$root.$i18n.locale = this.currentLanguageKey;
                FlutterBridge.callService('FlutterLangChannel', this.currentLanguageKey);
                // window.FlutterLangChannel && window.FlutterLangChannel.postMessage(this.currentLanguageKey);

                const redirect = await this.$store.dispatch('GET_PROFILE_DATA');

                FlutterBridge.callService('FlutterPaymentChannel', 'check');
                // window.FlutterPaymentChannel && window.FlutterPaymentChannel.postMessage('check');

                if (redirect) {
                    this.$router.push({ name: 'home' })
                }
            } catch (err) {
                console.dir(err);
                this.handleError(err);
            } finally {
                this.isBusy = false;
            }
        },
        handleError(err) {
            if (err.response && err.response.data && err.response.data.errors) {
                this.errors = Object.values(err.response.data.errors).map(el => el[0]).join('<br>')
                this.errorNames = err.response.data.errors;
                for (let key in this.errorNames) {
                    this.errorNames[key] = this.errorNames[key][0];
                }
            } else {
                this.customError = true;
            }
        },
        resetErrors() {
            this.errors = null;
        },
        handleTeacherAuth(token) {
            this.$root.$emit('v-modal', {
                text: 'В данный момент функционал приложения доступен только ученикам. Пожалуйста, перейдите на наш сайт.',
                size: 'sm',
                buttons: [
                    {
                        text: 'Отмена',
                        class: 'v-button v-button--gradient-red',
                        callback: () => { this.$root.$emit('v-modal', { close: true }) }
                    },
                    {
                        text: 'Перейти',
                        class: 'v-button v-button--gradient-red',
                        callback: () => {
                            let TEACHER_URL = process.env.NODE_ENV == 'production' ? 'https://teacher.vznaniya.ru' :  'https://devteacher.vznaniya.ru';
                            FlutterBridge.callService('FlutterLinkChannel', TEACHER_URL + `?token=${token}`);
                            // window.FlutterLinkChannel && window.FlutterLinkChannel.postMessage(
                            //     TEACHER_URL + `?token=${token}`
                            // );
                        }
                    }
                ],
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.login-screen {
    height: 100vh;

    &__bottom-decor,
    &__top-decor {
        position: absolute;
        width: 30vw;
        max-width: 80px;
        pointer-events: none;
    }

    &__bottom-decor {
        bottom: 0;
        left: 0;
    }
    &__top-decor {
        top: 0;
        right: 0;
    }

    .splash-logo {
        width: 100px;
        mix-blend-mode: multiply;
    }
}
</style>
