<template>
	<NewMobileBgWrapper>
		<div
			class="v-game flex flex-col"
			v-if="currentLesson"
			:class="{
				'flex flex-col overflow-y-auto': isTrueMobile,
				dislexic: isDislexicMode(),
				[getFontSizeClass()]: true,
			}"
			:style="{
				position: isNewMobileApp && 'relative',
				height: isNewMobileApp && '100vh',
				paddingTop: isNewMobileApp && '20px',
			}"
		>
			<!-- Desktop header -->
			<!-- <div class="v-game__navigator v-navigator" v-if="showNavigator && !isTrueMobile && false">
      <div class="container mx-auto w-full flex" :class="getGames.length >= 10 ? 'justify-between' : 'justify-center'">
        <div class="v-game__icon mx-1" :id="`game-${game.name}`" :class="{ current: currentGameName === game.name, disabled: getGameCount(game) === 0 }" v-for="(game, gameIndex) in getGames" :key="gameIndex" @click="goTo(game)">
          <img class="mx-auto" :src="game.name !== 'FinalTest' ? game.icon : getTestIcon(game)" alt="">
          <div class="mt-1 text-center text-sm" style="line-height: 1; height: 28px; display: flex; align-items: center; justify-content: center;">{{ getTranslatedName(game) }}</div>
          <div class="icon-checkers">
            <checkers relative :count="getGameCount(game)" :done="getGameDone(game)" :offset="40"/>
          </div>
        </div>
      </div>
    </div> -->

			<!-- Mobile header -->
			<div class="container game-navbar">
				<ScrollComponent>
					<div class="game-navbar__lists" v-if="showNavigator">
						<div
							class="game-navbar__list"
							v-for="(game, gameIndex) in getGames"
							:key="gameIndex"
							:class="{ active: currentGameName === game.name }"
							@click="goTo(game)"
						>
							<LexicalTaskIconRoundedHead
								:count="game.isTest ? 1 : getGameCount(game)"
								:done="getGameDone(game)"
								:lock="game.isTest && getNotDoneTask !== 'FinalTest'"
								:activeColor="game.activeColor"
							>
								<div class="game-navbar__list__icowrapper">
									<IconDisplay :iconPath="`/images/lesson/icons/new/${game.slug}.svg`" :customClass="['game-navbar__list__ico', { active: getGameDone(game) >= 1 }]"/>
								</div>
							</LexicalTaskIconRoundedHead>

							<div class="game-navbar__list__name">{{ getTranslatedName(game) }}</div>
						</div>
					</div>
				</ScrollComponent>

				<div class="game-navbar__contents">
					<div class="game-navbar__content">
						<div
							class="game-navbar__title"
							@click="
									!showNavigator
										? resetSnake()
										: $router.push({
												name: 'lesson',
												params: { id: $route.params.id },
												query: { ...$route.query, slug },
											})
								"
						>
							<IconDisplay iconPath="/images/cursor_type1.svg" customClass="game-navbar__title__ico"/>
							<div class="game-navbar__title__name">{{ getTranslatedName(currentGameMeta) }}</div>
						</div>

						<div class="game-navbar__sounds" v-if="['listen'].includes(currentGameMeta.slug)">
							<LexicalAudioButton
								:animated="true"
								class="mr-2"
								:global="true"
								@click="$root.$emit('lexical-audio-play')"
							/>

							<LexicalAudioButton
								:animated="true"
								:slow="true"
								:global="true"
								@click="$root.$emit('lexical-audio-play', true)"
							/>
						</div>
					</div>

					<div class="game-navbar__content game-navbar__progressbar__wrapper" v-if="!currentGameMeta.isTest">
						<progress-bar
							class="v-progress__bar"
							:style="{
										background: isNewMobileApp && 'rgba(255, 255, 255, 0.2)',
									}"
							:percent="getCurrentGameProgress.percent"
							type="new"
							revertedSlice
						/>

						<div class="game-navbar__progressbar">
							<div class="game-navbar__progressbar__title">{{$t('games.learnt_progress')}}</div>

							<div class="game-navbar__progressbar__info">
								{{
									$t('games.learnt_out_of_short', {
										p1: getCurrentGameProgress.done,
										p2: getCurrentGameProgress.count,
									})
								}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Desktop components -->
			<!-- <div v-if="!isTrueMobile && false" class="v-game__container" :class="{ full: !showNavigator }" @scroll="(...data) => ($refs.component && $refs.component.onScroll) && $refs.component.onScroll(data)">

      <div class="container mx-auto flex justify-between mb-2 items-center p-5 pb-0 lg:pb-5">
        <div class="flex cursor-pointer items-center lg:w-3/12" @click="!showNavigator ? resetSnake() : $router.push({ name: 'lesson', params: { id: $route.params.id }, query: { ...$route.query, slug } })">
          <svg class="h-5 mr-2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
              <path fill="#009ee0" stroke="#009ee0" stroke-width="20" d="M384.834,180.699c-0.698,0-348.733,0-348.733,0l73.326-82.187c4.755-5.33,4.289-13.505-1.041-18.26
                c-5.328-4.754-13.505-4.29-18.26,1.041l-82.582,92.56c-10.059,11.278-10.058,28.282,0.001,39.557l82.582,92.561
                c2.556,2.865,6.097,4.323,9.654,4.323c3.064,0,6.139-1.083,8.606-3.282c5.33-4.755,5.795-12.93,1.041-18.26l-73.326-82.188
                c0,0,348.034,0,348.733,0c55.858,0,101.3,45.444,101.3,101.3s-45.443,101.3-101.3,101.3h-61.58
                c-7.143,0-12.933,5.791-12.933,12.933c0,7.142,5.79,12.933,12.933,12.933h61.58c70.12,0,127.166-57.046,127.166-127.166
                C512,237.745,454.954,180.699,384.834,180.699z"/>
          </svg>
          <div class="v-text--gray text-sm">{{ !showNavigator ? 'Остановить игру' : currentLesson.name }}</div>
        </div>
        <div class="hidden lg:block v-text--bold v-link v-text--desc" v-if="!$route.query.onlypreview && !isTrueMobile" @click="$router.push({ name: 'tariffs' })">{{ $t('home.do_the_sets') }}</div>
        <div class="v-text--gray text-sm lg:w-3/12 flex justify-end" v-show="currentGameName !== 'FinalTest'">{{ $t('games.learnt_out_of', { p1: getCurrentGameProgress.done, p2: getCurrentGameProgress.count }) }}</div>
      </div>


      <div class="fullpage-inbox" style="overflow-x: hidden" :style="{ paddingBottom }">
        <transition name="fade" mode="out-in" v-if="currentGameName">
          <component :showNavigator="showNavigator" @toggleNavigator="action => showNavigator = action" ref="component" :is="currentGameComponent" class="container mx-auto" @hint="onHint" v-if="currentWords" />
        </transition>
      </div>
      <v-footer class="mt-10 hidden lg:block"/>
    </div> -->

			<!-- Mobile components -->
			<div
				class="v-body w-full flex-grow flex flex-col container"
				:style="{
					paddingBottom,
					background: '#fff',
					marginTop: isNewMobileApp && '0',
				}"
				:class="{ [`vzn-${route?.params?.gname}`]: true }"
			>
				<div class="flex-grow pb-10 flex flex-col">
					<!-- <transition name="fade" mode="out-in" v-if="currentGameName"> -->
					<component
						:showNavigator="showNavigator"
						@toggleNavigator="(action) => (showNavigator = action)"
						ref="component"
						:is="currentGameComponent"
						class="container mx-auto"
						@hint="onHint"
						v-if="currentWords"
						@testProgress="(val) => (currentTestProgress = val)"
					/>
					<!-- </transition> -->
				</div>
				<!-- <v-footer class="mt-10 hidden lg:block"/> -->
			</div>

			<TaskResult @navigate="goTo" />
			<TestResult @navigate="goTo" />
		</div>
		<div v-else class="fullpage-noheader flex justify-center items-center">
			<loader />
		</div>
	</NewMobileBgWrapper>
</template>

<script setup>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import Flashcard from '@/screens/games/Flashcard/index.vue'
import Listen from '@/screens/games/Listen/index.vue'
import Match from '@/screens/games/Match/index.vue'
import Snake from '@/screens/games/Snake/index.vue'
import Unscramble from '@/screens/games/Unscramble/index.vue'
import FillGame from '@/screens/games/FillGame/index.vue'
import TypeIn from '@/screens/games/TypeIn/index.vue'
import Remember from '@/screens/games/Remember/index.vue'
import Quiz from '@/screens/games/Quiz/index.vue'
import FindWord from '@/screens/games/FindWord/index.vue'
import FindDefinition from '@/screens/games/FindDefinition/index.vue'
import ListenType from '@/screens/games/ListenType/index.vue'
import FinalTest from '@/screens/games/FinalTest/index.vue'
import TaskResult from '@/components/modals/TaskResultModal.vue'
import TestResult from '@/components/modals/TestResultModal.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import LexicalTaskIconRoundedHead from '@/components/lesson/LexicalTaskIconRoundedHead.vue'
import ScrollComponent from '@/components/ScrollComponent.vue'
import IconDisplay from '@/components/IconDisplay.vue'

import { useStore } from 'vuex'
import { getSlug, getOnlypreview } from '@/helpers.js'
import LexicalTaskIconRounded from '@/components/lesson/LexicalTaskIconRounded.vue'
import LexicalAudioButton from '@/components/LexicalAudioButton.vue'
import NovakidBackSvg from '../../novakid/assets/svg/NovakidBackSvg.vue'
import NovakidProgress from '../../novakid/common/NovakidProgress.vue'
import { onBeforeMount } from '@vue/runtime-dom'
import NewMobileBgWrapper from '@/components/NewMobileBgWrapper.vue'
import { computed, getCurrentInstance } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import useEventBus from '../composables/useEventBus.js'
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'


const { t } = useI18n()
const store = useStore()
const route = useRoute()
const router = useRouter()
const emitter = useEventBus()
const slug = ref(null)
const currentGameName = ref(null)
const showNavigator = ref(true)
const currentTestProgress = ref(0)
const games = computed(() => store.getters.games)
const currentLesson = computed(() => store.getters.getCurrentLesson)
const currentWords = computed(() => store.getters.getCurrentWords)
const currentLessonProgressData = computed(() => store.getters.getCurrentLessonProgressData(currentGameMeta.value.slug))
const getNotDoneTask = computed(() => store.getters.getNotDoneTask)
const isTestAvailable = computed(() => store.getters.isTestAvailable)
const getPlatform = computed(() => store.getters.getPlatform)
const isNewMobileApp = computed(() => store.getters.isNewMobileApp)
const currentGameMeta = computed(() => games.value.find((game) => game.name === currentGameName.value) || {})
const scrollbar = ref(null)

const scrollLeft = () => {
	scrollbar.value?.$el?.scrollLeft && (scrollbar.value.$el.scrollLeft -= 100)
}

const scrollRight = () => {
	scrollbar.value?.$el?.scrollWidth > scrollbar.value?.$el?.clientWidth && (scrollbar.value.$el.scrollLeft += 100)
}

const getGameCount = ({ slug }) => {
	if (!currentLesson.value?.additional_info?.tasks) return 0
	return parseInt(currentLesson.value.additional_info.tasks[slug], 10)
}
const isDislexicMode = () => currentLesson?.value?.additional_info?.dialexicalMode || false

const getGames = computed(() => games.value.filter((game) => !!getGameCount(game) || game.isTest))
const isTrueMobile = () => window.isTrueMobile
const paddingBottom = () => {
	if (route.meta.auth) return 0

	return (window.flutterPaddingBottom || 0) * 2 + 'px'
}
const imgUrl = import.meta.env.VITE_IMG_URL

const getImgPathFromHeader = (game) => {
	const path = game.isTest
		? isTestAvailable || currentGameName.value === game.name
			? getIcon('/images/lesson/icons/test.svg')
			: getIcon('/images/lesson/icons/test_header.svg')
		: getIcon(
				`${game.icon_base}/${currentGameName.value === game.name || getGameDone(game) >= getGameCount(game) ? 'filled' : 'header'}.svg`
			)

	return path
}

const getIcon = (path) => {
	const cleanedPath = path.includes('/images/') ? path.replace('/images/', '') : path
	return new URL(`../assets/${cleanedPath}`, import.meta.url).href
}

const currentGameComponent = computed(() => {
	switch (currentGameName.value) {
		case 'Match':
			return Match
		case 'Snake':
			return Snake
		case 'Listen':
			return Listen
		case 'TypeIn':
			return TypeIn
		case 'FillGame':
			return FillGame
		case 'Flashcard':
			return Flashcard
		case 'FinalTest':
			return FinalTest
		case 'Unscramble':
			return Unscramble
		case 'Remember':
			return Remember
		case 'Quiz':
			return Quiz
		case 'FindWord':
			return FindWord
		case 'ListenType':
			return ListenType
		case 'FindDefinition':
			return FindDefinition
	}
})

const getCurrentGameProgress = computed(() => {
	if (!currentLessonProgressData.value || !currentWords.value) return { count: 0, done: 0 }

	const done = Object.keys(currentLessonProgressData.value.words)
	return {
		count: currentWords.value.length,
		done: done ? done.length : 0,
		percent: ((done ? done.length : 0) * 100) / currentWords.value.length,
	}
})
const getCurrentTestProgress = () => {
	return { percent: currentTestProgress.value }
}

const created = async () => {
	slug.value = getSlug()
	if (!currentLesson.value) {
		console.log('нет урока')
		if (slug.value) {
			await store.dispatch('GET_CURRENT_LESSON_SLUG', {
				id: route.params.id,
				slug: slug.value,
				onlypreview: getOnlypreview(),
				group_id: route.query.group_id,
			})
			// TODO: Осторожнее с этим
			const urlParams = new URLSearchParams(window.location.search)
		} else {
			await store.dispatch('GET_CURRENT_LESSON', {
				id: route.params.id,
				group_id: route.query.group_id,
			})
		}

		if (currentLessonProgressData.value?.words?.length === currentWords.length) {
			emitter.emit('v-task-result', {
				gameName: currentGameMeta.value?.slug,
				debug: true,
			})
		}
	}

	if (!route.query.force && !getGameCount(currentGameMeta)) {
		const firstActualGame = games.value.filter((el) => !el.isTest).find((game) => getGameCount(game) > 0)
		if (firstActualGame) {
			await router.replace({
				name: 'games',
				params: { gname: firstActualGame.name },
				query: { ...route.query, slug: slug.value, onlypreview: getOnlypreview() },
			})
			currentGameName.value = firstActualGame.name
		} else {
			await router.replace({
				name: 'games',
				params: { gname: 'FinalTest' },
				query: { ...route.query, slug: slug.value, onlypreview: getOnlypreview() },
			})
			currentGameName.value = 'FinalTest'
		}
	}

	await nextTick()
	if (document.querySelector('.v-nav-icon.active')) {
		document.querySelector('.v-nav-icon.active').scrollIntoView()
	}
}

onBeforeMount(async () => {
	await created()
	await nextTick()
	if (document.querySelector('.v-nav-icon.active')) {
		document.querySelector('.v-nav-icon.active').scrollIntoView()
	}
})

watch(
	() => route.fullPath, // отслеживает изменения полного пути
	(n, o) => {
		currentGameName.value = route.params.gname
	},
	{ immediate: true }
)

const backToHome = () => {
	router.push({ name: 'home' })
}
const getFontSizeClass = () => {
	return `vzn-custom-fonts vzn-custom-fonts__${currentLesson.value.additional_info?.custom_font?.font_size ?? 'base'}
			 vzn-custom-font__${currentLesson.value.additional_info?.custom_font?.name ?? 'base'}`
}
const resetSnake = () => {
	showNavigator.value = true
}
const getTranslatedName = (game) => {
	return t(`lesson.${game.slug}_short`)
}
const getTestIcon = (game) => (getNotDoneTask.value === 'FinalTest' ? game.icon_alter : game.icon)
const goTo = (name) => {
	name = name.name || name

	if (currentGameName.value === name) return

	if (name === 'FinalTest' && getNotDoneTask.value !== 'FinalTest') {
		emitter.emit('v-modal', {
			text: t('games.the_test_will_be_available'),
			size: 'sm',
		})
		return
	}

	console.log('currentGameName.value', currentGameName.value, name)

	currentGameName.value = name

	console.log('currentGameName.value', currentGameName.value, name)

	router.replace({
		name: 'games',
		params: { gname: name },
		query: { ...route.query, slug: slug.value, onlypreview: getOnlypreview() },
	})
}
const getGameDone = ({ slug }) => {
	if (
		!currentLesson.value ||
		!currentLesson.value.lr_additional_info ||
		!currentLesson.value.lr_additional_info?.tasks
	)
		return 0

	return parseInt(currentLesson.value.lr_additional_info.tasks[slug], 10)
}

const onHint = (word) => {
	emitter.emit('v-modal', {
		text: word,
		size: 'sm',
	})
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/game.scss';
</style>

<style lang="scss" scoped>

.v-game {
	&.dislexic * {
		font-family: 'OpenDyslexic-Regular' !important;
	}
}

.v-nav .ps {
	width: 100%;

	.ps__rail-x {
		background: transparent !important;
		opacity: 1;

		&:hover {
			background: transparent !important;
		}

		@media screen and (max-width: 768px) {
			pointer-events: none;
		}
	}

	.ps__thumb-x {
		opacity: 0.5;
	}
}

.v-navigator {
	// @media (max-width: 1024px) {
	// overflow-x: auto;
	// overflow-y: hidden;
	// }
	.container {
		overflow-x: auto;
		overflow-y: hidden;
		padding: 15px 0 10px 0;
	}
}

.v-diagram {
	width: 90%;
	margin: 0 auto 20px !important;

	img {
		width: 100%;
		border-radius: 6px;
	}

	&__sign {
		width: 20px;
		height: 20px;
		cursor: pointer;
		color: #000;
		background-color: rgba(255, 255, 255, 0.452);
		font-size: 18px;
		border: 2px solid #000;
		border-radius: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		@media (min-width: 1024px) {
			width: 30px;
			height: 30px;
			border: 3px solid #000;
		}

		&.wrong {
			background-color: #ec591d !important;
		}

		&.right {
			background-color: rgb(0, 164, 71) !important;
			pointer-events: none !important;
		}

		&.active {
			background-color: rgb(243, 212, 6);
			animation: toggle 1s linear infinite;

			@keyframes toggle {
				0% {
					transform: scale(0.9);
				}

				50% {
					transform: scale(1.1);
				}

				100% {
					transform: scale(0.9);
				}
			}
		}

		@media (min-width: 1024px) {
			margin: initial;

			img {
				min-width: 450px;
				width: 450px;
				border-radius: 6px;
			}
		}
	}
}
</style>

<style lang="scss">
.v-nav__game-icon {
	path:not(.prevent) {
		stroke: #fff;
		stroke-width: 2;
	}
	&-text {
		font-size: 16px;
		color: #141029;
	}
}
</style>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.v-body {
	background-color: #fff;
	// height: 100%;
	// flex-grow: 2;

	border-top-left-radius: 18px;
	border-top-right-radius: 18px;
	margin-top: -15px;
}

.v-nav {
	background-color: $bgMain;
	padding-bottom: 20px;
	max-width: 1440px;
	width: 95%;
	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	margin: 0 auto;
	&__game-icon {
		width: 85px;
		height: 100px;
		padding: 5px;
		min-width: 85px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		color: #5499fd;
		line-height: 1;
		text-align: center;
		font-size: 11px;

		img {
			height: 50px;
			width: 70%;
			object-fit: contain;
		}

		&.active {
			background-color: #fff;
			border-radius: 20px;
			color: gray;
		}

		&.disabled {
			// filter: grayscale(100%);
			pointer-events: none;
		}
	}
	&-icon {
	}
}

.v-link {
	cursor: pointer;
	text-decoration: underline;
}

.v-game {
	width: 100%;
	height: 100%;

	&__container {
		// background-image: url('/images/backgroundPattern.png');
		// height: calc(100vh - 190px);
		height: 100%;
		background-size: contain;
		overflow-y: auto;

		@media screen and (min-width: 1024px) {
			overflow: initial;
			height: auto;
		}

		// padding: 15px;

		&.full {
			height: 100vh;
		}
	}

	&__navigator {
		background-color: rgb(242, 242, 242);
		// padding: 15px 0 10px 0;
		position: relative;
	}

	&__icon {
		height: 90px;
		width: 100px;
		min-width: 100px;
		cursor: pointer;
		transition: all 0.15s linear;
		position: relative;

		&:hover {
			transform: scale(1.08);

			img {
				filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
			}
		}

		img {
			height: 60px;
			width: 60px;
			object-fit: contain;
			background: #fff;
			padding: 12px;

			border-bottom-color: transparent;
			border-bottom-width: 3px;
			border-radius: 12px;
			transition: all 0.2s linear;
		}

		&.current img {
			border-bottom-color: rgb(0, 164, 71);
			box-shadow: 0px 2px 10px -10px black;
		}

		&.disabled {
			color: #757575;
			img {
				filter: brightness(0.9) grayscale(1);
			}
		}

		.icon-checkers {
			position: absolute;
			top: 0;
			right: 0;
			width: 20px;
		}
	}
}
</style>
