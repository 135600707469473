<template>
	<div
		class="v-audio flex items-center justify-center cursor-pointer"
		:class="{ disabled, slow, big, animation, 'no-pulse': !animated }"
		@click.stop="play()"
	>
		<svg
			height="100%"
			v-if="slow"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style="pointer-events: none"
		>
			<path
				d="M11.6827 15.9337H9.58402C10.292 15.535 10.8455 14.8971 11.1353 14.0906C11.5232 14.0463 11.9593 13.9726 12.3984 13.8484C13.0666 13.6594 13.7988 13.3374 14.37 12.7688L14.3701 12.7686C14.9662 12.1748 15.2845 11.4029 15.2845 10.5609V6.85439C15.5008 6.87242 15.7174 6.87242 15.9337 6.85439V11.6827C15.9337 14.0257 14.0257 15.9337 11.6827 15.9337ZM5.60682 15.9337H4.73794C4.82659 15.8351 4.92517 15.7337 5.03432 15.6297C5.21792 15.7425 5.40901 15.8441 5.60682 15.9337ZM12.4531 10.8445L12.4526 10.8449C12.3211 10.976 12.0701 11.1175 11.6837 11.2316C11.4797 11.2917 11.2571 11.3387 11.0267 11.3731C10.3538 10.0208 8.80663 9.55895 7.52048 9.78473L7.51856 9.78507C6.78097 9.91683 6.28916 10.6216 6.41937 11.3594L6.41965 11.361C6.55087 12.0942 7.24988 12.5914 7.99423 12.4599L7.99618 12.4595C8.21856 12.4195 8.41245 12.4571 8.51683 12.5175C8.56052 12.5428 8.58471 12.5689 8.60063 12.5967C8.61656 12.6245 8.64185 12.6849 8.64185 12.8046C8.64185 13.1042 8.54108 13.3122 8.41006 13.4437C8.27997 13.5742 8.0709 13.6787 7.76171 13.6811C6.14234 13.6667 5.18812 12.2947 5.18812 10.8414C5.18812 9.39383 6.60988 7.99268 8.31727 7.99268C9.22879 7.99268 10.224 8.27055 11.0327 8.77734C11.8009 9.25877 12.3484 9.91059 12.5542 10.67C12.5394 10.738 12.5083 10.7895 12.4531 10.8445ZM13.9264 1.35C13.1765 1.35 12.5682 1.95822 12.5682 2.70815V6.52706C11.2803 5.69713 9.73176 5.27638 8.31727 5.27638C5.23183 5.27638 2.47182 7.81862 2.47182 10.8414C2.47182 11.8747 2.72217 12.8348 3.17207 13.659C2.03439 14.7445 1.35 15.9609 1.35 17.2918C1.35 18.0418 1.95822 18.65 2.70815 18.65H11.6827C15.525 18.65 18.65 15.525 18.65 11.6827V2.70815C18.65 1.95822 18.0418 1.35 17.2918 1.35C16.5419 1.35 15.9337 1.95823 15.9337 2.70815V4.10567C15.8185 4.13655 15.711 4.14993 15.6091 4.14993C15.5073 4.14993 15.3997 4.13655 15.2845 4.10567V2.70815C15.2845 1.95823 14.6763 1.35 13.9264 1.35Z"
				fill="#025AEF"
				stroke="white"
				stroke-width="1.3"
			/>
		</svg>
		<svg
			v-else
			:width="big ? 60 : 40"
			:height="big ? 60 : 40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#1CE18E" />
			<path
				d="M20.55 28.5901C19.76 28.5901 18.89 28.3101 18.02 27.7601L15.1 25.9301C14.9 25.8101 14.67 25.7401 14.44 25.7401H13C10.58 25.7401 9.25 24.4101 9.25 21.9901V17.9901C9.25 15.5701 10.58 14.2401 13 14.2401H14.43C14.66 14.2401 14.89 14.1701 15.09 14.0501L18.01 12.2201C19.47 11.3101 20.89 11.1401 22.01 11.7601C23.13 12.3801 23.74 13.6701 23.74 15.4001V24.5701C23.74 26.2901 23.12 27.5901 22.01 28.2101C21.57 28.4701 21.07 28.5901 20.55 28.5901ZM13 15.7501C11.42 15.7501 10.75 16.4201 10.75 18.0001V22.0001C10.75 23.5801 11.42 24.2501 13 24.2501H14.43C14.95 24.2501 15.45 24.3901 15.89 24.6701L18.81 26.5001C19.77 27.1001 20.68 27.2601 21.29 26.9201C21.9 26.5801 22.25 25.7301 22.25 24.6001V15.4101C22.25 14.2701 21.9 13.4201 21.29 13.0901C20.68 12.7501 19.77 12.9001 18.81 13.5101L15.88 15.3301C15.45 15.6101 14.94 15.7501 14.43 15.7501H13Z"
				fill="white"
			/>
			<path
				d="M26 24.7498C25.84 24.7498 25.69 24.6998 25.55 24.5998C25.22 24.3498 25.15 23.8798 25.4 23.5498C26.97 21.4598 26.97 18.5398 25.4 16.4498C25.15 16.1198 25.22 15.6498 25.55 15.3998C25.88 15.1498 26.35 15.2198 26.6 15.5498C28.56 18.1698 28.56 21.8298 26.6 24.4498C26.45 24.6498 26.23 24.7498 26 24.7498Z"
				fill="white"
			/>
			<path
				d="M27.83 27.2498C27.67 27.2498 27.52 27.1998 27.38 27.0998C27.05 26.8498 26.98 26.3798 27.23 26.0498C29.9 22.4898 29.9 17.5098 27.23 13.9498C26.98 13.6198 27.05 13.1498 27.38 12.8998C27.71 12.6498 28.18 12.7198 28.43 13.0498C31.5 17.1398 31.5 22.8598 28.43 26.9498C28.29 27.1498 28.06 27.2498 27.83 27.2498Z"
				fill="white"
			/>
		</svg>

		<!-- <img src="@/assets/listen.png" style="pointer-events: none" /> -->
	</div>
</template>
<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import useEventBus from '@/composables/useEventBus.js'
const emitter = useEventBus()
const props = defineProps({
	disabled: Boolean,
	src: String,
	slow: Boolean,
	big: Boolean,
	animated: Boolean,
	global: Boolean,
	keyToPlay: Number,
})

const currentSrc = ref(null)
const playTimeout = ref(null)
const audio = ref(null)
const animation = ref(false)

// Event listeners
function interruptAudio(src) {
	if (src !== props.src && audio.value) {
		audio.value.pause()
		audio.value.currentTime = 0
	}
}

function playAnimated({ src, slow = false }) {
	currentSrc.value = src

	if (slow === props.slow && src) {
		play(slow, src)
	}
}

watch(
	() => props.keyToPlay,
	() => play()
)

// Play function
async function play(slow = false, src) {
	if (props.src || src || currentSrc.value) {
		clearTimeout(playTimeout.value)

		audio.value = new Audio(props.src || src || currentSrc.value)
		audio.value.onended = () => {
			animation.value = false
			clearTimeout(playTimeout.value)
			console.log('ended')
		}

		window.dispatchEvent(new CustomEvent('interrupt-audio', { detail: props.src }))

		if (props.animated) {
			animation.value = true
		}

		if (props.slow || slow) {
			audio.value.playbackRate = 0.5
		}

		try {
			await audio.value.play()
		} catch {
			animation.value = false
		}
	}
}

// Lifecycle hooks
onMounted(() => {
	window.addEventListener('interrupt-audio', (event) => interruptAudio(event.detail))
	if (props.global) {
		// window.addEventListener('play-animated-audio', (event) => playAnimated(event.detail))
		emitter.on('play-animated-audio', (event) => playAnimated(event))
	}
})

onBeforeUnmount(() => {
	window.removeEventListener('interrupt-audio', interruptAudio)
})
</script>

<style lang="scss" scoped>
.v-audio {
	border-radius: 50%;
	width: 40px;
	min-width: 40px;
	height: 40px;

	&.big {
		width: 70px;
		min-width: 70px;
		height: 70px;
	}

	&.slow {
		padding: 6px;
		background: #fff;
	}

	&.disabled {
		filter: grayscale(100%);
		pointer-events: none;
	}

	img {
		height: 80%;
		filter: brightness(0) invert(100%);
	}

	@mixin pulse($name, $color, $count: 1) {
		animation: #{$name} 1s $count;
		@keyframes #{$name} {
			0% {
				box-shadow: 0 0 0 0 rgba($color: $color, $alpha: 0.4);
			}
			70% {
				box-shadow: 0 0 0 20px rgba($color: $color, $alpha: 0);
			}
			100% {
				box-shadow: 0 0 0 0 rgba($color: $color, $alpha: 0);
			}
		}
	}

	&.animation {
		$orange: rgba(243, 212, 6, 1);
		border: 3px solid $orange;
		&:not(.no-pulse) {
			@include pulse(pulse-selected, $orange, infinite);
		}
	}
}
</style>
