export default {
  enter_the_group_code: "Введи код группы",
  activate: "Активировать",
  ask_teacher_code: "Спроси код группы у своего учителя",
  your_groups: "Группы, в которых ты состоишь",

  incorrect_group_code: 'Ошибка! Проверь правильность кода',
  well_done: 'Успешно!',
  the_group_was_added: 'Группа добавлена',
  go_to_your_lessons: 'Перейти к урокам группы',
  incorrect_group_code: 'Неправильный код группы',
  the_group_already_exists: 'Группа уже добавлена!',
  sure_delete_group: 'Вы действительно хотите удалить группу',
  cancel: 'Отменить',
  remove: 'Удалить',
  groups: 'Группы',
  enter_code: 'Введите код!',
  check_activity_code: 'Проверьте предоставленный игровой код "{code}", так как он недействителен.',
}