<template>
  <FindWord
      :useTranslate="true"
      :noFilter="noFilter"
      :test="test"
      @done="$emit('done')"
      :fromAssessment="fromAssessment"
      :question="question"
      :index="index"
      ref="findWord"
  />
</template>

<script setup>
import FindWord from '@/screens/games/FindWord/index.vue';
import { ref } from 'vue'

const props = defineProps({
  noFilter: Boolean,
  test: Boolean,
  fromAssessment: Boolean,
  question: {},
  index: {
    type: Number,
    default: 0,
  },
});

const findWord = ref()

const getTestData = () => {
  const data = findWord.value.getTestData()
  return {
    ...data,
    type: 'lexical_find_a_value',
  }
}

defineExpose({ getTestData })

const emit = defineEmits(['done']);
</script>

<style lang="scss" scoped>

</style>
