<template>
  <div class="wrapper">
    <template v-for="(user, userIndex) in progress" :key="user.id">
      <div class="flex flex-wrap lg:flex-nowrap items-center w-full">
        <div class="flex items-center">
          <div class="mr-3 text-2xl">{{ user.position }}.</div>
          <div class="user-avatar">
            <img
              :src="JSON.stringify(user.avatar_32x32) === '{}' ? getIcon('stub_icon.png') : user.avatar_32x32"
              alt="not"
            />
          </div>
          <div class="mr-5" style="width: 200px; min-width: 200px">
            {{ user.name }} {{ user.surname }}
          </div>
        </div>

          <div
          class="v-user snail"
          v-if="progress_type === 1"
          :class="{ signle: progress.length === 1 }"
        >
          <!-- Улитка -->
          <div class="w-full relative">
            <div class="v-user__progress" style="overflow: hidden">
              <div
                class="v-user__progress-bar"
                :style="{ width: `${Math.ceil(user.percent)}%` }"
              >
                <div class="blink-decoration"></div>
                <div
                  class="progress-icon"
                  v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
                >
                  <img
                    class="snail-decoration"
                    src="@/assets/activityProgressBars/snail_path.png"
                  />
                </div>
              </div>
            </div>
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
              "
            >
              <div
                style="position: absolute; top: 0; left: 0; height: 100%"
                :style="{ width: `${Math.ceil(user.percent)}%` }"
              >
                <div
                  class="progress-icon"
                  style="z-index: 2"
                  v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
                >
                  <img
                    class="progress-icon__img"
                    :src="getIcon(`activityProgressBars/2/${getUserIndex(userIndex)}.png`)"
                    alt=""
                  />
                </div>
                <div class="progress-cloud snail" style="z-index: 2">
                  {{ Math.ceil(user.percent) }}%
                </div>
              </div>
              <img
                class="finish-icon"
                :src="getIcon(`activityProgressBars/finish/2/${getUserIndex(userIndex)}.png`)"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          class="v-user car"
          v-else-if="progress_type === 2"
          :class="{ signle: progress.length === 1 }"
        >
          <!-- Улитка -->
          <div class="v-user__progress">
            <div
              class="v-user__progress-bar"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
            >
              <div class="progress-cloud car">
                {{ Math.ceil(user.percent) }}%
              </div>
              <div
                class="progress-icon"
                v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
              >
                <img
                  class="progress-icon__img"
                  :src="getIcon(`activityProgressBars/1/${getUserIndex(userIndex)}.png`)"
                  alt=""
                />
              </div>
            </div>
            <div class="path-decoration"></div>
            <img
              class="finish-icon"
              :src="getIcon(`activityProgressBars/finish/1/${getUserIndex(
                userIndex
              )}.png`)"
              alt=""
            />
          </div>
        </div>
        <div
          class="v-user colored"
          v-else-if="progress_type === 3"
          :class="{ signle: progress.length === 1 }"
        >
          <!-- Улитка -->
          <div class="v-user__progress">
            <div
              class="v-user__progress-bar"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
              v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
            >
              <div class="progress-cloud colored">
                {{ Math.ceil(user.percent) }}%
              </div>
            </div>
            <div
              class="circle-decorations"
              :style="{
                'clip-path': `inset(0 ${100 - Math.ceil(user.percent)}% 0 0)`,
                zIndex: 2,
              }"
              style="pointer-events: none"
            >
              <div
                class="circle-decorations__circle"
                v-for="i in 20"
                :key="i"
                style="pointer-events: none"
              ></div>
            </div>
            <img
              class="finish-icon"
              src="@/assets/activityProgressBars/finish/colored.png"
              alt=""
            />
          </div>
        </div>

        <div
          class="v-user angel"
          v-else-if="progress_type === 4"
          :class="{ signle: progress.length === 1 }"
        >
          <div class="v-user__progress">
            <div
              class="v-user__progress-back"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
              :class="`type-${getUserIndex(userIndex)}`"
            ></div>
            <div
              class="v-user__progress-bar"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
              :class="`type-${getUserIndex(userIndex)}`"
            >
              <div class="progress-cloud car" style="top: -60px; right: -50px">
                {{ Math.ceil(user.percent) }}%
              </div>
              <div
                class="v-user__progress-special"
                :class="`type-${getUserIndex(userIndex)}`"
              >
<!--                <img-->
<!--                  src="@/assets/quests/valentine/progress_decoration2.png"-->
<!--                  alt=""-->
<!--                />-->
              </div>
              <div
                class="progress-icon"
                v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
              >
                <img
                  class="progress-icon__img"
                  :src="getIcon(`quests/valentine/p-angel${getUserIndex(
                    userIndex,
                    3
                  )}.png`)"
                  alt=""
                />
              </div>
            </div>
<!--            <img-->
<!--              class="finish-icon"-->
<!--              src="@/assets/quests/valentine/cubok.png"-->
<!--              alt=""-->
<!--            />-->
          </div>
          <!-- <div style="margin-left: 50px; color: #fff; font-size: 35px;">{{ Math.ceil(user.percent) }}%</div> -->
        </div>

        <div
          class="v-user christmas"
          v-else-if="progress_type === 5"
          :class="{ signle: progress.length === 1 }"
        >
          <!-- <div class="v-user__box" @click="$emit('watch', user.uId)" style="margin-right: 50px; margin-left: 50px; cursor: pointer; pointer-events: all">
            <div class="v-user__avatar"><img :src="`/images/activityAvatars/2/${user.avatarId}.png`" alt=""></div>
            <div class="v-user__name"><shortify :length="6"  class="comforta bold" :name="user.name" /></div>
          </div> -->
          <div class="v-user__progress">
            <div
              class="v-user__progress-bar"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
              :class="`type-1`"
            >
              <div class="progress-cloud car" style="top: -60px; right: -25px">
                {{ Math.ceil(user.percent) }}%
              </div>
              <div class="v-user__progress-special" :class="`type-1`">
                <img
                  src="@/assets/activityProgressBars/5/decoration.png"
                  alt=""
                />
              </div>
              <div class="progress-icon">
                <img
                  class="progress-icon__img"
                  :src="getIcon(`activityProgressBars/5/${getUserIndex(
                    userIndex,
                    6
                  )}.png`)"
                  alt=""
                />
              </div>
            </div>
            <img
              class="finish-icon"
              :src="getIcon(`activityProgressBars/finish/5/${getUserIndex(
                userIndex,
                6
              )}.png`)"
              alt=""
            />
          </div>

          <!-- <div style="margin-left: 50px; color: #fff; font-size: 35px;">{{ Math.ceil(user.percent) }}%</div> -->
        </div>
        <div
          class="v-user helloween"
          v-else-if="progress_type === 6"
          :class="{ signle: progress.length === 1 }"
        >
          <!-- <div class="v-user__box" @click="$emit('watch', user.uId)" style="margin-right: 50px; margin-left: 50px; cursor: pointer; pointer-events: all">
            <div class="v-user__avatar"><img :src="`/images/activityAvatars/2/${user.avatarId}.png`" alt=""></div>
            <div class="v-user__name"><shortify :length="6"  class="comforta bold" :name="user.name" /></div>
          </div> -->
          <div class="v-user__progress">
            <div
              class="v-user__progress-bar"
              :style="{ width: `${Math.ceil(user.percent)}%` }"
              :class="`type-${getUserIndex(userIndex, 6)}`"
            >
              <div
                class="progress-cloud colored"
                style="top: -80px; right: -30px"
              >
                {{ Math.ceil(user.percent) }}%
              </div>
              <!--                      <div class="v-user__progress-special" :class="`type-1`">-->
              <!--                          <img src="/images/activityProgressBars/5/decoration.png" alt="">-->
              <!--                      </div>-->
              <div
                class="progress-icon"
                v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }"
              >
                <img
                  class="progress-icon__img"
                  :src="getIcon(`activityProgressBars/6/${getUserIndex(
                    userIndex,
                    6
                  )}.png`)"
                  alt=""
                />
              </div>
            </div>
            <img
              class="finish-icon"
              :src="getIcon(`activityProgressBars/finish/6/${getUserIndex(
                userIndex,
                6
              )}.png`)"
              alt=""
            />
          </div>
        </div>
        <div class="v-user easter" v-else-if="progress_type === 7" :class="{ signle: progress.length === 1 }">
          <div class="v-user__progress">
            <div class="v-user__progress-bar" :style="{ width: `${Math.ceil(user.percent)}%` }" :class="`type-${getUserIndex(userIndex, 7)}`">
              <div class="progress-cloud colored" style="top: -80px;right: -30px;">{{ Math.ceil(user.percent) }}%</div>
              <!--                      <div class="v-user__progress-special" :class="`type-1`">-->
              <!--                          <img src="/images/activityProgressBars/5/decoration.png" alt="">-->
              <!--                      </div>-->
              <div class="progress-icon" v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }">
                <img class="progress-icon__img" :src="getIcon(`activityProgressBars/7/${getUserIndex(userIndex, 7)}.png`)" alt="">
              </div>
            </div>
            <img class="finish-icon" :src="getIcon(`activityProgressBars/finish/7/${getUserIndex(userIndex, 7)}.png`)" alt="">
          </div>
        </div>
          <div class="v-user panda" v-else-if="progress_type === 8" :class="{ signle: progress.length === 1 }">
              <div class="v-user__progress">
                  <div class="v-user__progress-bar" :style="{ width: `${Math.ceil(user.percent)}%` }" :class="`type-${getUserIndex(userIndex, 7)}`">
                      <div class="progress-cloud colored" style="top: -68px;right: -30px;">{{ Math.ceil(user.percent) }}%</div>
                      <!--                      <div class="v-user__progress-special" :class="`type-1`">-->
                      <!--                          <img src="/images/activityProgressBars/5/decoration.png" alt="">-->
                      <!--                      </div>-->
                      <div class="progress-icon" v-tooltip="{
                    content: `${$t('lesson.learnedWords')}: ${
                      user.learn_words || '?'
                    } <br> ${$t('lesson.completeLessons')}: ${
                      user.complete_lessons
                    } <br> ${$t('lesson.avgResult')}: ${user.avg_result}`,
                    classes: ['custom-tooltip'],
                  }">
                          <img class="progress-icon__img" :src="getIcon(`activityProgressBars/8/${getUserIndex(userIndex, 7)}.png`)" alt="">
                      </div>
                  </div>
                  <img class="finish-icon" :src="getIcon(`activityProgressBars/finish/8/${getUserIndex(userIndex, 7)}.png`)" alt="">
              </div>
          </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const props = defineProps({
  progress: {
    type: Array,
    default: () => []
  },
  progress_type: {
    type: [Number, String],
    default: 1
  }
})

const store = useStore()
const getUserIndex = (userIndex, indexPos = 7) => {
  if (userIndex < indexPos) return userIndex + 1;
  else {
    userIndex -= parseInt(userIndex / indexPos) * indexPos;
    return userIndex + 1
  }
}
const profile = computed(() => store.getters.profile);

const getIcon = path => {
  console.log(path)
  return new URL(`../assets/${path}`, import.meta.url).href
}
</script>

<style lang="scss" scoped>
.user-avatar {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-right: 20px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.283);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}
.progress-cloud {
  position: absolute;
  top: -50px;
  right: -60px;

  width: 45px;
  height: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.171));
  font-size: 19px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 90%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  &.snail {
    background: linear-gradient(
      -180deg,
      rgb(69, 146, 63) 0%,
      rgb(113, 189, 110) 100%
    );
    &::after {
      border-top: 8px solid rgb(113, 189, 110);
    }
  }

  &.car {
    background: linear-gradient(
      -180deg,
      rgb(75, 149, 198) 0%,
      rgb(118, 180, 190) 100%
    );
    &::after {
      border-top: 8px solid rgb(118, 180, 190);
    }
  }

  &.colored {
    right: -15px;
    background: linear-gradient(
      -180deg,
      rgb(121, 121, 121) 0%,
      rgb(182, 182, 182) 100%
    );
    &::after {
      border-top: 8px solid rgb(182, 182, 182);
    }
  }
}

.v-user__progress-bar {
  z-index: 2;
}

@media (max-width: 1024px) {
  .v-user {
    zoom: 0.6;
  }
}

.v-user.christmas {
  .v-user__progress {
    border-radius: 25px;
    background-color: #fff;
    height: 40px;
    border-bottom: unset;
  }

  .progress-icon {
    top: -20%;
    right: -28px;
  }

  .v-user__progress-bar {
    background-image: linear-gradient(
      180deg,
      rgb(38, 152, 38) 0%,
      rgb(3, 71, 4) 100%
    );
  }

  .progress-icon__img {
    min-width: 50px;
  }

  .v-user__progress-special {
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateY(-25%);
  }
  .finish-icon {
    position: absolute;
    height: 115px;
    right: -30px;
    bottom: -30px;
  }
}

.v-user.angel {
  .finish-icon {
    height: 75px;
    right: -35px;
    bottom: -15px;
  }
  .v-user__progress {
    border-radius: 3px;
    background-image: linear-gradient(
      180deg,
      rgb(255, 255, 255) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.19),
      inset 0px 1px 1px 0px rgba(0, 0, 0, 0.44);
    height: 40px;
    border-bottom: unset;
  }
  .progress-icon {
    top: -70%;
    right: -30px;
    height: 85px;
    transform: translateX(10%);
    z-index: 2;

    &__img {
      min-width: 80px;
      object-fit: contain;
    }
  }
  .v-user__progress-special {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;

    &.type-2 img {
      transform: translate(-30%, -50%);
    }

    img {
      position: absolute;
      top: 50%;
      right: 0;
      height: 65%;
      transform: translate(-75%, -50%);
    }
  }
  .v-user__progress-back {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    height: 100%;
    transform: translate(4px, -5px);

    &.type-1 {
      background-color: #780289;
    }
    &.type-2 {
      background-color: #2664ba;
    }
    &.type-3 {
      background-color: #8b2209;
    }
    &.type-4 {
      background-color: #148b37;
    }
    &.type-5 {
      background-color: #b4760b;
    }
    &.type-6 {
      background-color: #322aa5;
    }
    &.type-7 {
      background-color: #4f5153;
    }
  }
  .v-user__progress-bar {
    background-color: #a0a1a2;
    border-radius: 3px;

    transform: translate(4px, -9px);
    box-shadow: 0 0 10px -5px black;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: #787a7c;
      border-radius: 3px;
    }

    &.type-1 {
      background-color: #e334c6;
      &::after {
        background-color: #d31eb0;
      }
    }
    &.type-2 {
      background-color: #6db2ee;
      &::after {
        background-color: #408ee4;
      }
    }
    &.type-3 {
      background-color: #d76029;
      &::after {
        background-color: #c3360f;
      }
    }
    &.type-4 {
      background-color: #46cb7c;
      &::after {
        background-color: #46cb7c;
      }
    }
    &.type-5 {
      background-color: #e8bc30;
      &::after {
        background-color: #dfa214;
      }
    }
    &.type-6 {
      background-color: #8376ee;
      &::after {
        background-color: #594ce4;
      }
    }
    &.type-7 {
      background-color: #a0a1a2;
      &::after {
        background-color: #787a7c;
      }
    }
  }
}

.progress-icon {
  position: absolute;
  top: 50%;
  right: 0;

  &__img {
    height: 100%;
    z-index: 2;
    position: relative;
  }
}

.v-user.novakid {
  .progress-icon {
    height: 85px;
    transform: translateY(-47%) translateX(36%);
    &__img {
      min-width: 70px;
      max-width: 171px;
    }
  }

  .progress-cloud {
    background-color: #8432d9;

    &:after {
      border-top: 8px solid #8432d9;
    }
  }

  .v-user__progress-bar {
    background: #8432d9;
    box-shadow: inset 0px 2px 0px 0px rgba(255, 255, 255, 0.45);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50px;
      //background: repeating-linear-gradient(
      //                -45deg,
      //                transparent,
      //                transparent 15px,
      //                #629d5d 0px,
      //                #629d5d 18px
      //);
    }
  }
  .finish-icon {
    transform: translateX(41%);
    bottom: -26px;
  }
}

.v-user.snail {
  .progress-icon {
    height: 85px;
    transform: translateY(-63%) translateX(50%);
    &__img {
      min-width: 100px;
    }
  }
  .v-user__progress-bar {
    background-image: linear-gradient(
      180deg,
      rgb(129, 193, 117) 0%,
      rgb(58, 122, 47) 100%
    );
    box-shadow: inset 0px 2px 0px 0px rgba(255, 255, 255, 0.45);
    border: 2px solid #306d26;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50px;
      background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 15px,
        #629d5d 0px,
        #629d5d 18px
      );
    }
  }
  .finish-icon {
    transform: translateX(50%);
  }
}

.v-user.car {
  .v-user__progress-bar {
    background-image: linear-gradient(
      180deg,
      rgb(113, 179, 183) 0%,
      rgb(45, 130, 196) 100%
    );
    box-shadow: inset 0px 2px 0px 0px rgba(255, 255, 255, 0.45);
    border-top: 2px solid #b7d7db;
  }
  .progress-icon {
    height: 70px;
    transform: translateY(-50%) translateX(50%);
    z-index: 1;
    &__img {
      min-width: 135px;
    }
  }
  .finish-icon {
    transform: translateX(50%);
  }
}

.v-user.easter {

  .progress-icon {
    position: absolute;
    width: 178px;
    top: -71px;
    right: -101px;
  }

  .progress-icon__img {
    max-height: 178px;
    min-height: 178px;
    max-width: 178px;
    min-width: 178px;
  }

  .v-user__progress-bar {
    &.type-1{
      background: #d76788;
    }
    &.type-2{
      background: #e5980d;
    }
    &.type-3{
      background: #487de4;
    }
    &.type-4{
      background: #40c50f;
    }
    &.type-5{
      background: #de3236;
    }
    &.type-6{
      background: #9264c9;
    }

    &.type-7{
      background: #ee26e5;
    }
  }

  .finish-icon {
    position: absolute;
    height: 114px;
    right: -60px;
    bottom: -15px;
  }
}

.v-user.panda {

  .progress-icon {
    position: absolute;
    width: 139px;
    top: -37px;
    right: -101px;
  }

  .progress-icon__img {
    max-height: 110px;
    min-height: 110px;
    max-width: 110px;
    min-width: 110px;
  }

  .v-user__progress-bar {
    &.type-1 {
      background: linear-gradient(70deg, #e8a521, #f6f767);
    }

    &.type-2 {
      background: #53dc82;
    }

    &.type-3 {
      background: #a56ef3;

      & ~ .finish-icon {
        height: 113px;
      }
    }

    &.type-4 {
      background: #45ced4;
    }

    &.type-5 {
      background: #dd308f;
    }

    &.type-6 {
      background: #f0f26b;
    }

    &.type-7 {
      background: #dc342a;
    }
  }

  .finish-icon {
    position: absolute;
    height: 137px;
    right: -60px;
    bottom: -29px;
  }
}

.v-user.colored {
  .v-user__progress-bar {
    background-image: linear-gradient(
      180deg,
      rgb(220, 107, 59) 0%,
      rgb(215, 83, 63) 100%
    );
    box-shadow: inset 0px 2px 0px 0px rgba(255, 255, 255, 0.45);
    border-top: 2px solid rgb(215, 83, 63);
  }
  .finish-icon {
    bottom: 1px;
    right: 10px;
  }
}

.circle-decorations {
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  &__circle {
    width: 3%;
    padding-bottom: 3%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
    filter: blur(1px);
  }
}

.finish-icon {
  position: absolute;
  height: 100px;
  right: 0;
  bottom: 10px;
}

.path-decoration {
  position: absolute;
  top: 50%;
  left: 4%;
  width: 92%;
  border-bottom: 4px dashed #fff;
}

.snail-decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -35%);
  z-index: 1;
  min-width: 400px;
}

.v-user.helloween {
  .progress-icon {
    position: absolute;
    min-height: 178px;
    max-height: 178px;
    max-width: 178px;
    top: -71px;
    right: -101px;
    min-width: 178px;
  }

  .progress-icon__img {
    display: block;
    min-height: 178px;
    min-width: 178px;
    max-height: 178px;
    max-width: 178px;
  }

  .v-user__progress-bar {
    &.type-1 {
      background: #d76788;
    }
    &.type-2 {
      background: #e5980d;
    }
    &.type-3 {
      background: #487de4;
    }
    &.type-4 {
      background: #40c50f;
    }
    &.type-5 {
      background: #de3236;
    }
    &.type-6 {
      background: #9264c9;
    }

    &.type-7 {
      background: #ee26e5;
    }
  }
}

.blink-decoration {
  position: absolute;
  width: 70%;
  height: 10px;
  border-radius: 25px;
  background-color: rgba($color: #fff, $alpha: 0.5);
  top: 10%;
  left: 5%;
}
.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    padding: 15px;
    padding-right: 50px;
  }

  & * {
    pointer-events: all;
  }

  .v-user {
    width: 100%;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    height: 150px;
    box-sizing: border-box;
    // margin-bottom: 10px;
    margin-right: auto;

    &.signle {
      width: 100%;
      margin-right: unset;
    }

    &__box {
      height: 70px;
      display: flex;
      align-items: center;
      border-radius: 12px;
      padding: 5px;
      margin-right: 150px;
    }

    &__avatar {
      height: 70px;
      width: 70px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: #fff;
      padding: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__name {
      height: 25px;
      color: #fff;
      font-size: 24px;
      width: 120px;
    }

    &__progress {
      width: 100%;
      height: 50px;
      position: relative;
      background-color: rgb(217, 225, 238);
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.3),
        inset 0px 1px 12px -4px rgb(0, 0, 0);
      border-radius: 50px;
      border-bottom: 1px solid #ffffff;
    }

    &__progress-bar {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s linear;
      border-radius: 50px;
      box-sizing: border-box;
      // box-shadow: inset 0px 1px 9px -3px black;
    }
  }
}
</style>
