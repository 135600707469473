<template>
	<div class="v-progress" :class="type">
		<div class="v-progress__line" :style="{ width: `${percent}%` }" :class="{ reverted: revertedSlice }"></div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'actual',
		},
		percent: {
			type: [String, Number],
			default: 0,
		},
		revertedSlice: Boolean,
	},
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.v-progress {
	width: 100%;
	height: 8px;
	background-color: rgb(236, 236, 236);
	border-radius: 20px;
	overflow: hidden;
	position: relative;

	&.actual {
		background-color: #deebf8;

		.v-progress__line {
			background-color: #1CE18E;
		}
	}

	&.lesson {
		.v-progress__line {
			background-color: #cf6e49;
		}
	}

	&.expired {
	}

	&.done {
		.v-progress__line {
			background-color: #1CE18E;
		}
	}

	&.new-gray,
	&.new {
		background: $lightGrey;
		// opacity: 0.2;
		border-radius: 64px;

		.v-progress__line {
			border-top-right-radius: 64px;
			border-bottom-right-radius: 64px;
			// background-color: red;
			background: $baseGreen;
		}
	}

	&.new-gray {
		background: #d9dce6;
	}

	&__line {
		background-color: rgb(168, 168, 168);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	width: 5%;
		// 	height: 100%;
		// 	background-color: inherit;
		// 	clip-path: polygon(0 0, 46% 0, 100% 100%, 0% 100%);
		// 	transform: translateX(90%);
		// }

		// &.reverted::after {
		// 	transform: translateX(90%) rotateX(180deg);
		// }
	}
}
</style>
