export default {
	сompleted_percent: 'Completed {p1}%',
	words: 'Words',
	words_count: 'words',
	tasks: 'Tasks',

	learn_word: 'Check yourself',
	listen: 'Listen',
	find_word: 'Match',
	snake: 'Snake',
	scrabble: 'Unscramble',
	quiz: 'Quiz',
	remember: 'Remember',
	listen_type: 'Listen and type in',
	find_words: 'Find the word',
	find_definition: 'Find the definition',
	write_word: 'Type in',
	craft_answer: 'Gather an answer',
	insert_missed: 'Fill in',
	final_test: 'Final test',
	fillFields: 'Fill in all the fields!',
	message: 'Message',

	learn_word_short: 'Check yourself',
	listen_short: 'Listen',
	find_word_short: 'Match',
	snake_short: 'Snake',
	scrabble_short: 'Unscramble',
	remember_short: 'Remember',
	listen_type_short: 'Listen and type in',
	quiz_short: 'Quiz',
	find_words_short: 'Find the word',
	find_definition_short: 'Find the definition',
	write_word_short: 'Type in',
	insert_missed_short: 'Fill in',
	final_test_short: 'Final test',
	learnedWords: 'Learned words',
	completeLessons: 'Complete lessons',
	avgResult: 'Avg result',

	test_closed: '(closed - first do all the tasks)',
	complete_all: 'Complete all the tasks',
	test_open: '(open)',
	test: 'Test',

	translation: 'Translation',
	word: 'Word',
	audio: 'Audio',
	listen_do: 'Listen',
	date: 'Date',
	result: 'Result',
	mistakes: 'Mistakes',
	no_mistakes: 'No mistakes',
	no_test_results: 'No test results',
	video_to_complete: 'To finish the lesson watch the video and complete the tasks',
	test_not_avaiable: 'The test is no longer available',
	enter_text: 'Enter the text',

	multiple: 'Multiple choice',
	true_false: 'True / False',
	make_up: 'Make up the sentences',
	fill_gaps: 'Drag and drop',
	fill_gaps_input: 'Fill in the gaps',
	choose: 'Choose the correct option',
	find_a_pair: 'Matching',
	classification: 'Categories',
	order_of_proposals: 'Order the sentences',
	open_question: 'Open question',
	text: 'Text',
	image: 'Image',
	media: 'Mediafile',

	order: 'Timeline',
	voice_record: 'Voice recorder',
	partition: 'New section',
	mistake: 'Correct the mistake',
	interactive: 'Interactive worksheet',
	except: 'Find the odd one out',
	suggestion: 'Request a task',
	show_mistakes: 'Mistakes',
	result: 'Overall score',
	time_test: 'Test time',
	not_completed: 'Not completed',
	result_after_check: 'You will see the result after your teacher checks your answer',
	teacher_check: 'Under review',

	teacher_variant: 'Teacher’s variant',
	student_variant: 'Student’s variant',
	comments: 'Comments',

	press_mic: 'Click on «mic» to start recording or pause. Click «stop» to finish recording',
	record_limit: 'Recording time limit',
	no_mic: 'No access to mic',
	rec_audio: 'Record audio',
	finish: 'Finish',

	open_full_screen: 'Enable fullscreen mode',
	close_full_screen: 'Disable fullscreen mode',
	lock_game: 'Complete all tasks to open the test',

	progress: 'Progress',
	lexical_words: 'Words',
	lexical_tasks: 'Tasks',
	lexical_test: 'Test',
	complete_all_tasks: 'Complete all tasks',
	print: 'Print',
	back: 'Back',
	next: 'Next',
	my_dictionary: 'My dictionary',
	drag_definition_and_voiceover: 'Drag and drop the definition and voice over to the term',
	memorized: 'Memorized',
	drag_and_drop: 'Drag and drop',
	restart: 'Restart',
	hint: 'Hint',
	connect_voiceover_and_definition: 'Connect voice over with definition',
	connect_voiceover_and_word: 'Connect voice over with word',
	choose_right_answer: 'Choose the right answer',
	pair_yellow_cards: 'Find a pair for a yellow card',
	type_what_heard: 'Type what you heard',

	comments_chat_title: 'Comments',
	comments_chat_messages_placeholder: 'To start chat send a message',
	comments_input_placeholder: 'Comment',

	true: 'True',
	false: 'False',
	'not-stated': 'Not stated',

	'waiting-for-connection': 'Waiting for connection',
	'dont-close-page': 'Please, do not close or refresh this page to prevent data loss',

	draft_deleted: 'The teacher has changed the assignment, please take it again.',

	access_restricted: 'Access is restricted',
	write_teacher_about_access: 'Contact your teacher to continue using the platform',
	request_teacher_to_buy_subscription:
		"Hello. I can't open your lesson because your subscription has not been renewed.",
	write_teacher: 'Write to the teacher',
}
