const formatMessage = (message, index = 0) => {
	const files = message.attachment
		? [
				{
					name: `file${index}`,
					audio: message.attachment.path.split('.').slice(-1)[0] === 'mp3',
					url: message.attachment.path,
					type: message.attachment.path.split('.').slice(-1)[0],
				},
			]
		: []

	const updatedAt = new Date(message.updated_at)
	const createdAt = new Date(message.created_at)

	return {
		_id: String(message.id),
		indexId: index,
		content: message.message,
		senderId: String(message.user_from_id),
		username: message.name,
		date: createdAt.toDateString(),
		distributed: true,
		timestamp: `${updatedAt.getHours()}:${updatedAt.getMinutes()}`,
		files: files,
		seen: !!message.readed,
	}
}

const validateFiles = (files) => {
	const result = {
		validated: [],
		unvalidated: [],
	}
	files.forEach((file) => {
		if (file.size > 20480 * 1000) result.unvalidated.push(file)
		else result.validated.push(file)
	})

	return result
}

export { formatMessage, validateFiles }
