import { createI18n } from 'vue-i18n'

import ru from './locale/ru/index.js'
import en from './locale/en/index.js'

const messages = {
	ru,
	en,
}

const i18nConf = {
	locale: localStorage.getItem('lang') || 'ru',
	allowComposition: true,
	messages,
	pluralizationRules: {
		ru: function (choice) {
			if (choice === 0) {
				return 0
			}

			if (choice !== 11 && !(choice >= 12 && choice <= 14)) {
				let lastDigit = choice % 10
				if (lastDigit === 1) {
					return 1
				}

				if (lastDigit >= 2 && lastDigit <= 4) {
					return 2
				}
			}

			return 3
		},
	},
}

export default createI18n(i18nConf)
export const setupI18n = () => createI18n(i18nConf)
