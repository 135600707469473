export default {
	learnt_out_of: 'Learned {p1} out of {p2}',
	learnt_out_of_short: '{p1} out of {p2}',
	learnt_progress: 'Progress',
	i_dont_know: 'I don’t know',
	i_know: 'I know',
	hint: 'Hint',
	restart: 'Restart',
	click_on: 'Click on a word if you don’t know its translation',
	ok: 'OK',
	word: 'word',
	resart: 'Resart',
	type_in_the_translation: 'Type in',
	next: 'Next',
	check: 'Проверить',
	send_for_checking: 'Send for checking',

	complete_all_tasks: 'Complete all the tasks and pass the test',
	the_test_will_be: 'The test will be available only when all the tasks are completed',
	teachers_note: 'Teacher’s note',
	all_the_tasks: 'all the tasks are completed',
	the_test_will_be_available: 'The test will be available only when you complete all the tasks',
	correct_your_mistake: 'Correct your mistake',
	complete_all_fields: 'Complete all the fields',
	are_you_sure: 'Are you sure?',
	confirm: 'Confirm',
	try_again: 'Try again',
	do_it_again: 'Try again',
	try_it_again: 'Try again',
	to_improve_the_result: 'To improve the result complete all the tasks again and pass the test',
	improve_the_result: 'Improve the result',
	finish_the_lesson: 'Finish the lesson',
	you_can_do_it_better: 'You can do it better!',
	test_results: 'Test results',
	experience: 'experience',
	you_are_the_best: 'You are the best! Go on this way!',
	perfect: 'Perfect!',
	well_done: 'Well done!',
	good_but: 'Good, but you can do it better',
	if_you_get: 'If you get 90%, you can improve your result without completing all the tasks',
	you_did_it: 'You did it!',
	next_task: 'Next task',
	to_complete: 'To complete the task you must get 90%',
	your_result: 'Your result',
	result: 'Result',
	date: 'Date',
	mistakes: 'Mistakes',
	no_mistakes: 'No mistakes',
	new_words: 'new words',
	click_on_the_letter: 'Click on the letter to return',
	take_the_test: 'Take the test',
	enter_answer: 'Enter your answer',
	in_progress: 'Progress',
	sec: 'sec',
	no_internet: 'Load failed. No internet connection',
	oops_time_is_up: 'Oops, time is up!',
}
