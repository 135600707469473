<template>
  <div class="folder-sections" @click="section.isOpen = !section.isOpen">
    <div
      class="tree-node__wrapper unit-node__wrapper flex items-center justify-between cursor-pointer unit-node rounded-xl"
      @click="openSection()"
    >
      <div class="flex items-center">
        <img
          :src="section.image || ''"
          class="img-section rounded-lg mr-2"
		  @error="handleImageError"
        />
        <!--        <div class="hidden lg:block v-folder-lesson__name">-->
        <!--          {{ section.name }}-->
        <!--        </div>-->
        <div class="group-name">{{ section.name }}</div>
      </div>
      <img
        class="arrow-expanded"
        :class="{ horizontal: !isOpened }"
        src="@/assets/arrow_expanded.png"
        alt=""
      />
    </div>
    <div v-if="isOpened" class="mt-3 w-full" :class="'lg:pl-8'">
      <div class="w-full rounded-xl" style="background: #fff">
        <div
          class="mb-2"
          v-for="lesson in getLessons"
          :key="`lesson-${section.name}-${lesson.id}`"
        >
          <FolderLesson :lesson="lesson" :group="group" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import defaultIcon from '@/assets/img-stub.svg'

function handleImageError(event) {
	event.target.src = defaultIcon // Устанавливаем стандартный иконку
}
</script>

<script>
import FolderLesson from "./FolderLesson.vue";
import { mapActions } from "vuex";

export default {
  name: "FolderSection",
  components: { FolderLesson },
  props: {
    section: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    getLessons() {
      if (!this.section || !this.section.lessons) return [];
      return this.section.lessons.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
  methods: {
    ...mapActions(["GET_SECTION_LESSONS"]),
    openSection() {
      this.isOpened = !this.isOpened;
    },
  },
  watch: {
    "section.isOpen"(value) {
      if (value) {
        this.GET_SECTION_LESSONS({
          groupId: this.group.id,
          sectionId: this.section.id,
          isRootSection: Number(this.section.is_root) === 1,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-section {
  width: 3rem;
  min-width: 3rem;
  min-height: 3rem;
}
.folder-mini-icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
}
img.horizontal {
  transform: rotate(-90deg);
}
.arrow-expanded {
  opacity: 0.75;
  transition: all 0.2s ease;
}
.icon-status-plus {
  position: absolute;
  color: #fff;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
}
.unit-node {
  background-color: #fff;
  padding: 10px 10px;
  //border-radius: 30px;
  color: #404040;
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    background-color: #fbfbfb;
  }

  &:hover {
    box-shadow: 0px 5px 30px 0px rgba(0, 174, 239, 0.1);
  }

  &.reset {
    background-color: transparent;
    &:hover {
      box-shadow: none;
    }
  }
}
.group-name.reset {
  font-size: 20px;
  font-weight: bold;
}
.tree-node {
  //border-radius: 30px;
  background-color: rgb(250, 250, 247);
  padding: 10px 20px;
  color: #404040;

  &:hover {
    box-shadow: 0 2px 6px -3px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 1024px) {
    padding: 5px 10px;
  }
}

.tree-node,
.unit-node {
  &:hover .arrow-expanded {
    opacity: 1;
  }
}
</style>
