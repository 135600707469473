export const getTimeString = (seconds) => {
    if (isNaN(seconds)) {
        return '--:--'
    }

    let isNegative = false
    if (seconds < 0) {
        isNegative = true
    }

    const min = Math.floor(Math.abs(seconds) / 60);
    const sec = Math.floor(Math.abs(seconds) % 60);

    const minStr = min >= 10 ? String(min) : "0" + min
    const secStr = sec >= 10 ? String(sec) : "0" + sec

    return `${isNegative ? '-' : ''}${minStr}:${secStr}`;
};