<template>
  <transition name="fade" mode="out-in">
    <div class="v-modal" @click.self.stop="$emit('close')">
      <div class="v-modal__wrapper" @click.stop>
        <div class="w-full flex items-center justify-between mb-5">
          <div class="v-text--bold text-xl">{{ $t('messages.mistakes') }}</div>
          <img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click.stop="$emit('close')">
        </div>
        <div class="v-modal__body" v-if="!isLoading">
          <template v-if="Object.keys(getMistakesGroups).length > 0">
            <div v-for="(mistakeGroup, type) in getMistakesGroups" :key="type">
              <div class="v-text--bold my-2" v-if="type !== 'default'">
                  {{ getTitleByType(type) }}
              </div>
              <div v-for="(mistake, mistake_index) in mistakeGroup" :key="mistake_index">
                <template v-if="!mistake.set">
                  <span class="cursor-pointer" style="color: green;">{{ mistake.word || mistake.text }}</span> - <span style="color: #c95c48;" >{{ mistake.error_word || mistake.answer_text }}</span>
                </template>
                <template v-else>
                  <span class="cursor-pointer" style="color: green;">{{ mistake.word }}</span>
                </template>
              </div>
            </div>
          </template>
          <div v-else class="w-full text-center">{{ $t('messages.not_mistakes') }}</div>
        </div>
        <div v-else-if="isLoading" class="flex justify-center items-center py-20">
          <loader />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import groupBy from 'lodash/groupBy';
import { getLessonsTestResults } from '../../api/lessons';

export default {
  props: {
    lesson: Object,
  },
  data: () => ({
    loading: true,
    show: false,
    results: [],
    isLoading: false,

    types: [
      { title: 'Найди слово', type: 'find_words', minWords: 5, words: [] },
      { title: 'Найди определение', type: 'find_definition', minWords: 5, words: [] },
      { title: 'Квиз', type: 'quiz', minWords: 1, words: [] },
      { title: 'Послушай', type: 'listen', minWords: 1, words: [] },
      { title: 'Скрембл', type: 'scrabble', minWords: 1, words: [] },
      { title: 'Заполни пропуски', type: 'insert_missed', minWords: 1, words: [] },
      { title: 'Послушай впиши', type: 'listen_type', minWords: 1, words: [] },
      { title: 'Письмо', type: 'write_word', minWords: 1, words: [] },
    ]
  }),
  async created() {
    try {
      this.isLoading = true;
      const { data } = await getLessonsTestResults(this.lesson.id);
      this.results = data.data;
    } catch (error) {
      console.log('err', error)
    } finally {
      this.isLoading = false;
    }
  },
  // created() {
  //   this.$root.$on('lexical-test-result', async ({ lesson, results }) => {
  //     this.lesson = lesson;
  //     this.results = results;
  //     this.show = true;
  //   })
  // },
  methods: {
    getTitleByType(type) {
      return (this.types.find(el => el.type === type) || {}).title;
    },
  },
  computed: {
    getLastResult() {
      return this.results && this.results[0] && this.results[0].test[this.results.length - 1]
    },
    getMistakesGroups(lessonGroup) {
      if (!this.getLastResult) return [];
      const mistakes = (this.getLastResult.mistakes_in_words || []).filter(el => !el.credited);
      return groupBy(mistakes, 'test_type');
    },
  }
}
</script>

<style lang="scss" scoped>
.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__wrapper {
    background-color: #fff;
    // background: url(/images/modal-pattern.png);
    background-size: cover;
    border-radius: 12px;
    padding: 10px;

    position: relative;

    opacity: 0;
    width: 500px;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }
  }

  &__body {
    max-height: 300px;
    overflow: auto;
  }

  &__close {
    right: 10px;
    width: 25px;
    cursor: pointer;
  }
}
</style>
