<template>
  <div v-if="needSpecialCharacter" class="flex justify-center items-center mx-auto">
    <div class="flex flex-wrap justify-center mt-5 flex-grow">
      <div class="p-1" v-for="(char, charIndex) in getSpecialCharacter" :key="charIndex" @mousedown.prevent="$emit('char', char)">
        <div class="v-char-button v-clickable">{{ char }}</div>
      </div>
      <div class="p-1" @click="specialCaptalize = !specialCaptalize">
        <div class="v-char-button v-clickable">
          <img src="@/assets/whiteUp.png" alt="" :class="{ active: specialCaptalize }">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lesson: Object,
  },
  data() {
    return {
      specialCaptalize: false,
    }
  },
  computed: {
    getSpecialCharacter() {
      const type = this.specialCaptalize ? 'uppercase' : 'lowercase'
      return this.$helpers.specialSymbols[this.lesson.language][type];
    },
    needSpecialCharacter() {
      const symbolsExists = !!this.$helpers.specialSymbols[this.lesson.language];
      if (!symbolsExists) return false;

      if (this.lesson.language === 'CN' && this.symbolMode) return false;
      return true;
    },
    symbolMode() {
      return !this.lesson.with_pinyin;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
