<template>
	<transition name="fade" mode="out-in">
	  <div class="v-modal" v-if="show" @click.self="show = false">
  		<div class="v-modal__wrapper">
  			<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false">
        <div>
          <img class="v-modal__icon" src="@/assets/emoji/good_up.png" alt="">
          <img class="v-modal__icon" src="@/assets/emoji/good_up.png" alt="">
        </div>
        <div>
          <div class="mb-10">
            Если тебе понравилась платформа ВЗНАНИЯ, и ты выучил слова гораздо быстрее и легче, чем без нее, то жми donate и поддержи проект <img src="@/assets/emoji/blink.png" style="height: 20px; display: inline">
          </div>
          <div class="flex items-center justify-around">
            <div>Сумма к оплате: </div>
            <div class="flex items-center">
              <input class="v-modal__input" type="text" placeholder="Сумма к оплате" v-model.number="paySum">
              <div>руб.</div>
            </div>
            <div class="v-button v-button--gradient-red v-clickable" @click="makePayment">Donate</div>
          </div>
        </div>
  		</div>
  	</div>
	</transition>
</template>

<script>
import { createSupport } from '@/api/payment.js';
import { ref, reactive } from "vue";
import useEventBus from "@/composables/useEventBus.js";

export default {
  name: 'donate-modal',
  setup() {
    const emitter = useEventBus();
    const show = ref(false);
    const state = reactive({params: {}});
    const paySum = ref(null);

    emitter.on('donate', (params = {}) => {
      show.value = true;
      state.params = { ...params };
    })

    const makePayment = async () => {
      if (!paySum.value || String(paySum.value).match(/(^0|\.)/)) {
        emitter.emit('v-modal', {
          text: 'Некорректная сумма',
          error: true,
        });
        return;
      }

      try {
        const { data } = await createSupport({ amount: this.paySum });
        if (data.success) {
          window.location.href = data.data.confirmation_url;
        } else {
          emitter.emit('v-modal', {
            text: 'Ошибка при создании платежа: [success: false]',
            error: true,
          });
        }
      } catch (err) {
        console.log('err', err);
        emitter.emit('v-modal', {
          text: 'Ошибка при создании платежа: ' + JSON.stringify(err),
          error: true,
        });
      }
    }
    return {
      show,
      paySum,
      makePayment
    }
  },
};
</script>

<style lang="scss" scoped>

.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  form {
    width: 90%;
  }

  &__input {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: 25px;
    border: 1px solid #696969;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    max-width: 230px;
    padding: 0 20px;
    height: 36px;
    transition: border .2s linear;
  }

  &__icon {
    position: absolute;

    &:nth-child(1) {
      top: 4%;
      left: 1%;
      width: 30px;
      transform: rotate(-25deg);
    }
    &:nth-child(2) {
      bottom: 39%;
      right: 1%;
      width: 30px;
      transform: rotate(25deg) scale(-1, 1);
    }
  }

  &__wrapper {
    // background-color: #fff;
    background: url(/images/modal-pattern.png);
    background-size: cover;
    border-radius: 12px;

    width: 720px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding: 50px 25px 25px;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    .v-button {
      font-size: 16px !important;
    }

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }

    &.sm {
      width: 350px;
      min-height: 250px;
    }

    &.md {
      width: 450px;
      min-height: 250px;
    }

    &.lg {
      width: 600px;
      min-height: 350px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    cursor: pointer;
    z-index: 1;
  }
}

@media screen and (max-width: 700px) {
	.v-modal__form {
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.v-modal {
		padding: 25px;
	}
	input::placeholder {
		font-size: 16px;
	}
}
</style>
