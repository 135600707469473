<template>
	<transition name="fade">
		<div class="v-result" v-if="show">
			<div class="v-result__wrapper">
				<template v-if="isOnline && !isLoading">
					<div class="v-result__title text-center text-lg v-text--bold mb-10">
						{{ $t('games.your_result') }}
					</div>
					<div class="v-result__container w-full flex flex-col items-center justify-center">
						<BrainLogoControlled :percentage="percentage" />
						<div class="v-text--bold text-2xl text-center my-3">{{ getPercentage }}%</div>
						<div class="v-text--gray text-md text-center">
							{{ isCompleted ? $t('games.you_did_it') : $t('games.try_again') }}
						</div>

						<div>
							<img
								v-if="getPercentage >= minTaskPercent"
								class="v-result__icon"
								src="@/assets/emoji/good_up.png"
								alt=""
							/>
							<div v-else class="v-result__icon"></div>

							<img
								v-if="getPercentage < minTaskPercent"
								class="v-result__icon"
								src="@/assets/emoji/bad_down.png"
								alt=""
							/>
							<div v-else class="v-result__icon"></div>

							<img
								v-if="getPercentage < minTaskPercent"
								class="v-result__icon"
								src="@/assets/emoji/bad_down.png"
								alt=""
							/>
							<div v-else class="v-result__icon"></div>

							<img
								v-if="getPercentage >= minTaskPercent"
								class="v-result__icon"
								src="@/assets/emoji/good_up.png"
								alt=""
							/>
							<div v-else class="v-result__icon"></div>
						</div>
					</div>
					<div
						class="v-text--gray mt-5"
						v-if="getPercentage < minTaskPercent && getTip"
						v-html="getTip"
					></div>
					<div class="flex justify-around">
						<div class="vzn-button" @click="getButtonBehaviour.callback">
							{{ getButtonBehaviour.text }}
						</div>
					</div>
				</template>
				<template v-else-if="isOnline && isLoading">
					<LoaderBrainLogo />
				</template>
				<template v-else>
					<div class="v-result__container w-full flex flex-col items-center justify-center">
						<LoaderBrainLogo />
						<div class="text-2xl text-center my-3">
							<b>{{ $t('lesson.waiting-for-connection') }}...</b>
						</div>

						<div class="v-text--gray text-md text-center">{{ $t('lesson.dont-close-page') }}</div>
					</div>
				</template>
			</div>
		</div>
	</transition>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, defineEmits } from 'vue'
import { useStore } from 'vuex'
import BrainLogoControlled from '../BrainLogoControlled.vue'
import FinalModalNovakid from './FinalModalNovakid/FinalModalNovakid.vue'
import LoaderBrainLogo from '../LoaderBrainLogo.vue'
import { getSlug, getOnlypreview } from '../../helpers'
import { delay } from '../../helpers/delay'
import FlutterNovakidBridge from '../../helpers/flutterNovakidBridge'
import useEventBus from '../../composables/useEventBus.js'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
const { t } = useI18n()
const emitter = useEventBus()
const emit = defineEmits(['navigate'])

const store = useStore()
const route = useRoute()
const show = ref(false)
const isOnline = ref(true)
const GAME_SLUG = ref('')
const percentage = ref(0)
const rating = ref(0)
const isLoading = ref(false)
const completeCallback = () => {}

// Computed properties
const lessons = computed(() => store.state.lessons)
const currentLessonProgressData = computed(() => lessons.value.currentLessonProgressData[GAME_SLUG.value])
const currentLesson = computed(() => lessons.value.currentLesson)
const games = computed(() => store.state.base.games)

const getNotDoneTask = computed(() => store.getters.getNotDoneTask)
const isProfileLoaded = computed(() => store.getters.isProfileLoaded)
const getPlatform = computed(() => store.getters.getPlatform)

const minTaskPercent = computed(() => {
	if (
		!currentLesson.value ||
		!currentLesson.value.additional_info ||
		!currentLesson.value.additional_info.minTaskPercent
	)
		return 90
	return parseInt(currentLesson.value.additional_info.minTaskPercent, 10)
})

const getTip = computed(() => {
	if (minTaskPercent.value === 0) return null
	return t('games.to_complete').replace('90%', `<span class="v-text--blue">${minTaskPercent.value}%</span>`)
})

const getPercentage = computed(() => Math.ceil(percentage.value))
const isCompleted = computed(() => getPercentage.value >= minTaskPercent.value)
const isNovakid = computed(() => getPlatform.value && (getPlatform.value.id === 17 || getPlatform.value.id === 16))
const isNovakidModal = computed(() => getNotDoneTask.value !== 'FinalTest' && isNovakid.value)

const getCurrentGameDone = computed(() => {
	if (
		!currentLesson.value ||
		!currentLesson.value.lr_additional_info ||
		!currentLesson.value.lr_additional_info.tasks
	)
		return 0
	return parseInt(currentLesson.value.lr_additional_info.tasks[GAME_SLUG.value], 10) || 0
})

const getCurrentGameCount = computed(() => {
	if (!currentLesson.value || !currentLesson.value.additional_info || !currentLesson.value.additional_info.tasks)
		return 0
	return parseInt(currentLesson.value.additional_info.tasks[GAME_SLUG.value], 10)
})

const getButtonBehaviour = computed(() => {
	if (getPercentage.value >= minTaskPercent.value && getNotDoneTask.value === 'FinalTest') {
		return {
			text: t('games.take_the_test'),
			callback: goToTest,
		}
	} else if (getPercentage.value >= minTaskPercent.value && getCurrentGameDone.value >= getCurrentGameCount.value) {
		return {
			text: t('games.next_task'),
			callback: nextTask,
		}
	} else {
		return {
			text: t('games.do_it_again'),
			callback: restart,
		}
	}
})

// Lifecycle hooks
onMounted(() => {
	emitter.on('v-task-result', init)
	// Optionally use store.$on for global events
})

onBeforeUnmount(() => {
	// emitter.off('v-task-result', init)
})

// Methods
const checkConnection = () => {
	isOnline.value = window.navigator.onLine
}

const init = async (taskResultPayload) => {
	show.value = true
	isLoading.value = true
	checkConnection()

	if (!isOnline.value) {
		await delay(1000)
		return init(taskResultPayload)
	}

	GAME_SLUG.value = taskResultPayload.gameName

	// Проверка, есть ли данные перед доступом к ним
	if (!currentLessonProgressData.value) {
		console.warn('currentLessonProgressData is undefined')
		isLoading.value = false
		return
	}

	if (currentLessonProgressData.value.approved) {
		store.commit('RESTART_TASK', { gameName: GAME_SLUG.value })
		isLoading.value = false
		return
	}

	const wordsCount = Object.keys(currentLessonProgressData.value.words || {}).length
	const wrongCount = currentLessonProgressData.value.tips?.length || 0
	percentage.value = Math.ceil(100 - (100 / wordsCount) * wrongCount)

	if (
		currentLessonProgressData.value.rating_info &&
		typeof currentLessonProgressData.value.rating_info === 'object' &&
		currentLessonProgressData.value.rating_info.hasOwnProperty('rating')
	) {
		rating.value = currentLessonProgressData.value.rating_info.rating
	}

	if (getPercentage.value >= minTaskPercent.value && getCurrentGameDone.value < getCurrentGameCount.value) {
		console.log('Сохраняем')
		await saveTask()
	} else {
		store.commit('SET_APPROVED_LESSON_TASK', GAME_SLUG.value)
	}

	isLoading.value = false

	setTimeout(() => {
		document.activeElement && document.activeElement.blur()
	}, 100)
}

const saveTask = async () => {
	const slug = getSlug()
	const count =
		getCurrentGameDone.value < getCurrentGameCount.value ? getCurrentGameDone.value + 1 : getCurrentGameCount.value

	if ((!isProfileLoaded.value || getOnlypreview()) && slug) {
		console.log('1')
		store.commit('STAGE_LOCAL_DATA_FOR_SLUG', { type: GAME_SLUG.value, count, slug })
	} else {
		console.log('2')
		await stageLessonResult(count)
	}
}

const stageLessonResult = async (count) => {
	try {
		await store.dispatch('STAGE_LESSON_RESULT', { type: GAME_SLUG.value, count, group_id: route.query.group_id })
		checkConnection()
	} catch (e) {
		checkConnection()
		await delay(1000)
		return stageLessonResult(count)
	}
}

const onNextClick = () => {
	if (!FlutterNovakidBridge.isFlutterApp()) {
		nextTask()
	} else {
		FlutterNovakidBridge.callService('FlutterGoBack', 'back')
	}
}

const nextTask = async () => {
	store.commit('RESTART_TASK', { gameName: GAME_SLUG.value })
	emit('navigate', getNotDoneTask.value)
	show.value = false
}

const restart = () => {
	store.commit('RESTART_TASK', { gameName: GAME_SLUG.value })
	show.value = false
}

const goToTest = () => {
	store.commit('RESTART_TASK', { gameName: GAME_SLUG.value })
	emit('navigate', getNotDoneTask.value)
	show.value = false
}
</script>

<style lang="scss" scoped>
.v-result {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: #000000, $alpha: 0.7);

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	&__icon {
		position: absolute;
		width: 45px;

		&:nth-child(1) {
			top: 0;
			left: 5%;
			width: 40px;
			transform: rotate(-45deg);
		}

		&:nth-child(2) {
			top: 0;
			right: 10%;
			width: 35px;
		}

		&:nth-child(3) {
			bottom: 25%;
			left: 10%;
			width: 25px;
			transform: rotate(-45deg) scale(1, 1);
		}

		&:nth-child(4) {
			bottom: 0;
			right: 5%;
			width: 30px;
			transform: rotate(45deg) scale(-1, 1);
		}
	}

	&__container {
		position: relative;
	}

	&__title {
		position: absolute;
		top: 10px;
		left: 10px;
	}

	&__wrapper {
		width: 400px;
		min-height: 400px;
		background-color: #fff;
		// background: url(/images/modal-pattern.png);
		background-size: cover;
		border-radius: 12px;
		padding: 50px 25px 10px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
		opacity: 0;
		animation: showup 0.3s ease;
		animation-fill-mode: forwards;

		@keyframes showup {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		cursor: pointer;
	}

	.no-internet {
		color: red;
	}
}
</style>
