<template>
	<div class="container mx-auto mb-20 h-full lg:h-auto flex-grow flex flex-col">
		<user-row class="hidden lg:flex" />

		<div class="w-full lg:hidden px-5 pt-5">
			<div class="flex items-center justify-between w-full">
				<div class="v-text--title-bold">{{ $t('home.my_sets') }}</div>
				<div class="flex items-center" @click="showFilterModal = true">
					<div class="v-text--green mr-2">{{ getCurrentStatus.value }}</div>
					<svg width="15.5px" height="9.5px">
						<path
							fill-rule="evenodd"
							:stroke="'#27a34c'"
							stroke-width="1px"
							stroke-linecap="butt"
							stroke-linejoin="miter"
							:fill="'#27a34c'"
							d="M13.174,1.833 C12.997,1.664 12.710,1.663 12.533,1.833 L7.821,6.332 C7.645,6.501 7.356,6.501 7.179,6.332 L2.467,1.833 C2.290,1.663 2.003,1.664 1.826,1.833 C1.649,2.003 1.650,2.278 1.826,2.447 L6.538,6.946 C6.803,7.200 7.152,7.327 7.500,7.327 C7.848,7.327 8.197,7.200 8.462,6.946 L13.174,2.447 C13.352,2.278 13.352,2.003 13.174,1.833 Z"
						/>
					</svg>
				</div>
			</div>

			<div class="w-full flex items-center">
				<div class="w-full my-3 relative mr-3">
					<input
						type="text"
						class="v-input w-full"
						v-model="groupName"
						:placeholder="$t('home.search_by_name')"
						v-predioff
					/>
					<img
						class="v-clickable"
						style="
							height: 50%;
							position: absolute;
							top: 50%;
							z-index: 2;
							right: 10px;
							transform: translateY(-50%);
						"
						src="@/assets/close-xxxhdpi.png"
						alt=""
						@click="groupName = ''"
					/>
				</div>
				<ModeSwitcher v-model="viewMode" :modes="viewModes" />
			</div>
		</div>

		<transition name="fade" mode="out-in">
			<div v-if="(lessons.length > 0 && isLessonsLoaded) || groupName" class="px-5 lg:px-0 pb-20 lg:pb-0">
				<div class="hidden lg:flex justify-between items-center">
					<div class="v-text--title-bold" style="white-space: nowrap">
						{{ $t('home.my_sets') }}
					</div>
					<div class="flex w-full ml-12 items-center justify-end">
						<template v-if="viewMode === FolderViewMode.grid">
              <div class="mr-5 search-component w-11/12">
                <icon-display iconPath="/images/new/search-normal.svg"/>
                <input
                    type="text"
                    :placeholder="$t('home.search_by_name')"
                    class="mr-5 v-input w-11/12"
                    v-model="groupName"
                />
              </div>
							<v-select
								class="mr-5"
								:options="getGroupsOptions"
								:selected="selectedGroup ?? 0"
								v-if="groups && groups.length > 0"
								@update:selected="onChangeGroup"
							/>
							<v-select
								class="mr-5"
								:options="optionsStatus"
								:selected="selectedStatus"
								@update:selected="onChangeStatus"
							/>
						</template>
						<ModeSwitcher v-model="viewMode" :modes="viewModes" />
					</div>
				</div>

				<template v-if="viewMode === FolderViewMode.grid">
					<div v-for="(lessonType, lessonTypeIndex) in getOrderedLessonsWithBanners" :key="lessonTypeIndex">
						<div v-if="lessonType.length > 0">
							<div class="flex justify-between items-end">
								<div class="v-text--bold v-text--gray text-md mt-5">
									{{ getLessonTypeString(lessonType[0]) }}
								</div>
								<!-- <div class="v-text--bold v-link v-text--desc" @click="$router.push({ name: 'tariffs' })">{{ $t('home.do_the_sets') }}</div> -->
							</div>
							<transition-group
								class="flex flex-wrap justify-between grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5"
								name="list-item"
								tag="ul"
							>
								<template v-for="(lesson, lessonIndex) in lessonType">
									<div
										class="v-card-banner mb-1"
										v-if="
											lesson.isBanner &&
											!isTrueMobile &&
											!profile.subscriptionAvailability &&
											!getPlatform &&
											!profile.teachers.find((e) => e === 179750 || e === 102360 || e === 182882)
										"
										:key="'banner' + lessonIndex"
									>
										<img
											class="v-card-banner__background"
											src="@/assets/tariffs_banner.png"
											alt=""
										/>
										<div class="v-card-banner__content">
											<div class="v-tariffs-banner-text-1">
												<div>Оплата за</div>
												<div>мобильное<br />приложение</div>
											</div>
											<div class="v-tariffs-banner-text-2">
												<div>Всего за</div>
												<div>37 руб</div>
												<div>в неделю</div>
											</div>
											<div
												class="v-button v-tariffs-banner-button absolute v-clickable"
												@click="$router.push({ name: 'tariffs' })"
											>
												Подробнее
											</div>
										</div>
									</div>
									<LessonCard
										v-else-if="!lesson.isBanner"
										class="mb-1"
										:key="lesson.group_id + '' + lesson.id"
										:lesson="lesson"
										:type="getLessonType(lesson)"
									/>
								</template>
							</transition-group>
						</div>
					</div>

					<infinite-loading @infinite="onScrollBottom" :key="keyUpdate">
						<div slot="spinner">
							<div class="w-full flex justify-center">
								<loader />
							</div>
						</div>
						<div slot="no-more"></div>
						<div slot="no-results"></div>
					</infinite-loading>
				</template>
				<GroupFoldersTree v-else />
			</div>

			<div
				v-else-if="lessons.length === 0 && isLessonsLoaded && !groupName && !groups?.length"
				class="h-full flex-grow lg:h-auto flex flex-col items-center justify-center"
			>
				<div class="text-center">{{ $t('home.join_group') }}</div>
				<div class="lg:mr-5 mt-5 w-full lg:w-2/5 px-10 lg:px-0 flex flex-col items-center lg:block">
					<input
						class="v-input w-full text-center"
						:placeholder="$t('groups.enter_the_group_code')"
						type="text"
						v-model="code"
						@input="error = false"
					/>
					<div class="pl-3">
						<div class="flex mt-4 items-center">
							<img class="h-6 mr-3" src="/images/iii-xxxhdpi.png" alt="" />
							<div class="v-text--gray">
								{{ $t('groups.ask_teacher_code') }}
							</div>
						</div>
					</div>
				</div>
				<div class="v-button v-button--gradient-red mt-5" @click="joinGroup">
					{{ $t('groups.activate') }}
				</div>
			</div>

			<div
				v-else-if="lessons.length === 0 && isLessonsLoaded && !groupName && groups?.length > 0"
				class="text-xl v-text--bold text-center h-64 flex items-center justify-center"
			>
				{{ $t('home.no_lessons') }}
			</div>

			<div v-else class="lessons-loader-home-wrapper flex-center">
				<loader />
			</div>
		</transition>

		<transition name="fade" mode="out-in">
			<modal-description
				v-if="modalDescriptionName"
				@close="modalDescriptionName = null"
				:name="modalDescriptionName"
				:pooled="pooled"
				@setGroup="setGroupId"
			/>
		</transition>

		<transition name="fade" mode="out-in">
			<mobile-filter-modal
				@filter="handleMobileFilter"
				v-if="showFilterModal.value"
				@close="showFilterModal.value = false"
				:currentStatus="selectedStatus"
				:currentGroup="selectedGroup"
				:groups="getGroupsOptions"
			/>
		</transition>
	</div>
</template>

<script setup>
import { ref, watch, computed, reactive } from 'vue'
import UserRow from '@/components/UserRow.vue'
import VSelect from '@/components/Selector.vue'
import LessonCard from '@/components/LessonCard.vue'
import IconDisplay from '@/components/IconDisplay.vue'
import Expanded from '@/components/Expanded.vue'

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import throttle from 'lodash/throttle'
import { addToGroupStudent as joinGroupApi } from '@/api/user.js'
import { getAuthors, getPooledSubscriptions, paymentCreate } from '@/api/payment.js'
import LeafButton from '@/components/LeafButton.vue'
import ModalDescription from '@/components/modals/ModalDescription.vue'
import MobileFilterModal from '@/components/modals/MobileFilterModal.vue'
import ModeSwitcher from '@/components/ModeSwitcher.vue'
import GroupFoldersTree from '@/components/home/GroupFoldersTree.vue'
import useEventBus from '../composables/useEventBus.js'
import { useI18n } from 'vue-i18n'
const intensive = ref([
	{ text: 'Starter', description: '(1-2 класс)', price: '2 900 руб' },
	{ text: 'Basic', description: '(3-4 класс)', price: '2 900 руб' },
	{ text: 'Учимся читать', description: '', price: '2 900 руб' },
])
const FolderViewMode = reactive({
	grid: 'grid',
	list: 'list',
})
const viewModes = ref([
	{ key: FolderViewMode.grid, icon: '/images/mode_grid_new.svg' },
	{ key: FolderViewMode.list, icon: '/images/mode_list_new.svg' },
])

const { t } = useI18n()
const store = useStore()
const pooled = ref([])
const viewMode = ref(FolderViewMode.grid)
console.log(viewMode.value)
const route = useRoute()
const emitter = useEventBus()
const selectedGroup = ref(null)
const selectedStatus = ref(0)
const searchFilter = ref({})
const currentPage = ref(1)
const isLessonsLoaded = ref(false)
const showFilterModal = ref(false)
const keyUpdate = ref(0)
const code = ref('')
const groupName = ref('')
const modalDescriptionName = ref(null)

const isTrueMobile = computed(() => window.isTrueMobile)
const onChangeGroup = (e) => (selectedGroup.value = e)
const onChangeStatus = (e) => (selectedStatus.value = e)
const changeMode = (mode) => (viewMode.value = mode)
const profile = computed(() => store.getters.profile)
const lessons = computed(() => store.getters.getLessons)
const groups = computed(() => store.getters.userGroups)
const maxLessonsPage = computed(() => store.getters.maxLessonsPage)
const getPlatform = computed(() => store.getters.getPlatform)

const getLessonsInOreder = computed(() => {
	const orderMap = {
		actual: [getActualLessons.value, getOverdueLessons.value, getCompletedLessons.value],
		completed: [getCompletedLessons.value, getActualLessons.value, getOverdueLessons.value],
		overdue: [getOverdueLessons.value, getActualLessons.value, getCompletedLessons.value],
	}

	return orderMap[searchFilter.value.sortBy] || orderMap['actual']
})
const getOrderedLessonsWithBanners = computed(() => {
	if (getLessonsInOreder.value.reduce((a, b) => a.concat(b), []).length === 0) return []
	const group = getLessonsInOreder.value.find((el) => el.length > 0)
	group.splice(group.length === 1 ? 1 : 2, 0, { isBanner: true })
	return getLessonsInOreder.value
})
const getActualLessons = computed(() => lessons.value.filter((lesson) => getLessonType(lesson) === 'actual'))
const getCompletedLessons = computed(() => lessons.value.filter((lesson) => getLessonType(lesson) === 'done'))
const getOverdueLessons = computed(() => lessons.value.filter((lesson) => getLessonType(lesson) === 'expired'))
const optionsStatus = computed(() => {
	return [
		{ key: 0, value: t('home.all_sets') },
		{ key: 1, value: t('home.current') },
		{ key: 2, value: t('home.completed') },
		{ key: 3, value: t('home.expired') },
	]
})
const getCurrentStatus = computed(() => optionsStatus.value.find((el) => el.key === selectedStatus.value))
const optionsGroup = computed(() => {
	return [{ key: 0, value: t('home.all_groups') }]
})
const getGroupsOptions = computed(() => {
	if (!groups.value) return []
	return [
		...optionsGroup.value,
		...groups.value.map((group, index) => ({
			key: group.id,
			value: group.name,
		})),
	]
})

const setGroupId = (id) => {
	selectedGroup.value = id
}

const loadData = async () => {
	try {
		const data = await getPooledSubscriptions()
		pooled.value = data.data
	} catch (err) {
		console.log('err', err)
	}

	// Группы
	await store.dispatch('GET_GROUPS')
	// await store.dispatch('GET_ACTIVITY_TYPES');

	if (store.getters.settings && store.getters.settings.homeViewMode) {
		viewMode.value = store.getters.settings.homeViewMode
	}

	// Если в параметрах был id группы - переходим на группу
	if (route.params.group_id) {
		selectedGroup.value = route.params.group_id
		if (window.isTrueMobile) {
			console.log('HERE?')
			await handleMobileFilter({ selectedGroup: selectedGroup.value })
		}
		return
	}

	// Загрузить уроки (без фильтра)
	await loadLessons()
	isLessonsLoaded.value = true
}

const handleMobileFilter = async ({ selectedStatusParam, selectedGroupParam }) => {
	showFilterModal.value = false
	// if (selectedStatus == this.selectedStatus && selectedGroup == this.selectedGroup) return
	if (selectedStatusParam === selectedStatus.value && selectedGroupParam === selectedGroup.value) return

	if (selectedGroupParam) {
		searchFilter.value = { ...searchFilter.value, group_id: selectedGroupParam }
	} else {
		delete searchFilter.value.group_id
	}

	if (selectedStatusParam ?? false) {
		const statusName = ['actual', 'completed', 'overdue'][selectedStatus.value - 1]
		searchFilter.value = { ...searchFilter.value, sortBy: statusName }
	} else {
		delete searchFilter.value.sortBy
	}

	currentPage.value = 1
	isLessonsLoaded.value = false
	selectedStatus.value = selectedStatusParam || 0
	selectedGroup.value = selectedGroup.value || null

	await loadLessons()

	isLessonsLoaded.value = true
}

// Загрузить уроки с текущим фильтром
const loadLessons = async () => {
	await store.dispatch('FILTER_LESSONS_DATA', {
		page: currentPage.value,
		filter: searchFilter.value,
	})
	keyUpdate.value++
}

const isExcludeTeacher = () => {
	const excludes = [
		179750, 102360, 182882, 194002, 268767, 162567, 156455, 343718, 362116, 362098, 308270, 304303, 121300, 375917,
	]
	const teachers = !!profile.value.teachers ? Object.values(profile.value.teachers) : []
	return !teachers.find((e) => excludes.includes(e))
}

loadData()

watch(viewMode, (value) => {
	store.dispatch('UPDATE_SETTINGS', {
		homeViewMode: value,
	})
})

// Обновить уроки при выборе группы
watch(selectedGroup, async (id, oldId) => {
	if (isTrueMobile.value) return

	if (!id && oldId) {
		isLessonsLoaded.value = false

		delete searchFilter.value.group_id
		currentPage.value = 1
		await loadLessons()

		isLessonsLoaded.value = true
	} else if (id) {
		isLessonsLoaded.value = false
		currentPage.value = 1
		searchFilter.value = { ...searchFilter.value, group_id: id }
		await loadLessons()
		isLessonsLoaded.value = true
	}
})

// Обновить уроки при вводе имени
watch(
	groupName,
	throttle(function (name) {
		searchFilter.value = {
			...searchFilter.value,
			searchBy: 'name',
			searchText: name,
		}
		currentPage.value = 1
		loadLessons()
	}, 1000)
)

// Сортировка по типу
watch(selectedStatus, (status) => {
	if (isTrueMobile.value) return

	currentPage.value = 1
	if (status === 0) {
		delete searchFilter.value.sortBy
		loadLessons()
	} else {
		const statusName = ['actual', 'completed', 'overdue'][status - 1]
		searchFilter.value.sortBy = statusName
		loadLessons()
	}
})

const getUserIndex = (userIndex, indexPos = 7) => {
	if (userIndex < indexPos) return userIndex + 1
	else {
		userIndex -= parseInt(userIndex / indexPos) * indexPos
		return userIndex + 1
	}
}

const joinGroup = async () => {
	if (code.value) {
		emitter.emit('v-modal', {
			error: true,
			text: t('groups.enter_code'),
		})
		// error = true
		return
	}

	try {
		store.dispatch('SET_BUSY_STATE', true)
		isLessonsLoaded.value = false
		const { data } = await joinGroupApi({
			student_id: profile.value.student_id,
			code: code.value,
		})

		await store.dispatch('GET_GROUPS')
		await store.dispatch('GET_PROFILE_DATA', true)
		await loadLessons()

		emitter.emit('v-modal', {
			text: t('groups.the_group_was_added'),
			size: 'sm',
		})
	} catch (err) {
		console.log(err)
		emitter.emit('v-modal', {
			text: t('groups.incorrect_group_code'),
			size: 'sm',
		})
	} finally {
		store.dispatch('SET_BUSY_STATE', false)
		isLessonsLoaded.value = true
		code.value = ''
	}
}

const getLessonTypeString = (lesson) => {
	const currentGroup = groups.value.filter((group) => group.id === lesson.group_id)[0]

	if (
		getLessonType(lesson) === 'actual' ||
		(currentGroup &&
			currentGroup.additional_info &&
			Number(currentGroup.additional_info.dontUseDeadline) === 1 &&
			getLessonType(lesson) !== 'done')
	) {
		console.log('current')
		return t('home.current')
	} else if (getLessonType(lesson) === 'done') {
		return t('home.completed')
	} else if (
		getLessonType(lesson) === 'expired' &&
		(!currentGroup || !currentGroup.additional_info || Number(currentGroup.additional_info.dontUseDeadline) !== 1)
	) {
		return t('home.expired')
	}
}

// const onExpanded = (id) => {
//   const selectors = this.$refs
//
//   for (let key in this.$refs) {
//     if (key !== id) {
//       this.$refs[key].expanded = false
//     }
//   }
// }

const onScrollBottom = async ($state) => {
	if (currentPage.value < maxLessonsPage.value) {
		currentPage.value += 1
		await loadLessons()
		$state.loaded()
	}

	if (currentPage.value === maxLessonsPage.value) {
		$state.complete()
	}
}

// Определить тип урока
const getLessonType = (lesson) => {
	const currentGroup = groups.value.filter((group) => group.id === lesson.group_id)[0]
	const getExpireDate = (date) => {
		date = new Date(date)
		date.setHours(0, 0, 0, 0)
		date.setDate(date.getDate() + 1)
		return date
	}

	if (
		lesson.test_result > 0 ||
		lesson.pass_once_current > 0 ||
		(lesson.type === 'challenge' && !!lesson.is_published_post)
	) {
		return 'done'
	}
	if (
		new Date().getTime() > getExpireDate(lesson.expires_at.date).getTime() &&
		(!currentGroup || !currentGroup.additional_info || Number(currentGroup.additional_info.dontUseDeadline) !== 1)
	)
		return 'expired'
	return 'actual'
}
</script>

<style lang="scss" scoped>
.v-tariffs-banner-text-1 {
	position: absolute;
	top: 45px;
	color: #fff;

	div:nth-child(1) {
		font-size: 18px;
		margin-bottom: 15px;
	}
	div:nth-child(2) {
		font-size: 16px;
		text-align: center;
	}
}

.v-tariffs-banner-text-2 {
	position: absolute;
	top: 180px;
	left: 30px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 1.3;

	div:nth-child(2) {
		color: #fff814;
	}
}

.v-tariffs-banner-button {
	color: #cf7247;
	background: #fff;
	box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.15);

	&.absolute {
		bottom: 20px;
		position: absolute;
		left: 10px;
	}

	&:hover {
		filter: unset;
	}
}

.v-button.pink {
	background: #db0045;
}

.v-card-banner {
	// width: 320px;
	min-height: 225px;
	border-radius: 10px;
	background-color: white;
	transition: all 0.2s linear;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	position: relative;

	@media (min-width: 1024px) {
		width: 320px;
	}

	&:hover {
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.09);
	}

	&__background {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 20px 15px;
	}
}

.v-link {
	cursor: pointer;
	text-decoration: underline;
}

.lessons-loader-home-wrapper {
	height: 100vh;

	@media (min-width: 1024px) {
		height: calc(100vh - 300px);
	}
}

.search-component {
  display: flex;
  align-items: center;
  background-color: #f0f1f5;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid transparent;

  .v-input {
    padding: 0 10px;
    min-height: 0;
    border: none;
  }
}
.v-banner {
	height: 300px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 5px 13px 0px #00000017;

	img {
		height: 100%;
	}

	&__buttons {
		position: absolute;
		bottom: 15px;
		right: 0;
		width: 100%;
		padding: 0 50px;
		display: flex;
		justify-content: space-between;
	}
}

.v-expanded {
	position: relative;
	z-index: 1;

	&__arrow {
		transform: rotate(180deg);
		transition: all 0.2s linear;

		&.active {
			transform: rotate(0);
		}
	}
}
</style>
