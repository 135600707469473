<template>
  <div v-if="currentSets.length > 0" class="w-full flex flex-wrap justify-center">
    <div v-for="set in currentSets" :key="set.word.id" class="w-full lg:w-1/2 p-1 mb-5">
      <div class="flex-wrap lg:flex-nowrap ">
        <lexical-word-card :big="true" class="w-full mb-5 mx-auto " :word="set.word" :useTranslate="true" :showTranslate="false" text="text-lg text-center justify-center md:text-2xl" />

        <div class="w-full">
          <transition-group class="w-full lg:w-full px-5 lg:px-0 flex flex-wrap mx-auto justify-center mb-5" name="list-item-fast" tag="div">
            <div class="p-1" v-for="(letter, letterIndex) in set.currentWordLetters" :key="'w' + letterIndex">
              <div
                class="v-cell Raleway-Medium"
                :class="{ 'v-cell--empty': !letter, 'v-cell--letter': !!letter }"
                @click="remove(letter, letterIndex, set)"
              >{{ letter && letter.value }}</div>
            </div>
          </transition-group>

          <transition-group class="w-full lg:w-full px-5 lg:px-0 flex flex-wrap mx-auto justify-center" name="list-complete-fast" tag="div">
            <div class="p-1 list-complete-fast-item Raleway-Medium" v-for="(letterObject, index) in set.currentWordSplit" :key="'w' + index" @click="move(index, set)">
              <div class="v-cell v-cell--letter v-clickable">{{ letterObject.value }}</div>
            </div>
          </transition-group>
        </div>
      </div>

      <div v-if="set.error" class="v-text--red text-lg text-center my-5">{{ $t('lesson.craft_answer') }}</div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import shuffle    from 'lodash/shuffle';
import cloneDeep  from 'lodash/cloneDeep';
import LexicalWordCard from '../../../components/LexicalWordCard.vue';
import {computed, onMounted, ref} from "vue";

const props = defineProps({
  words: {
    type: Array,
    default: () => []
  }
})

const store = useStore();

const GAME_SLUG = 'scrabble';
const currentSets = ref([]);

const currentLesson = computed(() => store.getters.getCurrentLesson);
const getTransProp = computed(() => currentLesson.with_pinyin ? 'pinyin' : 'text')

const validateTest = () => {
  currentSets.forEach(set => {
    $set(set, 'error', false);
  })

  return currentSets.every(set => {
    if (set.currentWordLetters.filter(el => el && el.value).length === set.currentWordSplitSource.length) {
      return true;
    }
    $set(set, 'error', true);
    return false;
  })
}

const getTestData = () => {
  return currentSets.value.map(set => ({
    id: set.word.id,
    test_type: GAME_SLUG,
    text: set.currentWordLetters.map(el => el.value).join(''),
  }))
}

// Создает массивы для игры
const initGame = (set) => {
  set.currentWordSplitSource = set.word[getTransProp.value].split('').map((l, i) => ({ value: l, rightIndex: i }));
  set.currentWordSplit = shuffle(cloneDeep(set.currentWordSplitSource));

  set.currentWordLetters = new Array(set.currentWordSplit.length).fill(null);

  return set;
}

// Поставить букву
const move = (i, set) => {
  // Установка в свободный слот
  const freeIndex = set.currentWordLetters.findIndex(obj => !obj);
  set.currentWordLetters[freeIndex] = cloneDeep(set.currentWordSplit[i]);

  // Удаление из исходныъх букв
  delete set.currentWordSplit[i];
  set.currentWordSplit = set.currentWordSplit.filter(obj => obj);
}

// Вернуть букву
const remove = (letter, letterIndex, set) => {
  if (!letter) return;

  set.currentWordSplit.push(cloneDeep(letter));
  set.currentWordLetters[letterIndex] = null;
}

onMounted(() => {
  currentSets.value = shuffle(props.words).map(word => {

    return {
      error: false,
      selected: null,
      word,
      // Массив установленных букв
      currentWordLetters: [],
      // Массив исходных букв слова
      currentWordSplit: [],
      // Копия исходных слов
      currentWordSplitSource: [],
    }
  })

  currentSets.value = currentSets.value.map(el => initGame(el))
})
</script>

<style lang="scss" scoped>
.v-cell {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Raleway-Medium' !important;

  &--empty {
    background-color: #d9dce6; // rgb(195, 195, 195);
  }

  &--wrong,
  &--letter {
    background-color: #fff;
    border: 1px solid #d9dce6;
  }

  &--right {
    background-color: #1fc05d;
    color: #fff;
  }
}
</style>
