<template>
  <div class="modal-wrapper" @mousewheel.self.prevent @click.self="$emit('close')">
    <div class="support" @mousewheel.stop>
      <span class="support__close" @click="$emit('close')">X</span>

      <div v-if="isBusy" class="loader-wrapper flex justify-center items-center">
        <loader />
      </div>

      <img class="logo-image" src="@/assets/brain_3d.png" alt="">
      <div class="expert-text" v-if="name.toLowerCase() === 'starter'">
          <div class="w-full text-center mb-3">Приглашаем пройти обучающий интенсив <br> по английскому языку</div>
          <div class="w-full text-center v-text--bold text-2xl mb-3">"Starter" для (1-2 классов)</div>
          <br>
          <div><span class="mr-10"></span>За период летних каникул полученные в учебном году знания быстро утрачиваются. Наши уроки помогут ребёнку не забыть пройденное и поработать над расширением словарного запаса. Главная функция языка – это общение между людьми, а значит и цель изучения языка – это умение на нем говорить. Этот курс – первый шаг на пути к этой цели! На курсе информация запоминается не на пару дней, а навсегда</div>
          <br>
          <div class="v-text--bold text-xl mb-2">В курс входят: </div>
          <div class="flex mb-1"><span class="mr-1">✔️</span> 40 уроков (<a class="v-link" href="https://student.vznaniya.ru/home/lesson/167309/Match?slug=7IZLCPLS&onlypreview=true" target="_blank">пример урока</a>) </div>
          <div class="flex mb-1"><span class="mr-1">✔️</span> 22 увлекательные игры на повторение (<a class="v-link" href="https://games.vznaniya.ru/run/activities/2cJpEbSv/memory-game" target="_blank">пример урока</a>) </div>
          <div class="flex mb-1"><span class="mr-1">✔️</span> 273 слова</div>
          <div class="flex mb-1"><span class="mr-1">✔️</span> Все основные темы программы по ФГОС  для 1-2 классов</div>


          <div class="v-text--bold text-xl mb-2 mt-5">📖 Как проходит курс:</div>
          <div class="flex mb-1"><span class="mr-1">👉</span> Новый урок становится доступен, только после выполнения предыдущего. </div>
          <div class="flex mb-1"><span class="mr-1">👉</span> Чтобы выполнить урок, нужно пройти финальный тест </div>
          <div class="flex mb-1"><span class="mr-1">👉</span> Финальный тест становится доступен, только после успешного выполнения всех обязательных заданий </div>
          <div class="flex mb-1"><span class="mr-1">👉</span> После выполнения всех уроков вам будет выслан сертификат об успешном прохождении интенсива </div>

          <div class="v-text--bold text-xl my-10">💰 Стоимость обучающего курса - 2900 р </div>
      </div>

      <div class="expert-text" v-else-if="name.toLowerCase() === 'basic'">
        <div class="w-full text-center mb-3">Приглашаем пройти обучающий интенсив <br> по английскому языку</div>
        <div class="w-full text-center v-text--bold text-2xl mb-3">"Basic" для (3-4 классов)</div>
        <br>
        <div><span class="mr-10"></span>За период летних каникул полученные в учебном году знания быстро утрачиваются. Наши уроки помогут ребёнку не забыть пройденное и поработать над расширением словарного запаса. Главная функция языка – это общение между людьми, а значит и цель изучения языка – это умение на нем говорить. Этот курс – первый шаг на пути к этой цели! На курсе информация запоминается не на пару дней, а навсегда</div>
        <br>
        <div class="v-text--bold text-xl mb-2">В курс входят: </div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> 36 уроков (<a class="v-link" href="https://student.vznaniya.ru/home/lesson/167247/Match?slug=jxXKFMV5&onlypreview=true" target="_blank">пример урока</a>) </div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> 14 увлекательных игр на повторение (<a class="v-link" href="https://games.vznaniya.ru/run/activities/MxekYo4B/quiz-game" target="_blank">пример урока</a>) </div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> 208 слов</div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> Все основные темы программы по ФГОС для 3-4 классов</div>


        <div class="v-text--bold text-xl mb-2 mt-5">📖 Как проходит курс:</div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Новый урок становится доступен, только после выполнения предыдущего. </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Чтобы выполнить урок, нужно пройти финальный тест </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Финальный тест становится доступен, только после успешного выполнения всех обязательных заданий </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> После выполнения всех уроков вам будет выслан сертификат об успешном прохождении интенсива </div>

        <div class="v-text--bold text-xl my-10">💰 Стоимость обучающего курса - 2900 р </div>
      </div>

      <div class="expert-text" v-else-if="name.toLowerCase() === 'учимся читать'">
        <div class="w-full text-center mb-3">Приглашаем пройти обучающий интенсив <br> по английскому языку</div>
        <div class="w-full text-center v-text--bold text-2xl mb-3">"Reading rules" для (1-2 классов)</div>
        <br>
        <div><span class="mr-10"></span>За период летних каникул полученные в учебном году знания быстро утрачиваются. Наша задача не допустить этого и закрыть все пробелы в знаниях правил чтения у младших школьников. Ведь дальнейшее усвоение языкового материала пройдет гораздо легче и эффективнее, если отработаны базовые навыки чтения. В процессе прохождения курса ученик многократно услышит правильный вариант прочтения, благодаря встроенной озвучке, а многократное повторение материала гарантирует, что все нужное запомнится надолго.</div>
        <br>
        <div class="v-text--bold text-xl mb-2">В курс входят: </div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> 50 уроков (<a class="v-link" href="https://student.vznaniya.ru/home/lesson/167355/Match?slug=R3ILwPfO&onlypreview=true" target="_blank">пример урока</a>) </div>
        <div class="flex mb-1"><span class="mr-1">✔️</span> 226 слов</div>


        <div class="v-text--bold text-xl mb-2 mt-5">📖 Как проходит курс:</div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Новый урок становится доступен, только после выполнения предыдущего. </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Чтобы выполнить урок, нужно пройти финальный тест </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> Финальный тест становится доступен, только после успешного выполнения всех обязательных заданий </div>
        <div class="flex mb-1"><span class="mr-1">👉</span> После выполнения всех уроков вам будет выслан сертификат об успешном прохождении интенсива </div>

        <div class="v-text--bold text-xl my-10">💰 Стоимость обучающего курса - 2900 р </div>
      </div>


      <div class="support__input select flex justify-center mt-10">
        <div class="s-button s-button--orange" @click="currentUserSubscription ? start() : pay()">{{ currentUserSubscription ? 'Начать' : 'Открыть доступ' }}</div> <!-- Начать -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createPooledSubscribe, joinGroupBySubscribe } from '../../api/payment';

export default {
  props: {
    name: String,
    pooled: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    error: null,
    isBusy: false,
  }),
  computed: {
    ...mapState({
      profile: state => state.user.profile,
      groups: state => state.user.groups,
    }),
    currentUserSubscription() {
      if (!this.profile || !this.profile.id) return false;
      // console.log()
      return (this.profile.pooled_subscriptions || []).find(subscription => subscription.pooled_subscription.name.toLowerCase() === this.name.toLowerCase())
    },
  },
  methods: {
    ...mapActions(['GET_GROUPS']),
    async start() {
      if (this.currentUserSubscription) {
        const products = this.currentUserSubscription.pooled_subscription.pooled_products || [];
        console.log('products', this.currentUserSubscription, products)
        if (products.length > 0) {
          const group_id = products[0].productable_id;

          if (this.groups.some(group => group.id == group_id)) {
            this.$emit('setGroup', group_id)
            this.$emit('close');
          } else {
            try {
              this.isBusy = true;
              await joinGroupBySubscribe(this.currentUserSubscription.pooled_subscription_id)
              await this.GET_GROUPS();

              this.$emit('setGroup', group_id)
              this.$emit('close');
            } catch (err) {
              console.log('err', err)
            }
            finally {
              this.isBusy = false;
            }
          }
        }
      }
    },
    async pay() {
      try {
        const currentPooled = this.pooled.find(el => el.name.toLowerCase() === this.name.toLowerCase())
        if (!currentPooled) return;

        const { data } = await createPooledSubscribe({
          subscribe_id: currentPooled.id,
        })

        if (data.success) {
          window.location.href = data.data.confirmation_url;
        } else {
          this.$root.$emit('v-modal', {
            text: 'Ошибка при создании платежа: [success: false]',
            error: true,
          });
        }
      } catch (err) { console.log('err', err) }
    }
  },
}
</script>


<style lang="scss">
.support b {
  font-weight: bold;
  text-shadow: 1px 0 #757575;
}
a.v-link {
  color:rgb(88, 157, 220);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>

<style lang="scss" scoped>
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #ffffff55;
}

.expert-text {
  font-size: 17px;
  line-height: 1.2;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  height: 140px;
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
}

.support {
  width: 900px;
  max-height: 95vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -5px black;
  padding: 50px;
  overflow-y: auto;
  position: relative;
  background: #f1f1f1 url('/images/modal-bg.jpg') repeat 50%;

  p,ul {
    margin-bottom: 20px;
    line-height: 1.3;
    list-style: disc inside;

    &.birthday {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: bold;
    text-shadow: 1px 0 #757575;
  }

  &__close {
    font-size: 34px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    transform: scaleY(0.8);
    right: 18px;
  }
}

.select {
  padding-top: 20px;

  .select__custom {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: 25px;
    border: 1px solid #696969;
    background: #fff;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    max-width: 230px;
    padding: 0 20px;
    height: 36px;
    transition: border .2s linear;

    &.error {
      border: 2px solid red !important;
    }

    input {
      border: none;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      text-align: center;

      &::placeholder {
        font-size: 16px;
        font-weight: 600;
        color: #7e7e7e;
      }
    }
  }

  &.birthday {
    padding: 0;
    span.sum {
      display: none;
    }
  }
}

</style>
