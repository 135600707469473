<template>
  <div class="rating-section relative pt-10">
    <div class="rating-section__bottom"></div>
    <div class="m-container mx-auto py-10">
      <div class="flex justify-between items-center h-24 mb-10">
        <div class="relative inline-flex mb-10">
          <img src="@/assets/marathon/rating.svg" alt="">
          <div class="absolute text-4xl whitespace-no-wrap rating-section__title v-text--white">{{ $t('marathon.rating') }}</div>
        </div>
        <div class="flex items-start w-4/12">
          <img src="@/assets/marathon/info.svg" alt="">
          <div class="v-text--white text-sm opacity-75" v-if="!profile.marathon_is_available"><a class="cursor-pointer link" @click="createMarathonPayment">{{ $t('marathon.pay_action') }}</a> {{ $t('marathon.answer_3') }}</div>
          <div class="v-text--white text-sm opacity-75" v-else>{{ $t('marathon.online_update') }}</div>
        </div>
      </div>

      <div :class="{ 'table-disabled': !profile.marathon_is_available }">
        <div class="rating-section__tabs flex justify-center">
          <div class="rating-section__tab mr-5" :class="{ active: mode === 'teacher' }" @click="mode = 'teacher'">
            <div class="text-lg">{{ $t('marathon.top_teachers') }}</div>
            <div class="text-xs">{{ $t('marathon.in_marathon') }} {{ info.teachers_count }}</div>
          </div>
          <div class="rating-section__tab ml-5" :class="{ active: mode === 'student' }" @click="mode = 'student'">
            <div class="text-lg">{{ $t('marathon.top_students') }}</div>
            <div class="text-xs">{{ $t('marathon.in_marathon') }} {{ info.students_count }}</div>
          </div>
        </div>


        <div class="mt-10" v-if="mode === 'teacher'">
          <!-- Header -->
          <div class="flex v-text--white text-sm opacity-75 mb-5">
            <div class="w-1/12 px-2"></div>
            <div class="w-4/12 px-2"></div>
            <div class="w-2/12 px-2">{{ $t('marathon.city') }}</div>
            <div class="w-2/12 px-2">{{ $t('marathon.school') }}</div>
            <div class="w-1/12 px-2">{{ $t('marathon.num_of_students') }}</div>
            <div class="w-2/12 px-2">{{ $t('marathon.num_of_words') }}</div>
          </div>

          <!-- Body -->
          <div class="flex flex-col">
            <div class="flex v-text--white py-4 cursor-pointer rating-section__row" v-for="(teacher, index) in teachers" :key="index">
              <div class="w-1/12 px-2 flex justify-start">{{index + 1}}</div>
              <div class="w-4/12 px-2 text-md">{{ teacher.surname }} {{ teacher.name }}</div>
              <div class="w-2/12 px-2 text-sm opacity-75">{{ teacher.city }}</div>
              <div class="w-2/12 px-2 text-sm opacity-75">{{ teacher.school }}</div>
              <div class="w-1/12 px-2 text-sm opacity-75">{{ teacher.students_count || 0 }}</div>
              <div class="w-2/12 px-2 text-sm opacity-75">{{ teacher.learn_words }}</div>
            </div>
          </div>
        </div>
        <div class="mt-10" v-else>
          <!-- Header -->
          <div class="flex v-text--white text-sm opacity-75 mb-5">
            <div class="w-1/12 px-2"></div>
            <div class="w-4/12 px-2"></div>
            <div class="w-2/12 px-2">{{ $t('marathon.city') }}</div>
            <div class="w-3/12 px-2">{{ $t('marathon.school') }}</div>
            <div class="w-2/12 px-2">{{ $t('marathon.num_of_words') }}</div>
          </div>

          <!-- Body -->
          <div class="flex flex-col">
            <div class="flex v-text--white py-4 cursor-pointer rating-section__row" v-for="(student, index) in students" :key="index">
              <div class="w-1/12 px-2 flex justify-start">{{index + 1}}</div>
              <div class="w-4/12 px-2 text-md">{{ student.surname }} {{ student.name }}</div>
              <div class="w-2/12 px-2 text-sm opacity-75">{{ student.city }}</div>
              <div class="w-3/12 px-2 text-sm opacity-75">{{ student.school }}</div>
              <div class="w-2/12 px-2 text-sm opacity-75">{{ student.learn_words }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStudentsRating, getTeachersRating, payMarathon } from '../../api/marathon';
export default {
  props: ['info'],
  data: () => ({
    mode: 'teacher',
    teachers: [],
    students: [],
  }),
  async mounted() {
    const { data: studentsData } = await getStudentsRating();
    const { data: teachersData } = await getTeachersRating();

    this.students = studentsData.data;
    this.teachers = teachersData.data;

    console.log('data', studentsData, teachersData);
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
    })
  },
  methods: {
    async createMarathonPayment() {
      try {
        if (this.profile.marathon_is_available) return;

        const { data } = await payMarathon();
        window.location.href = data.data.confirmation_url;
      } catch (err) {
        console.log('Payment create error; Type: marathon; err: ', err);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.table-disabled {
  filter: blur(7px);
  pointer-events: none;
}

.rating-section {
  background-color: #009EE0;

  &__row {
    border-bottom: 1px solid #fff;
    transition: all .2s linear;

    &:hover {
      background-color: #EC591D;
    }
  }

  &__tab {
    border: 1px solid #A4DCF1;
    background-color:  #009EE0;
    // padding: 20px 125px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: all .2s linear;
    position: relative;

    div:nth-child(2) {
      opacity: 0.5;
    }

    &:hover {
      filter: brightness(1.1);
    }

    &.active {
      background-color: #0AA6E0;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #A4DCF1;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 90%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #0AA6E0;
      }

    }
  }

  &__title {
    bottom: 25px;
    right: 25px;
    transform: translateX(100%);
    background-color: #009EE0;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    transform: translateY(100%);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: 0;
      transform: scaleY(-1);
      border-bottom: 50px solid #009EE0;
      border-left: 400px solid transparent;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      transform: scaleY(-1);
      border-bottom: 50px solid #009EE0;
      border-right: 400px solid transparent;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    border-bottom: 50px solid #009EE0;
    border-left: 400px solid transparent;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    transform: translateY(-100%);
    border-bottom: 50px solid #009EE0;
    border-right: 400px solid transparent;
  }
}

.link {
  text-decoration: underline;
}
</style>
