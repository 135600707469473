export default {
	state: {
		// Мета данные заданий
		games: [
			{
				name: 'Remember',
				slug: 'remember',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/remember',
				activeColor: '#E96779',
			},
			{
				name: 'FindWord',
				slug: 'find_words',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/find_words',
				activeColor: '#FFDD9D',
			},
			{
				name: 'FindDefinition',
				slug: 'find_definition',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/find_definition',
				activeColor: '#FDB74F',
			},
			{
				name: 'Quiz',
				slug: 'quiz',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/quiz',
				activeColor: '#0000ff',
			},
			{
				name: 'Listen',
				slug: 'listen',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/listen',
				activeColor: '#D879F4',
			},
			{
				name: 'Match',
				slug: 'find_word',
				isGame: true,
				icon: '/images/findCouple.png',
				icon_base: '/images/lesson/icons/find_word',
				activeColor: '#FDB74F',
			},
			{
				name: 'Flashcard',
				slug: 'learn_word',
				isGame: true,
				icon: '/images/learnWordsLogo.png',
				icon_base: '/images/lesson/icons/learn_word',
				activeColor: '#2AA04B',
			},
			{
				name: 'Snake',
				slug: 'snake',
				isGame: true,
				icon: '/images/SnakeLogo.png',
				icon_base: '/images/lesson/icons/snake',
				activeColor: '#24D656',
			},
			{
				name: 'Unscramble',
				slug: 'scrabble',
				isGame: true,
				icon: '/images/scrambleLogo.png',
				icon_base: '/images/lesson/icons/scrabble',
				activeColor: '#EB5757',
			},
			{
				name: 'FillGame',
				slug: 'insert_missed',
				isGame: true,
				icon: '/images/fillInLogo.png',
				icon_base: '/images/lesson/icons/insert_missed',
				activeColor: '#025AEF',
			},
			{
				name: 'ListenType',
				slug: 'listen_type',
				isGame: true,
				icon: '/images/listenUp.png',
				icon_base: '/images/lesson/icons/listen_type',
				activeColor: '#00A447',
			},
			{
				name: 'TypeIn',
				slug: 'write_word',
				isGame: true,
				icon: '/images/putWordLogo.png',
				icon_base: '/images/lesson/icons/write_word',
				activeColor: '#FDB74F',
			},
			{
				name: 'FinalTest',
				slug: 'final_test',
				isTest: true,
				icon: '/images/test.png',
				icon_alter: '/images/testRed.png',
				icon_base: '/images/lesson/icons/remember',
				activeColor: '#EB5757',
			},
		],
		// Статус ожидания - если true, то экран сайта перекроет плашка ожидания
		busyState: false,
		showBottomBar: true,
		isNewMobileAppInUrl: false,
	},
	getters: {
		// Для наглядности
		isSiteBusy(state) {
			return state.busyState
		},
		isNewMobileApp(state) {
			return (process.env.MOBILE_APP_NEW === 'true' || state.isNewMobileAppInUrl) && window.isTrueMobile
		},
		games: (state) => state.games,
	},
	actions: {},
	mutations: {
		// Установить статус ожидания
		SET_BUSY_STATE: (state, action) => {
			state.busyState = action
		},
		SET_BOTTOMBAR_STATUS: (state, action) => {
			state.showBottomBar = action
		},
		SET_IS_NEW_MOBILE_APP_IN_URL: (state, action) => {
			state.isNewMobileAppInUrl = action
		},
	},
}
