<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.405 23.4328C14.1266 23.4328 12.3175 22.5336 10.8875 18.2328L10.1075 15.8928L7.76747 15.1128C3.47747 13.6828 2.57831 11.8736 2.57831 10.5953C2.57831 9.32776 3.47747 7.50776 7.76747 6.06693L16.965 3.00109C19.2616 2.23193 21.1791 2.45943 22.36 3.62943C23.5408 4.79943 23.7683 6.72776 22.9991 9.02443L19.9333 18.2219C18.4925 22.5336 16.6833 23.4328 15.405 23.4328ZM8.27664 7.61609C5.26497 8.62359 4.19247 9.81526 4.19247 10.5953C4.19247 11.3753 5.26497 12.5669 8.27664 13.5636L11.0066 14.4736C11.245 14.5494 11.44 14.7444 11.5158 14.9828L12.4258 17.7128C13.4225 20.7244 14.625 21.7969 15.405 21.7969C16.185 21.7969 17.3766 20.7244 18.3841 17.7128L21.45 8.51526C22.0025 6.84693 21.905 5.48193 21.2008 4.77776C20.4966 4.07359 19.1316 3.98693 17.4741 4.53943L8.27664 7.61609Z"
      fill="white"
    />
    <path
      d="M10.9525 15.5993C10.7467 15.5993 10.5408 15.5235 10.3783 15.361C10.0642 15.0468 10.0642 14.5268 10.3783 14.2127L14.2567 10.3235C14.5708 10.0093 15.0908 10.0093 15.405 10.3235C15.7192 10.6377 15.7192 11.1577 15.405 11.4718L11.5267 15.361C11.375 15.5235 11.1583 15.5993 10.9525 15.5993Z"
      fill="white"
    />
  </svg>
</template>
