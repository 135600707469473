import ChatController from '@/controllers/chat/ChatController.js'
import {
	getStudentData,
	getGroups,
	updateProfileData,
	setLocale,
	auth,
	register,
	getNotificationsCount,
	getSubjects,
	getProfileSettings,
	setProfileSettings,
	getStudentRatingStars,
} from '@/api/user.js'
import flutterBridge from '@/helpers/flutterBridge.js'
import router from '@/router/index.js'
import { clearLocalStorageButLessonTimers } from '@/helpers.js'

export default {
	state: {
		profile: {},
		groups: null,
		isTempTeacher: false,
		tempTeacherData: {},
		token: null,
		notificationsCount: 0,
		subjects: [],
		chatController: null,
		settings: {},
		ratingStars: {},
	},
	getters: {
		isProfileLoaded: (state) => !!state.profile,
		isProfileLoadedFullCheck: (state) => !!state.profile && state.profile?.id,
		isTeacher: (state) => state.isTempTeacher,
		profile: (state) => state.profile,
		settings: (state) => state.settings,
		userGroups: (state) => state.groups,
		chatController: (state) => state.chatController,
	},
	actions: {
		UPDATE_SETTINGS: async ({ commit }, payload) => {
			try {
				const { data } = await setProfileSettings(payload)
				commit('SET_SETTINGS', data.data || {})
			} catch (error) {
				console.log('error', error)
			}
		},
		GET_SETTINGS: async ({ commit }) => {
			try {
				const { data } = await getProfileSettings()
				commit('SET_SETTINGS', data.data || {})
			} catch (error) {
				console.log('error', error)
			}
		},
		AUTH: async ({ commit, dispatch }, { slug, id, group_id, onlyteacher, ...payload }) => {
			try {
				const { data } = await auth(payload)

				if (data.data.role === 'teacher') {
					commit('SET_TEACHER', { token: data.data.access_token, id: data.data.teacher_id })
				} else {
					if (onlyteacher) {
						this.emitter.emit('v-modal', {
							text: 'Копирование урока доступно только для учителей!',
							error: true,
						})
					}
					localStorage.setItem('token', data.data.access_token)
					await dispatch('GET_PROFILE_DATA')

					if (slug && id) {
						dispatch('GET_CURRENT_LESSON_SLUG', { slug, id, group_id })
					}
				}
			} catch (err) {
				throw err
			}
		},
		REGISTER: async ({ commit, dispatch }, { slug, id, group_id, onlyteacher, ...payload }) => {
			let profileData = null

			try {
				const { data } = await register(payload)
				profileData = data
			} catch (err) {
				throw err
			}

			if (profileData.data.role === 'teacher') {
				commit('SET_TEACHER', { token: profileData.data.access_token, id: profileData.data.teacher_id })
			} else {
				if (onlyteacher) {
					return this.emitter.emit('v-modal', {
						text: 'Копирование урока доступно только для учителей!',
						error: true,
					})
				}
				localStorage.setItem('token', profileData.data.access_token)
				await dispatch('GET_PROFILE_DATA')

				if (slug && id) {
					dispatch('GET_CURRENT_LESSON_SLUG', { slug, id, group_id })
				}
			}
		},
		GET_PROFILE_DATA: async ({ commit, dispatch }, force = false) => {
			try {
				console.log('GET PROFILE DATA')
				const data = await getStudentData()
				console.log(data)
				flutterBridge.callService('FlutterAccountIdChannel', data.data.id)
				console.log('PROFI', data)

				console.log('APP VERSION', window.flutterAppVersion, data.data.current_app_version_ios)
				if (window.flutterAppVersion < data.data.current_app_version_ios) {
					console.log('VERSION MISMATCH')
					router.push({ name: 'app-update' })
					return false
				}

				console.log(data.data)
				commit('SET_PROFILE_DATA', data.data)

				if (!force) {
					await dispatch('GET_NOTIFICATIONS_COUNT')
					await dispatch('GET_SUBJECTS')
					await dispatch('GET_SETTINGS')
				}

				localStorage.removeItem('teacher_id')
				localStorage.removeItem('teacher-token')
				localStorage.removeItem('slug-data')
				return true
			} catch (err) {
				console.log('Profile catch err', err)
			} finally {
				commit('SET_BUSY_STATE', false)
			}
		},
		GET_SUBJECTS: async ({ commit }) => {
			try {
				const { data } = await getSubjects()
				commit('SET_SUBJECTS', data.data)
			} catch (err) {
				console.log('err', err)
			}
		},
		GET_NOTIFICATIONS_COUNT: async ({ commit, state }) => {
			const { data } = await getNotificationsCount()
			commit('SET_NOTIFICATIONS_COUNT', data.data)
		},
		GET_GROUPS: async ({ commit, state }) => {
			try {
				const { data } = await getGroups(state.profile.student_id)
				commit('SET_GROUPS', data.data)
			} catch (err) {}
		},
		SET_USER_LOCALE: async ({ commit }, locale) => {
			try {
				const { data } = await setLocale(locale)
				console.log('lcoale resp', data)
				commit('SET_USER_LOCALE', locale)
			} catch (err) {}
		},
		UPDATE_STUDENT_INFO: async ({ commit }, payload) => {
			try {
				commit('SET_BUSY_STATE', true)
				const { data } = await updateProfileData(payload)
				commit('UPDATE_STUDENT_INFO', data.data)
			} catch (err) {
			} finally {
				commit('SET_BUSY_STATE', false)
			}
		},
		UPDATE_RATING_STARS: async ({ commit, state }, { date_to = null, date_from = null, country = null }) => {
			try {
				const { data } = await getStudentRatingStars(state.profile.id, date_from, date_to, country)
				commit('SET_RATING_STARS', data)
			} catch (e) {
				console.log(e.message)
			}
		},
	},
	mutations: {
		SET_SETTINGS: (state, settings) => {
			state.settings = settings
		},
		OPEN_SUBSCRIPTION_ACCESS: (state) => {
			if (state.profile) {
				state.profile.subscriptionAvailability = true
			}
		},
		LOGOUT: (state) => {
			clearLocalStorageButLessonTimers()
			state.profile = null
		},
		SET_NOTIFICATIONS_COUNT: (state, count) => {
			state.notificationsCount = count
		},
		SET_TEACHER: (state, { token, id }) => {
			// localStorage.setItem('teacher-token', token);
			// localStorage.setItem('teacher_id', id);
			state.isTempTeacher = true
			state.tempTeacherData = { token, id }
		},
		UPDATE_STUDENT_INFO: (state, profile) => {
			state.profile = {
				...state.profile,
				...profile,
			}
		},
		SET_PROFILE_DATA: (state, profile) => {
			state.profile = profile
			// state.chatController = new ChatController(state.profile)
			// state.chatController.loadUnreadCount()
		},
		SET_GROUPS: (state, groups) => {
			state.groups = groups
		},
		SET_USER_LOCALE: (state, locale) => {
			state.profile.locale = locale
		},
		SET_SUBJECTS: (state, subjects) => {
			state.subjects = subjects
		},
		SET_RATING_STARS: (state, data) => {
			state.ratingStars = data
		},
	},
}
