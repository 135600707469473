<template>
	<transition name="fade" mode="out-in">
	  <div class="v-modal" v-if="show" @click.self="show = false">
  		<div class="v-modal__wrapper">
  			<img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="show = false">
        <div class="relative">
          <img class="background-banner" src="@/assets/banner.png" alt="">
          <div class="button-banner v-clickable cursor-pointer" @click="makePayment">Хочу годовую подписку всего за 715 рублей</div>
        </div>
  		</div>
  	</div>
	</transition>
</template>

<script>
import { createSubscribe } from '@/api/payment.js';
import { ref, reactive } from "vue";
import useEventBus from "@/composables/useEventBus.js";

export default {
  name: 'banner-modal',
  setup() {
    const emitter = useEventBus();
    const show = ref(false);
    const state = reactive({params: {}});

    emitter.on('banner', (params = {}) => {
      show.value = true;
      state.params = { ...params };
    })

    const makePayment = async () => {
      try {
        const { data } = await createSubscribe({ period: 'P12M' });
        if (data.success) {
          window.location.href = data.data.confirmation_url;
        } else {
          emitter.emit('v-modal', {
            text: 'Ошибка при создании платежа: [success: false]',
            error: true,
          });
        }
      } catch (err) {
        console.log('err', err);
        emitter.emit('v-modal', {
          text: 'Ошибка при создании платежа: ' + JSON.stringify(err),
          error: true,
        });
      }
    }
    return {
      show,
      makePayment
    }
  },
};
</script>

<style lang="scss" scoped>

.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  form {
    width: 90%;
  }

  &__wrapper {
    // background-color: #fff;
    background: url(/images/auth-bg.jpg);
    background-size: cover;
    border-radius: 12px;

    width: 900px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;

    .background-banner {
      width: 100%;
    }
    .button-banner {
      position: absolute;
      bottom: 38px;
      left: 75px;
      width: 410px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 15px;
      background: #ea4f2e;
      border-radius: 25px;
      font-size: 16px;
    }

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }

    &.sm {
      width: 350px;
      min-height: 250px;
    }

    &.md {
      width: 450px;
      min-height: 250px;
    }

    &.lg {
      width: 600px;
      min-height: 350px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    cursor: pointer;
    z-index: 1;
  }
}

@media screen and (max-width: 700px) {
	.v-modal__form {
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.v-modal {
		padding: 25px;
	}
	input::placeholder {
		font-size: 16px;
	}
}
</style>
