<template>
	<div class="v-dropdown" :class="{ green, mobile, gray }">
		<div
			class="v-dropdown__main flex justify-between items-center relative"
			:class="{ expanded }"
			tabindex="0"
			@blur="blurWithTimeOut"
			@click="
				() => {
					!mobile ? (expanded ^= true) : null
					$emit('expanded', expanded)
				}
			"
		>
			<div :class="center ? 'mx-auto' : ''">
				<shortify class="mr-2" :value="getCurrentItem[selectValue]" :length="12" />
			</div>
			<svg :class="{ expanded }" width="15.5px" height="9.5px">
				<path
					fill-rule="evenodd"
					:stroke="'#141029'"
					stroke-width="1px"
					stroke-linecap="butt"
					stroke-linejoin="miter"
					:fill="'#141029'"
					d="M13.174,1.833 C12.997,1.664 12.710,1.663 12.533,1.833 L7.821,6.332 C7.645,6.501 7.356,6.501 7.179,6.332 L2.467,1.833 C2.290,1.663 2.003,1.664 1.826,1.833 C1.649,2.003 1.650,2.278 1.826,2.447 L6.538,6.946 C6.803,7.200 7.152,7.327 7.500,7.327 C7.848,7.327 8.197,7.200 8.462,6.946 L13.174,2.447 C13.352,2.278 13.352,2.003 13.174,1.833 Z"
				/>
			</svg>

			<div v-if="mobile" style="position: absolute; opacity: 0; top: 0; left: 0; width: 100%; height: 100%">
				<select
					ref="mobileSelect"
					name="select"
					class="w-full h-full"
					@change="handleGroupChange"
					@blur="expanded = false"
				>
					<option
						:value="item[selectKey]"
						:selected="currentSelected == item[selectKey]"
						v-for="(item, index) in options"
						:key="item[selectKey]"
					>
						{{ item[selectValue] }}
					</option>
				</select>
			</div>
		</div>
		<div class="v-dropdown__list" v-show="expanded && !mobile">
			<div
				class="v-dropdown__item"
				v-for="(item, index) in options"
				:key="item[selectKey]"
				@click="
					() => {
						currentSelected = item[selectKey]
						expanded = false
					}
				"
			>
				{{ item[selectValue] }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			type: Array,
			default: () => [{ key: 0, value: 'Показать все' }],
		},
		selected: {
			type: [Number, String],
			default: 0,
		},
		selectKey: {
			type: String,
			default: 'key',
		},
		selectValue: {
			type: String,
			default: 'value',
		},
		green: Boolean,
		gray: Boolean,
		center: Boolean,
		mobile: Boolean,
	},
	data: () => ({
		expanded: false,
	}),
	computed: {
		currentSelected: {
			get() {
				return this.selected
			},
			set(value) {
				this.$emit('update:selected', value)
				this.$emit('select')
			},
		},
		getCurrentItem() {
			return this.options.find((el) => el[this.selectKey] == this.currentSelected) || {}
		},
	},
	methods: {
		handleGroupChange(evt) {
			const id = parseInt(evt.target.value, 10)
			this.currentSelected = id
			this.expanded = false
		},
		blurWithTimeOut() {
			//иначе не срабатывает изменение значения
			setTimeout(() => (this.expanded = false), 100)
		},
	},
}
</script>

<style lang="scss" scoped>
.v-dropdown {
	width: 100%;
	max-width: 300px;
	position: relative;
	z-index: 10;

	&.mobile {
		max-width: unset;
	}

	&.gray {
		color: #959595;
	}

	&.gray &__main {
		border-color: #959595;
		border-width: 1px;
	}

	&.green {
		color: #27a34c;
	}

	&.green &__main {
		border-color: #27a34c !important;

		&.expanded {
			background: #27a34c;
		}
	}

	&.green &__list {
		border-color: #27a34c !important;
	}

	&.green &__item {
		&:hover {
			background: #27a34c;
		}
	}

	&__main {
		border-radius: 8px;
		padding: 8px 15px;
    background-color: #f0f1f5;
    border: 1px solid transparent;
		cursor: pointer;
		z-index: 2;
		position: relative;

		&.expanded {
      background-color: #ffffff;
      border: 1px solid #4579f5;
			color: #141029;
		}
	}

	&__list {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		max-width: 300px;
		border: 2px solid #f0f1f5;
		padding-top: 40px;
		border-radius: 22px;
		background: #fff;
		animation: show 0.2s linear;
		animation-fill-mode: forwards;
		overflow: hidden;

		max-height: 300px;
		overflow: auto;

		@keyframes show {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}

	&__item {
		padding: 5px 30px;
		cursor: pointer;
		transition: all 0.2s linear;
		position: relative;
		&:hover {
      color: #4579f5;
			background: #ecf7ff;
		}
	}
}

.discount-line {
	position: relative;

	&::after {
		position: absolute;
		top: 11px;
		left: -8px;
		width: 79%;
		height: 2px;
		background-color: red;
		transform: rotate(0deg);
		content: '';
	}
}

.discount-text {
	color: red;
	font-weight: bold;
	display: inline-block;
	text-align: center;
}

svg {
	transition: all 0.3s linear;
	&.expanded {
		transform: rotate(180deg);
	}
	&.expanded path {
		stroke: #141029;
	}
}

.v-price {
	position: relative;

	&__count {
		position: relative;
		&.discount::after {
			content: '';
			width: 120%;
			left: -10%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 2px;
			background-color: red;
		}
	}

	&__discount {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateX(94%) translateY(-30%);
		width: 115px;
		font-size: 13px;
		text-align: center;

		div:nth-child(1) {
			font-size: 16px;
			line-height: 1;
			font-weight: 200;
		}
		div:nth-child(2) {
			font-size: 12px;
			line-height: 1;
			font-weight: 200;
		}
	}
}
</style>
