<template>
  <div></div>
</template>

<script>
import {inviteStudentByCode} from "../api/user";

export default {
  name: "InviteStudentByCode",
  async mounted() {
    const response = await inviteStudentByCode(this.$route.params.code);

    if(response?.data?.success) {
      this.$router.push({ name:"home" })
    }
  }
}
</script>

