<template>
    <div class="v-modal">
        <div class="v-modal__wrapper">
            <div class="w-full flex items-center justify-between mb-5">
                <div class="v-text--bold text-lg">{{ $t('home.filter') }}</div>
                <img class="v-modal__close v-clickable" src="@/assets/close-xxxhdpi.png" alt="" @click="$emit('close')">
            </div>
            <div class="w-full mb-5">
                <div class="flex w-full mb-5 items-center">
                    <div class="v-text--bold mr-3">{{ $t('home.group') }}:</div>
                    <v-select green :options="groups" :selected="selectedGroup" mobile/>
                </div>
                <div class="flex w-full">
                    <div class="v-text--bold mr-3">{{ $t('statistics.status') }}:</div>
                    <div class="flex flex-wrap">
                        <div
                            @click="selectedStatus = item.key"
                            class="status-item mr-3 mb-2"
                            :class="{ active: selectedStatus === item.key }"
                            v-for="item in optionsStatus"
                            :key="item.key"
                        >{{ item.value }}</div>
                    </div>
                </div>
            </div>

            <div class="w-full flex justify-center">
                <div class="v-button v-button--gradient-red" @click="apply">{{ $t('home.apply') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import VSelect from '../Selector.vue';

export default {
    components: { VSelect },
    props: {
        currentStatus: Number,
        currentGroup: [Number, String],
        groups: {
            type: Array,
            default: () => ([]),
        }
    },
    created() {
        this.selectedStatus = this.currentStatus || 0;
        this.selectedGroup = this.currentGroup || 0;
    },
    data: () => ({
        selectedGroup: 0,
        selectedStatus: 0,
    }),
    watch: {
        currentGroup(value) {
            console.log('VALUE', value)
            this.apply();
        }
    },
    computed: {
        optionsStatus() {
            return [
                { key: 0, value: this.$t('home.all_sets') },
                { key: 1, value: this.$t('home.current') },
                { key: 2, value: this.$t('home.completed') },
                { key: 3, value: this.$t('home.expired') },
            ]
        },
    },
    methods: {
        apply() {
            this.$emit('filter', {
                selectedGroup: this.selectedGroup,
                selectedStatus: this.selectedStatus,
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.status-item {
    padding: 5px 15px;
    border-color: var(--v-blue);
    border-width: 2px;
    border-style: solid;
    border-radius: 25px;
    color: var(--v-blue);
    text-align: center;

    &.active {
        border-color: var(--v-green);
        color: #fff;
        background-color: var(--v-green);
    }
}

.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__wrapper {
    background-color: #fff;
    // background: url(/images/modal-pattern.png);
    // background-size: cover;
    border-radius: 12px;
    padding: 15px 10px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: 0;
    animation: showup .3s ease;
    animation-fill-mode: forwards;
    width: 90vw;

    @keyframes showup {
      0% { opacity: 0; transform: scale(0.5); }
      100% { opacity: 1; transform: scale(1); }
    }
  }

  &__close {
    width: 25px;
    cursor: pointer;
  }
}
</style>
