export default {
  personal_statistics: "Персональная статистика",
  status: "Статус",
  words_left:
    "Слов до перехода на новый уровень",
  words_learnt: "выучено слов",
  sets_completed: "пройдено уроков",
  superpower: "суперсила",
  your_average: "Твой средний результат прохождения тестов",
  superpowers: "сверхспособности",
  the_percentage:
    "На сколько процентов тебе удалось улучшить результаты своих тестов",
  newbie: 'Новичок',
  beginner: 'Практик',
  intermediate_user: 'Знаток',
  upper_intermediate_user: 'Мастер',
  advanced_user: 'Магистр',
  expert: 'Гроссмейстер',
  statistics: 'Статистика',
  realtime_rating_update: "Рейтинг обновляется в режиме реального времени",
  not_marathons: 'Нет активных марафонов'
}