<script setup>
import { onUpdated, toRef } from 'vue'

const props = defineProps({
	avatar: String,
	name: String,
	highlight: Boolean,
	room: HTMLElement,
	message: Array,
	unreadCount: Number,
})

const roomRef = toRef(props, 'room')
const emit = defineEmits(['click'])

onUpdated(() => {
	if (roomRef.value) {
		if (props.highlight) {
			roomRef.value.classList.add('highlighted')
			roomRef.value.style.backgroundColor = '#f2f2f2'
		} else {
			roomRef.value.classList.remove('highlighted')
			roomRef.value.style.backgroundColor = '#ffffff'
		}
	}
})
</script>

<template>
	<div
		class="chat-list__grid-item"
		:class="{
			highlighted: highlight,
			unread: !!props.message,
		}"
		@click="$emit('click')"
	>
		<div class="chat-list__grid-item__photo">
			<img :src="props.avatar" :alt="props.name" />
		</div>
		<div class="chat-list__grid-item__name">
			{{ props.name }}
		</div>
		<div v-if="unreadCount > 0" class="chat-list__grid-item__unread-count">
			{{ unreadCount }}
		</div>
		<!--        <div class="chat-list__grid-item__date">12.21</div>-->
	</div>
</template>
