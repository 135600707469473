<template>
    <div class="register-screen flex flex-col justify-center pt-10 pb-10">
        <img class="register-screen__bottom-decor" src="@/assets/mobile/decor_login_bottom.png" alt="">
        <img class="register-screen__top-decor" src="@/assets/mobile/decor_login_top.png" alt="">

        <div class="h-full flex flex-col items-center justify-center w-10/12 mx-auto" style="max-width: 400px">
<!--            <img class="splash-logo mb-5 text-center" src="/images/mobile/logo_brain.png" alt="">-->

            <v-select :options="roles" :selected="currentRole"  mobile gray center />
            <div class="v-text--red text-center w-full mt-1" v-if="isError('role')">{{ isError('role') }}</div>

            <input type="text" class="v-input mt-3 w-full text-center" placeholder="Имя" v-model="form.name" @input="errorNames.name = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('name')">{{ isError('name') }}</div>

            <input type="text" class="mt-3 v-input w-full text-center" placeholder="Фамилия" v-model="form.surname" @input="errorNames.surname = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('surname')">{{ isError('surname') }}</div>

            <input type="text" class="mt-3 v-input w-full text-center" placeholder="Город" v-model="form.city" @input="errorNames.city = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('city')">{{ isError('city') }}</div>

            <input type="text" class="mt-3 v-input w-full text-center" placeholder="Школа" v-model="form.school" @input="errorNames.school = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('school')">{{ isError('school') }}</div>

            <input type="email" class="mt-3 v-input w-full text-center" placeholder="E-mail" v-model="form.email" @input="errorNames.email = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('email')">{{ isError('email') }}</div>

            <input type="password" class="mt-3 v-input w-full text-center" placeholder="Пароль" v-model="form.password" @input="errorNames.password = null">
            <div class="v-text--red text-center w-full mt-1" v-if="isError('password')">{{ isError('password') }}</div>

            <div class="mt-4 flex w-full justify-center">
                <label class="text-center text-xs v-text--desc flex">
                    <input type="checkbox" class="mr-2 mt-1" v-model="form.accepted">
                    <div class="w-full text-center">
                        Заполняя данную форму даю согласие на сбор и обработку персональных данных
                        <div class="v-link-mobile mt-2" @click.stop.prevent="openLinkNative">Пользовательское соглашение</div>
                    </div>
                </label>
            </div>

            <!-- <div class="mb-5 v-text--red text-center w-full" v-html="errors" v-if="errors"></div> -->
            <div class="mb-5 mt-10 v-text--red text-center w-full mt-10" v-if="customError">Произошла ошибка, обратитесь в службу поддержки!</div>

            <div class="s-button s-button--orange mb-5 mt-10" @click="register" :disabled="!form.accepted">Зарегистрироваться</div>
        </div>

        <div class="flex flex-col items-center justify-center w-full mb-5">
            <div class="v-text--desc mb-2">Есть аккаунт?</div>
            <div class="v-text--bold v-text--gray" @click="$router.push({ name: 'login' })">Войти</div>
        </div>

        <div class="flex items-center justify-center absolute h-full w-full bg-black bg-opacity-50" v-if="isBusy">
            <loader />
        </div>
    </div>
</template>

<script>
import { register } from '../../api/user';
import VSelect from '../../components/Selector.vue';
import FlutterBridge from '../../helpers/flutterBridge';

export default {
    components: {
        VSelect
    },
    watch: {
        currentRole(value) {
            if (value === 0) {
                this.$root.$emit('v-modal', {
                    text: 'В данный момент функционал приложения доступен только ученикам. Пожалуйста, перейдите на наш сайт.',
                    size: 'sm',
                    buttons: [
                        {
                            text: 'Отмена',
                            class: 'v-button v-button--gradient-red',
                            callback: () => { this.$root.$emit('v-modal', { close: true }) }
                        },
                        {
                            text: 'Перейти',
                            class: 'v-button v-button--gradient-red',
                            callback: () => {
                                let VZNANIYA_URL = import.meta.env.NODE_ENV == 'production' ? import.meta.env.VITE_APP_URL ? import.meta.env.VITE_APP_URL : 'https://vznaniya.ru' :  'https://develop.vznaniya.ru';
                                FlutterBridge.callService('FlutterLinkChannel', VZNANIYA_URL);
                                // window.FlutterLinkChannel && window.FlutterLinkChannel.postMessage(VZNANIYA_URL);
                            }
                        }
                    ],
                });
                this.currentRole = 1;
            }
            this.errorNames.role = null;
        }
    },
    data: () => ({
        errors: null,
        form: {
            name: '',
            surname: '',
            city: '',
            school: '',
            email: '',
            password: '',
            role: 'student',
            accepted: true,
        },
        isBusy: false,
        customError: false,
        errorNames: {},
        currentRole: -1,
        roles: [
            { key: -1, value: 'Кто вы?', role: '' },
            { key: 0, value: 'Учитель', role: 'teacher' },
            { key: 1, value: 'Ученик', role: 'student' }
        ]
    }),
    methods: {
        getRole(roleKey) {
            return (this.roles.find(el => el.key == roleKey) || {}).role;
        },
        openLinkNative() {
            FlutterBridge.callService('FlutterLinkChannel', 'https://vznaniya.ru/terms-and-politics');
            // window.FlutterLinkChannel && window.FlutterLinkChannel.postMessage('https://vznaniya.ru/terms-and-politics');
        },
        isError(name) {
            return this.errorNames[name];
        },
        async register() {
            if (!this.form.accepted) return;

            this.resetErrors();

            try {
                this.isBusy = true;

                const { data } = await register({ ...this.form, role: this.getRole(this.currentRole), password_confirmation: this.form.password });
                localStorage.setItem('token', data.data.access_token);
                FlutterBridge.callService('FlutterTokenChannel', data.data.access_token);
                // window.FlutterTokenChannel && window.FlutterTokenChannel.postMessage(data.data.access_token);

                this.currentLanguageKey = data.data.locale || 'ru';
                this.$root.$i18n.locale = this.currentLanguageKey;

                FlutterBridge.callService('FlutterLangChannel', this.currentLanguageKey);
                // window.FlutterLangChannel && window.FlutterLangChannel.postMessage(this.currentLanguageKey);

                const redirect = await this.$store.dispatch('GET_PROFILE_DATA');

                FlutterBridge.callService('FlutterPaymentChannel', 'check');
                // window.FlutterPaymentChannel && window.FlutterPaymentChannel.postMessage('check');

                if (redirect) {
                    this.$router.push({ name: 'home' })
                }
            } catch (err) {
                console.dir(err);
                this.handleError(err);
            } finally {
                this.isBusy = false;
            }
        },
        handleError(err) {
            if (err.response && err.response.data && err.response.data.errors) {
                this.errors = Object.values(err.response.data.errors).map(el => el[0]).join('<br>')
                this.errorNames = err.response.data.errors;
                for (let key in this.errorNames) {
                    this.errorNames[key] = this.errorNames[key][0];
                }
            } else {
                this.customError = true;
            }
        },
        resetErrors() {
            this.errors = null;
            this.errorNames = {};
            this.customError = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.register-screen {
    min-height: 100vh;

    &__bottom-decor,
    &__top-decor {
        position: absolute;
        width: 30vw;
        max-width: 80px;
        pointer-events: none;
    }

    &__bottom-decor {
        bottom: 0;
        left: 0;
    }
    &__top-decor {
        top: 0;
        right: 0;
    }

    .splash-logo {
        width: 100px;
        mix-blend-mode: multiply;
    }
}
</style>
