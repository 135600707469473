// import VueRouter from 'vue-router';
import { createRouter, createWebHistory  } from "vue-router"

import Home from '../screens/Home.vue';
import Profile from '../screens/Profile.vue';
import Groups from '../screens/Groups.vue';
import Statistics from '../screens/Statistics.vue';
import Chat from '../screens/Chat.vue';
import Marathon from '../screens/marathon/Marathon.vue';
import Notifications from '../screens/Notifications.vue';
import Tariffs from '../screens/Tariffs.vue';
import Print from '../screens/PrintWords.vue';

const Assessment = defineAsyncComponent(() => import('@/screens/Assessment/index.vue'));
import Lesson from '../screens/Lesson.vue';

import Game from '@/screens/Game.vue';
const VideoLesson = defineAsyncComponent(() => import('@/screens/VideoLesson.vue'));

import Login from '../screens/mobile/Login.vue';
import Register from '../screens/mobile/Register.vue';
import Splash from '../screens/mobile/Splash.vue';
import Reset from '../screens/mobile/ResetPassword.vue';
import AppUpdate from '../screens/mobile/NeedsUpdate.vue';
import ActivityFrame from '../screens/mobile/ActivityFrame.vue';
import LexicalLesson from '../screens/LexicalLesson.vue';
const challenge = defineAsyncComponent(() => import("@/screens/Challenge.vue"));
import InviteStudentByCode from "../components/inviteStudentByCode.vue";
import {defineAsyncComponent} from "vue";
// const InviteStudentByCode = () => import("../components/inviteStudentByCode.vue")

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/groups',
    name: 'groups',
    component: Groups,
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/lesson/:id',
    name: 'lesson',
    component: LexicalLesson,
  },
  {
    path: '/assessment/:id',
    name: 'assessment',
    component: Assessment,
  },
  {
    path: '/video/:id',
    name: 'video',
    component: VideoLesson,
  },
  {
    path: '/challenge/:id',
    name: 'challenge',
    component: challenge,
  },
  {
    path: '/lesson/:id/:gname',
    name: 'games',
    component: Game,
    meta: { nobar: true }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
  },
  {
    path: '/print/:id?/:group_id?',
    name: 'print',
    component: Print,
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { auth: true },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: { auth: true },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { auth: true },
  },
  {
    path: '/splash',
    name: 'splash',
    component: Splash,
    meta: { auth: true },
  },
  {
    path: '/app-update',
    name: 'app-update',
    component: AppUpdate,
    meta: { auth: true },
  },
  {
    path: '/activity-frame',
    name: 'activity-frame',
    component: ActivityFrame,
    meta: { frame: true },
  },
  {
    path: '/invite/:code',
    name: 'invite',
    component: InviteStudentByCode
  },

  // { path: '*', name: 'not-found' }
]

const router = createRouter({
  history: createWebHistory('home'),
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
});

export default router;
